import Jsona, { SwitchCaseJsonMapper } from 'jsona'
import { irwin } from 'config'
import { httpClient } from './httpClient'
import { snakeCase } from 'change-case-object'

const deserializer = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper({ switchChar: '_' }),
})
const v1Deserializer = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper({ switchChar: '-' }),
})

function client() {
  return httpClient(irwin.baseUrl)
}

async function saveJob(job) {
  return job.id ? updateJob(job) : createJob(job)
}

async function createJob(job) {
  const response = await client().post('v1/platform_jobs', { platform_job: job })

  return {
    data: v1Deserializer.deserialize(response),
    meta: response.meta,
  }
}

async function updateJob(job) {
  const response = await client().patch(`v1/platform_jobs/${job.id}`, { platform_job: job })

  return {
    data: v1Deserializer.deserialize(response),
    meta: response.meta,
  }
}

async function saveJobOverride(params) {
  return params.id ? await updateJobOverride(params) : await createJobOverride(params)
}

async function createJobOverride(params) {
  const response = await client().post(`v2/ds1/job_overrides`, { ...snakeCase(params) })

  return {
    data: deserializer.deserialize(response),
  }
}
async function updateJobOverride(params) {
  const response = await client().patch(`v2/ds1/job_overrides/${params.id}`, {
    ...snakeCase(params),
  })
  return {
    data: deserializer.deserialize(response),
  }
}

async function deleteJobOverride({ id: jobId }) {
  return await client().delete(`v2/ds1/job_overrides/${jobId}`)
}

export { saveJob, createJob, updateJob, saveJobOverride, deleteJobOverride }
