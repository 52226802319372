import React from 'react'
import { useHistory } from 'react-router-dom'
import { Typography } from '@the-platform-group/component-library'
import { Grid } from '@the-platform-group/component-library/Layout'
import { Button } from '@the-platform-group/component-library/Button'
import { Home as HomeIcon } from '@the-platform-group/component-library/Icons'

function NotFound() {
  const history = useHistory()

  return (
    <Grid container alignItems="center" justifyContent="center" direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6">The page you are looking for does not exist.</Typography>
      </Grid>
      <Grid item>
        <Button variant="outlined" endIcon={<HomeIcon />} onClick={() => history.push('/')}>
          Back to Home
        </Button>
      </Grid>
    </Grid>
  )
}

export default NotFound
