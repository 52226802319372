import React from 'react'
import { TextField } from '@the-platform-group/component-library'
import { InputAdornment } from '@the-platform-group/component-library'
import { Search as SearchIcon } from '@the-platform-group/component-library/Icons'

import { CircularProgress } from '@mui/material'
import { useSurveillanceContext } from '../hooks/SurveillanceContext'

const SearchBar = ({ isLoading, disabled }) => {
  const { searchTerm, handleSearchTermChange } = useSurveillanceContext()

  return (
    <TextField
      variant="outlined"
      onChange={e => handleSearchTermChange(e.target.value)}
      value={searchTerm}
      placeholder="Type to search"
      margin="none"
      name="search-bar"
      hiddenLabel
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        ...(isLoading && {
          endAdornment: (
            <InputAdornment position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          ),
        }),
      }}
      fullWidth
      disabled={disabled}
    />
  )
}

export default SearchBar
