import { useState } from 'react'
import { useInfiniteQuery } from 'react-query'

import { getPlatformOrganizations } from 'services/organizations'
import { getPeople } from 'services/people'

import debounce from 'utils/debounce'

const PAGE_SIZE = 50
const DEBOUNCE_TIME = 150

const SERVICE = {
  ORGANIZATION: debounce(getPlatformOrganizations, DEBOUNCE_TIME),
  PERSON: debounce(getPeople, DEBOUNCE_TIME),
}

const initialOptions = {
  term: '',
  pageOffset: 0,
}

const usePlatformData = ({ type }) => {
  const [queryParams, setQueryParams] = useState(initialOptions)

  const {
    data: { data, itemCount },
    isFetching,
    fetchNextPage: handlePagination,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [`getPlatform${type}`, queryParams],
    ({ queryKey: [, params], pageParam = 0 }) => {
      return SERVICE[type](pageParam, PAGE_SIZE, params.term, {
        sort: 'updated_at_desc',
      })
    },
    {
      getNextPageParam: lastGroup => lastGroup.meta['page-offset'] + 1,
      refetchOnWindowFocus: false,
      placeholderData: { pages: [] }, // default value
      select: data => {
        const transformedData = { data: [], itemCount: 0 }
        if (data.pages.length) {
          transformedData.data = data.pages.reduce((list, d) => list.concat(d.data), [])
          transformedData.itemCount = data.pages[0].meta['item-count']
        }
        return transformedData
      },
    },
  )

  const handleSearchTerm = ({ target: { value } }) => {
    setQueryParams({ term: value })
  }

  return {
    canPaginate: data.length < itemCount,
    data,
    term: queryParams.term,
    handleSearchTerm,
    handlePagination,
    isLoading: isFetching || isFetchingNextPage,
  }
}

export default usePlatformData
