import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Select } from '@the-platform-group/component-library/Select'
import { TextField } from '@the-platform-group/component-library'
import { Button } from '@the-platform-group/component-library/Button'
import { Grid } from '@the-platform-group/component-library/Layout'
import LocationTypeahead from '@the-platform-group/component-library/LocationTypeahead'
import Checkbox from 'pages/People/components/Checkbox' // move to general components
import ORGANIZATION_TYPES from 'constants/organizationTypes'
import OrganizationSelect from 'components/OrganizationSelect'
import useContactImportForm from './hooks/useContactImportForm'
import IMPORT_TYPES from 'constants/importTypes'
import { Focus, FocusChips } from 'shared/Focus'
import { FormPaper } from 'components/Paper'
import { H2 } from 'components/Typography'
import JobFunctionsSelect from 'components/Select/JobFunctionsSelect'
import useFocuses from 'hooks/useFocuses'

const ContactImportForm = ({ match, history }) => {
  const {
    params: { id },
  } = match
  const {
    contact,
    handleContactChange,
    handleCheckboxChange,
    institution,
    contactFocuses,
    institutionFocuses,
    handleInstitutionChange,
    customer,
    address,
    identification,
    handleSave,
    handleAddressSelect,
    handleAddressChange,
    selectedOrganization,
    handleOrganizationSelect,
    handleFocusChange,
    handleFocusDelete,
    handleFocusCopy,
  } = useContactImportForm(id, history)

  return (
    <Fragment>
      <h2>{`Publish New Person (by ${customer.name})`}</h2>
      <ContactForm
        contact={contact}
        onContactChange={handleContactChange}
        onChecked={handleCheckboxChange}
        focuses={contactFocuses}
        onFocusChange={handleFocusChange}
        onFocusDelete={handleFocusDelete}
        onFocusCopy={handleFocusCopy}
        selectedOrganization={selectedOrganization}
      />
      <InstitutionForm
        identification={identification}
        institution={institution}
        onInstitutionChange={handleInstitutionChange}
        address={address}
        onAddressSelect={handleAddressSelect}
        onAddressChange={handleAddressChange}
        onOrganizationSelect={handleOrganizationSelect}
        selectedOrganization={selectedOrganization}
        focuses={institutionFocuses}
        onFocusChange={handleFocusChange}
        onFocusDelete={handleFocusDelete}
      />
      <Button color="primary" onClick={() => handleSave({ back: true })}>
        Save and Index
      </Button>
      <Button
        color="primary"
        onClick={handleSave}
        sx={{
          ml: 2,
        }}
      >
        Save and Edit
      </Button>
    </Fragment>
  )
}

const ContactForm = ({
  contact,
  onContactChange,
  onChecked,
  focuses,
  onFocusChange,
  onFocusDelete,
  onFocusCopy,
  selectedOrganization,
}) => {
  const {
    firstName,
    lastName,
    bio,
    email,
    title,
    phone,
    active,
    retailDiscretionaryFlag,
    jobFunctions,
  } = contact
  const { focuses: focusDefinitions } = useFocuses()

  return (
    <FormPaper>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <H2>Contact Information</H2>
          <TextField
            value={firstName || ''}
            label="First Name"
            name="firstName"
            onChange={onContactChange}
            sx={{
              mt: 1,
              mb: 0.5,
            }}
            fullWidth
          />
          <TextField
            value={lastName || ''}
            label="Last Name"
            name="lastName"
            onChange={onContactChange}
            sx={{
              mt: 1,
              mb: 0.5,
            }}
            fullWidth
          />
          <TextField
            value={bio || ''}
            label="Bio"
            name="bio"
            onChange={onContactChange}
            sx={{
              mt: 1,
              mb: 0.5,
            }}
            fullWidth
            multiline
          />
        </Grid>
        <Grid item xs={6}>
          <H2>Job Information</H2>
          <TextField
            value={title || ''}
            label="Title"
            name="title"
            onChange={onContactChange}
            sx={{
              mt: 1,
              mb: 0.5,
            }}
            fullWidth
          />
          <JobFunctionsSelect value={jobFunctions} onChange={onContactChange} />
          <TextField
            value={email || ''}
            label="Email"
            name="email"
            onChange={onContactChange}
            sx={{
              mt: 1,
              mb: 0.5,
            }}
            fullWidth
          />
          <TextField
            value={phone || ''}
            label="Phone"
            name="phone"
            onChange={onContactChange}
            sx={{
              mt: 1,
              mb: 0.5,
            }}
            fullWidth
          />
          <Checkbox
            value={active}
            checked={active}
            label="Active"
            name="active"
            onChange={onChecked}
          />
          <Checkbox
            value={retailDiscretionaryFlag}
            checked={retailDiscretionaryFlag}
            label="Retail Discretionary"
            name="retailDiscretionaryFlag"
            onChange={onChecked}
          />
        </Grid>
        <Grid item xs={12}>
          <H2>
            Job Focus
            <Button
              sx={{
                ml: 2,
              }}
              disabled={!selectedOrganization}
              onClick={onFocusCopy}
            >
              Copy From Org
            </Button>
          </H2>
          <Focus
            focuses={focuses}
            onFocusChange={e => onFocusChange(e, IMPORT_TYPES.CONTACT)}
            categories={focusDefinitions.CATEGORIES}
          />
          <FocusChips
            focuses={focuses}
            handleFocusDelete={onFocusDelete}
            type={IMPORT_TYPES.CONTACT}
          />
        </Grid>
      </Grid>
    </FormPaper>
  )
}

const InstitutionForm = ({
  institution,
  address,
  onInstitutionChange,
  onAddressSelect,
  identification,
  onOrganizationSelect,
  selectedOrganization,
  onAddressChange,
  focuses,
  onFocusChange,
  onFocusDelete,
}) => {
  const { focuses: focusDefinitions } = useFocuses()
  const { name, description, type, assetUnderManagement, website, phone } = institution
  const disabled = !!identification

  return (
    <FormPaper>
      <h3>Institution Info</h3>
      <Grid container spacing={3}>
        {disabled ? (
          <PGLSelectedSection name={name} />
        ) : (
          <Fragment>
            <Grid item xs={12}>
              <div>
                <h4>Choose an existing Organization</h4>
                <OrganizationSelect
                  label="Organization"
                  organization={selectedOrganization || {}}
                  onChange={onOrganizationSelect}
                />
                <div>
                  <Button onClick={() => onOrganizationSelect()}>Unselect</Button>
                </div>
              </div>
              <div>
                {!selectedOrganization && (
                  <Fragment>
                    <h4>Or Create New Organization</h4>
                    <Grid container>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          p: 2,
                        }}
                      >
                        <TextField
                          value={name || ''}
                          label="Name"
                          name="name"
                          onChange={onInstitutionChange}
                          sx={{
                            mt: 1,
                            mb: 0.5,
                          }}
                          fullWidth
                        />
                        <Select
                          fullWidth
                          id="type"
                          name="type"
                          label="Type"
                          options={Object.keys(ORGANIZATION_TYPES).map(t => ORGANIZATION_TYPES[t])}
                          value={type}
                          onChange={onInstitutionChange}
                        />
                        <TextField
                          value={assetUnderManagement || ''}
                          label="Aum"
                          name="assetUnderManagement"
                          onChange={onInstitutionChange}
                          sx={{
                            mt: 1,
                            mb: 0.5,
                          }}
                          fullWidth
                        />
                        <h4>Institution Coverage</h4>
                        <Focus
                          focuses={focuses}
                          onFocusChange={e => onFocusChange(e, IMPORT_TYPES.INSTITUTION)}
                          categories={focusDefinitions.ORGANIZATION_CATEGORIES}
                        />
                        <FocusChips
                          focuses={focuses}
                          handleFocusDelete={onFocusDelete}
                          type={IMPORT_TYPES.INSTITUTION}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          p: 2,
                        }}
                      >
                        <TextField
                          value={description || ''}
                          label="Description"
                          name="description"
                          onChange={onInstitutionChange}
                          sx={{
                            mt: 1,
                            mb: 0.5,
                          }}
                          fullWidth
                          multiline
                        />
                        <TextField
                          value={website || ''}
                          label="Website"
                          name="website"
                          onChange={onInstitutionChange}
                          sx={{
                            mt: 1,
                            mb: 0.5,
                          }}
                          fullWidth
                        />
                        <TextField
                          value={phone || ''}
                          label="Phone"
                          name="phone"
                          onChange={onInstitutionChange}
                          sx={{
                            mt: 1,
                            mb: 0.5,
                          }}
                          fullWidth
                        />
                        <LocationTypeahead
                          freeSolo
                          value={address.label}
                          name="label"
                          onAddressSelect={onAddressSelect}
                          onAddressChange={onAddressChange}
                        />
                      </Grid>
                    </Grid>
                  </Fragment>
                )}
              </div>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </FormPaper>
  )
}

const PGLSelectedSection = ({ name }) => (
  <Grid item xs={12}>
    <p>
      The institution
      <strong>{name}</strong>
      already exists within the Platform Database. You can make updates to it through the Platform
      Data interface.
    </p>
  </Grid>
)

ContactImportForm.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}
ContactForm.propTypes = {
  selectedOrganization: PropTypes.object,
  contact: PropTypes.object.isRequired,
  onContactChange: PropTypes.func.isRequired,
  onChecked: PropTypes.func.isRequired,
  focus: PropTypes.object.isRequired,
  onFocusChange: PropTypes.func.isRequired,
  onFocusAdd: PropTypes.func.isRequired,
  onFocusDelete: PropTypes.func.isRequired,
  onFocusCopy: PropTypes.func.isRequired,
}
InstitutionForm.propTypes = {
  institution: PropTypes.object.isRequired,
  selectedOrganization: PropTypes.object,
  address: PropTypes.object.isRequired, // this should change to string after the type ahead
  onInstitutionChange: PropTypes.func.isRequired,
  onAddressSelect: PropTypes.func.isRequired,
  onAddressChange: PropTypes.func.isRequired,
  onOrganizationSelect: PropTypes.func.isRequired,
  identification: PropTypes.object,
  focus: PropTypes.object.isRequired,
  onFocusChange: PropTypes.func.isRequired,
  onFocusAdd: PropTypes.func.isRequired,
  onFocusDelete: PropTypes.func.isRequired,
}
InstitutionForm.defaultProps = {
  identification: null,
  selectedOrganization: null,
}
PGLSelectedSection.propTypes = { name: PropTypes.string.isRequired }

export default ContactImportForm
