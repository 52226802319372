import React, { useCallback, useState } from 'react'

import { Box, AsyncSelect, Select } from '@the-platform-group/component-library'

import { debouncedLoadOrganizations, debouncedLoadPeople } from 'utils/optionsLoaders'
import SelectUuid from './SelectUuid'
import { SELECT_BY_OPTIONS } from 'pages/IrwinAssistant/Summaries/utils'

const SelectProfile = ({ setSelectedEntityId, setSelectedEntityType, selectedCustomer }) => {
  const [onMouseEnter, setOnMouseEnter] = useState(false)
  const [onFocus, setOnFocus] = useState(false)

  const [selectBy, setSelectBy] = useState('')
  const isUUID = selectBy.includes('uuid')

  const [selectedProfile, setSelectedProfile] = useState(null) // Temp: will be replaced with different state
  const [uuidState, setUuidState] = useState('')

  const optionsLoader = useCallback(
    term => {
      switch (selectBy) {
        case SELECT_BY_OPTIONS.organizations.key:
          return debouncedLoadOrganizations({
            customerId: selectedCustomer?.id,
            withActivities: true,
            term,
          })
        case SELECT_BY_OPTIONS.people.key:
          return debouncedLoadPeople({
            customerId: selectedCustomer?.id,
            withActivities: true,
            term,
          })
        default:
          return []
      }
    },
    [selectBy, selectedCustomer?.id],
  )

  const handleChangeSelectBy = e => {
    setSelectBy(e.target.value)
    setSelectedEntityType(SELECT_BY_OPTIONS[e.target.value]?.type)
    setSelectedProfile(null)
    setUuidState('')
    setSelectedEntityId(null)
  }

  const handleProfileSelection = profile => {
    setSelectedProfile(profile)
    setSelectedEntityId(profile.id)
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        outline: '1px solid',
        outlineColor: 'grey.400',
        outlineOffset: '-1px',
        borderRadius: 1,
        ...(onMouseEnter && { outlineColor: 'secondary.dark' }),
        ...(onFocus && { outlineColor: 'primary.main', outlineWidth: '2px' }),
      }}
      onMouseEnter={() => setOnMouseEnter(true)}
      onMouseLeave={() => setOnMouseEnter(false)}
      onFocus={() => setOnFocus(true)}
      onBlur={() => setOnFocus(false)}
    >
      <Select
        variant="outlined"
        label="Search by"
        placeholder="Select"
        margin="none"
        value={selectBy}
        onChange={handleChangeSelectBy}
        options={Object.values(SELECT_BY_OPTIONS).map(option => ({
          value: option.key,
          label: option.label,
        }))}
        sx={{
          minWidth: '210px',
          '& fieldset': {
            border: 'unset',
            borderRight: '1px solid',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderColor: 'grey.400',
            ...(onMouseEnter && { borderColor: 'secondary.dark' }),
            ...(onFocus && { borderColor: 'primary.main', borderWidth: '2px' }),
          },
          '& .MuiFormLabel-root': {
            backgroundColor: 'white',
            px: 1,
          },
        }}
      />

      {isUUID ? (
        <SelectUuid
          selectBy={selectBy}
          uuidState={uuidState}
          setUuidState={setUuidState}
          selectedProfile={selectedProfile}
          setSelectedProfile={handleProfileSelection}
        />
      ) : (
        <AsyncSelect
          key={selectBy}
          disabled={!(selectBy && selectedCustomer)}
          includeClearAll
          id="profileId"
          label={`Search ${selectBy ? SELECT_BY_OPTIONS[selectBy].label : 'Profile'}`}
          variant="outlined"
          value={selectedProfile}
          onChange={handleProfileSelection}
          optionsLoader={optionsLoader}
          margin="none"
          sx={{
            width: '100%',
            '& fieldset': {
              border: 'unset',
            },
            '& .MuiFormLabel-root:not(.Mui-disabled)': {
              backgroundColor: 'white',
              px: 1,
            },
          }}
        />
      )}
    </Box>
  )
}

export default SelectProfile
