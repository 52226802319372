import Spinner from 'components/Spinner'
import { Grid } from '@the-platform-group/component-library'
import { Typography } from '@the-platform-group/component-library'

import { useNotifications } from '../hooks/useNotifications'
import { useNotificationSubscriptions } from '../hooks/useNotificationSubscriptions'

import IqDigestTable from './Table/IqDigestTable'
import HolderAlertsTable from './Table/HolderAlertsTable'

const UserNotifications = ({ user }) => {
  const { notifications, notificationsLoading } = useNotifications()
  const { userSubscriptions, subscriptionsLoading } = useNotificationSubscriptions(user.id)

  const notificationTableComponent = notification => {
    switch (notification.name) {
      case 'Irwin IQ Digest':
        return (
          <IqDigestTable
            notification={notification}
            subscriptions={filterNotificationSubscriptions(userSubscriptions, notification.id)}
            user={user}
            key={notification.id}
          />
        )
      case 'Holding Changes Digest':
        return (
          <HolderAlertsTable
            subscriptions={filterNotificationSubscriptions(userSubscriptions, notification.id)}
            user={user}
            key={notification.id}
          />
        )
      default:
        return null
    }
  }

  const filterNotificationSubscriptions = (subscriptions, notificationId) => {
    return subscriptions.filter(sub => sub.notificationId === notificationId)
  }

  return notificationsLoading || subscriptionsLoading ? (
    <Spinner />
  ) : (
    <>
      <Typography variant="h5" component="h2">
        Notifications
      </Typography>
      <Grid container p={1}>
        {notifications.sort((a, b) => a.name.localeCompare(b.name)).map(notificationTableComponent)}
      </Grid>
    </>
  )
}

export default UserNotifications
