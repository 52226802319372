import React from 'react'
import PropTypes from 'prop-types'

import { FormControlLabel, Radio, RadioGroup } from '@the-platform-group/component-library'

import { H1 } from 'components/Typography'
import { ParentOrganizationProvider } from './contexts/ParentOrganizationContext'
import OrganizationFormContentConfirmation from './components/OrganizationFormContent'
import {
  ORGANIZATION_FORM_TYPE,
  OrganizationFormContextProvider,
  useOrganizationFormContext,
} from './contexts/OrganizationFormContext'

const OrganizationFormComponent = props => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props

  const {
    state: { isDirty, isParentOrgForm, organizationFormType },
    handleOrganizationFormTypeChange,
  } = useOrganizationFormContext()

  const handleFormTypeChange = ({ target: { value } }) => {
    if (!isDirty) {
      handleOrganizationFormTypeChange(value)
      return
    }

    const CONFIRM_MESSAGE = `Are you sure you want to switch to ${value} organization form? Doing so will lose all the updates you made on the form`
    if (window.confirm(CONFIRM_MESSAGE)) {
      handleOrganizationFormTypeChange(value)
    }
  }

  return (
    <>
      <H1>
        {id ? 'Edit ' : 'Add '}
        {isParentOrgForm ? 'Parent ' : ''}
        Organization
      </H1>
      <RadioGroup row sx={{ pb: 2 }} value={organizationFormType} onChange={handleFormTypeChange}>
        <FormControlLabel
          disabled={!!id}
          value={ORGANIZATION_FORM_TYPE.CHILD}
          control={<Radio />}
          label="Regular Organization"
        />
        <FormControlLabel
          disabled={!!id}
          value={ORGANIZATION_FORM_TYPE.PARENT}
          control={<Radio />}
          label="Parent Organization"
        />
      </RadioGroup>
      <ParentOrganizationProvider parentId={isParentOrgForm && id}>
        <OrganizationFormContentConfirmation prompt={isDirty} id={id} history={history} />
      </ParentOrganizationProvider>
    </>
  )
}

OrganizationFormComponent.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

const OrganizationFormComponentWithProvider = props => (
  <OrganizationFormContextProvider>
    <OrganizationFormComponent {...props} />
  </OrganizationFormContextProvider>
)

export default OrganizationFormComponentWithProvider
