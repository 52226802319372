import Jsona, { SwitchCaseJsonMapper } from 'jsona'
import axios from 'axios'
import { irwin } from 'config'
import { httpClient } from './httpClient'
import { camelCase, snakeCase } from 'change-case-object'

const deserializer = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper({ switchChar: '_' }),
})

function client() {
  return httpClient(irwin.baseUrl)
}

let abortController
async function getDs1People(params) {
  if (abortController) {
    abortController.abort('Operation cancelled by the user.')
  }

  try {
    abortController = new AbortController()
    const response = await client().get('v2/ds1/people', {
      params: snakeCase(params),
      signal: abortController.signal,
    })
    return {
      data: deserializer.deserialize(response),
      meta: camelCase(response.meta),
      links: response.links,
    }
  } catch (e) {
    if (axios.isCancel(e)) {
      return { data: [], meta: {}, links: {} }
    }
    throw e
  }
}

async function getDs1Person(id) {
  const response = await client().get(`v2/ds1/people/${id}`)
  return deserializer.deserialize(response)
}

export { getDs1People, getDs1Person }
