import React, { createContext, useEffect } from 'react'

import useAuth from 'hooks/useAuth'
import Spinner from 'components/Spinner'
import { useUnleashContext } from '@unleash/proxy-client-react'

const SessionStateContext = createContext()
const SessionHandlerContext = createContext()

function SessionProvider({ children }) {
  const { state, handlers } = useAuth()
  const updateContext = useUnleashContext()

  useEffect(() => {
    updateContext({ userId: state.currentUser?.id })
  }, [updateContext, state.currentUser])

  if (state.isLoading || state.currentUser === null) {
    return <Spinner size={75} fullPage />
  }

  return (
    <SessionStateContext.Provider value={state}>
      <SessionHandlerContext.Provider value={handlers}>
        {state.currentUser && children}
      </SessionHandlerContext.Provider>
    </SessionStateContext.Provider>
  )
}

function useSessionState() {
  const context = React.useContext(SessionStateContext)
  if (context === undefined) {
    throw new Error('useSessionState must be used within an SessionProvider')
  }
  return context
}

function useSessionHandlers() {
  const context = React.useContext(SessionHandlerContext)
  if (context === undefined) {
    throw new Error('useSessionHandlers must be used within an SessionProvider')
  }
  return context
}

export { useSessionState, useSessionHandlers, SessionProvider, SessionHandlerContext }
