import React from 'react'

import { Grid } from '@the-platform-group/component-library'
import { Table, TableBody } from '@the-platform-group/component-library/Table'
import LabelledField from './LabelledField'

const OrganizationProfileContent = ({ org }) => {
  if (!org) {
    return null
  }

  return (
    <Grid item>
      <Table>
        <TableBody>
          <LabelledField fieldName="source" label="Source" fieldValue={org.legacyType} />
          <LabelledField fieldName="name" label="Name" fieldValue={org.name} />
          {/* Add more fields */}
        </TableBody>
      </Table>
    </Grid>
  )
}

export default OrganizationProfileContent
