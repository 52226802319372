import { useState, useEffect } from 'react'
// Services
import { getCustomer } from 'services/customers'
import useCustomerModals from 'components/CustomerModals/useCustomerModals'

function useCustomer(customerId) {
  const [customer, setCustomer] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const {
    getCustomerModals,
    handleCustomerFeatureToggle,
    handleCustomerEdit,
    handleCustomerSecurity,
    defaultHandleCustomerSave,
    defaultHandleCustomerArchive,
    defaultHandleCustomerUnarchive,
    defaultHandleFeatureToggleUpdate,
  } = useCustomerModals()

  useEffect(() => {
    async function fetchCustomer() {
      try {
        setIsLoading(true)

        const { data } = await getCustomer(customerId)

        setCustomer(data)
        setIsLoading(false)
      } catch (error) {
        setCustomer(() => {
          throw error
        })
      }
    }

    fetchCustomer()
  }, [customerId])

  const handleCustomerSave = async customer => {
    try {
      setCustomer(await defaultHandleCustomerSave(customer))
    } catch (error) {
      setCustomer(() => {
        throw error
      })
    }
  }

  const handleCustomerArchive = async customer => {
    try {
      setCustomer(await defaultHandleCustomerArchive(customer))
    } catch (error) {
      setCustomer(() => {
        throw error
      })
    }
  }

  const handleCustomerUnarchive = async customer => {
    try {
      setCustomer(await defaultHandleCustomerUnarchive(customer))
    } catch (error) {
      setCustomer(() => {
        throw error
      })
    }
  }

  const handleFeatureToggleUpdate = async (customer, features) => {
    try {
      const updatedCustomer = await defaultHandleFeatureToggleUpdate(customer, features)
      setCustomer(updatedCustomer)
    } catch (error) {
      setCustomer(() => {
        throw error
      })
    }
  }

  const getModalComponents = () => {
    return getCustomerModals(handleFeatureToggleUpdate, handleCustomerSave)
  }

  return {
    customer,
    isLoading,
    handleFeatureToggleUpdate,
    handleCustomerEdit,
    handleCustomerSave,
    handleCustomerArchive,
    handleCustomerUnarchive,
    handleCustomerSecurity,
    handleCustomerFeatureToggle,
    getModalComponents,
  }
}

export default useCustomer
