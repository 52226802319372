import { useController } from 'react-hook-form'

import { AsyncSelectWithHelpIcon } from '../Components/Common'
import useUserSearch from '../hooks/useUserSearch'
import debounce from 'utils/debounce'

/**
 * @param {object} param0 - props
 * @param {object} param0.control - react hook form control
 * @param {string} param0.name - react hook form field name
 * @param {boolean} param0.required - is the field required or not
 * @param {function} param0.onChange - additional function to call with onChange event
 * @returns {Node} Async selector for Irwin User
 */
function UserSelector({ control, name = 'user', required = false, ...rest }) {
  const { searchUsers } = useUserSearch()
  const { field, formState } = useController({ control, name, rules: { required } })

  return (
    <AsyncSelectWithHelpIcon
      fullWidth
      hasError={formState.errors.user}
      includeClearAll
      label={`Irwin User ${required ? '*' : ''}`}
      margin="none"
      optionsLoader={debounce(searchUsers)}
      placeholder="Search for Irwin user"
      tooltipText="Selecting an Irwin User will link it to this Factset User."
      variant="outlined"
      {...field} // name, onChange, onBlur, ref, value, etc.
      {...rest}
    />
  )
}

export default UserSelector
