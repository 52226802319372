import { useQueryClient } from 'react-query'

import { useNotification } from 'components/Notification'
import { getFactsetUsers } from 'services/factsetProvisioning'
import { transformFactsetUserForSelect } from '../Utils'

/**
 * @returns {function} async function that searches Factset users based on term
 */
function useFactsetUserSearch() {
  const { createNotification } = useNotification()
  const queryClient = useQueryClient()

  return async search => {
    try {
      const userSearchParams = {
        search,
        pageOffset: 0,
        pageSize: 20,
      }
      const response = await queryClient.fetchQuery(
        ['searchFactsetUsers', userSearchParams],
        () => getFactsetUsers(userSearchParams),
        { retry: false },
      )

      return response?.data.map(transformFactsetUserForSelect)
    } catch (error) {
      createNotification({
        variant: 'error',
        message: `Error fetching factset users "${search}"`,
      })
      return []
    }
  }
}

export default useFactsetUserSearch
