/* eslint-disable react-hooks/exhaustive-deps */
import { useNotification } from 'components/Notification'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import {
  uploadProfilePicture,
  updatePersonProfilePicture,
  deleteProfilePicture,
} from 'services/FileUpload'

function useProfilePictureUploader({ personProfileImage = {} }) {
  const { createNotification } = useNotification()

  // Selected Image State for the profile picture, if person has a profile picture, it will be the default value
  const [selectedImage, setSelectedImage] = useState({
    src: personProfileImage?.url,
    file: null,
    isBlob: false,
  })

  // Updated selectedImage when personProfileImage changes
  useEffect(() => {
    if (selectedImage.src) {
      return
    }

    // If person has a profile picture, set it as the default selected image
    if (personProfileImage?.url !== selectedImage.src) {
      setSelectedImage({
        src: personProfileImage?.url,
        file: null,
        isBlob: false,
      })
    }
  }, [personProfileImage])

  // Handle upload profile picture mutation
  const { mutateAsync: handleUpload, isLoading: isUploadLoading } = useMutation({
    mutationFn: async ({ personId }) => {
      // add file to formData
      const formData = new FormData()
      formData.append('file', selectedImage.file)

      // Upload profile picture service call
      const { file_url } = await uploadProfilePicture({ formData, personId })

      // Return file_url
      return file_url
    },
    onSuccess: file_url => {
      // Set selected image to the uploaded image url
      setSelectedImage({
        src: file_url,
        file: null,
        isBlob: false,
      })
    },
    onError: () => {
      createNotification({
        variant: 'error',
        message: 'Error uploading profile picture',
      })
    },
  })

  // Handle image change when user selects a new image from the file input
  function handleImageChange({ target: { files } }) {
    const file = files[0]

    // Save selected image to state as a blob
    setSelectedImage({
      src: URL.createObjectURL(file),
      file,
      isBlob: true,
    })
  }

  // Reset selected image
  const resetSelectedImage = () => {
    setSelectedImage({
      src: null,
      file: null,
      isBlob: false,
    })
  }

  // Handle update profile picture mutation
  const {
    mutateAsync: handleUpdateProfilePicture,
    isLoading: isUpdateProfilePictureLoading,
  } = useMutation({
    mutationFn: updatePersonProfilePicture,
    onError: () => {
      createNotification({
        variant: 'error',
        message: 'Error saving profile picture',
      })
    },
  })

  // Handle delete profile picture mutation
  const {
    mutateAsync: handleDeleteProfilePicture,
    isLoading: isDeleteProfilePictureLoading,
  } = useMutation({
    mutationFn: deleteProfilePicture,
    onError: () => {
      createNotification({
        variant: 'error',
        message: 'Error deleting profile picture',
      })
    },
  })

  const handleSaveProfilePicture = async ({ personProfileImageUrl, personId }) => {
    const profilePicture = {
      fileUrl: null,
      updated: false,
    }

    // handle image upload if selectedImage is a blob
    if (selectedImage.isBlob) {
      // upload image service call
      const uploadedProfileImageUrl = await handleUpload({
        personId,
      })

      // if upload is successful, update the person profile picture
      if (uploadedProfileImageUrl) {
        // update person profile picture service call
        await handleUpdateProfilePicture({
          personId,
          fileUrl: uploadedProfileImageUrl,
        })
        // set profilePicture.updated to true so we know person is updated
        profilePicture.updated = true
        // set profilePicture.fileUrl to the uploaded image url
        profilePicture.fileUrl = uploadedProfileImageUrl
      }
    }

    // if profilePicture.fileUrl is null but personProfileImageUrl is not null and selected Image src is null, we are deleting the profile picture and need to update the person
    if (!profilePicture.fileUrl && personProfileImageUrl && selectedImage.src === null) {
      await handleDeleteProfilePicture({
        personId,
      })
      profilePicture.updated = true
    }

    // return profilePicture object
    return profilePicture
  }

  return {
    isLoading: isUploadLoading || isUpdateProfilePictureLoading || isDeleteProfilePictureLoading,
    selectedImage,
    setSelectedImage,
    handleUpload,
    handleImageChange,
    resetSelectedImage,
    handleSaveProfilePicture,
    handleUpdateProfilePicture,
  }
}

export default useProfilePictureUploader
