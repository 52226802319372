import { useState, useEffect } from 'react'

function buildOrganization(editOrg) {
  return editOrg || {}
}

const useOrganization = editOrg => {
  const [organization, setOrganization] = useState({ ...buildOrganization(editOrg) })

  useEffect(() => {
    setOrganization({ ...buildOrganization(editOrg) })
  }, [editOrg])

  function handleOrgChange(org) {
    if (org) {
      setOrganization(org)
    }
  }

  return {
    organization,
    handleOrgChange,
  }
}

export { useOrganization }
