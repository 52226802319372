import { irwin } from 'config'
import { httpClient } from './httpClient'
import Jsona, { SwitchCaseJsonMapper } from 'jsona'
import { toCamel, toSnake } from 'change-object-case'

const deserializer = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper({ switchChar: '_' }),
})

function client() {
  return httpClient(irwin.baseUrl)
}

async function getSites(params = {}) {
  const response = await client().get('v2/analytics/sites', { params: toSnake(params) })

  return {
    data: deserializer.deserialize(response),
    meta: toCamel(response.meta),
    links: response.links,
  }
}

async function getSite(id) {
  const response = await client().get(`v2/analytics/sites/${id}`)

  return {
    data: deserializer.deserialize(response),
    meta: toCamel(response.meta),
    links: response.links,
  }
}

async function createSite(site) {
  const response = await client().post(
    'v2/analytics/sites',
    toSnake(site, { recursive: true, arrayRecursive: true }),
  )
  return deserializer.deserialize(response)
}

async function updateSite(site) {
  const response = await client().put(
    `v2/analytics/sites/${site.id}`,
    toSnake(site, { recursive: true, arrayRecursive: true }),
  )
  return deserializer.deserialize(response)
}

async function saveSite(site) {
  const savedSite = site.id ? await updateSite(site) : await createSite(site)

  return savedSite
}

async function fetchLastSiteHealthCheck(siteId) {
  const response = await client().get(`v2/analytics/sites/${siteId}/status`)

  return deserializer.deserialize(response)
}

async function putNewSiteHealthCheck(siteId) {
  const response = await client().put(`v2/analytics/sites/${siteId}/status`)

  return deserializer.deserialize(response)
}

export { getSite, getSites, saveSite, fetchLastSiteHealthCheck, putNewSiteHealthCheck }
