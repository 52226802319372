import capitalize from 'lodash/capitalize'
import { formatDistanceToNow, isAfter, isSameDay } from 'date-fns'
import { TIME_FORMAT } from 'constants/formats'

export const humanizeSnakeCase = str =>
  str
    .split('_')
    .map(j => capitalize(j))
    .join(' ')

export const daysLeft = expiredAt => {
  if (!expiredAt) {
    return ''
  }
  const today = new Date()
  const expiryDate = new Date(`${expiredAt}${TIME_FORMAT}`)

  if (isSameDay(today, expiryDate)) {
    return '(expires today at 17:00 EST)'
  } else if (isAfter(today, expiryDate)) {
    return `(expired on ${expiredAt})`
  } else {
    return `(${formatDistanceToNow(expiryDate)} remaining)`
  }
}

// TODO: Move this into our formatters library
/**
 * Format line breaks in a paragraph of text
 * @param {string} paragraph - text
 * @returns {string} - text with line breaks joined with a space
 */
export const formatWithLineBreaks = paragraph => {
  // prettier-ignore
  return (paragraph || '')
          .split('\\n')
          .join('. ')
}

// TODO: Move this to the formatters library

/**
 * Format a long string of text with ellipsis to display as a preview.
 * @param {string} preview - the text to be displayed in the preview
 * @param {string} content - the full content of text to be displayed
 * @returns {string} - a truncated preview of text with ellipsis
 */
export function formatPreviewString(preview, content) {
  const tokenizedPreview = preview.split(' ')
  const firstWordPreview = tokenizedPreview.shift()
  const lastWordPreview = tokenizedPreview.pop()

  const tokenizedContent = content.split(' ')
  const firstWordContent = tokenizedContent.shift()
  const lastWordContent = tokenizedContent.pop()

  // prepend ellipsis if preview does not start at beginning of full content
  const lead = firstWordPreview !== firstWordContent
  // append ellipsis if preview does not finish at end of full content
  const follow = lastWordPreview !== lastWordContent

  return `${lead ? '...' : ''}${preview}${follow ? '...' : ''}`
}
