import React, { useState } from 'react'
import { Loader } from '@the-platform-group/component-library/Loader'
import { titleCase } from 'change-object-case'

import { Grid, Typography, TextField, Box, IconButton } from '@the-platform-group/component-library'
import {
  AutoAwesome as SparkleIcon,
  Send as SendIcon,
} from '@the-platform-group/component-library/Icons'

import MessageBox from './MessageBox'

const SummariesView = ({
  beforeNotesPrompt,
  setBeforeNotesPrompt,
  afterNotesPrompt,
  setAfterNotesPrompt,
  activityNotesSummaryData,
  activityNotesSummaryDataIsLoading,
  isValidToSummarize,
}) => {
  const [beforeNotesTextFieldValue, setBeforeNotesTextFieldValue] = useState('')
  const [afterNotesTextFieldValue, setAfterNotesTextFieldValue] = useState('')

  const handleBeforeNotesTextFieldChange = event => {
    setBeforeNotesTextFieldValue(event.target.value)
  }

  const handleAfterNotesTextFieldChange = event => {
    setAfterNotesTextFieldValue(event.target.value)
  }

  return (
    <>
      {!activityNotesSummaryData && !activityNotesSummaryDataIsLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Box
            sx={{
              borderRadius: '50%',
              p: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: 'rgba(162, 155, 254, 0.28)',
              aspectRatio: '1',
              width: '40px',
            }}
          >
            <SparkleIcon sx={{ color: 'reports.112', fontSize: '20px' }} />
          </Box>
          <Typography>Irwin Assistant Summaries</Typography>
        </Box>
      )}
      {(activityNotesSummaryData || activityNotesSummaryDataIsLoading) && (
        <Grid
          container
          direction="column"
          justifyContent="flex-end"
          sx={{
            height: '100%',
            mb: 4,
          }}
          rowGap={2}
        >
          <MessageBox
            isUser
            message={
              beforeNotesPrompt || afterNotesPrompt
                ? `Prompt-1: ${beforeNotesPrompt}
            <activity_notes>...</activity_notes>
            Prompt-2: ${afterNotesPrompt}`
                : 'Using default prompt...'
            }
            // default prompt specified here: https://gitlab.com/the-platform-group/irwin-app/irwin-api/-/blob/master/lib/bedrock/activity_summary.rb
          />
          {activityNotesSummaryDataIsLoading ? (
            <Loader />
          ) : (
            <MessageBox message={formatDefaultPromptResult(activityNotesSummaryData?.summary)} />
          )}
        </Grid>
      )}
      <Grid
        container
        component="section"
        sx={{
          bgcolor: 'secondary.light',
          borderRadius: 3,
          width: '100%',
          border: '1px solid',
          p: 1,
        }}
      >
        <Grid item xs={11}>
          <TextField
            multiline
            maxRows={8}
            placeholder="Prompt-1 e.g. You are an expert research assistant"
            variant="standard"
            size="small"
            fullWidth
            value={beforeNotesTextFieldValue}
            onChange={handleBeforeNotesTextFieldChange}
          />
          <br />
          <br />
          <TextField
            multiline
            maxRows={8}
            placeholder="Prompt-2 e.g. please summarize these notes"
            variant="standard"
            size="small"
            fullWidth
            value={afterNotesTextFieldValue}
            onChange={handleAfterNotesTextFieldChange}
          />
        </Grid>
        <Grid item xs={1}>
          <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
            <Grid item>
              <IconButton
                disabled={!isValidToSummarize}
                onClick={() => {
                  setBeforeNotesPrompt(beforeNotesTextFieldValue)
                  setAfterNotesPrompt(afterNotesTextFieldValue)
                  setBeforeNotesTextFieldValue('')
                  setAfterNotesTextFieldValue('')
                }}
              >
                <SendIcon sx={{ color: isValidToSummarize ? 'primary.dark' : 'text.disabled' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

const formatDefaultPromptResult = summary => {
  try {
    const jsonSummary = JSON.parse(summary)
    let formattedString = `${jsonSummary.summary}\n\n`

    // Loop through each section and its items
    for (const [section, items] of Object.entries(jsonSummary.sections)) {
      let formattedSection = `${titleCase(section)}:\n`
      items.forEach(item => {
        formattedSection += `- ${item}\n`
      })
      formattedSection += '\n' // Add a newline for spacing between sections
      formattedString += formattedSection
    }

    return formattedString
  } catch {
    return summary
  }
}

export default SummariesView
