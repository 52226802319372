import { useContext } from 'react'
import { SearchInputStateContext } from '../contexts/SearchInputContext'

function useSearchInputRead() {
  const context = useContext(SearchInputStateContext)

  if (!context) {
    throw new Error('useSearchInput must be used within a SearchInputStateContext.Provider')
  }

  const { searchInput, affiliatedId } = context

  return { searchInput, affiliatedId }
}

export default useSearchInputRead
