import { getSavedTableState } from '../utils'

export const SORT_DIRECTIONS = ['asc', 'desc']

// TO DO: Move into component library
/**
 * Function to find the table's saved sort state from localStorage or the initial state provided. The values are used to determine how the table is to be sorted on initial load.
 * @param {string} tableName - table name
 * @param {object} initialState - contains the column name and direction of sort
 * @param {Function} transformSortFn - function to transform the saved sort state returned
 * @returns {object} - the column name and sort direction
 */
export const getInitialSortState = (tableName, initialState, transformSortFn) => {
  let sortState = initialState
  if (tableName) {
    // get table's sort state from localStorage
    const tableState = getSavedTableState(tableName)
    const savedSortState = tableState.sorting?.sortModel

    sortState = savedSortState?.length ? savedSortState.map(transformSortFn)[0] : initialState
  }
  return sortState
}
