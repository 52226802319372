import React from 'react'
import { Grid } from '@the-platform-group/component-library/Layout'
import { Button } from '@the-platform-group/component-library/Button'
import { Typography } from '@the-platform-group/component-library'

import { useCustomers } from './hooks/useCustomers'

import CustomerFilters from './components/CustomerFilters'
import CustomersTable from './components/Table/CustomersTable'
import { ListPaper } from 'components/Paper'

const CustomersComponent = () => {
  const {
    customers,
    pagination,
    isLoading,
    filters,
    setPagination,
    handleCustomerTypeFilterChange,
    handleCustomerActiveStateFilterChange,
    handleCustomerNameSearch,
    handleUserAdded,
    handleCustomerEdit,
    handleCustomerFeatureToggle,
    handleCustomerArchive,
    handleCustomerUnarchive,
    handleCustomerSecurity,
    handleCreateOpen,
    getModalComponents,
  } = useCustomers()

  return (
    <React.Fragment>
      <Typography variant="h4" component="h1">
        Customers
      </Typography>
      <Grid container direction="column" spacing={2}>
        <Grid item alignItems="center" container>
          <Grid item xs={3}>
            <Button onClick={handleCreateOpen}>Create Customer</Button>
          </Grid>

          <Grid item xs={9}>
            <CustomerFilters
              filters={filters}
              handleCustomerTypeFilterChange={handleCustomerTypeFilterChange}
              handleCustomerNameSearch={handleCustomerNameSearch}
              handleCustomerActiveStateFilterChange={handleCustomerActiveStateFilterChange}
            />
          </Grid>
        </Grid>

        <Grid item>
          <ListPaper>
            <CustomersTable
              isLoading={isLoading}
              customers={customers}
              pagination={pagination}
              setPagination={setPagination}
              handleUserAdded={handleUserAdded}
              handleCustomerEdit={handleCustomerEdit}
              handleCustomerFeatureToggle={handleCustomerFeatureToggle}
              handleCustomerArchive={handleCustomerArchive}
              handleCustomerUnarchive={handleCustomerUnarchive}
              handleCustomerSecurity={handleCustomerSecurity}
            />
          </ListPaper>
        </Grid>
      </Grid>

      {getModalComponents()}
    </React.Fragment>
  )
}

export default CustomersComponent
