import { Box } from '@the-platform-group/component-library'
import DeleteHolder from './DeleteHolder'
import { REPORT_FIELD_PREFIXES } from '../utils/reports'

const HolderNameCell = ({ value, row, api, isLoading }) => {
  // if current shares is null or undefined (0 reported shares not included) and no shares in any reports, the holder can be deleted
  const canHolderBeDeleted =
    !row.currentShares &&
    row.currentShares !== 0 &&
    !Object.keys(row).some(rowKey => {
      return rowKey.includes(REPORT_FIELD_PREFIXES.shares) && row[rowKey] > 0
    })

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      {value}
      {!isLoading && canHolderBeDeleted && <DeleteHolder value={value} row={row} api={api} />}
    </Box>
  )
}

export default HolderNameCell
