import { useEffect } from 'react'

/**
 * @param {object} params - hook params
 * @param {function} params.formProps - built-in props returned from useForm()
 * @returns {object} methods required by SiteModal
 */
function useSiteForm({ onSave, onClose, refreshTable, formProps, customerId, editableSite }) {
  const { reset, setError } = formProps // return values from react-hook-form's useForm()

  useEffect(() => {
    // Set initial form values to `editableSite` data whenever it becomes available
    // This needs to run in useEffect due to `editableSite` data is asynchronous
    // NOTE: we only use editableSite data as initial values, form values & changes are
    // tracked separately by react-hook-form & will NOT affect editableSite until form is saved
    reset({
      id: editableSite?.id || '',
      name: editableSite?.name || '',
      url: editableSite?.url || '',
      security: editableSite?.security || '',
      // if we're editing, use the enabled attribute
      // if we're not editing, it should be checked on default
      enabled: editableSite?.id ? editableSite.enabled : true,
    })
  }, [editableSite, reset])

  const saveSite = async formData => {
    try {
      const params = { ...formData, securityId: formData.security.id, customerId }
      delete params.security // API call only need `securityId`, not `security`
      const response = await onSave(params)
      if (response) {
        await refreshTable()
        reset()
        onClose()
      }
    } catch (error) {
      let errorString = ''
      if (error.response.status === 503) {
        errorString = error.response.data.errors[0]
      } else {
        error.response.data.errors.forEach(error => {
          errorString += `${error.field} ${error.message} \n`
        })
      }
      // Leverage react-hook-form's built-in error state to set a server error
      // NOTE: server errors need to be cleared manually via clearError()
      // Ref: https://react-hook-form.com/docs/useform/seterror
      setError('root.serverErrors', { type: 'custom', message: errorString })
    }
  }

  return { saveSite }
}

export default useSiteForm
