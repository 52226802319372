import React, { useState } from 'react'

import {
  Card,
  CardHeader,
  CardContent,
  Collapse,
  IconButton,
  styled,
} from '@the-platform-group/component-library'
import { ExpandMore as ExpandMoreIcon } from '@the-platform-group/component-library/Icons'

const ExpandPreview = styled(IconButton, { shouldForwardProp: prop => prop !== 'isExpanded' })(
  ({ theme, isExpanded }) => ({
    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }),
)

const Preview = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <Card>
      <CardHeader
        title="Preview"
        titleTypographyProps={{ variant: 'h6' }}
        action={
          <ExpandPreview
            isExpanded={isExpanded}
            aria-expanded={isExpanded}
            aria-label="show preview"
            onClick={() => setIsExpanded(!isExpanded)}
            // disabled={!preview}
            size="large"
          >
            <ExpandMoreIcon />
          </ExpandPreview>
        }
      />
      <Collapse in={isExpanded}>
        <CardContent sx={{ p: 1 }}>
          {/* Add fields here */}
          {/* Add fields here */}
        </CardContent>
      </Collapse>
    </Card>
  )
}

export default Preview
