import createDataContext from './createDataContext'

const affiliationReducer = (state, action) => {
  switch (action.type) {
    case 'AFFILIATION:FETCH_STARTED':
      return { ...state, isLoading: true, failed: false }
    case 'AFFILIATION:FETCH_RECEIVED':
      return {
        ...state,
        isLoading: false,
        affiliations: action.payload.affiliations || [],
        total: action.payload.total || 0,
        self: action.payload.self,
        next: action.payload.next,
        prev: action.payload.prev,
      }
    case 'AFFILIATION:SET_PAGE':
      return { ...state, page: action.payload.page }
    case 'AFFILIATION:SET_ROWS_PER_PAGE':
      return { ...state, page: action.payload.page, rowsPerPage: action.payload.rowsPerPage }
    case 'AFFILIATION:FAILED':
      // reset the state if it's a 400 error. because the user may have entered a custom person/organization
      // the error message notify the user that only system person/organization is supported here
      if (action.payload.response && action.payload.response.status === 400) {
        return { ...state, page: 0, failed: true, isLoading: false }
      } else {
        throw action.payload
      }
    default:
      throw new Error(`Unsupported action type: ${action.type}`)
  }
}

const initialState = {
  affiliations: [],
  links: null,
  total: 0,
  rowsPerPage: 25,
  page: 0,
  self: null,
  next: null,
  prev: null,
  isLoading: false,
  failed: false,
}

export const { Provider, Context } = createDataContext(affiliationReducer, initialState)
