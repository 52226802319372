import React from 'react'
import { Delete as DeleteIcon } from '@the-platform-group/component-library/Icons'
import { IconButton, Tooltip } from '@the-platform-group/component-library'

const DeleteButton = ({ title, onClick, disabled, sx }) => (
  <Tooltip title={title}>
    <IconButton
      aria-label={title}
      sx={{
        float: 'right',
        cursor: 'pointer',
        '& svg': {
          fontSize: 18,
        },
        '&:hover': {
          color: theme => theme.palette.primary.error,
        },
        ...sx,
      }}
      size="small"
      onClick={onClick}
      disabled={disabled}
    >
      <DeleteIcon />
    </IconButton>
  </Tooltip>
)

export default DeleteButton
