import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Button } from '@the-platform-group/component-library/Button'
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@the-platform-group/component-library/Modal'
import FeatureToggles from './FeatureToggles'

const CustomerFeatureToggleModal = ({ onSave, onClose, open, customer }) => {
  const [enabledFeatures, setEnabledFeatures] = useState([])

  useEffect(() => {
    if (customer?.features) {
      setEnabledFeatures(customer.features)
    }
  }, [customer.features])

  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle title={`Toggle Features for ${customer.name}`} />
      <ModalContent>
        <FeatureToggles features={enabledFeatures} onToggleChange={setEnabledFeatures} />
      </ModalContent>
      <ModalActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={() => onSave(customer, enabledFeatures)}>Save</Button>
      </ModalActions>
    </Modal>
  )
}

CustomerFeatureToggleModal.defaultProps = {
  customer: {},
}

CustomerFeatureToggleModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  customer: PropTypes.object,
}

export default CustomerFeatureToggleModal
