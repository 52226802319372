import { useMemo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'

import { getFactsetUserById } from 'services/factsetProvisioning'

/**
 * @param {object} param0.factsetUser - optional, factset user object to pre-fill form values
 *    typically this is passed in when editing a FS user, on FS user details or FS users list page
 * @param {object} param0.factsetLocation - optional, factset location object to pre-fill form values
 *    typically this is passed in when on FS location page
 * @param {object} param0.irwinUser - optional, an Irwin user object used to prefill form value
 *    typically this is passed in when on Irwin user page
 * @returns {object} react hook form control object for Factset User Creation form
 *    e.g. { control, reset, formState, getValues, setValue, etc. }
 */
function useFactsetUserForm({ factsetUser, factsetLocation, irwinUser }) {
  const defaultValues = useMemo(() => {
    const defaultLocation = !factsetUser && factsetLocation
    const defaultUser = !factsetUser && irwinUser
    return {
      type: 'unmanaged',
      location:
        // only set default FS location value if there's no factsetUser (on create modal) &&
        // factsetLocation is provided
        defaultLocation ? { label: defaultLocation.name, value: defaultLocation.id } : null,
      user:
        // only set default FS user value if there's no factsetUser (on create modal) && irwinUser
        // is provided
        defaultUser
          ? { label: `${defaultUser.firstName} ${defaultUser.lastName}`, value: defaultUser.id }
          : null, // Irwin user
      givenName: defaultUser ? defaultUser.firstName : '',
      familyName: defaultUser ? defaultUser.lastName : '',
      email: defaultUser ? defaultUser.email : '',
      serialNumber: '',
      username: '', // Factset username
      externalId: '',
      assertionValue: '',
      roleName: 'Corporate',
      royaltyCode: '',
      productIds: [], // this has to be [], if set to null or '', the <Select> component would crashes
    }
  }, [factsetLocation, factsetUser, irwinUser])

  const formControls = useForm({
    mode: 'onChange',
    defaultValues,
  })

  useEffect(() => {
    if (factsetUser?.id) {
      Object.entries(factsetUser).forEach(([key, value]) => {
        // this loop basically populates form inputs with factset user data provided
        // if some values are not available, set them back to default
        // the reason we set FORM_DEFAULT_VALUES here is that some provided values might be `null` coming
        // from API, but if we pass `null` to a react-hook-form controlled input, the value won't get
        // "overwritten/reset" as `null` is ignored, so we have to reset using FORM_DEFAULT_VALUES
        formControls.setValue(key, value || defaultValues[key], { shouldDirty: false })
      })
    }
  }, [defaultValues, factsetUser, formControls])

  return formControls // control, reset, formState, getValues, setValue, etc.
}

/**
 * @param {string} factsetUserId - factset user id to fetch the user by
 * @param {object} options - extra useQuery (react-query) options to apply/overwrite
 * @returns {object} - factset user object
 */
function useFactsetUser(factsetUserId, options = {}) {
  return useQuery(['factsetUser', factsetUserId], () => getFactsetUserById(factsetUserId), {
    enabled: !!factsetUserId,
    refetchOnWindowFocus: false,
    ...options,
  })
}

export { useFactsetUserForm, useFactsetUser }
