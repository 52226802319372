import React from 'react'
import PropTypes from 'prop-types'

import {
  TableRow,
  TableCell,
  TableActionList,
  TableAction,
} from '@the-platform-group/component-library/Table'
import Edit from 'images/edit.svg'
import { formatDate } from '@the-platform-group/formatters/dateFormat'
import { DATETIME_WITH_TIMEZONE_FORMAT } from 'constants/formats'

const PersonRow = ({ person }) => {
  const { id, firstName, lastName, bio, approvedAt } = person
  return (
    <TableRow>
      <TableCell>{firstName}</TableCell>
      <TableCell>{lastName}</TableCell>
      <TableCell>{bio}</TableCell>
      <TableCell>{formatDate(approvedAt, DATETIME_WITH_TIMEZONE_FORMAT)}</TableCell>
      <TableCell>
        <TableActionList>
          <TableAction title="Edit" to={`/platform/people/${id}/edit`}>
            <img src={Edit} alt="Edit a person" />
          </TableAction>
        </TableActionList>
      </TableCell>
    </TableRow>
  )
}

PersonRow.propTypes = {
  person: PropTypes.object.isRequired,
}

export default PersonRow
