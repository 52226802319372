import client, { deserializer } from 'services/client'
import { toSnake } from 'change-object-case'

async function getInternalNotesList(params) {
  const response = await client().get('/v2/internal_notes/', { params: toSnake(params) })

  return deserializer.deserialize(response)
}

async function createInternalNote(content, associatedId, associatedType) {
  const requestBody = {
    content,
    internal_notable_id: associatedId,
    internal_notable_type: associatedType,
  }
  const responseBody = await client().post(`/v2/internal_notes`, requestBody)

  return deserializer.deserialize(responseBody)
}

async function updateInternalNote(id, content) {
  const requestBody = { content }
  const responseBody = await client().put(`/v2/internal_notes/${id}`, requestBody)

  return deserializer.deserialize(responseBody)
}

export { getInternalNotesList, createInternalNote, updateInternalNote }
