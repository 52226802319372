import { httpClient } from '../services/httpClient'
import { irwin } from 'config'
import { useState } from 'react'

function client() {
  return httpClient(irwin.baseUrl, true)
}

// new extensions should be added here
const extensions = {
  'text/csv': 'csv',
  'text/plain': 'txt',
}

export function useFileDownload(urls) {
  const [, setState] = useState()
  const [isLoading, setIsLoading] = useState(false)

  async function handleDownload(url, title) {
    try {
      setIsLoading(true)

      const res = await client().get(url, { responseType: 'blob' })
      const blob = res.data
      const filename = res.headers['content-disposition']?.split('filename="')[1]?.split('"')[0]

      const generatedUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')

      link.href = generatedUrl
      link.setAttribute('download', filename || `${title}.${extensions[blob.type]}`)
      document.body.appendChild(link)

      link.click()
      link.remove()
    } catch (error) {
      setState(() => {
        throw error
      })
    } finally {
      setIsLoading(false)
    }
  }

  function defineDownloads() {
    if (Array.isArray(urls)) {
      return urls.map(url => title => handleDownload(url, title))
    }

    return title => handleDownload(urls, title)
  }
  return {
    download: urls?.length ? defineDownloads() : handleDownload,
    isLoading,
  }
}
