import React, { createContext, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { isEmpty } from '@the-platform-group/formatters/checks'
import { useNotification } from 'components/Notification'
import {
  createOrganizationLineages,
  deleteOrganizationLineages,
  getChildrenOrganizations,
} from 'services/organizationLineages'
import { useOrganizationFormContext } from './OrganizationFormContext'

const DEFAULT_PAGE_SIZE = 50

export const PAGINATION_PARAMS = {
  page: 0,
  pageSize: DEFAULT_PAGE_SIZE,
}

const ParentOrganizationContext = createContext({})

const ParentOrganizationProvider = ({ children, parentId }) => {
  const { createNotification } = useNotification()
  const {
    state: { deletedChildOrganizations, newChildOrganizations },
  } = useOrganizationFormContext()

  const queryClient = useQueryClient()

  const [paginationState, setPaginationState] = useState(PAGINATION_PARAMS)
  const { page, pageSize } = paginationState
  const params = {
    parentId,
    pageSize,
    pageOffset: pageSize * page,
  }
  const childOrgQueryKey = ['childOrganizationsQuery', params]
  const { isLoading, data } = useQuery(childOrgQueryKey, () => getChildrenOrganizations(params), {
    select: ({ data, meta }) => ({
      childrenOrgs: data.map(({ organization }) => organization),
      meta: { ...meta, pageSize: Number(meta.pageSize), page },
    }),
    initialData: { data: [], meta: { ...PAGINATION_PARAMS, itemCount: 0 } },
    refetchOnWindowFocus: false,
    useErrorBoundary: true,
    enabled: Boolean(parentId),
  })
  const { childrenOrgs: linkedChildOrganizations, meta: pagination } = data

  // memoize that way it filters linkedChildOrganizations only when deletedChildOrganizations state is changed
  const filteredLinkedOrganizations = useMemo(
    () => linkedChildOrganizations.filter(linkedOrg => !deletedChildOrganizations[linkedOrg.id]),
    [deletedChildOrganizations, linkedChildOrganizations],
  )

  const { mutateAsync: unlinkChildrenOrganizations } = useMutation(
    () => {
      deleteOrganizationLineages(Object.keys(deletedChildOrganizations))
    },
    {
      onError: ({ message }) =>
        createNotification({
          message: `Error unlinking children organizations - ${message}`,
          variant: 'error',
        }),
    },
  )

  const { mutateAsync: linkChildOrganizations } = useMutation(
    parentId => {
      createOrganizationLineages(
        newChildOrganizations.map(({ id: organizationId }) => ({ parentId, organizationId })),
      )
    },
    {
      onError: ({ message }) =>
        createNotification({
          message: `Error linking children organizations - ${message}`,
          variant: 'error',
        }),
    },
  )
  const hasDeletedChildOrganizations = !isEmpty(deletedChildOrganizations)

  /**
   * Function that handles linking and unlinking of children organizations.
   * if `newChildOrganizations` is not empty, it calls linkChildOrganizations function
   * if `deletedChildOrganizations` is not empty, it calls unlink function
   * @param {string} parentOrgId - parent organization's id. we need to pass parentOrgId on handleLinkSubmit since value of parentId  in context will be undefined if parent org is new
   */
  const handleLinkSubmit = async parentOrgId => {
    const promises = []
    if (newChildOrganizations.length) {
      promises.push(linkChildOrganizations(parentOrgId))
    }
    if (hasDeletedChildOrganizations) {
      promises.push(unlinkChildrenOrganizations())
    }

    if (!promises.length) {
      return
    }
    await Promise.all(promises)
    queryClient.resetQueries(childOrgQueryKey)
  }

  const handlePageUpdate = (name, newPageValue) => {
    setPaginationState(oldPaginationState => ({ ...oldPaginationState, [name]: newPageValue }))
  }

  return (
    <ParentOrganizationContext.Provider
      value={{
        isLoading,
        isArchiveDisabled: Boolean(linkedChildOrganizations.length),
        childOrganizations: filteredLinkedOrganizations.concat(newChildOrganizations),
        pagination,
        handleLinkSubmit,
        handlePageUpdate,
      }}
    >
      {children}
    </ParentOrganizationContext.Provider>
  )
}

const useParentOrganizationContext = () => {
  const context = useContext(ParentOrganizationContext)
  if (context === undefined) {
    throw new Error('ParentOrganizationContext must be used within a ParentOrganizationProvider')
  }
  return context
}

ParentOrganizationProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { ParentOrganizationProvider, useParentOrganizationContext }
