import { useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useForm } from 'react-hook-form'
import { useNotification } from 'components/Notification'
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@the-platform-group/component-library/Modal'
import { Button } from '@the-platform-group/component-library/Button'
import CreateFactsetLocationForm from './CreateFactsetLocationForm'
import { FactsetMetadataProvider } from 'contexts/FactsetMetadataContext'
import { updateFactsetLocation as updateFactsetLocationService } from 'services/factsetProvisioning'

const UpdateFactsetLocationModal = ({ open, closeModal, location }) => {
  const queryClient = useQueryClient()
  // Only unmanaged locations can be updated
  const type = 'unmanaged'
  const { id, name, ds1Id } = location
  const { createNotification } = useNotification()
  const formObject = useForm({
    mode: 'onChange',
    defaultValues: {
      name,
      ds1Id,
    },
  })
  const { formState, handleSubmit, reset: resetForm } = formObject

  const {
    mutateAsync: updateFactsetLocation,
    isLoading: isEditingFactsetLocation,
    error,
    reset: resetMutation,
  } = useMutation({
    mutationFn: updateFactsetLocationService,
    onError: () => {
      createNotification({
        variant: 'error',
        message: 'There was an error updating the location.',
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['factsetLocation', id] })
      createNotification({
        variant: 'success',
        message: 'Factset location was updated successfully.',
      })
      closeModal()
    },
  })
  const onSaveForm = ({ name, ds1Id }) => {
    updateFactsetLocation({
      id,
      DS1Id: ds1Id,
      name,
    })
  }

  useEffect(() => {
    if (!open) {
      resetMutation()
      resetForm()
    }
  }, [open, resetMutation, resetForm])

  return (
    <Modal open={open} closeModal={closeModal}>
      <ModalTitle title={`Edit ${name}`} />
      <ModalContent>
        <FactsetMetadataProvider>
          <CreateFactsetLocationForm
            type={type}
            errors={error?.response?.data?.errors}
            disableCustomerField
            {...formObject}
          />
        </FactsetMetadataProvider>
      </ModalContent>
      <ModalActions>
        <Button variant="text" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          isLoading={isEditingFactsetLocation}
          disabled={!formState.isValid}
          onClick={handleSubmit(onSaveForm)}
        >
          Save
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default UpdateFactsetLocationModal
