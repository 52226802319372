import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { titleCase } from 'change-object-case'
import orderBy from 'lodash/orderBy'
import { formatDate } from '@the-platform-group/formatters/dateFormat'
import { Grid, Typography, CustomLink, Tooltip } from '@the-platform-group/component-library'
import { Button } from '@the-platform-group/component-library/Button'
import {
  AddLink as LinkIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  LinkOff as UnlinkIcon,
} from '@the-platform-group/component-library/Icons'
import { getFactsetLocationById } from 'services/factsetProvisioning'

import Spinner from 'components/Spinner'
import LinkFactsetLocationModal from './Components/LinkFactsetLocationModal'
import KeyValuePair from './Components/KeyValuePair'
import UpdateFactsetLocationModal from './Components/UpdateFactsetLocationModal'
import FactsetUserApiRequestsTable from './Components/FactsetUserApiRequestsTable'
import FactsetUsers from 'pages/Factset/FactsetUsers'

import useDeleteFactsetLocation from './hooks/useDeleteFactsetLocation'
import { useUnlinkFactsetLocation } from './hooks/useLinkFactsetLocation'
import useLinkFactsetEntityActions from './hooks/useLinkFactsetEntityActions'
import { useModal } from 'hooks/useModal'

function FactsetLocationDetail() {
  const { id: locationId } = useParams()
  const {
    open: isUpdateFactsetLocationModalOpen,
    handleOpen: openUpdateFactsetLocationModal,
    handleClose: closeUpdateFactsetLocationModal,
  } = useModal()

  const { data: location, isFetching } = useQuery(
    ['factsetLocation', locationId],
    () => getFactsetLocationById(locationId),
    {
      enabled: Boolean(locationId),
      refetchOnWindowFocus: false,
    },
  )

  const {
    open: isLinkFactsetLocationModalOpen,
    handleClose: closeLinkFactsetLocationModal,
    handleOpen: openLinkFactsetLocationModal,
  } = useLinkFactsetEntityActions()

  const { onUnlink, isUnlinking } = useUnlinkFactsetLocation()
  const { onDelete, isDeleting } = useDeleteFactsetLocation()

  if (isFetching) {
    return <Spinner />
  }

  const {
    apiRequests,
    id,
    name,
    createdAt,
    updatedAt,
    lastRefreshedAt,
    customer,
    ds1Id,
    status,
    // start - fields to remove from UI
    type,
    relationshipNames,
    // end
    ...restOfData
  } = location.data
  const isUnmanaged = status === 'unmanaged'
  const dates = { createdAt, updatedAt, lastRefreshedAt }
  const displayData = [
    ['Factset ID (DS1 ID)', ds1Id],
    ...Object.entries(dates).map(([label, value]) => [titleCase(label), formatDate(value, 'PPp')]),
    ...Object.entries(restOfData).map(([label, value]) => [titleCase(label), value]),
  ]

  return (
    <>
      <Grid container spacing={1} flexWrap="nowrap" justifyContent="space-between">
        <Grid item xs>
          <Typography variant="h4" component="h1">
            {name}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            ID: {id}
          </Typography>
        </Grid>
        <Grid container item spacing={1} sx={{ width: 'initial' }}>
          <Grid item>
            <Tooltip
              title={
                !isUnmanaged &&
                'Only unmanaged locations can be deleted. Factset managed locations must be deleted by submitting a FactSet Issue Tracker ticket.'
              }
            >
              <span>
                <Button
                  color="error"
                  isLoading={isDeleting}
                  disabled={isDeleting || !isUnmanaged}
                  onClick={onDelete(id, name)}
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              </span>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip
              title={
                !isUnmanaged &&
                'Only unmanaged locations can be updated. Factset managed locations must be updated by submitting a FactSet Issue Tracker ticket.'
              }
            >
              <span>
                <Button
                  onClick={openUpdateFactsetLocationModal}
                  startIcon={<EditIcon />}
                  disabled={!isUnmanaged}
                >
                  Edit
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            disabled={!customer}
            isLoading={isUnlinking}
            onClick={onUnlink(id)}
            startIcon={<UnlinkIcon />}
          >
            Unlink
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={openLinkFactsetLocationModal}
            disabled={!!customer}
            startIcon={<LinkIcon />}
          >
            Link an Irwin Customer
          </Button>
        </Grid>
      </Grid>
      <br />
      <Grid container flexDirection="column" spacing={1}>
        <KeyValuePair
          label="Status"
          value={
            <Typography component="span" textTransform="uppercase">
              {status}
            </Typography>
          }
        />
        <KeyValuePair
          label="Linked Customer"
          value={
            customer && (
              <CustomLink to={`/accounts/customers/${customer.id}`} underline="always">
                {customer.name}
              </CustomLink>
            )
          }
        />
        {orderBy(displayData, ([label]) => label).map(([label, value]) => {
          return <KeyValuePair label={label} value={value} />
        })}
      </Grid>
      <FactsetUserApiRequestsTable rows={apiRequests} factsetLocationId={locationId} />
      <FactsetUsers factsetLocation={location.data} />
      <LinkFactsetLocationModal
        isModalOpen={isLinkFactsetLocationModalOpen}
        closeModal={closeLinkFactsetLocationModal}
        location={{ value: location.data.id, label: name }}
      />
      <UpdateFactsetLocationModal
        open={isUpdateFactsetLocationModalOpen}
        closeModal={closeUpdateFactsetLocationModal}
        location={location.data}
      />
    </>
  )
}

export default FactsetLocationDetail
