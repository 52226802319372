import mergeWith from 'lodash/mergeWith'

const mergeArrayCustomizer = (oldState, payloadState) => {
  if (Array.isArray(oldState)) {
    // lodash.merge merges arrays by index as default. what we want is for the old array state to be overwritten by the payload so we disregard old state if object is array
    return payloadState
  }
}

/**
 * Merge method that uses a mergeArrayCustomizer function which overwrites the source array state with the destination array
 * @param {args} args - (object, [sources]), same as lodash.merge arguments
 * @returns {object}  the merged object
 */
const merge = (...args) => mergeWith(...args, mergeArrayCustomizer)

export default merge
