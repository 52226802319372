import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'
import { Button } from '@the-platform-group/component-library/Button'
import {
  Table,
  TableHead,
  TableRow,
  TableHeaderCell,
  TableBody,
} from '@the-platform-group/component-library/Table'
import { H2 } from 'components/Typography'
import { FormPaper } from 'components/Paper'
import PersonRow from './PersonRow'

const PeopleTable = ({ organizationId, people, peopleInformation }) => {
  const { total, approved } = peopleInformation
  const approvedText = `(${approved}/${total})`
  return (
    <FormPaper>
      <H2>People</H2>
      <Button component={Link} to={`/platform/people/new?organizationId=${organizationId}`}>
        Add Person
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>First Name</TableHeaderCell>
            <TableHeaderCell>Last Name</TableHeaderCell>
            <TableHeaderCell>Bio</TableHeaderCell>
            <TableHeaderCell>Approved At {approvedText}</TableHeaderCell>
            <TableHeaderCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {people.map(person => (
            <PersonRow key={person.id} person={person} />
          ))}
        </TableBody>
      </Table>
    </FormPaper>
  )
}

PeopleTable.propTypes = {
  people: PropTypes.array.isRequired,
  organizationId: PropTypes.string.isRequired,
}

export default PeopleTable
