import { useEffect, useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useForm } from 'react-hook-form'
import { useNotification } from 'components/Notification'
import { Chip } from '@the-platform-group/component-library'
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@the-platform-group/component-library/Modal'
import { Button } from '@the-platform-group/component-library/Button'

import CreateFactsetLocationForm from './CreateFactsetLocationForm'

import TypeSelector from './TypeSelector'

import {
  FactsetModalActionsProvider,
  FactsetModalActionsContext,
} from '../Context/FactsetModalActionsContext'
import { createFactsetLocation as createFactsetLocationService } from 'services/factsetProvisioning'

const FORM_DEFAULT_VALUES = {
  type: 'unmanaged',
  name: null,
  firmDescriptionId: '12',
  address2: null,
  address3: null,
  description: null,
  region: null,
  phoneNumber: null,
}

const CreateFactsetLocationModal = ({ isModalOpen, closeModal, customer }) => {
  const queryClient = useQueryClient()
  const { currentStep, gotoPrevStep, gotoNextStep, gotoFirstStep } = useContext(
    FactsetModalActionsContext,
  )

  const { createNotification } = useNotification()
  const formObject = useForm({
    mode: 'onChange',
    defaultValues: FORM_DEFAULT_VALUES,
  })
  const { control, watch, formState, handleSubmit, setValue, reset: resetForm } = formObject
  const type = watch('type')

  const {
    mutateAsync: createFactsetLocation,
    isLoading: isCreatingFactsetLocation,
    error,
    reset: resetMutation,
  } = useMutation({
    mutationFn: createFactsetLocationService,
    onError: () => {
      createNotification({
        variant: 'error',
        message: 'There was an error creating the location.',
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['factsetLocations'] })
      closeModal()
    },
  })

  useEffect(() => {
    if (customer) {
      setValue(
        'customer',
        { value: customer.id, label: customer.name, ...customer },
        { shouldValidate: true },
      )
    }
  }, [customer, setValue])

  useEffect(() => {
    // Effect triggered when form goes back to first page
    if (currentStep === 0) {
      resetMutation()
      // reset form state except for type selection
      resetForm(({ type, customer }) => ({
        ...FORM_DEFAULT_VALUES,
        customer,
        type,
      }))
    }
  }, [currentStep, resetMutation, resetForm])

  useEffect(() => {
    // Effect triggered when modal closes
    if (!isModalOpen) {
      // Go back to original form state
      gotoFirstStep()
      resetForm()
    }
  }, [isModalOpen, resetForm, gotoFirstStep])

  const onSaveForm = ({ customer, country, region, name, ds1Id, type, ...formData }) => {
    let data
    if (type === 'unmanaged') {
      data = {
        name,
        DS1Id: ds1Id,
      }
    } else {
      data = {
        location: { ...formData, name, country: country.value.code, region: region?.value },
      }
    }
    createFactsetLocation({
      type,
      customerId: customer.value,
      ...data,
    })
  }

  return (
    <Modal open={isModalOpen} closeModal={closeModal}>
      <ModalTitle
        title={
          <>
            Create Factset Location&nbsp;&nbsp;
            {type && <Chip label={type} size="small" color="secondary" />}
          </>
        }
      />
      <ModalContent>
        {currentStep === 0 && (
          <TypeSelector title="Does a Factset location already exist?" control={control} />
        )}
        {currentStep === 1 && (
          <CreateFactsetLocationForm
            type={type}
            errors={error?.response?.data?.errors}
            disableCustomerField={Boolean(customer)}
            {...formObject}
          />
        )}
      </ModalContent>
      <ModalActions>
        <Button variant="text" onClick={closeModal}>
          Cancel
        </Button>
        {currentStep > 0 && (
          <Button variant="outlined" onClick={gotoPrevStep}>
            Previous
          </Button>
        )}
        {currentStep === 0 && <Button onClick={gotoNextStep}>Next</Button>}
        {currentStep === 1 && (
          <Button
            isLoading={isCreatingFactsetLocation}
            disabled={!formState.isValid}
            onClick={handleSubmit(onSaveForm)}
          >
            Save
          </Button>
        )}
      </ModalActions>
    </Modal>
  )
}

function CreateFactsetLocationModalWrapper(props) {
  return (
    <FactsetModalActionsProvider>
      <CreateFactsetLocationModal {...props} />
    </FactsetModalActionsProvider>
  )
}

export default CreateFactsetLocationModalWrapper
