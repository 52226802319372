export function serializeFocus(focus, initialFocuses) {
  return {
    id: initialFocuses.find(f => f.categoryValue === focus.categoryValue)?.id,
    category: focus.category,
    category_value: focus.categoryValue,
  }
}

function formatFocusParams(jobFocuses, initialFocuses = []) {
  const finalFocusValues = jobFocuses.reduce((result, focus) => {
    // let's put the category value as key so we can check for existing
    // category values in initialFocuses, we don't have to iterate again
    return {
      ...result,
      [focus.categoryValue]: serializeFocus(focus, initialFocuses),
    }
  }, {})

  const deletedFocuses = initialFocuses.reduce((result, f) => {
    if (!finalFocusValues[f.categoryValue]) {
      result.push({ ...serializeFocus(f, initialFocuses), _destroy: true })
    }
    return result
  }, [])

  return Object.values(finalFocusValues).concat(deletedFocuses)
}

function formatFocusCategories(focus, newFocusFromCategory) {
  if (focus.categoryValues.includes('all')) {
    return newFocusFromCategory(focus.category)
  }
  return focus.categoryValues.map(f => ({
    category: focus.category,
    categoryValue: f,
  }))
}

const getUpdatedFocuses = (oldFocuses, newFocus, newFocusFromCategory) => {
  const allFocus = formatFocusCategories(newFocus, newFocusFromCategory)
  const prevFocusFiltered = oldFocuses.filter(foc => foc.category !== newFocus.category)

  return prevFocusFiltered.concat(allFocus)
}

const deleteFromFocuses = (oldFocuses, focusToRemove) =>
  oldFocuses.filter(f => f.categoryValue !== focusToRemove.categoryValue)

export {
  formatFocusParams as formatFocus,
  formatFocusCategories,
  deleteFromFocuses,
  getUpdatedFocuses,
}
