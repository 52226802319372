import Jsona, { SwitchCaseJsonMapper } from 'jsona'
import { irwin } from 'config'
import { httpClient } from './httpClient'

const deserializer = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper(),
})

function client() {
  return httpClient(irwin.baseUrl)
}

async function getContactImport(id) {
  const response = await client().get(`v1/contact_imports/${id}`)

  return {
    data: deserializer.deserialize(response),
    meta: response.meta,
  }
}

async function getContactImports(params) {
  const response = await client().get('v1/contact_imports', { params })

  return {
    data: deserializer.deserialize(response),
    meta: response.meta,
  }
}

async function ignoreContactImport(contactId) {
  await client().patch(`v1/contact_imports/${contactId}`, { contact_import: { status: 'ignored' } })
}

async function saveImport(importContact) {
  const response = await client().post('v1/import_service', { import: importContact })

  return {
    data: deserializer.deserialize(response),
  }
}

export { getContactImports, getContactImport, saveImport, ignoreContactImport }
