import { useEffect, useState } from 'react'
import { snake } from 'change-case-object'
import { getContactImports, ignoreContactImport } from 'services/imports'
import { useNotification } from 'components/Notification'

const fetchContactImports = async (setContactImports, options) => {
  // BUGFIX I don't think this is the correct place to fix this, however
  // modifying the TablePagination page value messes up the next page calculation
  // This is just a quick fix
  const { page, pageSize } = options
  const pageOffset = page * pageSize
  const { customer, ...restOfOptions } = options

  try {
    const response = await getContactImports(
      snake({ ...restOfOptions, pageOffset, customerId: customer?.value }),
    )
    setContactImports({
      contactImports: response.data,
      totalCount: response.meta['item-count'],
      options,
    })
  } catch (error) {
    setContactImports(() => {
      throw error
    })
  }
}

const checkIgnore = (contactId, ignored, setIgnored) => {
  const { id } = ignored
  if (contactId !== id) {
    setIgnored({ id: contactId, color: 'red' })
    return false
  }
  return true
}

function useContactImports(options) {
  const [contactImportsState, setContactImports] = useState({ contactImports: [], totalCount: 0 })
  const [ignored, setIgnored] = useState({ id: '', color: 'black' })
  const { createNotification } = useNotification()

  const { contactImports, totalCount } = contactImportsState

  useEffect(() => {
    fetchContactImports(setContactImports, options)
  }, [options])

  const handleContactIgnore = async contactId => {
    if (checkIgnore(contactId, ignored, setIgnored)) {
      try {
        await ignoreContactImport(contactId)
        createNotification({ message: 'the contact was ignored' })
        setIgnored({ id: '', color: 'black' })
        fetchContactImports(setContactImports, options)
      } catch (e) {
        createNotification({ message: e.message, variant: 'error' })
        setIgnored({ id: '', color: 'black' })
      }
    }
  }

  return {
    contactImports,
    ignored,
    totalCount,
    handleContactIgnore,
    isLoading: options !== contactImportsState.options,
  }
}

export { useContactImports }
