export const getColumnValues = () => {
  return [
    {
      field: 'siteName',
      headerName: 'Site',
    },
    { field: 'configuration' },
    {
      field: 'enabled',
      width: 180,
      flex: null,
      renderCell: ({ row }) => {
        return row.rowComponent
      },
    },
  ]
}

export const getRowValues = rowValues => {
  return rowValues.map(row => {
    const { id, siteName, configuration, enabled, rowComponent } = row
    return {
      id: id,
      siteName: siteName,
      configuration: configuration,
      enabled: enabled,
      rowComponent: rowComponent,
    }
  })
}
