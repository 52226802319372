import React from 'react'

import { DataGrid } from '@the-platform-group/component-library/DataGrid'
import { getColumnValues, getRowValues } from './UserTableValues'

function UsersTable({ isLoading, users, pagination, handlePageChange, handleRowsChange }) {
  const { page, itemCount, pageSize } = pagination

  return (
    <DataGrid
      columns={getColumnValues()}
      commonColumnProps={{ headerAlign: 'left' }}
      rows={getRowValues(users)}
      sx={{
        '.MuiDataGrid-actionsCell .MuiIconButton-root': { px: 1 },
        '.hoverCell': {
          '&.MuiDataGrid-cell:hover span, &.MuiDataGrid-cell:hover .MuiButtonBase-root': {
            display: 'block',
            fontSize: 11,
          },
          'span, .MuiButtonBase-root': { display: 'none' },
        },
      }}
      loading={isLoading}
      localeText={{
        noRowsLabel:
          'Looks like nothing matches your search. Try removing some criteria or attempt a new search.',
      }}
      name="usersTable"
      page={page}
      pageSize={pageSize}
      onPageChange={handlePageChange}
      onPageSizeChange={handleRowsChange}
      rowCount={itemCount}
      pageSizeOptions={[10, 25, 50]}
    />
  )
}

export default UsersTable
