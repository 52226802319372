import React from 'react'
import useAffiliationTasks from '../hooks/useAffiliationTasks'
import SharedTable from './SharedTable'
import { DATE } from '@the-platform-group/component-library/DataGrid/constant'

export const emptyMessage = 'No result found.'

const columns = [
  ...['customer', 'subject', 'status'].map(v => ({ field: v })),
  ...['dueAt', 'createdAt'].map(v => ({ field: v, type: DATE })),
]

const getRowData = task => {
  const { customer, id, subject, status, dueAt, createdAt } = task
  return { customer, id, subject, status, dueAt, createdAt }
}

function TasksTable() {
  const useHook = useAffiliationTasks()
  const props = { columns, emptyMessage, getRowData, useHook, name: 'tasksTable' }
  return <SharedTable rows={useHook.state.tasks} {...props} />
}

export default TasksTable
