import React from 'react'
import { Grid } from '@the-platform-group/component-library/Layout'

import useSecuritySearch from './hooks/useSecuritySearch'
import SecuritiesSearch from './SecuritiesSearch'
import SecuritiesTable from './SecuritiesTable'

function Securities() {
  const {
    securities,
    isLoading,
    securityOptions,
    setSecurityOptions,
    saveOverride,
    pagination,
    fetchNext,
    removeOverride,
  } = useSecuritySearch()
  return (
    <>
      <h1>Security Overrides</h1>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SecuritiesSearch value={securityOptions} setSecurityOptions={setSecurityOptions} />
        </Grid>
        <Grid item xs={12}>
          <SecuritiesTable
            securities={securities}
            isLoading={isLoading}
            saveOverride={saveOverride}
            pagination={pagination}
            fetchNext={fetchNext}
            removeOverride={removeOverride}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default Securities
