import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { createFactsetUser, updateFactsetUser } from 'services/factsetProvisioning'
import { useNotification } from 'components/Notification'
import { useModal } from 'hooks/useModal'

import { getFactsetUserProductIds, getErrorMessage } from '../Utils'

/**
 * @returns {object} Factset creation mutation attributes
 * e.g. { isSaving, onSaveForm, etc. }
 */
function useFactsetUserCreateActions(closeModal) {
  const queryClient = useQueryClient()
  const { createNotification } = useNotification()

  const {
    isLoading: isSaving,
    mutate: createFactsetUserMutate,
    reset: resetMutation,
  } = useMutation({
    mutationFn: createFactsetUser,
    onSuccess: () => {
      createNotification({
        variant: 'success',
        message: 'Factset user successfully created',
      })
      queryClient.invalidateQueries({ queryKey: ['factsetUsers'] })
      closeModal()
    },
    onError: error => {
      const message = getErrorMessage(error)

      createNotification({
        variant: 'error',
        message,
      })
    },
  })

  const onSaveForm = formData => {
    let data
    if (formData.type === 'unmanaged') {
      data = {
        type: formData.type,
        // NOTE: capitalize DS1 converts to correct snake case for our API
        // e.g.
        // toSnake('ds1LocationId') => 'ds_1_location_id' <== we don't want this
        // toSnake('DS1LocationId') => 'ds1_location_id'  <== we want this
        DS1LocationId: formData.location.value,
        userId: formData.user?.value,
        givenName: formData.givenName,
        familyName: formData.familyName,
        email: formData.email,
        serialNumber: formData.serialNumber,
      }
    } else if (formData.type === 'managed') {
      data = {
        type: formData.type,
        DS1LocationId: formData.location.value,
        userId: formData.user?.value,
        user: {
          assertionValue: formData.assertionValue,
          username: formData.username,
          givenName: formData.givenName,
          familyName: formData.familyName,
          email: formData.email,
          roleName: formData.roleName,
          royaltyCode: formData.royaltyCode,
          productIds: formData.productIds,
        },
      }
    }

    createFactsetUserMutate(data)
  }

  return { isSaving, onSaveForm, resetMutation }
}

/**
 * @returns {object} Factset creation mutation attributes
 * e.g. { isSaving, onSaveForm, etc. }
 */
function useFactsetUserUpdateActions(closeModal) {
  const queryClient = useQueryClient()
  const { createNotification } = useNotification()

  const {
    isLoading: isSaving,
    mutate: updateFactsetUserMutate,
    reset: resetMutation,
  } = useMutation({
    mutationFn: updateFactsetUser,
    onSuccess: () => {
      createNotification({
        variant: 'success',
        message: 'Factset user successfully updated',
      })
      queryClient.invalidateQueries({ queryKey: ['factsetUsers'] })
      closeModal()
    },
    onError: error => {
      const message = getErrorMessage(error)

      createNotification({
        variant: 'error',
        message,
      })
    },
  })

  const onSaveForm = factsetMetadata => formData => {
    let data
    if (formData.type === 'unmanaged') {
      data = {
        id: formData.id,
        type: formData.type,
        givenName: formData.givenName,
        familyName: formData.familyName,
        email: formData.email,
        serialNumber: formData.serialNumber,
      }
    } else if (formData.type === 'managed') {
      const productIds = getFactsetUserProductIds(formData, factsetMetadata)
      data = {
        id: formData.id,
        type: formData.type,
        // Due to the asychronous nature of FS API when updating managed users
        // @version is used to handle concurrency to avoid data being overwritten by diff users
        // updating FS user back-to-back, this value is returned from the original FS user API
        version: formData.version,
        user: {
          assertionValue: formData.assertionValue,
          username: formData.username,
          givenName: formData.givenName,
          familyName: formData.familyName,
          email: formData.email,
          roleName: formData.roleName,
          royaltyCode: formData.royaltyCode,
          productIds,
        },
      }
    }

    updateFactsetUserMutate(data)
  }

  return { isSaving, onSaveForm, resetMutation }
}

function useFactsetUserUpdateModal() {
  const queryClient = useQueryClient()
  const [selectedFactsetUserId, setSelectedFactsetUserId] = useState()
  const {
    handleOpen: openUpdateFactsetUserModal,
    handleClose: closeUpdateFactsetUserModal,
    open: isUpdateFactsetUserModalOpen,
  } = useModal()

  // Open modal for specific FS user when clicking update button on a FS User row in the table
  const onOpenUpdateFactsetUserModal = factsetUserId => () => {
    // Always reset query cache for the specified FS user before opening update modal so that we always
    // fetch latest data because FS user info might have been asynchronously updated in the background
    // by Factset APIs
    if (factsetUserId) {
      queryClient.invalidateQueries(['factsetUser', factsetUserId])
      setSelectedFactsetUserId(factsetUserId)
    }
    openUpdateFactsetUserModal()
  }

  return {
    selectedFactsetUserId,
    openUpdateFactsetUserModal,
    closeUpdateFactsetUserModal,
    isUpdateFactsetUserModalOpen,
    onOpenUpdateFactsetUserModal,
  }
}

export { useFactsetUserCreateActions, useFactsetUserUpdateActions, useFactsetUserUpdateModal }
