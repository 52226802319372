import React from 'react'

import { Avatar, Box } from '@the-platform-group/component-library'
import { generateBgcolorByName, getInitials } from './utils'
import { AVATAR_SIZES } from './utils'
import { AVATAR_FONT_SIZES } from './utils'

const DEFAULT_AVATAR_SX = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%) scale(0.5)',
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'translate(-50%, -50%) scale(1)',
    zIndex: 1,
  },
}

/**
 * Returns an avatar component for a person
 * @param {object} params Parameter object.
 * @param {object} params.name - person's name object
 * @param {string} params.profilePictureSrc - person's picture url
 * @returns {Avatar} - Avatar component
 */
const ProfilePicture = ({ name = {}, profilePictureSrc, size = 'default' }) => {
  const avatarSize = AVATAR_SIZES[size] || AVATAR_SIZES.default
  const avatarComponentSize = avatarSize * 2
  const avatarFontSize = AVATAR_FONT_SIZES[size] || AVATAR_FONT_SIZES.default

  const isProfilePictureExist = !!profilePictureSrc
  const fullName = `${name.firstName} ${name.lastName}`

  const avatarSx = {
    ...DEFAULT_AVATAR_SX,
    width: avatarComponentSize,
    height: avatarComponentSize,
    fontSize: avatarFontSize,
    ...(!isProfilePictureExist && {
      fontSize: avatarFontSize,
      bgcolor: generateBgcolorByName({ fullName }),
    }),
  }

  const avatarProps = isProfilePictureExist
    ? { src: profilePictureSrc }
    : {
        children: getInitials(name),
      }

  return (
    <Box width={avatarSize} height={avatarSize} position="relative">
      <Avatar alt={fullName} sx={avatarSx} {...avatarProps} />
    </Box>
  )
}

export default React.memo(ProfilePicture)
