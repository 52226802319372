import { Link } from 'react-router-dom'
// icons
import { Delete as DeleteIcon, Edit as EditIcon } from '@the-platform-group/component-library/Icons'
import { DataGridActionCell } from '@the-platform-group/component-library/DataGrid'
import { DATE } from '@the-platform-group/component-library/DataGrid/constant'
import ENTITY_TYPES from 'constants/entityTypes'
import { DATETIME_WITH_TIMEZONE_FORMAT } from 'constants/formats'

// transform function that will generate custom column configuration(if any)
const toColumnConfig = (column, { type, handleModalOpen }) => {
  switch (column.field) {
    case 'approvedAt':
      // Use DATE type for approved at column
      return { ...column, type: DATE, dateFormat: DATETIME_WITH_TIMEZONE_FORMAT }
    case 'actions':
      // Actions column with Edit & Delete buttons
      return {
        ...column,
        type: 'actions',
        // Actions column with Edit & Delete buttons
        getActions: ({ id, row }) => {
          const title = ENTITY_TYPES[type].singular
          return [
            <DataGridActionCell
              icon={<EditIcon color="default" />}
              title={`Edit ${title}`}
              component={Link}
              to={`/platform/${ENTITY_TYPES[type].plural}/${id}/edit`}
            />,
            <DataGridActionCell
              icon={<DeleteIcon color="default" />}
              title={`Archive ${title}`}
              onClick={() => handleModalOpen(row)}
            />,
          ]
        },
      }
    default:
      // the rest of the columns should just return string value without any custom config
      return column
  }
}

export const getColumnValues = (columns, opts) =>
  columns.map(column => toColumnConfig(column, opts))
