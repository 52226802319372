import React from 'react'
import { Link } from 'react-router-dom'
import { daysLeft } from 'utils/format'
import { TABLE_DATE_FORMAT } from 'constants/formats'
import { Box, styled, Typography, Tooltip } from '@the-platform-group/component-library'
import { DATE } from '@the-platform-group/component-library/DataGrid/constant'
import { formatDate } from '@the-platform-group/formatters/dateFormat'
import UserCellComponent from './UserCellComponent'
import CustomerActionsDropdown from 'components/CustomerActionsDropdown/CustomerActionsDropdown'

const StyledBox = styled(Box)(() => ({ display: 'flex', flexDirection: 'column' }))

export const getColumnValues = () => {
  return [
    {
      field: 'name',
      cellClassName: 'hoverCell',
      renderCell: ({ id, value }) => (
        <StyledBox>
          <Link to={'customers/' + id}>{value}</Link>
          <Typography variant="body2" component="span">
            {id}
          </Typography>
        </StyledBox>
      ),
    },
    {
      field: 'type',
      renderCell: ({ row }) => (
        <Tooltip
          title={
            row.expiredAt && (
              <Typography variant="caption">{`Trial Period Ends: ${formatDate(
                row.expiredAt,
                TABLE_DATE_FORMAT,
              )}`}</Typography>
            )
          }
        >
          <StyledBox>
            <Typography>{row.type || ''}</Typography>
            <Typography variant="caption">{daysLeft(row.expiredAt)}</Typography>
          </StyledBox>
        </Tooltip>
      ),
    },
    {
      field: 'defaultSecurity',
      renderCell: ({ value: defaultSecurity }) => (
        <StyledBox>
          {defaultSecurity ? (
            <>
              <Typography variant="body2">{defaultSecurity.name}</Typography>
              <Typography variant="body2">
                {defaultSecurity.listingPrimary?.tickerExchange}
              </Typography>
            </>
          ) : (
            <Typography variant="body2" color="error">
              Security not setup yet
            </Typography>
          )}
        </StyledBox>
      ),
    },
    {
      field: 'users',
      cellClassName: 'hoverCell',
      minWidth: 300,
      renderCell: ({ row }) => {
        const { id, users, onUserAdd } = row
        return (
          <StyledBox>
            <UserCellComponent customerId={id} users={users} onUserAdd={onUserAdd} />
          </StyledBox>
        )
      },
    },
    { field: 'createdBy' },
    { field: 'createdAt', type: DATE },
    {
      field: 'actions',
      renderCell: ({ row }) => {
        return <CustomerActionsDropdown data={row} />
      },
    },
  ]
}

export const getRowValues = (customers, authorizations, handlers) => {
  const {
    handleUserAdded,
    handleCustomerEdit,
    handleCustomerFeatureToggle,
    handleCustomerArchive,
    handleCustomerUnarchive,
    handleCustomerSecurity,
  } = handlers
  return customers.map(customer => {
    const {
      id,
      name,
      type,
      defaultSecurity,
      createdAt,
      archivedAt,
      expiredAt,
      creator,
      users,
    } = customer
    const authorization = authorizations.find(auth => auth.customer.id === id)

    return {
      id,
      name,
      type,
      defaultSecurity,
      createdAt,
      createdBy: creator ? `${creator.firstName} ${creator.lastName}` : 'Unknown',
      archived: !!archivedAt,
      users,
      authorization,
      onUserAdd: handleUserAdded,
      expiredAt,
      onCustomerEdit: () => handleCustomerEdit(customer),
      onCustomerFeatureToggle: () => handleCustomerFeatureToggle(customer),
      onCustomerArchive: () => handleCustomerArchive(customer),
      onCustomerSecurity: () => handleCustomerSecurity(customer),
      onCustomerUnarchive: () => handleCustomerUnarchive(customer),
    }
  })
}
