import { useState } from 'react'
import { useQuery } from 'react-query'
import { getReports } from 'services/nobo'

const defaultPagination = {
  itemCount: 0,
  pageSize: 25,
  pageOffset: 0,
  page: 0,
}

const defaultFilters = {
  customerId: null,
}

function useReports() {
  const [pagination, setPagination] = useState(defaultPagination)
  const [filters, setFilters] = useState(defaultFilters)

  const { isLoading, data: reports } = useQuery(
    ['getNoboReports', pagination.pageSize, pagination.pageOffset, filters.customerId],
    () =>
      getReports({
        customerId: filters.customerId,
        pageSize: pagination.pageSize,
        pageOffset: pagination.pageOffset,
      }),
    {
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
      onSuccess: data => {
        setPagination(prevState => ({
          ...prevState,
          itemCount: data.meta.itemCount,
        }))
      },
    },
  )

  const handlePageChange = newPage => {
    const { page, pageSize } = pagination

    if (newPage === page) {
      return
    }

    const updatingPage = newPage > page ? page + 1 : page - 1

    setPagination(prevState => ({
      ...prevState,
      page: updatingPage,
      pageOffset: updatingPage * pageSize,
    }))
  }

  const handleRowsChange = pageSize => {
    setPagination(prevState => ({ ...prevState, page: 0, pageSize: pageSize, pageOffset: 0 }))
  }

  async function handleCustomerSearch(customer) {
    setFilters(prevState => ({
      ...prevState,
      customerId: customer?.id,
      customerSearch: customer,
    }))
  }

  return {
    reports: reports?.data || [],
    isLoading,
    filters,
    pagination,
    handleCustomerSearch,
    handlePageChange,
    handleRowsChange,
  }
}

export default useReports
