import { camelCase, snakeCase } from 'change-case-object'
import { client, deserializer } from './client'

async function getChildrenOrganizations(params) {
  const response = await client().get(`v2/organization_lineages`, snakeCase({ params }))
  return {
    data: deserializer.deserialize(response),
    meta: camelCase(response.meta),
  }
}

async function createOrganizationLineages(organization_lineages) {
  const response = await client().post(
    'v2/organization_lineages/bulk_create',
    snakeCase({ organization_lineages }),
  )

  return { data: deserializer.deserialize(response) }
}

async function deleteOrganizationLineages(organization_ids) {
  return await client().delete(`v2/organization_lineages/bulk_destroy`, {
    data: { organization_ids },
  })
}

export { createOrganizationLineages, deleteOrganizationLineages, getChildrenOrganizations }
