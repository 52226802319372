import { Typography } from '@the-platform-group/component-library'

const H1 = ({ children }) => (
  <Typography variant="h4" component="h1" sx={{ fontWeight: 'fontWeightBold', py: 2 }}>
    {children}
  </Typography>
)

const H2 = ({ children }) => (
  <Typography variant="h6" component="h2" sx={{ fontWeight: 'fontWeightBold', py: 2 }}>
    {children}
  </Typography>
)

const H3 = ({ children }) => (
  <Typography
    variant="h6"
    component="h3"
    sx={{ fontWeight: 'fontWeightBold', py: 2, fontSize: '1.17rem' }}
  >
    {children}
  </Typography>
)

export { H1, H2, H3 }
