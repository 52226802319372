import client, { deserializer } from 'services/client'
import { toCamel, toSnake } from 'change-object-case'

export async function getUserSites(params) {
  const response = await client().post('v2/analytics/sites/fetch', toSnake(params))

  return {
    data: deserializer.deserialize(response),
    meta: toCamel(response.meta),
  }
}
