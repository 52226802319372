import { useState, useEffect } from 'react'
import changeCase from 'change-case-object'
// Services
import { saveJob } from 'services/jobs'
// Transforms
import { formatFocus } from 'transforms/focus'
import { formatAddress } from 'transforms/address'
import { useNotification } from 'components/Notification'
import { useOrganization } from './useOrganization'
import useFocus from 'hooks/useFocus'
import { MARKET_CAP_ERROR_FIELD } from 'constants/marketCaps'

const useJob = (defaultJob = null, handleJobAdd = null) => {
  const [job, setJob] = useState({ ...jobBuilder(defaultJob) })
  const [isCreatingJob, setIsCreatingJob] = useState(false)
  const { createNotification } = useNotification()
  const { organization, handleOrgChange } = useOrganization(defaultJob && defaultJob.organization)

  const { focuses, handleFocusCopy, handleFocusChange, handleFocusDelete } = useFocus(
    job.jobFocuses,
    organization,
  )

  useEffect(() => {
    setJob({ ...jobBuilder(defaultJob) })
  }, [defaultJob])

  const handleJobChange = ({ target }) => {
    setJob(prevJob => ({ ...prevJob, [target.name]: target.value }))
  }

  const handleJobCheckChange = ({ target }) => {
    setJob(prevJob => ({ ...prevJob, [target.name]: target.checked }))
  }

  const handleAddressChange = ({ target }) => {
    setJob(prevJob => ({
      ...prevJob,
      address: { ...prevJob.address, [target.name]: target.value },
    }))
  }

  const handleAddressSelect = address => {
    setJob({ ...job, address: { id: job.address.id, ...address } })
  }

  const handleJobSave = async personId => {
    setIsCreatingJob(true)
    const { address, delegateAddress, phone, ...editedJob } = job

    const formattedPhone = phone || organization.phone || null
    const addressAttributes = delegateAddress
      ? { address_id: null }
      : { address_attributes: formatAddress(address) }

    const focusAttributes = formatFocus(focuses, job.jobFocuses)
    const { relationshipNames, ...rest } = editedJob
    const jobAttributes = changeCase.snake({ ...rest, organizationId: organization.id, personId })
    const jobParams = {
      ...addressAttributes,
      job_focuses_attributes: focusAttributes,
      ...jobAttributes,
      phone: formattedPhone,
    }

    try {
      const { data: newJob } = await saveJob(jobParams)
      createNotification({ message: 'Job was saved' })
      handleJobAdd({
        ...newJob,
        organization,
      })
      setJob({ ...jobBuilder() })
    } catch (error) {
      // intentionally throw on market cap definition changes,
      // these errors will be handled by an error boundary that will
      // refresh the values on the page
      if (error.response?.data?.errors?.map(e => e.field).includes(MARKET_CAP_ERROR_FIELD)) {
        setIsCreatingJob(() => {
          throw error
        })
        return
      }

      createNotification({ message: 'Job unable to be saved', variant: 'error' })
    } finally {
      setIsCreatingJob(false)
    }
  }

  return {
    job,
    isCreatingJob,
    focuses,
    organization,
    handleFocusCopy,
    handleJobChange,
    handleJobCheckChange,
    handleOrgChange,
    handleAddressChange,
    handleAddressSelect,
    handleFocusChange,
    handleFocusDelete,
    handleJobSave,
  }
}

function jobBuilder(jobProps) {
  const j = { active: true, retailDiscretionaryFlag: false, ...(jobProps || {}) }
  const a = j.address || {}
  const f = j.jobFocuses || []

  return {
    ...j,
    address: {
      ...a,
    },
    delegateAddress: !a.id,
    jobFocuses: f,
  }
}

export { useJob }
