import React from 'react'
import { Select } from '@the-platform-group/component-library/Select'
import JOB_FUNCTIONS from 'constants/jobFunctions'

const JobFunctionsSelect = ({ value, onChange }) => {
  return (
    <Select
      fullWidth
      name="jobFunctions"
      label="Job Functions"
      data-testid="job-functions-select"
      options={Object.keys(JOB_FUNCTIONS).map(jobFunc => ({
        value: jobFunc,
        label: JOB_FUNCTIONS[jobFunc],
      }))}
      value={value || []}
      onChange={onChange}
      isMulti
    />
  )
}

export default JobFunctionsSelect
