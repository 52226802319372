import React from 'react'
import { Route, Switch } from 'react-router-dom'

// People Components
import PersonEditForm from 'pages/People/PersonEditForm'
import PersonCreateForm from 'pages/People/PersonCreateForm'
import PeopleComponent from 'pages/People/PeopleComponent'

const PeopleRouter = () => (
  <Switch>
    <Route exact path="/platform/people" component={PeopleComponent} />
    <Route path={['/platform/people/:id/edit']} component={PersonEditForm} />
    <Route path={['/platform/people/new']} component={PersonCreateForm} />
  </Switch>
)

export default PeopleRouter
