import { putNewSiteHealthCheck, fetchLastSiteHealthCheck } from 'services/customerWebsites'
import { useQuery } from 'react-query'
import { convertTimezoneAndFormat } from '@the-platform-group/formatters/dateFormat'
import { useNotification } from 'components/Notification'

const INTERVAL = 3000
const FAIL_LIMIT = 3

const useHealthCheck = website => {
  const { createNotification } = useNotification()

  const showingSiteId = website?.id

  const getLastSiteHealthCheck = async showingSiteId => {
    let response
    try {
      response = await fetchLastSiteHealthCheck(showingSiteId)
    } catch (error) {
      if (error.response.status === 404) {
        return { status: 'unavailable' }
      } else {
        throw error
      }
    }

    if (!response?.status || response?.status === 'pending') {
      // We are taking advantage of the internal
      // useQuery retry system to retry any failed
      // status response in the case that the BE
      // has not updated to reflect the proper
      // status yet up to a max fail limit. The
      // benefit of this means that react-query
      // will always properly associate the token
      // and site id to the proper query.
      throw new HealthCheckError()
    }

    if (response?.status === 'unavailable') {
      createNotification({
        message:
          'The site checker is currently unavailable due to maintenance. Please try again later.',
        variant: 'warning',
      })
    }

    return { status: response.status, updatedAt: response.updatedAt }
  }

  const runNewSiteHealthCheck = async showingSiteId => {
    const response = await putNewSiteHealthCheck(showingSiteId)

    if (!response?.status) {
      throw new HealthCheckError()
    }
    return response?.status
  }

  function isCustomError(error) {
    return error instanceof HealthCheckError
  }

  const getLastSiteHealthCheckQuery = useQuery(
    ['healthCheckQuery', { id: showingSiteId }],
    () => getLastSiteHealthCheck(showingSiteId),
    {
      refetchOnWindowFocus: false,
      enabled: !!showingSiteId,
      retryOnMount: false,
      retryDelay: INTERVAL,
      retry: (failureCount, error) => {
        // if the error is the one that we're expecting,
        // and failureCount doesn't exceed the fail limit,
        // then we fetch again!
        return isCustomError(error) && failureCount < FAIL_LIMIT
      },
      useErrorBoundary: error => {
        // if this is our special exception, then we don't want to throw
        // if this is any other exception, we'll want to throw
        return !isCustomError(error)
      },
    },
  )

  const runNewSiteHealthCheckQuery = useQuery(
    ['newHealthCheck', { id: showingSiteId }],
    () => runNewSiteHealthCheck(showingSiteId),
    {
      refetchOnWindowFocus: false,
      enabled: !!showingSiteId,
      retryOnMount: false,
      retryDelay: INTERVAL,
      retry: (failureCount, error) => {
        // if the error is the one that we're expecting,
        // and failureCount doesn't exceed the fail limit,
        // then we fetch again!
        return isCustomError(error) && failureCount < FAIL_LIMIT
      },
      useErrorBoundary: error => {
        // if this is our special exception, then we don't want to throw
        // if this is any other exception, we'll want to throw
        return !isCustomError(error)
      },
    },
  )

  return {
    status: getLastSiteHealthCheckQuery.isError
      ? 'failed'
      : getLastSiteHealthCheckQuery.data?.status,
    lastUpdated: getLastSiteHealthCheckQuery.data?.updatedAt
      ? convertTimezoneAndFormat(getLastSiteHealthCheckQuery.data.updatedAt, {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
      : 'N/A',
    isLoading: getLastSiteHealthCheckQuery.isFetching || runNewSiteHealthCheckQuery.isFetching,
    executeNewSiteHealthCheck: runNewSiteHealthCheckQuery.refetch,
    refreshLastSiteHealthCheck: getLastSiteHealthCheckQuery.refetch,
  }
}

class HealthCheckError extends Error {}

export default useHealthCheck
