import React from 'react'
import { AsyncSelect } from '@the-platform-group/component-library'

import debounce from 'utils/debounce'
import { isString } from 'utils'
import { searchSecurities } from 'services/securities'

const debouncedSecuritiesSearch = debounce(searchSecurities, 250)

export function transformSecurityToOption(security) {
  if (!security) {
    return null
  }

  if (isString(security)) {
    return security
  }

  return {
    id: security.id,
    label: security.label || `${security.name} (${security.listingPrimary?.tickerExchange})`,
    value: security.id,
  }
}

export async function loadSecurities(term) {
  if (!term) {
    return []
  }

  const response = await debouncedSecuritiesSearch(term)
  return response.map(transformSecurityToOption)
}

function SecuritySelect({ id, label, value, onChange, ...rest }) {
  return (
    <AsyncSelect
      id={id}
      label={label}
      variant="outlined"
      optionsLoader={loadSecurities}
      onChange={onChange}
      value={transformSecurityToOption(value)}
      fullWidth
      {...rest}
    />
  )
}

export default SecuritySelect
