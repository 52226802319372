import { client } from 'services/client'

const uploadProfilePicture = async ({ personId, formData }) => {
  const response = await client().post(`/v2/people/${personId}/upload`, formData)

  return response
}

const updatePersonProfilePicture = async ({ personId, fileUrl }) => {
  const response = await client().put(`/v2/person_profile_images/${personId}/upsert`, {
    url: fileUrl,
  })

  return response
}

const deleteProfilePicture = async ({ personId }) => {
  const response = await client().delete(`/v2/person_profile_images/${personId}`)

  return response
}

export { uploadProfilePicture, updatePersonProfilePicture, deleteProfilePicture }
