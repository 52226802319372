import React from 'react'
import PropTypes from 'prop-types'

import { TextField } from '@the-platform-group/component-library'
import LocationTypeahead from '@the-platform-group/component-library/LocationTypeahead'
import { H2 } from 'components/Typography'
import { FormPaper } from 'components/Paper'

const AddressForm = ({ address, onAddressChange, onAddressSelect }) => {
  return (
    <FormPaper>
      <H2>Address</H2>
      <LocationTypeahead
        freeSolo
        onAddressSelect={onAddressSelect}
        onAddressChange={onAddressChange}
        value={address.street}
      />
      <TextField
        label="Unit, Floor, Etc."
        name="street2"
        value={address.street2 || ''}
        onChange={onAddressChange}
        sx={{ my: 1 }}
        fullWidth
      />
      <TextField
        label="City"
        name="city"
        value={address.city || ''}
        onChange={onAddressChange}
        sx={{ my: 1 }}
        fullWidth
      />
      <TextField
        label="State"
        name="state"
        value={address.state || ''}
        onChange={onAddressChange}
        sx={{ my: 1 }}
        fullWidth
      />
      <TextField
        label="Country"
        name="country"
        value={address.country || ''}
        onChange={onAddressChange}
        sx={{ my: 1 }}
        fullWidth
      />
      <TextField
        label="Postal Code"
        name="postalCode"
        value={address.postalCode || ''}
        onChange={onAddressChange}
        sx={{ my: 1 }}
        fullWidth
      />
    </FormPaper>
  )
}

AddressForm.propTypes = {
  address: PropTypes.object.isRequired,
  onAddressChange: PropTypes.func.isRequired,
  onAddressSelect: PropTypes.func.isRequired,
}

export default AddressForm
