import React from 'react'

import { DataGrid } from '@the-platform-group/component-library/DataGrid'
import { getColumnValues, getRowValues } from './CustomerTableValues'

import { useSessionState } from 'contexts/Session'
import useCustomerAuthorization from 'components/CustomerActionsDropdown/hooks/useCustomerAuthorization'

function CustomersTable({
  isLoading,
  customers,
  pagination,
  setPagination,
  handleUserAdded,
  handleCustomerEdit,
  handleCustomerFeatureToggle,
  handleCustomerArchive,
  handleCustomerUnarchive,
  handleCustomerSecurity,
}) {
  const { currentUser } = useSessionState()
  const { setupAuthorizeCustomerMutation } = useCustomerAuthorization()

  setupAuthorizeCustomerMutation()

  const { page, itemCount = 0, pageSize } = pagination

  const handlePageChange = newPage => {
    setPagination(prevState => ({
      ...prevState,
      page: newPage,
      pageOffset: newPage * pagination.pageSize,
    }))
  }

  const handleRowsChange = newPageSize => {
    setPagination(prevState => ({ ...prevState, page: 0, pageSize: newPageSize, pageOffset: 0 }))
  }

  const handlers = {
    handleUserAdded,
    handleCustomerEdit,
    handleCustomerFeatureToggle,
    handleCustomerArchive,
    handleCustomerUnarchive,
    handleCustomerSecurity,
  }

  return (
    <DataGrid
      name="customerAccounts"
      columns={getColumnValues()}
      commonColumnProps={{ headerAlign: 'left' }}
      getRowHeight={() => 'auto'} // set row height to auto since this table doesn't have density selector
      rows={getRowValues(customers, currentUser.customerAuthorizations, handlers)}
      sx={{
        '.MuiDataGrid-actionsCell .MuiIconButton-root': { px: 1 },
        '.hoverCell': {
          '&.MuiDataGrid-cell:hover span, &.MuiDataGrid-cell:hover .MuiButtonBase-root': {
            display: 'block',
          },
          'span, .MuiButtonBase-root': { display: 'none' },
        },
      }}
      loading={isLoading}
      localeText={{
        noRowsLabel:
          'Looks like nothing matches your search. Try removing some criteria or attempt a new search.',
      }}
      page={page}
      pageSize={pageSize}
      onPageChange={handlePageChange}
      onPageSizeChange={handleRowsChange}
      rowCount={itemCount}
      pageSizeOptions={[10, 25, 50]}
    />
  )
}

export default CustomersTable
