// columnNames must always contain an id
// this must be in the order on how we want to display the headers from left to right
// Props:
// - name (required): camelCase format of header name, e.g. firstName to display First Name on header
// - hide: false by default. If set to true, this column wouldn't be displayed
import { Edit as EditIcon } from '@the-platform-group/component-library/Icons'
import TableProfilePicture from 'components/ProfilePicture/TableProfilePicture'

import { DataGridActionCell } from '@the-platform-group/component-library/DataGrid'
import { ACTIONS } from '@the-platform-group/component-library/DataGrid/constant'

import { Link } from 'react-router-dom'

export const getPeopleColumns = () => [
  { field: 'firstName' },
  { field: 'lastName' },
  { field: 'organization', headerName: 'Organization (Job Title)' },
  { field: 'updatedAt', type: 'date' },
  { field: 'createdAt', type: 'date' },
  {
    field: 'linkedinProfileLink',
    valueGetter: ({ row: { personSocialProfiles } }) =>
      personSocialProfiles?.find(psp => psp.provider === 'linked_in')?.url,
    align: 'center',
    resizable: true,
  },
  { field: 'profilePicture', renderCell: TableProfilePicture },
  {
    field: 'actions',
    type: ACTIONS,
    getActions: ({ id }) => [
      <DataGridActionCell
        icon={<EditIcon />}
        component={Link}
        to={`/factset/people/${id}`}
        title="Edit"
      />,
    ],
  },
]

const getJobsByOrganization = (org, jobs) =>
  jobs.reduce((jobAcc, job) => {
    if (job.active && job.organizationId === org.id) {
      jobAcc.push(`${org.name || '-'} (${job.title || '-'})`)
    }

    return jobAcc
  }, [])

export const getPeopleRows = ds1People =>
  ds1People.map(person => {
    const {
      id,
      firstName,
      lastName,
      organizations,
      updatedAt,
      createdAt,
      jobs,
      personSocialProfiles,
      personProfileImage,
    } = person
    const jobOrgInfo = organizations.map(org => getJobsByOrganization(org, jobs))
    return {
      id,
      firstName,
      lastName,
      organization: jobOrgInfo.join(', '),
      updatedAt,
      createdAt,
      linkedinProfileLink: '',
      jobs,
      personSocialProfiles,
      personProfileImage,
    }
  })
