import { Typography } from '@the-platform-group/component-library'
import {
  Table,
  TableBody,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableCell,
} from '@the-platform-group/component-library/Table'

function DefinitionsTable({ title = '', definitions = [] } = {}) {
  return (
    <Table data-testid={`${title}-table`}>
      <TableHead>
        <TableRow>
          <TableHeaderCell colSpan={2} align="center">
            <Typography variant="button">{title.toUpperCase()}</Typography>
          </TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {definitions.map(definition => (
          <TableRow key={definition.label}>
            <TableCell>
              <Typography variant="subtitle2">{definition.label}</Typography>
            </TableCell>
            <TableCell align="right">{definition.range}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default DefinitionsTable
