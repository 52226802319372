import React from 'react'
import { Route, Switch } from 'react-router-dom'

import FactsetTable from 'pages/Factset/FactsetTable'
import DS1Person from 'pages/Ds1People/DS1Person'
import Securities from 'pages/Factset/Securities'
import FactsetLocations from 'pages/Factset/FactsetLocations'
import FactsetUsers from 'pages/Factset/FactsetUsers'
import FactsetLocationDetail from 'pages/Factset/FactsetLocationDetail'
import FactsetUserDetail from 'pages/Factset/FactsetUserDetail'

import { FactsetMetadataProvider } from 'contexts/FactsetMetadataContext'

function FactsetRouter() {
  return (
    <Switch>
      <Route exact path="/factset/people" component={() => <FactsetTable entity="people" />} />
      <Route exact path="/factset/people/:id" component={DS1Person} />
      <Route
        exact
        path="/factset/organizations"
        component={() => <FactsetTable entity="organizations" />}
      />
      <Route exact path="/factset/securities" component={Securities} />
      <FactsetMetadataProvider>
        <Route exact path="/factset/locations" component={FactsetLocations} />
        <Route exact path="/factset/locations/:id" component={FactsetLocationDetail} />
        <Route exact path="/factset/users" component={FactsetUsers} />
        <Route exact path="/factset/users/:id" component={FactsetUserDetail} />
      </FactsetMetadataProvider>
    </Switch>
  )
}

export default FactsetRouter
