import React from 'react'
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from '@the-platform-group/component-library'
import { humanizeSnakeCase } from 'utils/format'

import AVAILABLE_FEATURES, { FEATURE_LABEL_MAPPING } from 'constants/features'
import useFeatures from './useFeatures'

export default function FeatureToggles({ features: currentFeatures, onToggleChange }) {
  const { checked, handleToggle } = useFeatures({ currentFeatures, onToggleChange })

  return (
    <List>
      {AVAILABLE_FEATURES.map(feature => (
        <ListItem key={feature}>
          <ListItemText
            id={feature}
            primary={FEATURE_LABEL_MAPPING[feature] || humanizeSnakeCase(feature)}
          />
          <ListItemSecondaryAction>
            <Switch
              edge="end"
              color="primary"
              onChange={handleToggle(feature)}
              checked={checked.indexOf(feature) !== -1}
              inputProps={{ 'aria-labelledby': `${feature}-switch` }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  )
}
