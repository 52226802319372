import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import { NavLink as Link, useRouteMatch } from 'react-router-dom'

import {
  Collapse,
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  styled,
} from '@the-platform-group/component-library'
import {
  AccountBalance as AccountBalanceIcon,
  AccountBox as AccountBoxIcon,
  AutoAwesome as AutoAwesomeIcon,
  Business as BusinessIcon,
  ExpandLess,
  ExpandMore,
  Home as HomeIcon,
  ImportExport as ImportIcon,
  LocationCity as LocationCityIcon,
  People as PeopleIcon,
  Share as ShareIcon,
  Tune as TuneIcon,
  Person as PersonIcon,
  ListAlt as ListAltAIcon,
  CloudUpload as CloudUploadIcon,
  CallMerge as MergeIcon,
  LocationOn as LocationIcon,
} from '@the-platform-group/component-library/Icons'

import { useSessionState } from 'contexts/Session'
import { isAdmin } from 'utils/auth'

import { NoboRoutes } from 'pages/Nobo/NoboRouter'

import { useFlag } from '@unleash/proxy-client-react'
import { FEATURE_FLAG_MERGE_PROFILES, FEATURE_FLAG_AI_SUMMARY } from 'constants/featureFlag'

const SIDE_NAV_WIDTH = 240

const ListItemLink = props => {
  const { icon, primary, to } = props
  const matchingPath = useRouteMatch(to)

  return (
    <ListItem
      className={props.className} // needed for `styled` components that use this as base
      to={to}
      button
      component={Link}
      sx={matchingPath && to !== '/' ? { bgcolor: 'primary.bright' } : {}} // highlight menu of current path
      aria-label={primary}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={primary} />
    </ListItem>
  )
}

ListItemLink.propTypes = {
  to: PropTypes.string.isRequired,
  primary: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
}

const NestedListItemLink = styled(ListItemLink)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
}))

const ListItemCollapsable = props => {
  const [open, setOpen] = useState(true)

  const handleClick = () => {
    setOpen(!open)
  }

  const { primary, icon, children } = props

  return (
    <Fragment>
      <List component="div" disablePadding>
        <ListItem button onClick={handleClick}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={primary} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </List>
    </Fragment>
  )
}

ListItemCollapsable.propTypes = {
  primary: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
}

const MenuSideComponent = props => {
  const { currentUser } = useSessionState()
  const isMergeProfileEnabled = useFlag(FEATURE_FLAG_MERGE_PROFILES)
  const isAiSummaryEnabled = useFlag(FEATURE_FLAG_AI_SUMMARY)

  return (
    <Drawer
      sx={{
        width: SIDE_NAV_WIDTH,
        flexShrink: 0,
      }}
      variant="permanent"
      PaperProps={{
        sx: {
          width: SIDE_NAV_WIDTH,
        },
      }}
    >
      <Box sx={theme => theme.mixins.toolbar} />
      <List>
        <ListItemLink to="/" primary="Home" icon={<HomeIcon />} />
        {/* TODO we should extract out "roles" and components that match them. DON'T copy/paste this!! */}
        {isAdmin(currentUser) && (
          <ListItemLink to="/accounts/customers" primary="Accounts" icon={<AccountBoxIcon />} />
        )}
        {isAdmin(currentUser) && <ListItemLink to="/users" primary="Users" icon={<PersonIcon />} />}
        <ListItemLink to="/imports/contacts" primary="Irwin Imports" icon={<ImportIcon />} />
        <ListItemLink to="/affiliations" primary="Affiliations" icon={<ShareIcon />} />
        {isMergeProfileEnabled && (
          <ListItemLink to="/merge-profiles" primary="Merge Profiles" icon={<MergeIcon />} />
        )}

        <Divider />

        <ListItemCollapsable primary="Platform Data" icon={<TuneIcon />}>
          <NestedListItemLink to="/platform/people" primary="People" icon={<PeopleIcon />} />
          <NestedListItemLink
            to="/platform/organizations"
            primary="Organizations"
            icon={<BusinessIcon />}
          />
        </ListItemCollapsable>

        <Divider />

        <ListItemCollapsable primary="Factset Data" icon={<LocationCityIcon />}>
          <NestedListItemLink
            to="/factset/securities"
            primary="Securities"
            icon={<AccountBalanceIcon />}
          />
          <NestedListItemLink to="/factset/people" primary="People" icon={<PeopleIcon />} />
          <NestedListItemLink
            to="/factset/organizations"
            primary="Organizations"
            icon={<BusinessIcon />}
          />
          <NestedListItemLink to="/factset/locations" primary="Locations" icon={<LocationIcon />} />
          <NestedListItemLink to="/factset/users" primary="Users" icon={<PersonIcon />} />
        </ListItemCollapsable>
        <Divider />
        <ListItemCollapsable primary="Surveillance" icon={<LocationCityIcon />}>
          {isAdmin(currentUser) && (
            <NestedListItemLink
              to="/reporting-grants"
              primary="Permissions"
              icon={<PeopleIcon />}
            />
          )}
          <NestedListItemLink to="/surveillance" primary="Reports" icon={<AccountBalanceIcon />} />
        </ListItemCollapsable>
        <Divider />
        <ListItemCollapsable primary="NOBO" icon={<LocationCityIcon />}>
          <NestedListItemLink
            to={NoboRoutes.listReports}
            primary="Reports"
            icon={<ListAltAIcon />}
          />
          <NestedListItemLink to={NoboRoutes.listReviews} primary="Reviews" icon={<PeopleIcon />} />
        </ListItemCollapsable>
        <ListItemLink
          to="/irwin_assistant/requests"
          primary="Irwin Assistant"
          icon={<CloudUploadIcon />}
        />
        {isAiSummaryEnabled && isAdmin(currentUser) && (
          <ListItemLink
            to="/irwin_assistant/summaries"
            primary="Irwin Assistant Summaries"
            icon={<AutoAwesomeIcon />}
          />
        )}
      </List>
    </Drawer>
  )
}

export default MenuSideComponent
