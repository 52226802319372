import { useState, useEffect } from 'react'
import { snake } from 'change-case-object'
import { getContactImport, saveImport } from 'services/imports'
import { useNotification } from 'components/Notification'
import useFocus from 'hooks/useFocus'
import { formatFocus } from 'transforms/focus'
import IMPORT_TYPES from 'constants/importTypes'
import { MARKET_CAP_ERROR_FIELD } from 'constants/marketCaps'

const useContactImportForm = (id, history) => {
  const [values, setValues] = useState({
    contact: {
      active: true,
      retailDiscretionaryFlag: false,
      focuses: [],
    },
    customer: {},
    address: {},
    institution: {
      focuses: [],
    },
    selectedOrganization: null,
  })
  const { contact, customer, address, institution, selectedOrganization, identification } = values
  const { createNotification } = useNotification()

  const {
    focuses: contactFocuses,
    handleFocusCopy,
    handleFocusChange: handleContactFocusChange,
    handleFocusDelete: handleContactFocusDelete,
  } = useFocus(contact.focuses, selectedOrganization)

  const {
    focuses: institutionFocuses,
    handleFocusChange: handleInstitutionFocusChange,
    handleFocusDelete: handleInstitutionFocusDelete,
  } = useFocus(institution.focuses, selectedOrganization)

  useEffect(() => {
    const fetchContactImports = async () => {
      try {
        const response = (await getContactImport(id)).data
        const selectedOrg =
          (response.institution.id &&
            // https://gitlab.com/the-platform-group/admin-app/admin-fe/issues/72
            response.institution.source !== 'CustomInstitution' && { ...response.institution }) ||
          null

        setValues({
          customer: response.customer,
          contact: {
            ...response.contact,
            active: true,
            retailDiscretionaryFlag: false,
            source_id: response.contact.id,
            focuses: [],
          },
          address: response.address,
          institution: {
            ...response.institution,
            source_id: response.institution.id,
            organization_id: response.identification && response.identification.commonId,
            type: 'retail',
            focuses: [],
          },
          identification: response.identification,
          selectedOrganization: selectedOrg,
        })
      } catch (error) {
        setValues(() => {
          throw error
        })
      }
    }

    fetchContactImports()
  }, [id])

  const handleSave = async ({ back = false }) => {
    let newId = null
    try {
      const response = await saveImport(
        formatContact(
          { ...contact, focuses: contactFocuses },
          { ...institution, focuses: institutionFocuses },
          address,
          selectedOrganization,
        ),
      )
      newId = response.data.id
    } catch (e) {
      // handle 401s and 403s in error boundary
      if (e.status === 401 || e.status === 403) {
        setValues(() => {
          throw e
        })
        return
      }

      // intentionally throw on market cap definition changes,
      // these errors will be handled by an error boundary that will
      // refresh the values on the page
      if (e.response?.data?.errors?.map(e => e.field).includes(MARKET_CAP_ERROR_FIELD)) {
        setValues(() => {
          throw e
        })
        return
      }

      createNotification({ message: `errors: ${e.message}`, variant: 'error' })
      return
    }

    createNotification({ message: 'Person saved successfully' })
    if (back) {
      history.push('/imports/contacts')
    } else {
      history.push(`/platform/people/${newId}/edit`)
    }
  }

  const handleContactChange = ({ target }) => {
    setValues(prev => ({ ...prev, contact: { ...contact, [target.name]: target.value } }))
  }

  const handleCheckboxChange = ({ target }) => {
    setValues(prev => ({ ...prev, contact: { ...contact, [target.name]: target.checked } }))
  }

  const handleAddressSelect = selAddress => {
    const { coordinates, ...rest } = selAddress
    coordinates &&
      setValues(prev => ({
        ...prev,
        address: {
          ...rest,
          latLng: [coordinates.lat, coordinates.lng],
        },
      }))
  }

  const handleAddressChange = ({ target }) => {
    setValues(prev => ({
      ...prev,
      address: {
        ...values.address,
        [target.name]: target.value,
      },
    }))
  }

  const handleInstitutionChange = ({ target }) => {
    setValues(prev => ({ ...prev, institution: { ...institution, [target.name]: target.value } }))
  }

  const handleOrganizationSelect = organization => {
    setValues(prev => ({ ...prev, selectedOrganization: organization && { ...organization } }))
  }
  // This is for when we are using a location typeahead only (with one input)
  // const formattedAddress = [
  //   address.street, address.street2, address.city, address.state, address.country,
  // ].filter(Boolean).join(', ')

  const handleFocusChange = (e, type) => {
    if (type === IMPORT_TYPES.CONTACT) {
      handleContactFocusChange(e)
    } else {
      handleInstitutionFocusChange(e)
    }
  }

  const handleFocusDelete = (delFocus, type) => {
    if (type === IMPORT_TYPES.CONTACT) {
      handleContactFocusDelete(delFocus)
    } else {
      handleInstitutionFocusDelete(delFocus)
    }
  }

  return {
    customer,
    contact,
    address, // formattedAddress,
    institution,
    contactFocuses,
    institutionFocuses,
    selectedOrganization,
    identification,
    handleFocusCopy,
    handleContactChange,
    handleAddressSelect,
    handleAddressChange,
    handleInstitutionChange,
    handleOrganizationSelect,
    handleFocusChange,
    handleFocusDelete,
    handleSave,
    handleCheckboxChange,
  }
}

function formatContact(contact, institution, address, selectedOrganization) {
  const { id, street2, label, ...restAddress } = address
  const { focuses: jobFocuses, ...restContact } = contact
  const { focuses: orgFocuses, ...restInstitution } = institution
  const formattedJobFocuses = formatFocus(jobFocuses)
  const formattedOrgFocuses = formatFocus(orgFocuses)

  return {
    contact: { ...snake(restContact), job_focuses_attributes: formattedJobFocuses },
    institution: {
      ...snake(restInstitution),
      organization_id:
        institution.organization_id || (selectedOrganization && selectedOrganization.id),
      address_attributes: { street2, ...snake(restAddress) },
      focuses_attributes: formattedOrgFocuses,
    },
  }
}

export default useContactImportForm
