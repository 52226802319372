import React from 'react'
import { Grid, Divider } from '@the-platform-group/component-library'
import { Button } from '@the-platform-group/component-library/Button'
import {
  Modal as ModalComponent,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@the-platform-group/component-library/Modal'

const Modal = ({
  open,
  handleClose,
  handleSave,
  title,
  children,
  saveButtonText,
  footer,
  ...rest
}) => {
  return (
    <ModalComponent open={open} onClose={handleClose} {...rest}>
      {title && <ModalTitle title={title} />}
      <ModalContent>{children}</ModalContent>
      <ModalActions>
        <Grid container direction="column" spacing={2}>
          {footer && (
            <Grid item xs>
              <Divider />
            </Grid>
          )}
          <Grid item container spacing={2} alignItems="center">
            <Grid item container justifyContent="flex-end" xs={8}>
              <Grid item>{footer}</Grid>
            </Grid>
            <Grid item container justifyContent="flex-end" spacing={1} xs={4}>
              {handleClose && (
                <Grid item>
                  <Button variant="text" onClick={handleClose}>
                    Cancel
                  </Button>
                </Grid>
              )}
              {handleSave && (
                <Grid item>
                  <Button onClick={handleSave}>{saveButtonText || 'Save'}</Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </ModalActions>
    </ModalComponent>
  )
}

export default Modal
