import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ReviewListComponent from 'pages/Nobo/ReviewListComponent'
import ReviewEditComponent from 'pages/Nobo/ReviewEditComponent'
import ReportListComponent from 'pages/Nobo/ReportListComponent'

const NoboRouter = () => (
  <Switch>
    <Route exact path="/nobo/review" component={ReviewListComponent} />
    <Route path="/nobo/review/:id" component={ReviewEditComponent} />
    <Route exact path="/nobo/reports" component={ReportListComponent} />
  </Switch>
)

const NoboRoutes = {
  listReviews: '/nobo/review',
  editReview: id => `/nobo/review/${id}`,
  listReports: '/nobo/reports',
}

export { NoboRouter, NoboRoutes }
