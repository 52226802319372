import RollbarRaw from 'rollbar'

const rollbarEnv = import.meta.env.MODE
const rollbarConfig = {
  accessToken: '38901a6d3ca0445c9065beacf86e53be',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled:
    window.location.hostname !== 'localhost' &&
    (rollbarEnv === 'production' || rollbarEnv === 'staging'),
  payload: {
    environment: rollbarEnv,
  },
}
const Rollbar = new RollbarRaw(rollbarConfig)

export default Rollbar
