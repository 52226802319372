import React from 'react'
import { useMutation } from 'react-query'
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  EditAttributes as EditAttributesIcon,
  ListAlt as ListAltIcon,
} from '@the-platform-group/component-library/Icons'
import { ListItemIcon, ListItemText } from '@the-platform-group/component-library'
import { DropdownButton, Button } from '@the-platform-group/component-library/Button'

const DropDownOption = ({ icon, color = 'primary.main', label }) => {
  return (
    <>
      <ListItemIcon sx={{ color }}>{icon}</ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </>
  )
}

const customerActionOptions = data => {
  const { onCustomerEdit, onCustomerFeatureToggle, onCustomerSecurity, onCustomerArchive } = data
  return [
    {
      label: <DropDownOption icon={<EditIcon />} label="Edit Account Name" />,
      onClick: onCustomerEdit,
    },
    {
      label: <DropDownOption icon={<EditAttributesIcon />} label="Toggle Features" />,
      onClick: onCustomerFeatureToggle,
    },
    {
      label: <DropDownOption icon={<ListAltIcon />} label="Link Securities to NOBO" />,
      onClick: onCustomerSecurity,
      divider: true,
    },
    {
      label: <DropDownOption icon={<DeleteIcon />} color="error.main" label="Archive Customer" />,
      onClick: onCustomerArchive,
    },
  ]
}

function CustomerActionsDropdown({ data }) {
  const { id, authorization, archived, onCustomerUnarchive } = data
  const { isLoading, mutate: authorizeCustomer } = useMutation({
    mutationKey: ['authorizeCustomer'],
  })

  const buttonStyle =
    authorization?.scope === 'user'
      ? {
          sx: { backgroundColor: 'success.main' },
          color: 'success',
          label: 'User Login',
        }
      : { label: 'Assistant Login' }

  return archived ? (
    <Button onClick={onCustomerUnarchive}>Unarchive</Button>
  ) : (
    <DropdownButton
      gridStyle={{ zIndex: 1 }}
      popperProps={{ disablePortal: false }}
      options={customerActionOptions(data)}
      isLoading={isLoading}
      handleClick={() => authorizeCustomer(id)}
      buttonStyle={{ whiteSpace: 'nowrap' }}
      {...buttonStyle}
    />
  )
}

export default CustomerActionsDropdown
