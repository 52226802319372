import React from 'react'
import { Controller } from 'react-hook-form'
import debounce from 'utils/debounce'
import { AsyncSelect, List, ListItem, Alert, Grid } from '@the-platform-group/component-library'
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@the-platform-group/component-library/Modal'
import { Button } from '@the-platform-group/component-library/Button'
import useFactsetUserSearch from 'pages/Factset/hooks/useFactsetUserSearch'
import UserSelector from 'pages/Factset/Components/UserSelector'
import { useLinkFactsetUser, useLinkFactsetUserForm } from '../hooks/useLinkFactsetUser'

const LinkFactsetUserModal = ({ irwinUser, factsetUser, isModalOpen, closeModal }) => {
  const searchFactsetUsers = useFactsetUserSearch()
  const { formState, handleSubmit, control } = useLinkFactsetUserForm({
    irwinUser,
    factsetUser,
    isModalOpen,
  })

  const { mutate: linkFactsetUser, isLoading: isSaving, error } = useLinkFactsetUser({
    onSuccess: closeModal,
    isModalOpen,
  })

  return (
    <Modal open={isModalOpen} closeModal={closeModal}>
      <ModalTitle title="Link Irwin User with Factset User" />
      <ModalContent>
        <Grid container spacing={1} direction="column">
          {/* In cases where we already know the factset user based on the context 
        (ie. user is on the factset user details page), disable factset user selector */}
          <Grid item>
            <Controller
              control={control}
              name="factsetUser"
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <AsyncSelect
                    disabled={Boolean(factsetUser)}
                    includeClearAll
                    fullWidth
                    variant="outlined"
                    label="Factset User *"
                    placeholder="Search for Factset user"
                    optionsLoader={debounce(searchFactsetUsers)}
                    {...field}
                  />
                )
              }}
            />
          </Grid>

          {/* In cases where we already know the Irwin user based on the context 
        (ie. user is on the Irwin user details page), disable Irwin user selector */}
          <Grid item>
            <UserSelector
              disabled={Boolean(irwinUser)}
              name="irwinUser"
              control={control}
              required
            />
          </Grid>
        </Grid>

        <List>
          {error?.response?.data?.errors?.map(error => (
            <ListItem key={error} sx={{ px: 0 }}>
              <Alert severity="error">{error}</Alert>
            </ListItem>
          ))}
        </List>
      </ModalContent>
      <ModalActions>
        <Button variant="text" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          isLoading={isSaving}
          disabled={!formState.isValid || isSaving}
          onClick={handleSubmit(linkFactsetUser)}
        >
          Link
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default LinkFactsetUserModal
