import Jsona, { SwitchCaseJsonMapper } from 'jsona'
import { camelCase, snakeCase } from 'change-case-object'

import { irwin } from 'config'
import { httpClient } from './httpClient'

const switchCaseOptions = {
  switchChar: '_',
}
const deserializer = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper(switchCaseOptions),
})

function client() {
  return httpClient(irwin.baseUrl)
}

async function getSecurityForHolder(params) {
  const response = await client().get('/v2/research/supplemental_holdings', {
    params: snakeCase(params),
  })

  return {
    data: deserializer.deserialize(response),
    total: camelCase(response.meta.item_count || 0),
    links: response.links,
  }
}

async function getTasks(params) {
  const response = await client().get('/v2/research/tasks', {
    params: snakeCase(params),
  })

  return {
    data: deserializer.deserialize(response),
    total: camelCase(response.meta.item_count || 0),
    links: response.links,
  }
}

async function getNotes(params) {
  const response = await client().get('/v2/research/notes', {
    params: snakeCase(params),
  })

  return {
    data: deserializer.deserialize(response),
    total: camelCase(response.meta.item_count || 0),
    links: response.links,
  }
}

export { getSecurityForHolder, getTasks, getNotes }
