import React from 'react'
import { Switch, Route } from 'react-router-dom'
import ContactImports from './ContactImportsComponent'
import ContactImportForm from './ContactImportForm'

const ContactImportRouter = () => (
  <Switch>
    <Route exact path="/imports/contacts/:id/edit" component={ContactImportForm} />
    <Route exact path="/imports/contacts" component={ContactImports} />
  </Switch>
)

export default ContactImportRouter
