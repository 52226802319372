const COVERAGE_STYLES = {
  yield: 'Yield',
  value: 'Value',
  garp: 'GARP',
  aggressive_growth: 'Aggressive Growth',
  growth: 'Growth',
  index: 'Index',
  deep_value: 'Deep Value',
  esg: 'ESG',
}

export default COVERAGE_STYLES
