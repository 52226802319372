import { useContext, useEffect } from 'react'
import { getCustomerAffiliations } from 'services/customerAffiliations'
import { Context as CustomerAffiliationContext } from '../contexts/CustomerAffiliationContext'

function useCustomerAffiliations(affiiatedId) {
  const context = useContext(CustomerAffiliationContext)
  if (!context) {
    throw new Error(`useCustomerAffiliations must be used within a CustomerAffiliationProvider`)
  }
  const [state, dispatch] = context

  useEffect(() => {
    const fetchCustomerAffiliations = async ({ affiiatedId }) => {
      try {
        const options = {
          affiliated_id: affiiatedId,
          page_offset: state.page * state.rowsPerPage, // Need to start at 0 to support @material-ui pagination
          page_size: Number(state.rowsPerPage),
        }
        dispatch({ type: 'AFFILIATION:FETCH_STARTED' })
        const {
          data,
          total,
          links: { self, next, prev },
        } = await getCustomerAffiliations(options)
        dispatch({
          type: 'AFFILIATION:FETCH_RECEIVED',
          payload: {
            affiliations: data,
            total: total,
            self: self,
            next: next,
            prev: prev,
            page: state.page,
            rowsPerPage: state.rowsPerPage,
          },
        })
      } catch (error) {
        dispatch({ type: 'AFFILIATION:FAILED', payload: error })
      }
    }

    if (affiiatedId) {
      fetchCustomerAffiliations({ affiiatedId })
    }
  }, [dispatch, affiiatedId, state.page, state.rowsPerPage])

  const setPage = page => {
    dispatch({ type: 'AFFILIATION:SET_PAGE', payload: { page } })
  }

  const setRowsPerPage = ({ page, rowsPerPage }) => {
    dispatch({ type: 'AFFILIATION:SET_ROWS_PER_PAGE', payload: { page, rowsPerPage } })
  }

  return {
    state,
    setPage,
    setRowsPerPage,
  }
}

export default useCustomerAffiliations
