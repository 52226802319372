const jobFunctions = {
  investment_advisor: 'investment advisor',
  fixed_income_analyst: 'fixed income analyst',
  analyst: 'analyst',
  equity_portfolio_manager: 'equity portfolio manager',
  fixed_income_portfolio_manager: 'fixed income portfolio manager',
  portfolio_manager: 'portfolio manager',
  equity_trader: 'equity trader',
  fixed_income_trader: 'fixed income trader',
  trader: 'trader',
  chief_investment_officer: 'chief investment officer',
  director_of_research: 'director of research',
  head_of_equities: 'head of equities',
  head_of_fixed_income: 'head of fixed income',
  head_of_trading: 'head of trading',
  consultant: 'consultant',
  investment_banker: 'investment banker',
  institutional_sales: 'institutional sales',
  sell_side_research: 'sell side research',
  corporate_access_representative: 'corporate access representative',
  chief_executive_officer: 'chief executive officer',
  chief_operating_officer: 'chief operating officer',
  chief_financial_officer: 'chief financial officer',
  investor_relations_representative: 'investor relations representative',
  managing_director: 'managing director',
  managing_partner: 'managing partner',
  business_development: 'business development',
  founder: 'founder',
  private_equity_investor: 'private equity investor',
  quantitative_portfolio_analyst: 'quantitative portfolio analyst',
  quantitative_portfolio_manager: 'quantitative portfolio manager',
  investment_stewardship_representative: 'investment stewardship representative',
}

export default jobFunctions

export const FEED_VALID_JOB_FUNCTIONS = [
  'analyst',
  'chief_investment_officer',
  'director_of_investments',
  'director_of_research',
  'equity_analyst',
  'equity_portfolio_manager',
  'head_of_equities',
  'investment_advisor',
  'managing_partner',
  'portfolio_manager',
]
