import React from 'react'
import { BrowserRouter as Router, useHistory, useLocation } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'

import {
  MuiThemeProvider as ThemeProvider,
  StyledEngineProvider,
  Box,
  CssBaseline,
  AdapterDateFns,
  MuiPickersUtilsProvider,
} from '@the-platform-group/component-library'
import Theme from '@the-platform-group/component-library/Theme'

import { SessionProvider } from 'contexts/Session'
import Layout from './Layout'
import GenericErrorBoundary from 'boundaries/GenericErrorBoundary'
import ForbiddenErrorBoundary from 'boundaries/ForbiddenErrorBoundary'
import { auth0Config } from 'utils/auth'
import MaintenanceErrorBoundary from 'boundaries/MaintenanceErrorBoundary'
import { loadDataGridPro } from '@the-platform-group/component-library/DataGrid'
import { QueryClientProvider } from 'react-query'
import queryClient from 'store/queryClient'
import MarketCapsErrorBoundary from 'boundaries/MarketCapsErrorBoundary'

loadDataGridPro(import.meta.env.VITE_MUI_DATAGRID_KEY)

function AuthorizationWrapper({ children }) {
  const { pathname, search } = useLocation()
  const history = useHistory()

  // we support setting the Auth0 connection from the "idp" query param which is
  // useful for testing SSO connections.  We use this over use-query-params because
  // the Auth0Provider is not wrapped in the QueryParamProvider and we don't need
  // to react to changes to this param or mutate it, only the initial value is used.
  const params = new URLSearchParams(search)

  if (params.has('idp')) {
    auth0Config.connection = params.get('idp')
  }

  function onRedirectCallback(appState) {
    history.push(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      {...auth0Config}
      forgotPassword={pathname === '/forgot_password'}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  )
}

const App = () => {
  return (
    <MuiPickersUtilsProvider dateAdapter={AdapterDateFns}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={Theme}>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <CssBaseline />
            <Router>
              <MaintenanceErrorBoundary>
                <AuthorizationWrapper>
                  <GenericErrorBoundary>
                    <ForbiddenErrorBoundary>
                      <SessionProvider>
                        <QueryClientProvider client={queryClient}>
                          <MarketCapsErrorBoundary>
                            <Layout />
                          </MarketCapsErrorBoundary>
                        </QueryClientProvider>
                      </SessionProvider>
                    </ForbiddenErrorBoundary>
                  </GenericErrorBoundary>
                </AuthorizationWrapper>
              </MaintenanceErrorBoundary>
            </Router>
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    </MuiPickersUtilsProvider>
  )
}

export default App
