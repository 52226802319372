import React from 'react'
import { Grid, Typography, Box, CustomLink as Link } from '@the-platform-group/component-library'
import { Person as PersonIcon } from '@the-platform-group/component-library/Icons'

const AssigneeCell = props => {
  const { params } = props

  return params.value?.id ? <Unassign {...props} /> : <Assign {...props} />
}

const Unassign = ({ handleRowChange, params, currentUser }) => {
  return (
    <Grid container direction="column">
      <Box>
        <PersonIcon sx={{ mr: 1, fontSize: 15 }} />
        <Typography variant="subtitle">
          {params.value.firstName} {params.value.lastName}
        </Typography>
      </Box>
      <Typography variant="subtitle">
        {currentUser.id === params.value.id && (
          <Link
            color="primary"
            id={params.value.id}
            sx={{ cursor: 'pointer' }}
            onClick={_ => handleRowChange({ ...params.row, assignee: null })}
            to="#"
          >
            Unassign Myself
          </Link>
        )}
      </Typography>
    </Grid>
  )
}

const Assign = ({ handleRowChange, params, currentUser }) => {
  return (
    <Link
      sx={{ cursor: 'pointer' }}
      onClick={_ => handleRowChange({ ...params.row, assignee: { id: currentUser.id } })}
    >
      Assign Myself
    </Link>
  )
}

export default AssigneeCell
