import React from 'react'
import { Route, Switch } from 'react-router-dom'
import OrganizationsComponent from './OrganizationsComponent'
import OrganizationFormComponent from './OrganizationFormComponent'

const OrganizationsRouter = () => (
  <Switch>
    <Route exact path="/platform/organizations/:id/edit" component={OrganizationFormComponent} />
    <Route exact path="/platform/organizations/new" component={OrganizationFormComponent} />
    <Route exact path="/platform/organizations" component={OrganizationsComponent} />
  </Switch>
)

export default OrganizationsRouter
