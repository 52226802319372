import { useQueryClient } from 'react-query'

import { useNotification } from 'components/Notification'
import { getUsers } from 'services/users'
import { transformIrwinUserForSelect } from '../Utils'

/**
 * @returns {function} async function that searches an Irwin user based on term
 */
function useUserSearch() {
  const { createNotification } = useNotification()
  const queryClient = useQueryClient()

  const searchUsers = async searchTerm => {
    try {
      const userSearchParams = {
        nameTerm: searchTerm,
        pageOffset: 0,
        pageSize: 20,
      }
      const data = await queryClient.fetchQuery(
        ['getUsers', userSearchParams],
        () => getUsers(userSearchParams),
        { cacheTime: 0 },
        // Q: Why are we setting cacheTime to 0 here? 🤔
        // Short Answer: circular ref in the data
        // Long Answer: see link below:
        // https://gitlab.com/the-platform-group/irwin-app/irwin-api/-/issues/2210
      )

      return data?.data.map(transformIrwinUserForSelect)
    } catch (error) {
      createNotification({
        variant: 'error',
        message: `Error fetching irwin users "${searchTerm}"`,
      })
      return []
    }
  }

  return { searchUsers }
}

export default useUserSearch
