import { getCustomers } from 'services/customers'
import { searchOrganizationsAdmin } from 'services/organizations'
import { searchPeopleAdmin } from 'services/people'
import debounce from 'utils/debounce'
import { getFullName } from '@the-platform-group/formatters/people'

const transformForSelectDisplay = ({ id, name, type }) => ({
  id,
  label: name,
  value: id,
  secondaryText: type ? `Account type: ${type}` : '',
})

// Transform organizations for select display
const transformForSelectDisplayOrganization = organization => ({
  id: organization.id,
  label: organization.name,
  value: organization.id,
})

// Transform people for select display
const transformForSelectDisplayPeople = person => ({
  id: person.id,
  label: getFullName(person),
  value: person.id,
})

async function loadCustomers(params) {
  const response = await getCustomers(params)
  return response?.data?.map(transformForSelectDisplay)
}

// Search for organizations
async function loadOrganizations(params) {
  const response = await searchOrganizationsAdmin(params)
  return response?.data?.map(transformForSelectDisplayOrganization)
}

// Search for people
async function loadPeople(params) {
  const response = await searchPeopleAdmin(params)
  return response?.data?.map(transformForSelectDisplayPeople)
}

const debouncedLoadCustomers = debounce(loadCustomers, 250)
const debouncedLoadOrganizations = debounce(loadOrganizations, 250)
const debouncedLoadPeople = debounce(loadPeople, 250)

export { loadCustomers, debouncedLoadCustomers, debouncedLoadOrganizations, debouncedLoadPeople }
