export const SCREEN_TYPES = ['Summaries', 'View Activity Notes']

export const SELECT_BY_OPTIONS = {
  organizations: { key: 'organizations', type: 'Organization', label: 'Organizations Profiles' },
  people: { key: 'people', type: 'Person', label: 'People Profiles' },
  organizations_by_uuid: {
    key: 'organizations_by_uuid',
    type: 'Organization',
    label: 'Organization by UUID',
  },
  people_by_uuid: { key: 'people_by_uuid', type: 'Person', label: 'Person by UUID' },
}
