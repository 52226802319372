import { useContext, useEffect } from 'react'
import { getTasks } from 'services/research'
import { Context as AffiliationTaskContext } from '../contexts/AffiliationTaskContext'

import useSearchInputRead from '../hooks/useSearchInputRead'

function useAffiliationTasks() {
  const { affiliatedId } = useSearchInputRead()

  const context = useContext(AffiliationTaskContext)

  if (!context) {
    throw new Error(`useAffiliationTasks must be used within a AffiliationTaskContext`)
  }

  const [state, dispatch] = context

  useEffect(() => {
    const fetchAffiliationTasks = async ({ affiliatedId }) => {
      try {
        const options = {
          associated_id: affiliatedId,
          page_offset: state.page * state.rowsPerPage, // Need to start at 0 to support @material-ui pagination
          page_size: Number(state.rowsPerPage),
        }
        dispatch({ type: 'AFFILIATION_TASK:FETCH_STARTED' })
        const {
          data,
          total,
          links: { self, next, prev },
        } = await getTasks(options)
        dispatch({
          type: 'AFFILIATION_TASK:FETCH_RECEIVED',
          payload: {
            tasks: data,
            total: total,
            self: self,
            next: next,
            prev: prev,
            page: state.page,
            rowsPerPage: state.rowsPerPage,
          },
        })
      } catch (error) {
        dispatch({ type: 'AFFILIATION_TASK:FAILED', payload: error })
      }
    }

    if (affiliatedId) {
      fetchAffiliationTasks({ affiliatedId })
    }
  }, [dispatch, affiliatedId, state.page, state.rowsPerPage])

  const setPage = page => {
    dispatch({ type: 'AFFILIATION_TASK:SET_PAGE', payload: { page } })
  }

  const setRowsPerPage = ({ page, rowsPerPage }) => {
    dispatch({ type: 'AFFILIATION_TASK:SET_ROWS_PER_PAGE', payload: { page, rowsPerPage } })
  }

  const handlePage = newPage => {
    const page = state.page
    if (newPage > page && newPage < state.total) {
      setPage(page + 1)
    } else if (newPage < page) {
      setPage(page - 1)
    }
  }

  return {
    state,
    setPage: handlePage,
    setRowsPerPage,
  }
}

export default useAffiliationTasks
