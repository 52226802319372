import React from 'react'
import useAffiliationNotes from '../hooks/useAffiliationNotes'
import { DATE } from '@the-platform-group/component-library/DataGrid/constant'
import SharedTable from './SharedTable'

export const emptyMessage = 'No result found.'

const columns = [
  ...['customer', 'content'].map(v => ({ field: v })),
  ...['createdAt'].map(v => ({ field: v, type: DATE })),
]

const getRowData = note => {
  const { customer, id, notes, createdAt } = note
  return { customer, id, content: notes, createdAt }
}

function NotesTable() {
  const useHook = useAffiliationNotes()
  const props = { columns, emptyMessage, getRowData, useHook, name: 'tasksTable' }
  return <SharedTable rows={useHook.state.notes} {...props} />
}

export default NotesTable
