import { useCallback, useRef } from 'react'
import { saveReport } from 'services/reports'
import { updateChangeValue } from '../transforms/report'
import { REPORT_FIELD_PREFIXES, updateReportsCache } from '../utils/reports'
import { useQueryClient } from 'react-query'
import { useSurveillanceContext } from './SurveillanceContext'

// Data Grid related functions and states are here
const useSurveillanceTable = ({ createNotification }) => {
  const queryClient = useQueryClient()

  const cellEditState = useRef() // Ref to track which cell is being edited to update the cell on BE from processRowUpdate function, useState is not used here causing the datagrid update old value to cell

  const { reportIds } = useSurveillanceContext()

  const handleRowEditStart = (_, event) => {
    event.defaultMuiPrevented = false
  }
  const handleRowEditStop = (_, event) => {
    event.defaultMuiPrevented = false
  }

  // Update the cell on BE
  const processRowUpdate = useCallback(
    async (newRow, oldRow) => {
      let updated = false

      const { holderId, type, reportedDate } = newRow

      // get the report id from the cell edit state
      const updatedIdSplit = cellEditState.current.split('_')
      const updatedReportId = updatedIdSplit[1]
      const updatedField = updatedIdSplit[0]

      // get the holding id of the updated report
      const holdingId = newRow[`${REPORT_FIELD_PREFIXES.holdingId}${updatedReportId}`]

      // check if there is any change in shares or notes (This is for preventing unnecessary update calls if value is not changed)
      const isSharesChanged =
        newRow[`${REPORT_FIELD_PREFIXES.shares}${updatedReportId}`] !==
        oldRow[`${REPORT_FIELD_PREFIXES.shares}${updatedReportId}`]
      const isNotesChanged =
        newRow[`${REPORT_FIELD_PREFIXES.notes}${updatedReportId}`] !==
        oldRow[`${REPORT_FIELD_PREFIXES.notes}${updatedReportId}`]

      // save the report's updated holding if there is any change in shares or notes
      if (isSharesChanged || isNotesChanged) {
        try {
          await saveReport([
            {
              id: updatedReportId,
              upsert: [
                {
                  ...(holdingId && { id: holdingId }),
                  holder_id: holderId,
                  holder_type: type,
                  shares: newRow[`${REPORT_FIELD_PREFIXES.shares}${updatedReportId}`],
                  notes: newRow[`${REPORT_FIELD_PREFIXES.notes}${updatedReportId}`],
                  date:
                    newRow[`${REPORT_FIELD_PREFIXES.reportDate}${updatedReportId}`] || reportedDate,
                },
              ],
            },
          ])
          updated = true
        } catch (error) {
          createNotification({
            type: 'error',
            message: 'Error while updating holding',
          })
        }
      }

      let processedRowData = { ...newRow }

      // if the updated field is shares, update the change value
      if (updatedField === 'shares') {
        processedRowData = {
          ...updateChangeValue(newRow, oldRow, updatedReportId),
        }
      }

      // if the report is updated, add the updated cell to the updated cells list to update its cell style
      if (updated) {
        const updatedCells = [...(processedRowData?.updatedCells || []), cellEditState.current]
        processedRowData.updatedCells = updatedCells

        const updatedValue = newRow[`${REPORT_FIELD_PREFIXES[updatedField]}${updatedReportId}`]

        // update the reports data in the cache
        queryClient.setQueryData(['reports', { reportIds }], reports => {
          // in date range mode reports return null so instead of updating the cache, invalidate the cache
          if (!reports) {
            queryClient.invalidateQueries(['reports', { reportIds }])
            return
          }
          return updateReportsCache({
            reports,
            updatedReportId,
            holdingId,
            updatedField,
            updatedValue,
            updatedCells,
          })
        })
      }

      // reset the cell edit state
      cellEditState.current = null

      //return the updated row to update datagrid row
      return processedRowData
    },
    [createNotification, queryClient, reportIds],
  )

  const onCellEditStop = useCallback(({ field, reason }) => {
    // prevent set cell edit state when click on escape key
    if (reason === 'escapeKeyDown') {
      cellEditState.current = null
      return
    }

    // set cell edit state when cell edit stop with field name
    cellEditState.current = field
  }, [])

  return {
    handleRowEditStart,
    handleRowEditStop,
    processRowUpdate,
    onCellEditStop,
  }
}

export default useSurveillanceTable
