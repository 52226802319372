import { formatDate } from '@the-platform-group/formatters/dateFormat'
import { MONTH_YEAR_FORMAT } from 'constants/formats'

const REPORT_FIELD_PREFIXES = {
  shares: 'shares_',
  value: 'value_',
  change: 'change_',
  notes: 'notes_',
  reportDate: 'reportDate_',
  holdingId: 'holdingId_',
  nextReportId: 'nextReportId_',
}
const updateReportsCache = ({
  reports,
  updatedReportId,
  holdingId,
  updatedField,
  updatedValue,
  updatedCells,
}) => {
  //update updated holding's shares or notes inside updated report
  const updatedReport = reports.data.find(report => report.id === updatedReportId)
  const updatedHolding = updatedReport.holdings.find(holding => holding.id === holdingId)
  // update the holding's shares or notes, updatedField is either shares or notes
  if (updatedHolding) {
    updatedHolding[updatedField] = updatedValue
    updatedHolding.updatedCells = updatedCells
  }

  return reports
}

const deleteHolderFromReportsCache = ({ reports, holdingIdsToDelete, row }) => {
  const updatedReports = [...reports.data]

  // find each holdingId_ and delete from reports
  holdingIdsToDelete.forEach(deletedHoldingId => {
    const reportId = deletedHoldingId.replace(REPORT_FIELD_PREFIXES.holdingId, '')
    // find the report index
    const reportIndex = updatedReports.findIndex(report => report.id === reportId)

    // find the holding index and delete from the report
    if (reportIndex > -1) {
      const holdingIndex = updatedReports[reportIndex].holdings.findIndex(
        holding => holding.id === row[deletedHoldingId],
      )
      if (holdingIndex > -1) {
        // delete the holding from the report
        updatedReports[reportIndex].holdings.splice(holdingIndex, 1)
      }
    }
  })

  // return the updated reports to the cache
  return { data: updatedReports }
}

const shouldDisableReportMonth = (date, availableReportsInYear) => {
  const monthAndYear = formatDate(date, MONTH_YEAR_FORMAT)

  return !availableReportsInYear.hasOwnProperty(monthAndYear)
}

const getReportIdsQueryKey = ({ customerId, securityId, datasource, selectedMonthAndYear }) => [
  'reportIds',
  { customerId, securityId, datasource, selectedMonthAndYear },
]

export {
  REPORT_FIELD_PREFIXES,
  updateReportsCache,
  deleteHolderFromReportsCache,
  shouldDisableReportMonth,
  getReportIdsQueryKey,
}
