export const SHAREHOLDER_CHANGES_FILING_OPTIONS = [
  {
    label: '13F',
    value: '13f',
  },
  {
    label: 'Sum of Funds',
    value: 'fund',
  },
  {
    label: 'Stakes',
    value: 'stake',
  },
]
