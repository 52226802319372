import React from 'react'
import { Typography } from '@the-platform-group/component-library'

export function Highlight({ text }) {
  // names can contain bolded terms (which indicate keyword matches)
  return (
    <Typography
      component="span"
      variant="body2"
      sx={{
        '& b': {
          color: 'primary.contrastText',
          backgroundColor: 'primary.main',
        },
      }}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  )
}

export default Highlight
