import React, { useState, useEffect, useMemo } from 'react'

import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  RawSelect as Select,
  Typography,
  TextField,
} from '@the-platform-group/component-library'
import { Grid } from '@the-platform-group/component-library/Layout'
import { isEmpty } from '@the-platform-group/formatters/checks'
import SecuritySelect from 'components/Select/SecuritySelect'

const initialFormState = {
  security: '',
  cusip: '',
  user: '',
  notifyUsers: [],
}

function useForm(formState) {
  const [security, setSecurity] = useState(formState.security)
  const [cusip, setCusip] = useState(formState.cusip)
  const [user, setUser] = useState(formState.user)
  const [notifyUsers, setNotifyUsers] = useState(formState.notifyUsers)

  return {
    state: useMemo(
      () => ({
        security,
        cusip,
        user,
        notifyUsers,
      }),
      [cusip, notifyUsers, security, user],
    ),
    handlers: { setSecurity, setCusip, setUser, setNotifyUsers },
  }
}

function CustomerSecurityForm({
  customer,
  formState = initialFormState,
  register,
  formErrors = [],
}) {
  const { state, handlers } = useForm(formState)

  useEffect(() => {
    register(state)
  }, [state, register])

  if (isEmpty(customer)) {
    return null
  }

  return (
    <>
      {Boolean(formErrors.length) && (
        <Alert severity="error">
          {formErrors.map(error => (
            <Typography>{`${error.field} ${error.message}`}</Typography>
          ))}
        </Alert>
      )}
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <InputLabel htmlFor="customer">Customer</InputLabel>
          <TextField
            id="customer"
            name="customer"
            value={customer.name}
            onChange={() => null}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item>
          <FormControl variant="outlined" fullWidth>
            <SecuritySelect
              id="security"
              label="Security *"
              onChange={handlers.setSecurity}
              value={state.security}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <InputLabel htmlFor="cusip">CUSIP *</InputLabel>
          <TextField
            id="cusip"
            name="cusip"
            value={state.cusip}
            onChange={event => handlers.setCusip(event.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="user-label">User *</InputLabel>
            <Select
              label="User *"
              labelId="user-label"
              id="user"
              value={state.user}
              onChange={event => handlers.setUser(event.target.value)}
            >
              {customer.users.map(user => (
                <MenuItem key={user.commonId} value={user.commonId}>
                  {`${user.firstName} ${user.lastName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="notification-users-label">Notification For *</InputLabel>
            <Select
              label="Notification For *"
              labelId="notification-users-label"
              id="notifcation-users"
              value={state.notifyUsers}
              multiple
              onChange={event => handlers.setNotifyUsers(event.target.value)}
            >
              {customer.users.map(user => (
                <MenuItem key={user.commonId} value={user.commonId}>
                  {`${user.firstName} ${user.lastName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

export default CustomerSecurityForm
