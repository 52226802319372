import changeCase from 'change-case-object'

function formatAddress(address) {
  const { street2, coordinates, ...addressProps } = address
  const { lat, lng } = { ...coordinates }
  const latLng = lat && lng ? { lat_lng: [lat, lng] } : {}

  return {
    ...changeCase.snake(addressProps),
    street2,
    ...latLng,
  }
}

/**
 * Format address by combining the city, state, and country into a single string
 * @param { object } address - address with city, state and country properties used to format a location
 * @returns { string } - formatted address
 */
const formatStringAddress = address => {
  if (address) {
    return [address.city, address.state, address.country].filter(Boolean).join(', ')
  }
  return '-'
}

export { formatAddress, formatStringAddress }
