function getLinkFactsetLocationTitle(hasCustomer) {
  return hasCustomer ? 'Link a Factset Location' : 'Link an Irwin Customer'
}

/**
 * 🔴 NOTE (semi-crazy business logic): 🔴
 * @param {object} formData - the formData from react-hook-form
 * @param {Array} formData.products - contains ALL previously saved products for the FS user
 *    e.g. default products + previously saved user-selected products
 *    a default product is an inherited product attached to a FS user that can NOT be changed, they are
 *    also NOT listed in products selector in the FS user form
 * @param {Array} formData.productIds - contains product ids selected from the product selector on the form
 *    e.g. current user-selected products
 * @param {object} factsetMetadata - the factset metadata, contains products, royaltyCodes & firmDesc
 * @param {Array} factsetMetadata.products - contains ALL the available options listed in the product selector
 *    that user can select from, they do NOT include ANY default products, and the values are provided from
 *    the ds1/metadata API
 * @returns {Array} a list of product ids to be saved
 *    e.g. default products (unchanged) + newly selected product ids
 *    When saving a Factset User, we need to combine all existing default products of the FS user with
 *    products that user selected via the product selector on the form, the user-selected products may
 *    change, but the default products will always stay the same
 */
function getFactsetUserProductIds(formData, factsetMetadata) {
  const defaultProductIds = formData.products.reduce((productIds, userProduct) => {
    if (
      !factsetMetadata.products.some(
        selectableProduct => userProduct.id === selectableProduct.value,
      )
    ) {
      // if product is NOT listed in factsetMetadata.products, then it's a default (user) product
      productIds.push(userProduct.id)
    }
    return productIds
  }, [])

  return Array.from(
    // The reason we use Set() here is to conveniently removes redundant entries in the array
    new Set(formData.productIds.concat(defaultProductIds)),
  )
}

const transformFactsetUser = factsetMetadata => factsetUser => {
  // status should only be one of ['unmanaged', 'created', 'updating.failed']
  const type = factsetUser.status === 'unmanaged' ? 'unmanaged' : 'managed'
  // 🔴 NOTE (business logic): 🔴
  // Every FS user may have inherited/default products that can NOT be changed via the form
  // these default products are NOT listed in Factset Metadata and they can not be added/removed
  // hence we need to filter them out of the value from the dropdown list
  const productIds = factsetUser.products.reduce((results, product) => {
    if (factsetMetadata?.products?.some(item => item.value === product.id)) {
      results.push(product.id)
    }
    return results
  }, [])

  return {
    ...factsetUser,
    type,
    productIds,
    location: factsetUser.location
      ? {
          value: factsetUser.location.id,
          label: factsetUser.location.name,
          usernames: factsetUser.location.usernames, // this is needed to populate username select options
        }
      : '',
    user: factsetUser.user
      ? {
          value: factsetUser.user.id,
          label: `${factsetUser.user.firstName} ${factsetUser.user.lastName}`,
        }
      : '',
  }
}

const transformFactsetUserForSelect = factsetUser => ({
  value: factsetUser.id,
  label: `${factsetUser.givenName} ${factsetUser.familyName}`,
  secondaryText: factsetUser.email,
})

const transformIrwinUserForSelect = irwinUser => ({
  value: irwinUser.id,
  label: `${irwinUser.firstName} ${irwinUser.lastName}`,
  secondaryText: irwinUser.email,
  firstName: irwinUser.firstName,
  lastName: irwinUser.lastName,
  email: irwinUser.email,
})

const getErrorMessage = error => {
  let message
  const errorData = error.response.data?.errors?.at(0)
  if (typeof errorData === 'string') {
    message = errorData
  } else if (errorData.field && errorData.message) {
    message = `${errorData.field} ${errorData.message}`
  } else {
    message = error.message
  }
  return `Error: ${message}`
}

export {
  getLinkFactsetLocationTitle,
  getFactsetUserProductIds,
  transformFactsetUser,
  transformFactsetUserForSelect,
  transformIrwinUserForSelect,
  getErrorMessage,
}
