import { useState, useEffect } from 'react'
import { formatDate } from '@the-platform-group/formatters/dateFormat'
import formats from 'constants/formats'

function useCustomerForm({ onSave, editableCustomer }) {
  const { DATE_FORMAT } = formats
  const [customer, setCustomer] = useState(editableCustomer || {})
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => setCustomer(editableCustomer || {}), [editableCustomer])

  const handleChange = ({ target }) => {
    setCustomer({ ...customer, [target.name]: target.value })
  }

  const handleChecked = ({ target }) => {
    setCustomer({ ...customer, [target.name]: target.checked })
  }

  const handleDateChange = ({ target }) => {
    setCustomer({ ...customer, [target.name]: target.value })
  }

  const saveCustomer = async () => {
    setIsSubmitting(true)
    try {
      await onSave({
        ...customer,
        expiredAt: customer.type === 'trial' ? formatDate(customer.expiredAt, DATE_FORMAT) : null,
      })
    } catch (error) {
      setCustomer(() => {
        throw error
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  return {
    state: { customer, isSubmitting },
    handlers: {
      handleChange,
      handleChecked,
      handleDateChange,
      saveCustomer,
    },
  }
}

export default useCustomerForm
