import React from 'react'

import { Grid, Avatar, Typography, Box } from '@the-platform-group/component-library'

import { AutoAwesome as SparkleIcon } from '@the-platform-group/component-library/Icons'

const MessageBox = ({ isUser, message }) => {
  return (
    <Grid
      item
      container
      direction="row"
      sx={{
        gap: 2,
      }}
    >
      <Grid item xs="auto">
        {isUser ? (
          <Avatar
            sx={{
              width: '24px',
              height: '24px',
            }}
          />
        ) : (
          <Box
            sx={{
              borderRadius: '50%',
              p: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: 'rgba(162, 155, 254, 0.28)',
              width: '24px',
              height: '24px',
            }}
          >
            <SparkleIcon sx={{ color: 'reports.112', fontSize: '15px' }} />
          </Box>
        )}
      </Grid>
      <Grid item container xs direction="column">
        <Grid item>
          <Typography fontWeight="fontWeightBold">
            {isUser ? 'You' : 'Irwin Assistant Summaries'}
          </Typography>
        </Grid>
        <Grid item>
          <Typography sx={{ whiteSpace: 'pre-line' }}>{message}</Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MessageBox
