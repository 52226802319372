import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
// components
import { TextField, Typography, IconButton } from '@the-platform-group/component-library'
import SOCIAL_PROFILES from 'constants/socialProfiles'
import { Button } from '@the-platform-group/component-library/Button'
import { Grid } from '@the-platform-group/component-library/Layout'
import { Add as AddIcon, Delete } from '@the-platform-group/component-library/Icons'
import ProfilePicture from 'components/ProfilePicture'
import { getCalulatedSize } from 'components/ProfilePicture/utils'

const SocialProfilesWrapper = ({
  children,
  person = {},
  selectedImage = {},
  handleImageChange,
  resetSelectedImage,
}) => {
  const fileUploadRef = useRef(null)

  const { firstName, lastName } = person

  return (
    <Grid item container direction="column" gap={1}>
      {children}
      <Grid item container direction="column" gap={0.5}>
        <Grid item xs={12}>
          <Typography fontWeight="bold">Profile Image:</Typography>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          xs={12}
        >
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            xs={2}
          >
            <ProfilePicture name={{ firstName, lastName }} profilePictureSrc={selectedImage.src} />
          </Grid>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            gap={1}
            xs={10}
            justifyContent="flex-end"
          >
            {selectedImage.src ? (
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  ...(selectedImage.isBlob && { bgcolor: 'secondary.light', borderRadius: 1 }),
                  p: 1,
                }}
              >
                <Grid item xs={8} container direction="column">
                  <Typography variant="caption" color="primary" fontWeight="bold">
                    {selectedImage.file?.name}
                  </Typography>
                  <Typography variant="caption" color="textSecondary" fontWeight="bold">
                    {selectedImage.file?.size && getCalulatedSize(selectedImage.file.size)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  xs={4}
                >
                  <IconButton
                    onClick={() => {
                      resetSelectedImage()
                      fileUploadRef.current.value = null
                    }}
                  >
                    <Delete color="error" />
                  </IconButton>
                </Grid>
              </Grid>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => fileUploadRef.current.click()}
                startIcon={<AddIcon />}
              >
                Select Image
              </Button>
            )}
          </Grid>
          <input
            ref={fileUploadRef}
            type="file"
            style={{
              display: 'none',
            }}
            onChange={handleImageChange}
            accept="image/png, image/jpeg"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

const PersonSocialProfilesForm = ({
  personSocialProfiles,
  onPersonSocialProfilesChange,
  person,
  selectedImage,
  handleImageChange,
  resetSelectedImage,
}) => {
  const personSocialProfilesList = personSocialProfiles.map((psp, index) => {
    return (
      <TextField
        key={psp.id}
        label={SOCIAL_PROFILES[psp.provider]}
        InputLabelProps={{ htmlFor: 'url' }}
        id={`${psp.provider}Url`}
        name="url"
        value={psp.url || ''}
        onChange={onPersonSocialProfilesChange(index)}
        fullWidth
      />
    )
  })
  const socialProfilesWrapperProps = {
    person,
    selectedImage,
    handleImageChange,
    resetSelectedImage,
  }

  return (
    <Fragment>
      <form>
        <SocialProfilesWrapper {...socialProfilesWrapperProps}>
          {personSocialProfilesList}
        </SocialProfilesWrapper>
      </form>
    </Fragment>
  )
}

PersonSocialProfilesForm.propTypes = {
  personSocialProfiles: PropTypes.array.isRequired,
  onPersonSocialProfilesChange: PropTypes.func.isRequired,
}

export default PersonSocialProfilesForm
