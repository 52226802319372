import { useContext } from 'react'

import { Chip, Grid } from '@the-platform-group/component-library'
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@the-platform-group/component-library/Modal'

import {
  FactsetModalActionsProvider,
  FactsetModalActionsContext,
} from '../Context/FactsetModalActionsContext'

import { useFactsetMetadata } from 'contexts/FactsetMetadataContext'
import { useFactsetUserForm } from '../hooks/useFactsetUser'
import { useFactsetUserCreateActions } from '../hooks/useCreateUpdateFactsetUser'

import TypeSelector from './TypeSelector'
import {
  CommonFactsetUserForm,
  ManagedFactsetUserForm,
  UnmanagedFactsetUserForm,
} from './FactsetUserForms'
import ModalStepsActions from './ModalStepsActions'

/**
 * @param {object} param0 - modal component props
 * @param {boolean} param0.isModalOpen - required, flag of whether modal is isModalOpen or closed
 * @param {function} param0.closeModal - required, function to close modal
 * @param {string} param0.customerId - optional, irwin customer id which we'll use as a filter option to
 *    fetch proper FS locations options for the FS location selector, this is typically passed in when
 *    on a customer page
 * @param {object} param0.factsetLocation - optional, a FS location info, if this is passed in, then we
 *    will use it to prefill factset location selector, this is typically passed in when on a FS
 *    location details page
 * @param {object} param0.irwinUser - optional, an Irwin user info, if this is passed in, then we will
 *    use it to prefill Irwin user selector on the form
 * @returns {Node} Modal containing form to create a FS user
 */
function CreateFactsetUserModal({
  closeModal,
  isModalOpen,
  customerId,
  factsetLocation,
  irwinUser,
}) {
  const { data: factsetMetadata } = useFactsetMetadata()
  const { currentStep } = useContext(FactsetModalActionsContext)
  const formControls = useFactsetUserForm({ factsetLocation, irwinUser })
  const formActions = useFactsetUserCreateActions(closeModal)

  const type = formControls.watch('type')

  return (
    <Modal open={isModalOpen} closeModal={closeModal}>
      <ModalTitle
        title={
          <>
            Create Factset User&nbsp;&nbsp;
            {type && <Chip label={type} size="small" color="secondary" />}
          </>
        }
      />
      <ModalContent>
        <Grid container>
          {currentStep === 0 && (
            <Grid item>
              <TypeSelector title="Does a Factset user already exist?" {...formControls} />
            </Grid>
          )}
          {currentStep === 1 && (
            <Grid container spacing={2}>
              <CommonFactsetUserForm
                customerId={customerId}
                irwinUser={irwinUser}
                factsetLocation={factsetLocation}
                type={type}
                {...formControls}
              />
              {type === 'unmanaged' && <UnmanagedFactsetUserForm {...formControls} />}
              {type === 'managed' && (
                <ManagedFactsetUserForm {...formControls} factsetMetadata={factsetMetadata} />
              )}
            </Grid>
          )}
        </Grid>
      </ModalContent>
      <ModalActions>
        <ModalStepsActions
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          type={type}
          handleSubmit={formControls.handleSubmit}
          resetForm={formControls.reset}
          {...formActions}
        />
      </ModalActions>
    </Modal>
  )
}

function CreateFactsetUserModalWrapper(props) {
  return (
    <FactsetModalActionsProvider>
      <CreateFactsetUserModal {...props} />
    </FactsetModalActionsProvider>
  )
}

export default CreateFactsetUserModalWrapper
