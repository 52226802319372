import React from 'react'
import { DataGrid } from '@the-platform-group/component-library/DataGrid'

const SurveillanceDataGrid = ({
  loading,
  rows = [],
  columns = [],
  columnGroupingModel = [],
  editState,
  handleRowEditStart,
  handleRowEditStop,
  processRowUpdate,
  handleRowModesModelChange,
  createNotification,
  ...rest
}) => {
  return (
    <DataGrid
      name="surveillancePortal"
      loading={loading}
      rows={rows}
      rowCount={rows.length}
      columns={columns}
      columnGroupingModel={columnGroupingModel}
      onRowEditStart={handleRowEditStart}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={error => {
        console.error('process error', error)
        createNotification({
          message: 'There was a problem with saving. Please try again.',
          variant: 'error',
        })
      }}
      rowModesModel={editState}
      onRowModesModelChange={handleRowModesModelChange}
      experimentalFeatures={{ columnGrouping: true }}
      initialState={{ pinnedColumns: { left: ['name'] } }}
      localeText={{
        noRowsLabel: 'Sorry, no holders found.',
      }}
      disableDensitySelector={false}
      disableVirtualization // Users search holders with Ctrl + F and virtualization breaks that
      pageSizeOptions={[50, 100, 200]}
      autoHeight={false}
      {...rest}
    />
  )
}

export default SurveillanceDataGrid
