import React, { useState } from 'react'
import { Grid, TextField } from '@the-platform-group/component-library'
import { Button } from '@the-platform-group/component-library/Button'

import { useModal } from 'hooks/useModal'
import { useUnlinkFactsetLocation } from './hooks/useLinkFactsetLocation'
import useLinkFactsetEntityActions from './hooks/useLinkFactsetEntityActions'
import useDeleteFactsetLocation from './hooks/useDeleteFactsetLocation'

import FactsetTableTitle from './Components/FactsetTableTitle'
import FactsetLocationsTable from './Components/FactsetLocationsTable'
import CreateFactsetLocationModal from './Components/CreateFactsetLocationModal'
import LinkFactsetLocationModal from './Components/LinkFactsetLocationModal'

import { getLinkFactsetLocationTitle } from './Utils'

/***
 * @param {object} param0 - component props
 * @param {string} param0.customer -  available when under context of an Irwin customer (e.g. on customer page)
 * @returns {Node} Factset locations tables with title, optional search, and action buttons
 */
function FactsetLocations({ customer }) {
  const customerId = customer?.id
  const [searchTerm, setSearchTerm] = useState('')
  const {
    handleOpen: openCreateFactsetLocationModal,
    handleClose: closeCreateFactsetLocationModal,
    open: isCreateFactsetLocationModalOpen,
  } = useModal()
  const {
    selected: selectedLocation,
    open: isLinkFactsetLocationModalOpen,
    handleClose: closeLinkFactsetLocationModal,
    handleOpen: openLinkFactsetLocationModal,
    onLink,
  } = useLinkFactsetEntityActions()

  const { onUnlink } = useUnlinkFactsetLocation()
  const { onDelete } = useDeleteFactsetLocation()

  return (
    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
      <FactsetTableTitle title="Factset Locations" isSubTitle={customerId} />
      {!customerId && (
        // show search bar if we are NOT on customer page
        <Grid item xs>
          <TextField
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            name="search"
            label="Search Factset Locations"
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={0}>
        <Button onClick={openLinkFactsetLocationModal} sx={{ mr: 1 }}>
          {getLinkFactsetLocationTitle(customerId)}
        </Button>
        <Button onClick={openCreateFactsetLocationModal}>Create Factset Location</Button>
        <CreateFactsetLocationModal
          isModalOpen={isCreateFactsetLocationModalOpen}
          closeModal={closeCreateFactsetLocationModal}
          customer={customer}
        />
        <LinkFactsetLocationModal
          isModalOpen={isLinkFactsetLocationModalOpen}
          closeModal={closeLinkFactsetLocationModal}
          customer={customer}
          location={selectedLocation}
        />
      </Grid>
      <Grid item xs={12}>
        <FactsetLocationsTable
          customerId={customerId}
          searchTerm={searchTerm}
          tableActions={{ onUnlink, onLink, onDelete }}
        />
      </Grid>
    </Grid>
  )
}

export default FactsetLocations
