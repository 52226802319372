import { addDays } from 'date-fns'
import { format } from '@the-platform-group/formatters/number'
import { formatDate } from '@the-platform-group/formatters/dateFormat'

import { REPORT_FIELD_PREFIXES } from '../utils/reports'
import { DATE_FORMAT } from '@the-platform-group/formatters/constants'
import { YEAR_FORMAT } from 'constants/formats'

// convert holdings array to map for faster lookup in transformReport function, if we keep it like array it will be O(n) lookup
const generateHoldingsMap = reports =>
  reports.data.map(report => ({
    ...report,
    holdings: new Map(report.holdings.map(holding => [holding.holder_id, holding])),
  }))

const transformReport = ({
  currentReport,
  previousReport,
  nextReportId,
  holdersData,
  selectedDateByMonth,
}) => {
  const transformedHoldings = holdersData.map(holder =>
    transformHolding({
      ...holder, // We need holderId, currentShares, price and type properties from holder
      firstHolder: holdersData[0],
      currentReport,
      previousReport,
    }),
  )

  return {
    id: currentReport.id,
    data: transformedHoldings,
    title: `${currentReport.name}${
      selectedDateByMonth ? ` (${formatDate(selectedDateByMonth, YEAR_FORMAT)})` : ''
    }`, // Title of Report Column Group (ex: Week of Oct 13), additionally if date range is selected then add the selected year to the title (ex: Week of Oct 13 (2022))
    date: new Date(currentReport.date.replace(/-/g, '/')),
    ...(nextReportId && { nextReportId }), // next report id is used to update the change value for the next report during processRowUpdate
  }
}

const EMPTY_HOLDING = {
  holder_id: null,
  id: null,
  shares: 0,
}

// This function is refactored version of /src/pages/Surveillance/selectors/index.js reportsQuery
const transformHolding = ({
  holderId, // from holder
  currentShares, // from holder
  price, // from holder
  type, // from holder
  firstHolder,
  currentReport,
  previousReport,
}) => {
  // Find the holding for the current holder in the report
  const currentHolding = currentReport.holdings.get(holderId) || EMPTY_HOLDING

  // Calculate the change in shares if there is a previous report
  let change = 0

  const previousReportHoldings = previousReport?.holdings

  // Find the holding for the current holder in the previous report
  const previousHolding = previousReportHoldings?.get(holderId)

  //If there is no previous report, then calculate the change from the current shares
  if (previousReportHoldings) {
    change = currentHolding.shares - (previousHolding ? previousHolding.shares : 0) // There can be previous report but no holding for the current holder if the holder was added later on the report, in that case we need to calculate the change from 0 (we assume holder newly added to reported shares too between current and previous report)
  } else {
    change = currentHolding.shares - currentShares
  }

  // Assign the check if the current holding has a holder id
  const hasHolderId = Boolean(currentHolding.holder_id)

  // Get the date value from the current holding or the current report
  const dateValue = hasHolderId ? currentHolding.date : currentReport.date
  const formattedHoldingDate = dateValue ? new Date(dateValue?.replace(/T.+/, '')) : new Date()
  const holdingDate = addDays(formattedHoldingDate, 1)
  const holdingShares = hasHolderId ? currentHolding.shares : 0
  const holdingPrice = hasHolderId ? price : firstHolder.price

  return {
    holderId: hasHolderId ? currentHolding.holder_id : holderId,
    price: holdingPrice,
    type: hasHolderId ? currentHolding.holder_type : type,
    reportedDate: formatDate(holdingDate, DATE_FORMAT),
    shares: holdingShares,
    value: holdingShares * holdingPrice,
    notes: currentHolding.notes,
    ...(hasHolderId && { change: format(change, 0) }),
    ...(currentHolding?.id && { holdingId: currentHolding.id }),
    ...(currentHolding?.updatedCells && { updatedCells: currentHolding.updatedCells }),
  }
}

// Update Change Value for the report and next report if there is any
const updateChangeValue = (newRowData, oldRowData, reportId) => {
  const data = { ...newRowData }

  // Get the previous change value
  const previousChange = parseInt(
    newRowData[`${REPORT_FIELD_PREFIXES.change}${reportId}`]?.replaceAll(',', ''),
  )
  // Get the current shares value
  const currentShares = newRowData[`${REPORT_FIELD_PREFIXES.shares}${reportId}`]
  // Get the previous shares value
  const previousShares = oldRowData[`${REPORT_FIELD_PREFIXES.shares}${reportId}`]
  // Calculate the updated change value by adding previous change with the difference between current and previous shares
  const updatedChange = previousChange + (currentShares - previousShares)

  // Update the change value
  data[`${REPORT_FIELD_PREFIXES.change}${reportId}`] = format(updatedChange, 0)

  // Update the value
  data[`${REPORT_FIELD_PREFIXES.value}${reportId}`] =
    newRowData[`${REPORT_FIELD_PREFIXES.shares}${reportId}`] * newRowData.price

  // Get the next report id
  const nextReportId = newRowData?.[`${REPORT_FIELD_PREFIXES.nextReportId}${reportId}`]

  // If there is a next report, then update the change value for the next report
  if (nextReportId) {
    const nextCurrentShares = newRowData[`${REPORT_FIELD_PREFIXES.shares}${nextReportId}`]
    const nextUpdatedChange = nextCurrentShares - currentShares
    data[`${REPORT_FIELD_PREFIXES.change}${nextReportId}`] = format(nextUpdatedChange, 0)
  }

  return data
}

export { generateHoldingsMap, transformReport, updateChangeValue }
