import React from 'react'
import { irwin } from 'config'
import useCustomerAffiliations from '../hooks/useCustomerAffiliations'
import useSearchInputRead from '../hooks/useSearchInputRead'
import { CustomLink as Link } from '@the-platform-group/component-library'
import SharedTable from './SharedTable'

export const emptyMessage =
  "Please enter an affiliated ID for a system person or organization. If the affiliated entity is a custom person or organization, please go to person's profile in that customer's account."

const AffiliationCell = React.memo(({ value }) => (
  <Link to={{ pathname: value.url }} target="_blank">
    {value.content}
  </Link>
))

const columns = [
  { field: 'customer', flex: 0.25 },
  {
    field: 'affiliation',
    renderCell: params => <AffiliationCell {...params} />,
  },
]

const buildUrlAndContent = (source, affiliatedType) => {
  const { id, type } = source || { id: 'unknown', type: 'unknown' }
  switch (type) {
    case 'activity':
      return {
        url: `${irwin.appUrl}/activities/${id}`,
        content: `${source.type} (${source.date}) - ${source.title}`,
      }
    case 'tag':
      return {
        url:
          affiliatedType === 'person'
            ? `${irwin.appUrl}/irm/people?tags=${id}`
            : `${irwin.appUrl}/irm/organizations?tags=${id}`,
        content: `${source.type} - ${source.name}`,
      }
    case 'customContact':
      return {
        url: `${irwin.appUrl}/people/${id}`,
        content: `Custom Contact - ${source.firstName} ${source.lastName}`,
      }
    case 'unknown':
      return {
        url: '',
        content: 'Unknown (deleted affiliation)',
      }
    default:
      throw new Error(`Unsupported source type: ${type}`)
  }
}

const getRowData = affiliation => {
  const { id, customer, source, affiliatedType } = affiliation
  return {
    id,
    customer: customer.name,
    affiliation: buildUrlAndContent(source, affiliatedType),
  }
}

function AffiliationsTable() {
  const { affiliatedId } = useSearchInputRead()
  const useHook = useCustomerAffiliations(affiliatedId)
  const props = { columns, emptyMessage, getRowData, useHook, name: 'affiliationsTable' }
  return <SharedTable rows={useHook.state.affiliations} {...props} />
}

export default AffiliationsTable
