import { formatDate } from '@the-platform-group/formatters/dateFormat'
import {
  SURVEILLANCE_HOLDER_NAME_COLUMN,
  SURVEILLANCE_HOLDER_COLUMNS,
  SURVEILLANCE_REPORT_COLUMNS,
  SURVEILLANCE_COLUMN_HOLDERS_GROUPING,
  SURVEILLANCE_COLUMN_REPORT_GROUPING,
} from '../utils/columnConfig'
import { REPORT_FIELD_PREFIXES } from '../utils/reports'
import { DATE_FORMAT } from '@the-platform-group/formatters/constants'

const transformSurveillanceColumns = ({ reportsContent = [], isLoading }) => {
  // default columns and columns grouping model
  let columns = [SURVEILLANCE_HOLDER_NAME_COLUMN({ isLoading }), ...SURVEILLANCE_HOLDER_COLUMNS]
  let columnGroupingModel = [...SURVEILLANCE_COLUMN_HOLDERS_GROUPING]

  if (reportsContent.length > 0) {
    // add reports to columns and columns grouping model
    const reportsColumns = reportsContent.flatMap(SURVEILLANCE_REPORT_COLUMNS)
    const reportsGroupingModel = reportsContent.map(SURVEILLANCE_COLUMN_REPORT_GROUPING)

    columns = [...columns, ...reportsColumns]
    columnGroupingModel = [...columnGroupingModel, ...reportsGroupingModel]
  }

  return {
    columns,
    columnGroupingModel,
  }
}

const transformSurveillanceRows = ({ holdersData = [], reportsContent = [] }) => {
  // default rows
  let combinedRows = [...holdersData]

  if (reportsContent.length > 0) {
    /**
     * Add Reports Data to Rows
     * 1. Loop through holdersData
     * 2. For each row, add the reports data to the row
     */
    const transformedHoldersData = holdersData.map((row, index) => {
      const holderRow = { ...row, updating: false }

      reportsContent.forEach(report => {
        // report.data[index] is the report data for the current holder
        const reportHolding = report.data[index] || {}
        // Deconstruct the holdings array into the variables we need
        const { shares, value, change, notes, holdingId } = reportHolding

        // Add the report data to the row, this key value approach is esential for editable cells
        // When setEditCellValue api called it cannot update row's objects, it can only update field value by key, also during processRowUpdate we need to update change value and access next report id to update the change value for the next report
        holderRow[`${REPORT_FIELD_PREFIXES.shares}${report.id}`] = shares
        holderRow[`${REPORT_FIELD_PREFIXES.value}${report.id}`] = value
        holderRow[`${REPORT_FIELD_PREFIXES.change}${report.id}`] = change
        holderRow[`${REPORT_FIELD_PREFIXES.notes}${report.id}`] = notes
        holderRow[`${REPORT_FIELD_PREFIXES.holdingId}${report.id}`] = holdingId
        holderRow[`${REPORT_FIELD_PREFIXES.reportDate}${report.id}`] = formatDate(
          report.date,
          DATE_FORMAT,
        )

        if (report.nextReportId) {
          holderRow[`${REPORT_FIELD_PREFIXES.nextReportId}${report.id}`] = report.nextReportId
        }

        // This key will be updated during processRowUpdate's setQueryData of reports function
        // previously processRowUpdate was updating it on row object but its not appliying these changes if user search or change page
        // with this update we update those changes to the holdings data which is only refetched if use change security or create/delete reports
        if (reportHolding.updatedCells) {
          holderRow.updatedCells = reportHolding.updatedCells
        }
      })

      return holderRow
    })

    // Set the combinedRows to the transformedHoldersData
    combinedRows = transformedHoldersData
  }

  return combinedRows
}

export { transformSurveillanceColumns, transformSurveillanceRows }
