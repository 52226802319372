import React from 'react'
import { Grid, Typography } from '@the-platform-group/component-library'
import { Loader } from '@the-platform-group/component-library/Loader'
import { useQuery } from 'react-query'
import { useNotification } from 'components/Notification'
import { getRawAssistantNotes } from 'services/assistantSummaries'

const ActivityNotesView = ({
  selectedEntityId,
  selectedCustomer,
  selectedSecurity,
  selectedEntityType,
}) => {
  const { createNotification } = useNotification()
  const { data: rawActivityNotesData, isLoading: rawActivityNotesIsLoading } = useQuery(
    [
      'rawActivityNotes',
      {
        entityId: selectedEntityId,
        customerId: selectedCustomer?.id,
        securityId: selectedSecurity?.id,
        type: selectedEntityType,
      },
    ],
    getRawAssistantNotes,
    {
      onError: error =>
        createNotification({
          message: error.response?.data?.error || error.message,
          variant: 'error',
        }),
      enabled: Boolean(
        selectedEntityId && selectedCustomer && selectedSecurity?.id && selectedEntityType,
      ),
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
    },
  )

  return (
    <Grid
      container
      sx={{
        height: '100%',
      }}
      justifyContent="flex-start"
      direction="column"
      rowGap={2}
    >
      {rawActivityNotesIsLoading ? (
        <Loader />
      ) : (
        <>
          <Grid item container direction="column">
            <Grid item>
              <Typography variant="h6">Activity Notes Used</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                Total activities used: {rawActivityNotesData?.count || 0}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container>
            <Typography sx={{ whiteSpace: 'pre-line' }} variant="body2">
              {rawActivityNotesData?.notes}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default ActivityNotesView
