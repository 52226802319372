import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { deleteReport } from 'services/reports'
import DeleteButton from './DeleteButton'
import DeleteConfirmationDialog from './DeleteConfirmationDialog'
import { useModal } from 'hooks/useModal'
import { useSurveillanceContext } from '../hooks/SurveillanceContext'
import { useNotification } from 'components/Notification'
import { getReportIdsQueryKey } from '../utils/reports'

const DeleteReport = ({ id, title }) => {
  const queryClient = useQueryClient()
  const { createNotification } = useNotification()
  const { open: isOpen, handleOpen, handleClose } = useModal()

  const { selectedSecurity, selectedMonthAndYear, setIsLoading } = useSurveillanceContext()

  const { security, customer, datasource } = selectedSecurity

  const [deleteConfig, setDeleteConfig] = useState({
    onClickConfirm: () => null,
    dialogTitle: null,
    dialogContent: null,
  })

  // delete holder mutation
  const { mutateAsync: deleteReportMutation } = useMutation({
    mutationFn: () => {
      setIsLoading(true)
      deleteReport(id)
    },
    onSuccess: async () => {
      // close confirmation dialog - if not closed, after refreshing queries, the dialog will be open again
      handleClose()

      // it takes some time for the report to be deleted from the database, so we wait 500ms before refetching the reportIds query
      await new Promise(resolve => setTimeout(resolve, 500))
      // refetch reportIds query since it shows the latest 20 reports
      await queryClient.refetchQueries(
        getReportIdsQueryKey({
          customerId: customer?.id,
          securityId: security?.id,
          datasource,
          selectedMonthAndYear,
        }),
        {
          refetchActive: true,
          refetchInactive: false,
        },
      )

      createNotification({
        message: 'Report deleted successfully',
        variant: 'success',
      })
      setIsLoading(false)
    },
    onError: () => {
      createNotification({ message: 'Error deleting report', variant: 'error' })
      setIsLoading(false)
    },
  })

  // open delete confirmation dialog
  const handleOpenDeleteDialog = () => {
    setDeleteConfig({
      onClickConfirm: deleteReportMutation,
      dialogTitle: 'Delete Report?',
      dialogContent: `Are you sure that you want to remove the report: ${title} ?`,
    })
    handleOpen()
  }

  return (
    <>
      <DeleteButton
        title="Delete Report"
        onClick={handleOpenDeleteDialog}
        sx={{
          p: 0,
        }}
      />
      <DeleteConfirmationDialog
        key={id}
        onClickConfirm={deleteConfig.onClickConfirm}
        dialogTitle={deleteConfig.dialogTitle}
        dialogContent={deleteConfig.dialogContent}
        isOpen={isOpen}
        handleClose={handleClose}
      />
    </>
  )
}

export default DeleteReport
