import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { retryFactsetAPIRequest } from 'services/factsetProvisioning'
import { useNotification } from 'components/Notification'

import { getErrorMessage } from '../Utils'

/**
 * @param {object} param0 - props
 * @param {string} param0.factsetUserId - passed in whenever available (e.g. on FS user page)
 * @param {string} param0.factsetLocationId - passed in whenever available (e.g. on FS location page)
 * @returns {object} react-query mutation object e.g. { mutate, isLoading, ... }
 */
function useFactsetAPIRequest({ factsetUserId, factsetLocationId }) {
  const [selectedRequestId, setSelectedRequestId] = useState()
  const { createNotification } = useNotification()
  const queryClient = useQueryClient()

  const mutation = useMutation({
    mutationFn: requestId => retryFactsetAPIRequest(requestId),
    onSuccess: response => {
      const { data } = response

      createNotification({
        message: `Attempting to retrying API request ${data.id}`,
        variant: 'success',
      })

      if (factsetUserId) {
        queryClient.invalidateQueries(['factsetUser', factsetUserId])
      }
      if (factsetLocationId) {
        queryClient.invalidateQueries(['factsetLocation', factsetLocationId])
      }
    },
    onError: error => {
      createNotification({
        variant: 'error',
        message: getErrorMessage(error),
      })
    },
  })

  return {
    selectedRequestId,
    setSelectedRequestId,
    ...mutation,
  }
}

export default useFactsetAPIRequest
