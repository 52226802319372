import { useQueryClient } from 'react-query'

import { useNotification } from 'components/Notification'
import { irwin } from 'config'
import { fetchOrCreateAuthorization } from 'services/customers'

function useCustomerAuthorization() {
  const { createNotification } = useNotification()
  const queryClient = useQueryClient()

  const setupAuthorizeCustomerMutation = () => {
    // setMutationDefaults stores the mutation config in queryClient (similar to how context works)
    // we can access the mutation anywhere within the <QueryClientProvider> using `useMutation(queryKey`)
    queryClient.setMutationDefaults(['authorizeCustomer'], {
      mutationFn: fetchOrCreateAuthorization,
      onSuccess: (_, id) => {
        window.open(`${irwin.appUrl}/?customer_id=${id}`)
      },
      onError: (error, id) => {
        createNotification({
          message: `Error authorizing customer ${id}, ${error.message}`,
          variant: 'error',
        })
      },
    })
  }

  return {
    setupAuthorizeCustomerMutation,
  }
}

export default useCustomerAuthorization
