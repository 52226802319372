import React from 'react'

import Rollbar from 'utils/rollbar'
import { Typography } from '@the-platform-group/component-library'
import { Grid } from '@the-platform-group/component-library/Layout'
import { MAINTENANCE_STATUS_CODE } from 'constants/status'

function Error() {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid
        item
        sx={{
          p: theme => theme.spacing(18),
          textAlign: 'center',
        }}
      >
        <Typography variant="h5">An error occurred. Please try again later.</Typography>
      </Grid>
    </Grid>
  )
}

class GenericErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    if (error.response && error.response.status === MAINTENANCE_STATUS_CODE) {
      throw error
    }

    // Catch errors thrown by descendants
    return { hasError: true }
  }

  componentDidCatch(error) {
    // Log error as a side effect
    Rollbar.error(error)
  }

  render() {
    if (this.state.hasError) {
      return <Error />
    }

    return this.props.children
  }
}

export default GenericErrorBoundary
