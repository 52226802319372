import React from 'react'
import PropTypes from 'prop-types'
// Components
import { Box } from '@the-platform-group/component-library/'
import { Button } from '@the-platform-group/component-library/Button'
// hooks
import useUserForm from 'components/UserForm/useUserForm'

const UserCellComponent = ({ customerId, users, onUserAdd }) => {
  const { handleUserEdit, getUserFormModal } = useUserForm(onUserAdd)

  return (
    <>
      {users.map((user, idx) => (
        <Box key={user.id} tabIndex={idx + 1} role="button" onClick={() => handleUserEdit(user)}>
          {[user.firstName, user.lastName].join(' ')}
          {`(${user.email})`}
        </Box>
      ))}
      <Button
        variant="text"
        size="small"
        tabIndex={0}
        onClick={() => handleUserEdit()}
        sx={{
          display: 'none',
          'td:hover &': {
            display: 'block',
            cursor: 'pointer',
          },
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        Create User
      </Button>
      {getUserFormModal(customerId)}
    </>
  )
}

UserCellComponent.propTypes = {
  customerId: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onUserAdd: PropTypes.func.isRequired,
}

export default UserCellComponent
