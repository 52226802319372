import { useEffect, useState } from 'react'
// constants
import { getOrganization } from 'services/organizations'
import { isEmpty } from '@the-platform-group/formatters/checks'
import useFocuses from './useFocuses'
import { deleteFromFocuses, getUpdatedFocuses } from 'transforms/focus'

const useFocus = (allFocuses, organization = {}) => {
  const [focuses, setFocuses] = useState(allFocuses)
  const { newFocusFromCategory } = useFocuses()

  useEffect(() => {
    setFocuses(allFocuses)
  }, [allFocuses])

  const handleFocusCopy = async () => {
    const orgFocuses =
      organization?.focuses || (await getOrganization(organization.id)).data?.focuses

    if (isEmpty(orgFocuses)) {
      return
    }

    setFocuses(prevFocuses =>
      prevFocuses.concat(
        orgFocuses.reduce((res, f) => {
          // check if focus has already been added to avoid duplicate
          if (!prevFocuses.some(savedFocus => savedFocus.categoryValue === f.categoryValue)) {
            res.push({
              category: f.category,
              categoryValue: f.categoryValue,
              id: f.categoryValue,
              isNew: true,
            })
          }
          return res
        }, []),
      ),
    )
  }

  const handleFocusChange = focus =>
    setFocuses(prevFocuses => getUpdatedFocuses(prevFocuses, focus, newFocusFromCategory))

  const handleFocusDelete = delFocus =>
    setFocuses(prevFocuses => deleteFromFocuses(prevFocuses, delFocus))

  return {
    focuses,
    handleFocusDelete,
    handleFocusChange,
    handleFocusCopy,
  }
}

export default useFocus
