import { Grid, Typography } from '@the-platform-group/component-library'

function KeyValuePair({ label, value }) {
  return (
    <Grid container item alignItems="center">
      <Grid item xs={2}>
        <Typography fontWeight="fontWeightBold">{label}:</Typography>
      </Grid>
      <Grid item xs={10}>
        {Array.isArray(value) ? value.join(', ') : value}
      </Grid>
    </Grid>
  )
}

export default KeyValuePair
