import { useState } from 'react'
import { useMutation } from 'react-query'
import { useNotification } from 'components/Notification'
import { deleteOrganization } from 'services/organizations'
import { deletePerson } from 'services/people'
import ENTITY_TYPES from 'constants/entityTypes'

const SERVICE = {
  ORGANIZATION: deleteOrganization,
  PERSON: deletePerson,
}

const usePlatformDelete = ({ history, toEntityLabel, type }) => {
  const [deletedIds, setDeletedIds] = useState([])
  const deleteService = SERVICE[type]

  const handleDeleted = id => {
    setDeletedIds([...deletedIds, id])
    history.push(`/platform/${ENTITY_TYPES[type].plural}`)
  }

  const { createNotification } = useNotification()

  const { mutate: deleteOrg } = useMutation(data => deleteService(data.id), {
    onError: e =>
      createNotification({
        message: e.response?.data?.errors[0]?.message || e.message,
        variant: 'error',
      }),
    onSuccess: (_, params) => {
      handleDeleted(params.id)
      createNotification({ message: `${type} ${toEntityLabel(params)} deleted` })
    },
  })

  const handleDelete = async data => {
    if (!data.id) {
      throw new Error('onDeleteActivity is missing activity ID.')
    }
    return await deleteOrg(data)
  }

  return { deletedIds, handleDelete }
}

export default usePlatformDelete
