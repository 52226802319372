import React, { Fragment } from 'react'

import { Grid } from '@the-platform-group/component-library/Layout'
import { TextField } from '@the-platform-group/component-library'
import { Button } from '@the-platform-group/component-library/Button'
import LocationTypeahead from '@the-platform-group/component-library/LocationTypeahead'

import useProcessing from './hooks/useProcessing'
import useReview from './hooks/useReview'
import useReviewForm from './hooks/useReviewForm'

const ReviewForm = ({ state, onChange, onAddressSelect }) => (
  <>
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          label="First Name"
          name="firstName"
          value={state.firstName}
          onChange={onChange}
          sx={{
            mt: 1,
            mb: 0.5,
          }}
          fullWidth
          autoFocus
          required
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Last Name"
          name="lastName"
          value={state.lastName}
          onChange={onChange}
          sx={{
            mt: 1,
            mb: 0.5,
          }}
          fullWidth
          required
        />
      </Grid>
    </Grid>
    <LocationTypeahead
      freeSolo
      value={state.street}
      name="street"
      placeholder="Street Address *"
      onAddressSelect={onAddressSelect}
      onAddressChange={onChange}
      required
    />
    <TextField
      label="Unit, Floor, etc."
      name="street2"
      value={state.street2}
      onChange={onChange}
      sx={{
        mt: 1,
        mb: 0.5,
      }}
      fullWidth
    />
    <TextField
      label="City"
      name="city"
      value={state.city}
      onChange={onChange}
      sx={{
        mt: 1,
        mb: 0.5,
      }}
      fullWidth
      required
    />
    <TextField
      label="State Code"
      name="state"
      value={state.state}
      onChange={onChange}
      inputProps={{ maxLength: 3 }}
      sx={{
        mt: 1,
        mb: 0.5,
      }}
      fullWidth
    />
    <TextField
      label="Country Code"
      name="country"
      value={state.country}
      onChange={onChange}
      required
      inputProps={{ maxLength: 3 }}
      sx={{
        mt: 1,
        mb: 0.5,
      }}
      fullWidth
    />
    <TextField
      label="Postal Code"
      name="postalCode"
      value={state.postalCode}
      onChange={onChange}
      sx={{
        mt: 1,
        mb: 0.5,
      }}
      fullWidth
    />
  </>
)

const ReviewEditComponent = ({ history, match }) => {
  const [processing, startProcessing, stopProcessing] = useProcessing()
  const { formState, onChange, onAddressSelect } = useReviewForm()

  const reviewId = match.params.id

  const { review, onRelease, onSubmit } = useReview({
    formState,
    reviewId,
    startProcessing,
    stopProcessing,
  })

  const lines = review.originalAddress.split('\n')

  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <h3>Unparsed Address</h3>
        <address>
          {lines.map(line => (
            <Fragment key={line}>
              {line}
              <br />
            </Fragment>
          ))}
        </address>
        <br />
        <br />
        <Button color="secondary" onClick={onRelease} disabled={processing}>
          Release Lock
        </Button>
      </Grid>
      <Grid item xs={8}>
        <form>
          <h3>Parsed Address</h3>
          <ReviewForm state={formState} onChange={onChange} onAddressSelect={onAddressSelect} />
          <br />
          <br />
          <Button onClick={onSubmit} disabled={processing}>
            Submit Review
          </Button>
        </form>
      </Grid>
    </Grid>
  )
}

export default ReviewEditComponent
