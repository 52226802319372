import { useQueryClient, useMutation } from 'react-query'
import { updateOrganizationSetting } from 'services/organizations'
import ORGANIZATION_TYPES from 'constants/organizationTypes'
import capitalize from 'lodash/capitalize'
import { Switch } from '@the-platform-group/component-library'
import { useNotification } from '@the-platform-group/component-library/Notification'

const useOrganizationConfig = ({ queryKey }) => {
  const queryClient = useQueryClient()
  const { createNotification } = useNotification()

  const { mutate: updateOrgSetting } = useMutation({
    mutationFn: updateOrganizationSetting,
    onSuccess: currentOrganization =>
      queryClient.setQueryData(queryKey, oldData => {
        const updatedOrgs = [...oldData.data]
        const currentOrgIndex = updatedOrgs.findIndex(
          organization => organization.id === currentOrganization.organizationId,
        )
        updatedOrgs[currentOrgIndex].setting = currentOrganization
        return { ...oldData, data: updatedOrgs }
      }),
    onError: () => {
      createNotification({
        variant: 'error',
        message: 'Error occurred while updating organization setting, please try again.',
      })
    },
  })

  const getOrganizationColumns = () => [
    { field: 'name' },
    {
      field: 'type',
      valueFormatter: ({ value }) => ORGANIZATION_TYPES[value] || capitalize(value),
    },
    {
      field: 'costBasis',
      renderCell: ({ row: { id, setting } }) => (
        <Switch
          checked={setting?.costBasis !== false}
          onChange={(_, value) => updateOrgSetting({ organizationId: id, costBasis: value })}
          inputProps={{ 'aria-labelledby': 'cost-basis-switch' }}
        />
      ),
    },
  ]

  return { getOrganizationColumns }
}
export default useOrganizationConfig
