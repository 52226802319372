import { snakeCase } from 'change-case-object'
import { client, deserializer } from './client'
import { toCamel } from 'change-object-case'

/**
 * @param {*} params - { before_notes_prompt, after_notes_prompt, customer_id, entity_id, type, security_id }
 * @returns AI generated activity notes summary
 */
async function getAssistantNoteSummary({ queryKey }) {
  let [, params] = queryKey

  // Filter out null or empty string values
  params = Object.fromEntries(
    Object.entries(params).filter(([key, value]) => value != null && value !== ''),
  )

  const response = await client().get(
    `v2/assistant_summaries/${params.entityId}/test_prompts`,
    snakeCase({ params }),
  )

  // If the response is empty object that means result is in different state (not enabled, no activities, no notes) which defined in the meta
  const deserializedResponse =
    Object.keys(response.data).length > 0
      ? deserializer.deserialize(response)
      : toCamel(response.meta)

  return deserializedResponse
}

/**
 * @param {*} params - { entity_id, customer_id }
 * @returns Raw activity notes
 */
async function getRawAssistantNotes({ queryKey }) {
  const [, params] = queryKey

  const response = await client().get(
    `v2/assistant_summaries/${params.entityId}/raw_notes`,
    snakeCase({ params }),
  )
  return deserializer.deserialize(response)
}

async function deleteAssistantSummaryCache(params) {
  return await client().delete('v2/assistant_summaries/bulk_destroy', snakeCase({ params }))
}

export { getAssistantNoteSummary, getRawAssistantNotes, deleteAssistantSummaryCache }
