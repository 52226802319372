import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
// components
import { TextField } from '@the-platform-group/component-library'

const PersonFormComponent = ({ person, onPersonChange }) => {
  const { firstName, lastName, bio } = person

  return (
    <Fragment>
      <form>
        <TextField
          label="First Name"
          InputLabelProps={{ htmlFor: 'firstName' }}
          id="firstName"
          name="firstName"
          value={firstName || ''}
          onChange={onPersonChange}
          sx={{ mt: 0.5, mb: 1 }}
          fullWidth
        />
        <TextField
          label="Last Name"
          InputLabelProps={{ htmlFor: 'lastName' }}
          id="lastName"
          name="lastName"
          value={lastName || ''}
          onChange={onPersonChange}
          sx={{ mt: 0.5, mb: 1 }}
          fullWidth
        />
        <TextField
          label="Biography"
          name="bio"
          value={bio || ''}
          onChange={onPersonChange}
          sx={{ mt: 0.5, mb: 1 }}
          fullWidth
          multiline
        />
      </form>
    </Fragment>
  )
}

PersonFormComponent.propTypes = {
  person: PropTypes.object.isRequired,
  onPersonChange: PropTypes.func.isRequired,
}

export default PersonFormComponent
