const ORGANIZATION_TYPES = {
  investment_manager: 'Investment Manager',
  hedge_fund: 'Hedge Fund',
  alternative: 'Alternative',
  bank: 'Bank',
  private_bank: 'Private Bank',
  insurance: 'Insurance',
  family_office: 'Family Office',
  foundation: 'Foundation',
  pension_fund: 'Pension Fund',
  corporate: 'Corporate',
  sovereign: 'Sovereign',
  retail: 'Retail',
}

export default ORGANIZATION_TYPES

export const FEED_DISABLED_ORG_TYPES = ['alternative', 'bank', 'corporate', 'private_bank', 'other']

export const PARENT_ORGANIZATION_KEY = 'irwin_ultimate_parent'
export const PARENT_ORGANIZATION_TYPE = { [PARENT_ORGANIZATION_KEY]: 'Irwin Ultimate Parent' }
