import { useState, useEffect } from 'react'
import { useNotification } from 'components/Notification'
import transform_linkedin_url from 'linkedin-canonical-url'
import { updatePersonSocialProfiles } from 'services/personSocialProfiles'
import { isEmpty } from '@the-platform-group/formatters/checks'

function buildPersonSocialProfiles(editPersonSocialProfiles) {
  const defaultProfiles = [
    {
      id: null,
      provider: 'linked_in',
      url: '',
    },
  ]
  return editPersonSocialProfiles?.length > 0 ? editPersonSocialProfiles : defaultProfiles
}

const usePersonSocialProfiles = (editPersonSocialProfiles, id = null) => {
  const [personSocialProfiles, setPersonSocialProfiles] = useState(
    buildPersonSocialProfiles(editPersonSocialProfiles),
  )

  const personId = id
  const { createNotification } = useNotification()
  const [editingPersonSocialProfiles, setEditingPersonSocialProfiles] = useState([])

  // For all Person Social Profile Components
  useEffect(() => {
    setPersonSocialProfiles(buildPersonSocialProfiles(editPersonSocialProfiles))
    setEditingPersonSocialProfiles(buildPersonSocialProfiles(editPersonSocialProfiles))
  }, [editPersonSocialProfiles])

  const handlePersonSocialProfilesChange = index => psp => {
    const data = psp.target
    let newArr = [...personSocialProfiles]
    newArr[index][data.name] = data.value
    setPersonSocialProfiles(newArr)
  }

  const handleEditPersonSocialProfilesChange = index => e => {
    const data = e.target
    let newArr = [...editingPersonSocialProfiles]
    newArr[index][data.name] = data.value

    setEditingPersonSocialProfiles(newArr)
  }

  const handlePersonSocialProfilesSave = async psp => {
    try {
      const personSocialProfilesParams = psp.map(psp => {
        const data = psp
        data.url = psp.url ? transform_linkedin_url(psp.url.trim()) : ''
        return data
      })

      const params = {
        person_social_profiles_attributes: personSocialProfilesParams,
      }

      const { data: updatedPersonSocialProfiles } = await updatePersonSocialProfiles(
        personId,
        params,
      )
      setPersonSocialProfiles(updatedPersonSocialProfiles)
      createNotification({ message: 'Person Social Profile was saved' })
    } catch (e) {
      // handle 401s and 403s in error boundary
      if (e.status === 401 || e.status === 403) {
        throw e
      }

      const data = Object.keys(e.response?.data || {})
      const errors = data.map(err => `${err}: ${e.response?.data[err]}`).join(', ')
      const message = isEmpty(errors) ? 'Person can not save' : errors

      createNotification({ message, variant: 'error' })
    }
  }

  return {
    personSocialProfiles,
    handlePersonSocialProfilesChange,
    editingPersonSocialProfiles,
    handleEditPersonSocialProfilesChange,
    handlePersonSocialProfilesSave,
  }
}

export default usePersonSocialProfiles
