import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { deleteHolderFromReport } from 'services/reports'
import DeleteButton from './DeleteButton'
import DeleteConfirmationDialog from './DeleteConfirmationDialog'
import { REPORT_FIELD_PREFIXES, deleteHolderFromReportsCache } from '../utils/reports'
import { useNotification } from 'components/Notification'
import { useModal } from 'hooks/useModal'
import { useSurveillanceContext } from '../hooks/SurveillanceContext'

const DeleteHolder = ({ row, value, api }) => {
  const queryClient = useQueryClient()
  const { createNotification } = useNotification()
  const { open: isOpen, handleOpen, handleClose } = useModal()

  const { reportIds } = useSurveillanceContext()
  const reportsQueryKey = ['reports', { reportIds }]

  const [deleteConfig, setDeleteConfig] = useState({
    onClickConfirm: () => null,
    dialogTitle: null,
    dialogContent: null,
  })

  const holdingIdsToDelete = Object.keys(row).filter(
    rowKey => rowKey.includes(REPORT_FIELD_PREFIXES.holdingId) && row[rowKey],
  )
  const hasHoldingsToDelete = Boolean(holdingIdsToDelete.length)

  // delete holder mutation
  const { mutateAsync: deleteHolderMutation } = useMutation({
    mutationFn: () => {
      const params = []

      // find each holdingId_ and add to params to delete
      holdingIdsToDelete.forEach(rowKey => {
        params.push({
          id: rowKey.replace(REPORT_FIELD_PREFIXES.holdingId, ''),
          deleteIds: [row[rowKey]],
        })
      })
      return deleteHolderFromReport(params)
    },
    onSuccess: () => {
      createNotification({
        message: 'Holder deleted successfully',
        variant: 'success',
      })

      // invalidate the holdings queries (instead of updating the cache this method is used to refetch the data from BE since there can be various of holdings in the cache has the deleted holder)
      queryClient.invalidateQueries(['holdings'], {
        refetchActive: true,
        refetchInactive: false,
      })

      // delete holdings from reports cache
      queryClient.setQueryData(reportsQueryKey, reports => {
        // in date range mode reports return null so instead of updating the cache, invalidate the cache
        if (!reports) {
          queryClient.invalidateQueries(reportsQueryKey)
          return
        }

        return deleteHolderFromReportsCache({ reports, holdingIdsToDelete, row })
      })

      // close confirmation dialog
      handleClose()
    },
    onError: () => createNotification({ message: 'Error deleting holder', variant: 'error' }),
  })

  // open delete confirmation dialog
  const handleOpenDeleteDialog = () => {
    setDeleteConfig({
      onClickConfirm: deleteHolderMutation,
      dialogTitle: 'Delete Holder?',
      dialogContent: `Are you sure that you want to remove the holder: ${value} ?`,
    })
    handleOpen()
  }

  return (
    <>
      <DeleteButton
        disabled={row.currentShares}
        title="Delete Holder"
        onClick={handleOpenDeleteDialog}
        sx={{
          p: 0,
        }}
      />
      <DeleteConfirmationDialog
        onClickConfirm={deleteConfig.onClickConfirm}
        dialogTitle={deleteConfig.dialogTitle}
        dialogContent={deleteConfig.dialogContent}
        isOpen={isOpen}
        handleClose={handleClose}
        disabled={!hasHoldingsToDelete}
      />
    </>
  )
}

export default DeleteHolder
