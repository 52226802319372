import COUNTRIES from './countries'

const TURNOVER_TYPES = {
  veryLow: 'Very Low',
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  veryHigh: 'Very High',
}

const GEO_FOCUS = {
  NA: 'North America',
  SA: 'South America',
  EUR: 'Europe',
  ASIA: 'Asia',
  AFR: 'Africa',
  AUS: 'Australia',
  GLOB: 'Global',
}

const SECTORS = {
  M: 'Materials',
  I: 'Industrials',
  CBD: 'Cannabis',
  CD: 'Consumer Discretionary',
  CSE: 'Communication Services',
  CS: 'Consumer Staples',
  H: 'Healthcare',
  T: 'Technology',
  U: 'Utilities',
  F: 'Financials',
  TC: 'Telecommunications',
  RE: 'Real Estate',
  E: 'Energy',
}

const SUBSECTOR_GROUPS = {
  // materials
  M: {
    M_OM: 'Other Metals',
    M_PM: 'Precious Metals',
    M_FP: 'Forest Products',
    M_CM: 'Construction Materials',
    M_CP: 'Containers / Packaging',
    M_C: 'Chemicals',
    M_PP: 'Paper Products',
  },
  // industrials
  I: {
    I_TL: 'Transportation / Logistics',
    I_EBM: 'Engineering / Building / Machinery',
    I_CPS: 'Commercial & Professional Services',
    I_TD: 'Trading & Distributors',
    I_OI: 'Other Industrials',
  },
  CBD: {
    CBD_AG: 'AgTech',
    CBD_BIO: 'Biotechnology',
    CBD_CON: 'Consumption Devices',
    CBD_CULT: 'Cultivation & Retail',
    CBD_HEMP: 'Hemp Products',
    CBD_INV: 'Investing & Finance',
    CBD_MARIJUANA: 'Marijuana Products',
    CBD_OTHER: 'Other Ancillary',
    CBD_REAL: 'Real Estate',
    CBD_SECONDARY: 'Secondary Services',
    CBD_TECH: 'Tech & Media',
  },
  // consumer discretionary
  CD: {
    CD_AC: 'Automobiles & Components',
    CD_DA: 'Consumer Durables & Apparel',
    CD_R: 'Retailing',
    CD_MRL: 'Hotels, Restaurants & Leisure',
    CD_OC: 'Other Consumer Specialties / Services',
  },
  // consumer staples
  CS: {
    CS_FDS: 'Food, Drug & Staples Retailing',
    CS_B: 'Beverages',
    CS_T: 'Tobacco',
    CS_HP: 'Household / Personal Care',
    CS_S: 'Consumer Sundries',
  },
  CSE: {
    CSE_M: 'Media',
    CSE_E: 'Entertainment',
  },
  H: {
    // healthcare
    H_P: 'Pharmaceuticals',
    H_B: 'Biotechnology',
    H_MS: 'Medical Specialties',
    H_HS: 'Health Services',
    H_MH: 'Managed Health Care',
    H_MD: 'Medical Distributors',
  },
  // technology
  T: {
    T_SW: 'Software',
    T_SC: 'Semiconductors',
    T_EE: 'Electrical Equipment',
    T_HE: 'Hardware Equipment',
    T_DPI: 'Data Processing & IT Services',
    T_CC: 'Computer Communications',
  },
  // utilities
  U: {
    U_EU: 'Electric Utilities',
    U_GD: 'Gas Distributors',
    U_WU: 'Water Utilities',
    U_AP: 'Alternative Power Generation',
  },
  // financials
  F: {
    F_B: 'Banks',
    F_IM: 'Investment Managers',
    F_I: 'Insurance',
    F_FC: 'Financial Conglomerates',
    F_FRL: 'Finance / Rental / Leasing',
  },
  // telecommunications
  TC: {
    TC_WT: 'Wireless Telecommunications',
    TC_TE: 'Telecommunications Equipment',
    TC_OT: 'Other Telecommunications',
  },
  // real estate
  RE: {
    RE_RED: 'Real Estate Development',
    RE_REIT: 'Real Estate Investment Trusts',
  },
  // energy
  E: {
    E_O: 'Oilfield Services / Equipment',
    E_OGPIPE: 'Oil & Gas Pipelines',
    E_EGPROD: 'Oil & Gas Production',
    E_IO: 'Integrated Oil',
    E_OR: 'Oil Refining / Marketing',
    E_C: 'Coal',
  },
}

const SUBSECTORS = {
  // materials
  M_OM: 'Materials :: Other Metals',
  M_PM: 'Materials :: Precious Metals',
  M_FP: 'Materials :: Forest Products',
  M_CM: 'Materials :: Construction Materials',
  M_CP: 'Materials :: Containers / Packaging',
  M_C: 'Materials :: Chemicals',
  M_PP: 'Materials :: Paper Products',
  // industrials
  I_TL: 'Industrials :: Transportation / Logistics',
  I_EBM: 'Industrials :: Engineering / Building / Machinery',
  I_CPS: 'Industrials :: Commercial & Professional Services',
  I_TD: 'Industrials :: Trading & Distributors',
  I_OI: 'Industrials :: Other Industrials',
  // cannabis
  CBD_AG: 'Cannabis :: AgTech',
  CBD_BIO: 'Cannabis :: Biotechnology',
  CBD_CON: 'Cannabis :: Consumption Devices',
  CBD_CULT: 'Cannabis :: Cultivation & Retail',
  CBD_HEMP: 'Cannabis :: Hemp Products',
  CBD_INV: 'Cannabis :: Investing & Finance',
  CBD_MARIJUANA: 'Cannabis :: Marijuana Products',
  CBD_OTHER: 'Cannabis :: Other Ancillary',
  CBD_REAL: 'Cannabis :: Real Estate',
  CBD_SECONDARY: 'Cannabis :: Secondary Services',
  CBD_TECH: 'Cannabis :: Tech & Media',

  // consumer discretionary
  CD_AC: 'Consumer Discretionary :: Automobiles & Components',
  CD_DA: 'Consumer Discretionary :: Consumer Durables & Apparel',
  CD_R: 'Consumer Discretionary :: Retailing',
  CD_MRL: 'Consumer Discretionary :: Hotels, Restaurants & Leisure',
  CD_OC: 'Consumer Discretionary :: Other Consumer Specialties / Services',

  CSE_M: 'Communication Services :: Media',
  CSE_E: 'Communication Services :: Entertainment',

  // consumer staples
  CS_FDS: 'Consumer Staples :: Food, Drug & Staples Retailing',
  CS_B: 'Consumer Staples :: Beverages',
  CS_T: 'Consumer Staples :: Tobacco',
  CS_HP: 'Consumer Staples :: Household / Personal Care',
  CS_S: 'Consumer Staples :: Consumer Sundries',
  // healthcare
  H_P: 'Healthcare :: Pharmaceuticals',
  H_B: 'Healthcare :: Biotechnology',
  H_MS: 'Healthcare :: Medical Specialties',
  H_HS: 'Healthcare :: Health Services',
  H_MH: 'Healthcare :: Managed Health Care',
  H_MD: 'Healthcare :: Medical Distributors',
  // technology
  T_SW: 'Technology :: Software',
  T_SC: 'Technology :: Semiconductors',
  T_EE: 'Technology :: Electrical Equipment',
  T_HE: 'Technology :: Hardware Equipment',
  T_DPI: 'Technology :: Data Processing & IT Services',
  T_CC: 'Technology :: Computer Communications',
  // utilities
  U_EU: 'Utilities :: Electric Utilities',
  U_GD: 'Utilities :: Gas Distributors',
  U_WU: 'Utilities :: Water Utilities',
  U_AP: 'Utilities :: Alternative Power Generation',
  // financials
  F_B: 'Financials :: Banks',
  F_IM: 'Financials :: Investment Managers',
  F_I: 'Financials :: Insurance',
  F_FC: 'Financials :: Financial Conglomerates',
  F_FRL: 'Financials :: Finance / Rental / Leasing',
  // telecommunications
  TC_WT: 'Telecom :: Wireless Telecommunications',
  TC_TE: 'Telecom :: Telecommunications Equipment',
  TC_OT: 'Telecom :: Other Telecommunications',
  // real estate
  RE_RED: 'Real Estate :: Real Estate Development',
  RE_REIT: 'Real Estate :: Real Estate Investment Trusts',
  // energy
  E_O: 'Energy :: Oilfield Services / Equipment',
  E_OGPIPE: 'Energy :: Oil & Gas Pipelines',
  E_EGPROD: 'Energy :: Oil & Gas Production',
  E_IO: 'Energy :: Integrated Oil',
  E_OR: 'Energy :: Oil Refining / Marketing',
  E_C: 'Energy :: Coal',
}

const STYLES = {
  convertibles: 'Convertibles',
  corp: 'Corp',
  corp_high_yield: 'Corp/High Yield',
  distressed_debt: 'Distressed Debt',
  aggressive_growth: 'Aggressive Growth',
  deep_value: 'Deep Value',
  generalist_fixed_income: 'Generalist (Fixed Income)',
  govt_treas: 'Govt/Treas',
  esg: 'ESG',
  garp: 'GARP',
  local_regional: 'Local/Regional',
  mbs_abs: 'MBS/ABS',
  money_market: 'Money Market',
  quantitative_index: 'Quantitative/Index',
  generalist: 'Generalist',
  growth: 'Growth',
  unsecured_debentures: 'Unsecured/Debentures',
  income: 'Income',
  index: 'Index',
  quantitative: 'Quantitative',
  value: 'Value',
  yield: 'Yield',
}

const CATEGORIES = {
  market_cap: 'Market Cap',
  geo_focus: 'Geo Focus',
  sector: 'Sector',
  subsector: 'Sub-Sector',
  style: 'Style',
}

const ORGANIZATION_CATEGORIES = {
  market_cap: 'Market Cap',
  geo_focus: 'Geo Focus',
  sector: 'Sector',
  subsector: 'Sub-Sector',
}

const CATEGORY_VALUES = {
  geo_focus: COUNTRIES,
  sector: SECTORS,
  subsector: SUBSECTORS,
  style: STYLES,
}

const SECTOR_SUBSECTOR_PICS_FICS = {
  CBD: {
    CBD: 'PGL_1100',
    CBD_AG: 'PGL_1105',
    CBD_BIO: 'PGL_1110',
    CBD_CON: 'PGL_1115',
    CBD_CULT: 'PGL_1120',
    CBD_HEMP: 'PGL_1125',
    CBD_INV: 'PGL_1130',
    CBD_MARIJUANA: 'PGL_1135',
    CBD_OTHER: 'PGL_1140',
    CBD_REAL: 'PGL_1145',
    CBD_SECONDARY: 'PGL_1150',
    CBD_TECH: 'PGL_1155',
  },
  CD: {
    CD: '1400',
    CD_AC: '1405',
    CD_DA: '1415',
    CD_MRL: '1435',
    CD_OC: '1445',
    CD_R: '1445',
  },
  CS: {
    CS: '2400',
    CS_B: '2420',
    CS_FDS: '2405',
    CS_HP: '2435',
    CS_T: '2430',
    CS_S: '2450',
  },
  CSE: {
    CSE: '4900',
    CSE_E: '4910',
    CSE_M: '4905',
  },
  E: {
    E: '2100',
    E_O: '2105',
    E_OGPIPE: '2105',
    E_EGPROD: '2105',
    E_IO: '2110',
    E_OR: '2120',
    E_C: '2125',
  },
  F: {
    F: '4800',
    F_B: '4805',
    F_IM: '4845',
    F_I: '4880',
    F_FC: '4850',
    F_FRL: '4830',
  },
  H: {
    H: '2300',
    H_P: '2305',
    H_B: '2320',
    H_MS: '2325',
    H_HS: '2325',
    H_MH: '2325',
    H_MD: '2325',
  },
  I: {
    I: '3100',
    I_TL: '4600',
    I_EBM: '3115',
    I_CPS: '3200',
    I_TD: '3250',
    I_OI: '1250',
  },
  M: {
    M: '1100',
    M_OM: '1125',
    M_PM: '1120',
    M_FP: '1130',
    M_CM: '1135',
    M_CP: '2235',
    M_C: '2205',
    M_PP: '2230',
  },
  RE: {
    RE: '4885',
    RE_RED: '4885',
    RE_REIT: '4890',
  },
  T: {
    T: '3300',
    T_SW: '3320',
    T_SC: '1305',
    T_EE: '1315',
    T_HE: '1340',
    T_DPI: '3305',
    T_CC: '1352',
  },
  TC: {
    TC: '4900',
    TC_WT: '4915',
    TC_TE: '1320',
    TC_OT: '1352',
  },
  U: {
    U: '4700',
    U_EU: '4705',
    U_GD: '4735',
    U_WU: '4755',
    U_AP: '4760',
  },
}

const focuses = {
  TURNOVER_TYPES,
  GEO_FOCUS,
  SECTORS,
  SUBSECTOR_GROUPS,
  SUBSECTORS,
  STYLES,
  CATEGORIES,
  ORGANIZATION_CATEGORIES,
  CATEGORY_VALUES,
  SECTOR_SUBSECTOR_PICS_FICS,
}

export default focuses
