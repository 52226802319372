import React from 'react'
import { useQuery } from 'react-query'

import { Grid, Typography } from '@the-platform-group/component-library'
import { Button } from '@the-platform-group/component-library/Button'

import Spinner from 'components/Spinner'
import CustomerAuthorizationModal from 'components/CustomerModals/components/CustomerAuthorizationModal'
import UserNotifications from './components/UserNotifications'
import UserCustomers from './components/UserCustomers'
import FactsetUsers from 'pages/Factset/FactsetUsers'

import { getUser } from 'services/users'
import { useSessionState } from 'contexts/Session'
import useCustomerAuthModal from './hooks/useCustomerAuthModal'
import { FactsetMetadataProvider } from 'contexts/FactsetMetadataContext'

import { isAdmin } from 'utils/auth'

function UserDetailComponent() {
  const { currentUser } = useSessionState()
  const { userId, handleOpen, handleClose, open, onSave, isSaving } = useCustomerAuthModal()
  const { data: user, isLoading } = useQuery(['getUser', userId], () => getUser(userId), {
    enabled: !!userId,
    refetchOnWindowFocus: false,
    useErrorBoundary: true,
  })

  return isLoading || !user ? (
    <Spinner />
  ) : (
    <FactsetMetadataProvider>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={6}>
          <Typography variant="h4" component="h1" gutterBottom>
            {[user.firstName, user.lastName].join(' ')}
          </Typography>
          <Typography variant="subtitle2" component="h1">
            {user.email}
          </Typography>
          <Typography variant="subtitle2" component="h3">
            Role: {user.role}
          </Typography>
        </Grid>
        <Grid item xs={0}>
          {isAdmin(currentUser) ? (
            <>
              <Button onClick={handleOpen}>Add Customer to User</Button>
              <CustomerAuthorizationModal
                isOpen={open}
                onClose={handleClose}
                onSave={onSave}
                isSaving={isSaving}
              />
            </>
          ) : null}
        </Grid>
      </Grid>
      <br />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UserCustomers user={user} />
        </Grid>
      </Grid>
      <br />
      <FactsetUsers irwinUser={user} />
      <br />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UserNotifications user={user} />
        </Grid>
      </Grid>
      <br />
    </FactsetMetadataProvider>
  )
}

export default UserDetailComponent
