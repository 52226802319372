import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'

import { useNotification } from 'components/Notification'
import { useConfirmationDialog } from 'components/ConfirmationDialog'

import {
  unlinkFactsetUser as unlinkFactsetUserService,
  linkFactsetUser as linkFactsetUserService,
} from 'services/factsetProvisioning'

import { transformIrwinUserForSelect, transformFactsetUserForSelect } from '../Utils/index.js'

/**
 * @param {object} param0 - hook params
 * @param {object} param0.irwinUser - available when under context of an Irwin user (e.g. on user page)
 * @param {object} param0.factsetUser - available when under context of an Factset user (e.g. on FS users page)
 * @param {boolean} param0.isModalOpen - open/close state of the modal
 * @returns {object} react-hook-form useForm returns (e.g. control, formState, setValue, etc.)
 */
function useLinkFactsetUserForm({ irwinUser, factsetUser, isModalOpen }) {
  const formControls = useForm({
    mode: 'onChange',
    defaultValues: {
      factsetUser: '',
      irwinUser: '',
    },
  })

  useEffect(() => {
    if (!isModalOpen) {
      formControls.reset()
    } else {
      if (factsetUser?.id) {
        formControls.setValue('factsetUser', transformFactsetUserForSelect(factsetUser), {
          shouldDirty: false,
        })
      }
      if (irwinUser?.id) {
        formControls.setValue('irwinUser', transformIrwinUserForSelect(irwinUser), {
          shouldDirty: false,
        })
      }
    }
  }, [factsetUser, irwinUser, formControls, isModalOpen])

  return formControls
}

/**
 * @returns {object} mutation hook for linking Factset user with Irwin user
 */
function useLinkFactsetUser({ onSuccess }) {
  const { createNotification } = useNotification()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: formData => {
      return linkFactsetUserService(formData.factsetUser.value, formData.irwinUser.value)
    },
    onSuccess: response => {
      createNotification({
        message: 'Successfully linked Factset user with Irwin user',
        variant: 'success',
      })
      queryClient.invalidateQueries({ queryKey: ['factsetUsers'] })
      queryClient.invalidateQueries({ queryKey: ['factsetUser', response.id] })
      onSuccess()
    },
    // no `onError` callback needed as the consuming component is using the returned `error` property to
    // display errors
  })
}

/**
 * @returns {function} mutation hook for unlinking Factset user from Irwin user
 */
function useUnlinkFactsetUser() {
  const { createNotification } = useNotification()
  const { createConfirmationDialog } = useConfirmationDialog()
  const queryClient = useQueryClient()
  const { mutate: unlinkFactsetUser, isLoading } = useMutation({
    mutationFn: unlinkFactsetUserService,
    onSuccess: response => {
      queryClient.invalidateQueries({ queryKey: ['factsetUser', response.id] })
      queryClient.invalidateQueries({ queryKey: ['factsetUsers'] })
      createNotification({ message: 'Successfully unlinked user.', variant: 'success' })
    },
    onError: error => {
      const message = error.response?.data?.errors[0] || error.message
      createNotification({ message, variant: 'error' })
    },
  })

  const onUnlink = factsetUser => () => {
    createConfirmationDialog({
      title: `Unlink ${factsetUser.givenName} ${factsetUser.familyName}`,
      body: `Are you sure you want to unlink ${factsetUser.givenName} ${factsetUser.familyName}? The Factset user will no longer be linked to an Irwin user.`,
      onConfirm: () => unlinkFactsetUser(factsetUser.id),
    })
  }

  return { onUnlink, isUnlinking: isLoading }
}

export { useLinkFactsetUserForm, useLinkFactsetUser, useUnlinkFactsetUser }
