import ERRORS from 'constants/errorTypes'

export class ForbiddenError extends Error {
  constructor(user) {
    const message = `Forbidden to access admin with a role of ${user.role}`
    super(message)

    this.error = ERRORS.FORBIDDEN
  }
}
