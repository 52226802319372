import axios from 'axios'
import debounce from 'utils/debounce'

function setupCancellableQuery() {
  let abortController

  return async function(query, params) {
    if (abortController) {
      abortController.abort('Operation cancelled by the user.')
    }
    abortController = new AbortController()
    return await query(params, abortController.signal)
  }
}

export const runCancellableQuery = setupCancellableQuery()

/**
 * API call wrapper with cancellable option, use this for any debounced calls
 * @param {function} query : API call that performs query & response formatting
 * @param {object} params : parameters to pass to the API call
 * @param {int} debounceTimer: optional, if defined, will add debounce to query call
 * 
 * general format for query method:
 * 
 async function exampleAPICallWrapper(params, signal) {
   const formattedParams = formatParams(params) // format params if needed
   const response = await client().get(`api_url?${queryParams}`, { signal })
   return deserializeAndFormat(response) // deserialize & format response if needed
  }
  */
async function cancellableQuery(query, params, debounceTimer) {
  const runQuery = debounceTimer
    ? debounce(async (params, signal) => await query(params, signal), debounceTimer)
    : query

  try {
    return await runCancellableQuery(runQuery, params)
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request cancelled', error.message)
      return null
    }

    throw error // propagate error if not a cancelled request
  }
}

export { cancellableQuery }
