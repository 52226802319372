import { DATAGRID_KEY } from 'constants/localStorage'
import { toSnake as toSnakeCase } from 'change-object-case'

// TO DO: Move into component library
/**
 * Function to format sorting column values returned from data grid tables
 * @param {Array} fieldArr - collection of column fields and direction of sort
 * @param {Function} handleSortFn - callback function to receive the results of the formatted sort field and direction
 * @param {boolean} [toSnake] - optionally format the field name from camelCase to snake_case
 */
const handleSortModelChange = (fieldArr, handleSortFn, toSnake = true) => {
  if (fieldArr?.length) {
    const { field, sort } = fieldArr[0]
    const formattedField = toSnake ? toSnakeCase(field) : field
    handleSortFn(formattedField, sort)
  }
}

/**
 * Function to fetch the table's state saved in local storage
 * @param {string} name - table name
 * @returns {object} - the tables's state
 */
const getSavedTableState = name =>
  JSON.parse(localStorage.getItem(`${DATAGRID_KEY}/${name}`) || '{}')

export { handleSortModelChange, getSavedTableState }
