import React, { useState } from 'react'

const FactsetModalActionsContext = React.createContext({})

function FactsetModalActionsProvider({ children }) {
  const [currentStep, setStep] = useState(0) // modal form steps

  const gotoPrevStep = () => setStep(step => (step > 1 ? step - 1 : 0))
  const gotoNextStep = () => setStep(step => step + 1)
  const gotoFirstStep = () => setStep(0)

  const value = {
    currentStep: currentStep,
    gotoPrevStep,
    gotoNextStep,
    gotoFirstStep,
  }

  return (
    <FactsetModalActionsContext.Provider value={value}>
      {children}
    </FactsetModalActionsContext.Provider>
  )
}

export { FactsetModalActionsContext, FactsetModalActionsProvider }
