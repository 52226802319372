import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@the-platform-group/component-library'

const DatePicker = ({ label, ...rest }) => (
  <TextField
    label={label}
    type="date"
    sx={{
      mr: 1,
      mt: 1,
      mb: 0.5,
      width: 200,
    }}
    InputLabelProps={{
      shrink: true,
    }}
    fullWidth
    {...rest}
  />
)

DatePicker.propTypes = {
  label: PropTypes.string.isRequired,
}

export default DatePicker
