import React from 'react'

import { FormControl, Select, TextField } from '@the-platform-group/component-library'
import { Grid } from '@the-platform-group/component-library/Layout'

import customerTypes from 'constants/customerTypes'
import activeStateTypes from 'constants/activeStateTypes'
import capitalize from 'lodash/capitalize'

function CustomerFilters({
  filters,
  handleCustomerNameSearch,
  handleCustomerTypeFilterChange,
  handleCustomerActiveStateFilterChange,
}) {
  const { type, state: activeState } = filters
  const currentTypeFilterValue = type ? customerTypes.find(o => o === type) : 'all'

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={6}>
        <TextField
          onChange={handleCustomerNameSearch}
          placeholder="Search by customer or user name"
          name="customer"
          sx={{
            mt: 0.5,
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <Select
            id="customer-state-filter"
            name="activeState"
            variant="outlined"
            value={activeState}
            options={Object.entries(activeStateTypes).map(([label, value]) => ({
              value,
              label: capitalize(label),
            }))}
            onChange={e => handleCustomerActiveStateFilterChange(e.target.value)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <Select
            id="customer-type-filter"
            name="type"
            variant="outlined"
            value={currentTypeFilterValue}
            options={['all', ...customerTypes].map(type => ({
              value: type,
              label: capitalize(type),
            }))}
            onChange={e => handleCustomerTypeFilterChange(e.target.value)}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default CustomerFilters
