import React, { createContext } from 'react'
import { useQuery } from 'react-query'
import { getFactsetMetadata } from 'services/factsetProvisioning'

const FactsetMetadataStateContext = createContext()

function FactsetMetadataProvider({ children }) {
  const query = useQuery(['factsetMetadata'], getFactsetMetadata, {
    select: response => ({
      royaltyCodes: response.royaltyCodes.map(data => ({
        value: data.code,
        label: data.description,
      })),
      products: response.products.map(data => ({ value: data.id, label: data.name })),
      firmDescriptions: response.firmDescriptions.map(data => ({
        value: data.id,
        label: data.name,
      })),
    }),
    placeholderData: {
      royaltyCodes: [],
      products: [],
      firmDescriptions: [],
    },
    refetchOnWindowFocus: false,
    useErrorBoundary: false,
  })

  return (
    <FactsetMetadataStateContext.Provider value={query}>
      {children}
    </FactsetMetadataStateContext.Provider>
  )
}

function useFactsetMetadata() {
  const context = React.useContext(FactsetMetadataStateContext)
  if (context === undefined) {
    throw new Error('useFactsetMetadata() must be used within an FactsetMetadataProvider')
  }
  return context
}

export { useFactsetMetadata, FactsetMetadataProvider }
