import { Controller } from 'react-hook-form'
import { allCountries } from 'country-region-data'
import { List, ListItem, Alert, Typography, Grid } from '@the-platform-group/component-library'
import {
  TextFieldWithHelpIcon,
  SelectWithHelpIcon,
  TypeaheadSingleSelectWithHelpIcon,
} from 'pages/Factset/Components/Common'
import { useFactsetMetadata } from 'contexts/FactsetMetadataContext'
import CustomerSelect from 'pages/Users/components/CustomerSelect'

const COUNTRY_OPTIONS = allCountries.reduce((acc, [label, code, regions]) => {
  const item = {
    label,
    value: { code, regions },
  }
  // Add United States and Canada to top of list as these are most commonly chosen
  if (['US', 'CA'].includes(code)) {
    acc.unshift(item)
  } else {
    acc.push(item)
  }
  return acc
}, [])

const CreateFactsetLocationForm = ({ type, control, watch, errors, disableCustomerField }) => {
  const country = watch('country')
  const { data: factsetMetadata, error } = useFactsetMetadata()

  if (error) {
    return (
      <Alert severity="error">
        There was an error loading the form. Please try reloading the page.
      </Alert>
    )
  }

  return (
    <Grid container flexDirection="column" rowSpacing={2}>
      <Grid item>
        <Controller
          control={control}
          name="name"
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <TextFieldWithHelpIcon
                label="Name"
                required
                fullWidth
                tooltipText="A human friendly name for this Location."
                {...field}
              />
            )
          }}
        />
      </Grid>
      <Grid item>
        <Controller
          control={control}
          name="customer"
          rules={{ required: true }}
          render={({ field }) => (
            <CustomerSelect
              activeCustomerOnly
              disabled={disableCustomerField}
              includeCustomerType="all"
              label="Customer *"
              margin="none"
              tooltipText={
                disableCustomerField &&
                "This value can not be changed once the Location has been created. To change the linked Irwin customer use the 'Link an Irwin Customer' action."
              }
              {...field}
            />
          )}
        />
      </Grid>
      {type === 'unmanaged' && (
        <Grid item>
          <Controller
            control={control}
            name="ds1Id"
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <TextFieldWithHelpIcon
                  label="Factset Location ID (DS1 ID)"
                  required
                  fullWidth
                  tooltipText="The ID of the FactSet Location from FactSet's system."
                  {...field}
                />
              )
            }}
          />
        </Grid>
      )}
      {type === 'managed' && (
        <>
          <Grid item>
            <Controller
              control={control}
              name="description"
              render={({ field }) => {
                return (
                  <TextFieldWithHelpIcon
                    label="description"
                    multiline
                    fullWidth
                    tooltipText="Optional additional text to describe the Location. This is typically left unfilled but should be able to be filled by the user if needed."
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="address1"
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <TextFieldWithHelpIcon
                    label="Address 1"
                    required
                    fullWidth
                    tooltipText="Combined with the other Address X fields this field should identify the sub-locality part of the mailing address of the Irwin Customer account. Typically the Address 1 contains the building number and road name - but it's not uncommon to find unit numbers, suite, floor number, etc as well. "
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="address2"
              render={({ field }) => {
                return (
                  <TextFieldWithHelpIcon
                    label="Address 2"
                    fullWidth
                    tooltipText="Combined with the other Address X fields this field should identify the sub-locality part of the mailing address of the Irwin Customer account. In some cases Address 2 is filled with unit numbers, suite numbers, floor numbers, or other information typically nested under the building details - but as with other Address X fields this is mostly left freeform."
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="address3"
              render={({ field }) => {
                return (
                  <TextFieldWithHelpIcon
                    label="Address 3"
                    fullWidth
                    tooltipText="Combined with the other Address X fields this field should identify the sub-locality part of the mailing address of the Irwin Customer account. In almost all cases address_3 is unfilled, but could be useful in some cases to fill out very unique cases."
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="locality"
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <TextFieldWithHelpIcon
                    label="Locality"
                    placeholder="Toronto"
                    required
                    fullWidth
                    tooltipText="The locality of the mailing address of the Irwin Customer account. In almost all cases this is the City the address belongs to, but is not always the case."
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="country"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => {
                return (
                  <TypeaheadSingleSelectWithHelpIcon
                    label="Country *"
                    value={value}
                    onChange={(__, newValue) => onChange(newValue)}
                    variant="outlined"
                    options={COUNTRY_OPTIONS}
                    tooltipText="An ISO-3166 alpha-2 country code that identifies the country to which the mailing address of the Irwin Customer account belongs."
                  />
                )
              }}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="region"
              render={({ field: { onChange, value } }) => {
                return (
                  <TypeaheadSingleSelectWithHelpIcon
                    label="Region"
                    value={value}
                    onChange={(__, newValue) => onChange(newValue)}
                    variant="outlined"
                    margin="none"
                    disabled={!country}
                    options={country?.value.regions.map(([label, value]) => ({
                      label,
                      value,
                    }))}
                    tooltipText={
                      <>
                        <Typography variant="inherit" paragraph>
                          A two letter code identifying the major region to which the mailing
                          address of the Irwin Customer account belongs. In most cases this is the
                          code of a State, Province or Territory.
                        </Typography>
                        <Typography variant="inherit" paragraph>
                          <b>NOTE:</b> this field is normally optional but is required when the
                          country field is US (United States) or AU (Australia) [ this list of
                          countries may change in the future ]. Even if the field is optional it's
                          still recommended to fill out where readily available codes are defined,
                          such as in Canada.
                        </Typography>
                      </>
                    }
                  />
                )
              }}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="postalCode"
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <TextFieldWithHelpIcon
                    label="Postal Code"
                    required
                    fullWidth
                    tooltipText="The postal code portion of the mailing address to which the Irwin Customer account belongs."
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field }) => {
                return (
                  <TextFieldWithHelpIcon
                    label="Phone number"
                    fullWidth
                    tooltipText="An optional phone number which could be given to FactSet for the purposes of reaching out to the Irwin Customer if needed."
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="firmDescriptionId"
              render={({ field }) => {
                return (
                  <SelectWithHelpIcon
                    fullWidth
                    label="Firm Description"
                    options={factsetMetadata.firmDescriptions}
                    tooltipText="The ID of the FactSet Firm Description that is appropriate for describing this Irwin Customer account. In almost every case this value should be 'Corporate', but in very very rare cases this value may need to be set to something else."
                    {...field}
                  />
                )
              }}
            />
          </Grid>
          <Grid item>
            <Controller
              control={control}
              name="emailDomain"
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <TextFieldWithHelpIcon
                    label="Email domain"
                    placeholder="acme.com"
                    required
                    fullWidth
                    tooltipText={
                      <>
                        <Typography variant="inherit" paragraph>
                          The domain portion of the email address that any users of this Irwin
                          Customer account will have. This field must match the domain portion
                          precisely or else it will be impossible to provision FactSet User accounts
                          for this Location.
                        </Typography>
                        <Typography variant="inherit" paragraph>
                          For example for a customer account with email addresses: alice@acme.com,
                          bob@acme.com and charlie@acme.com this field should be filled out with
                          acme.com.
                        </Typography>
                        <Typography variant="inherit" paragraph>
                          At the current time only one email domain can be assigned to a FactSet
                          Location by Irwin and cannot be changed by Irwin after the Location is
                          created. To add more domains or modify existing ones the user should
                          submit a FactSet support ticket.
                        </Typography>
                      </>
                    }
                    {...field}
                  />
                )
              }}
            />
          </Grid>
        </>
      )}
      <List>
        {errors?.map(error => (
          <ListItem sx={{ px: 0 }}>
            <Alert severity="error">
              <Typography variant="body2" component="span" fontWeight="fontWeightBold">
                {error.field}:{' '}
              </Typography>
              {error.message}
            </Alert>
          </ListItem>
        ))}
      </List>
    </Grid>
  )
}

export default CreateFactsetLocationForm
