import React from 'react'
import { Box, Typography } from '@the-platform-group/component-library'
import { Grid } from '@the-platform-group/component-library/Layout'

import { getStatus } from 'services/status'

function Error({ reason, expiresAt }) {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
      }}
    >
      <Grid
        container
        direction="column"
        align="center"
        spacing={2}
        sx={{
          position: 'relative',
          top: 'calc(50% - 84px)',
        }}
      >
        <Grid item xs>
          <Typography variant="h2">Irwin is currently under maintenance.</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h5">{reason}</Typography>
        </Grid>
        <Grid item xs>
          <Typography variant="h5">
            Maintenance will continue approximately until: {expiresAt}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export const POLLING_FREQUENCY = 1000 * 60 * 10 // 10 minutes

class MaintenanceErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, reason: '', expiresAt: '', interval: null }
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      reason: error.response.data.reason,
      expiresAt: new Date(error.response.data.expires_at).toLocaleString(),
    }
  }

  componentDidCatch() {
    const interval = setInterval(async () => {
      try {
        await getStatus()

        this.setState(state => {
          clearInterval(state.interval)
          return { hasError: false, interval: null }
        })
      } catch (error) {
        // errors at this point are a noop, we only care about
        // a 200 response when the system is back up
      }
    }, POLLING_FREQUENCY)

    this.setState({ interval })
  }

  render() {
    if (this.state.hasError) {
      return <Error reason={this.state.reason} expiresAt={this.state.expiresAt} />
    }

    return this.props.children
  }
}

export default MaintenanceErrorBoundary
