import createDataContext from './createDataContext'

const taskReducer = (state, action) => {
  switch (action.type) {
    case 'AFFILIATION_TASK:FETCH_STARTED':
      return { ...state, isLoading: true, failed: false }
    case 'AFFILIATION_TASK:FETCH_RECEIVED':
      return {
        ...state,
        isLoading: false,
        tasks: action.payload.tasks || [],
        total: action.payload.total || 0,
        self: action.payload.self,
        next: action.payload.next,
        prev: action.payload.prev,
      }
    case 'AFFILIATION_TASK:SET_PAGE':
      return { ...state, page: action.payload.page }
    case 'AFFILIATION_TASK:SET_ROWS_PER_PAGE':
      return { ...state, page: action.payload.page, rowsPerPage: action.payload.rowsPerPage }
    case 'AFFILIATION_TASK:FAILED':
      throw action.payload
    default:
      throw new Error(`Unsupported action type: ${action.type}`)
  }
}

const initialState = {
  tasks: [],
  links: null,
  total: 0,
  rowsPerPage: 25,
  page: 0,
  self: null,
  next: null,
  prev: null,
  isLoading: false,
  failed: false,
}

export const { Provider, Context } = createDataContext(taskReducer, initialState)
