import { useEffect, useState } from 'react'

import { useNotification } from 'components/Notification'
import { approveOrganization } from 'services/organizations'
import { isEmpty } from '@the-platform-group/formatters/checks'
import { formatDate } from '@the-platform-group/formatters/dateFormat'
import { DATETIME_WITH_TIMEZONE_FORMAT } from 'constants/formats'
import { FEED_DISABLED_ORG_TYPES } from 'constants/organizationTypes'

const WARNING_MESSAGES = {
  peopleCount: {
    title: 'People Count',
    body:
      "There are no associated people with the organization and approving it won't show this record in the feed",
  },
  orgType: {
    title: 'Type',
    body:
      'Organization type associated with this organization will not get added in the feed. They will appear in the application but not in the feed',
  },
  orgFocus: {
    title: 'Focus',
    body:
      'There is no organization focus for this organization, but if you approve this record, it will show up in the feed',
  },
  description: {
    title: 'Description',
    body:
      "There is no associated description with this organization and approving it won't show this record in the feed",
  },
}

const useOrganizationApprove = ({ id, approvedAt, type, description, people, focuses, submit }) => {
  const [approved, setApproved] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [warnings, setWarnings] = useState([])
  const [peopleInformation, setPeopleInformation] = useState({})

  const { createNotification } = useNotification()

  useEffect(() => {
    setApproved(formatDate(approvedAt, DATETIME_WITH_TIMEZONE_FORMAT))

    const warningsText = []

    if (people?.length === 0) {
      warningsText.push(WARNING_MESSAGES.peopleCount)
    }

    if (FEED_DISABLED_ORG_TYPES.includes(type)) {
      warningsText.push(WARNING_MESSAGES.orgType)
    }

    if (focuses?.length === 0) {
      warningsText.push(WARNING_MESSAGES.orgFocus)
    }

    if (!description) {
      warningsText.push(WARNING_MESSAGES.description)
    }

    setWarnings(warningsText)

    const total = people?.length || 0
    const approved = people?.filter(person => person.approvedAt !== null)?.length || 0

    const approveButtonEnabled = total === approved
    setPeopleInformation({
      total,
      approved,
      approveButtonEnabled,
    })
  }, [approvedAt, type, people, focuses, description])

  const handleApproveOrganization = async () => {
    setIsLoading(true)
    try {
      const { data } = await approveOrganization(id)
      setApproved(formatDate(data.approvedAt, DATETIME_WITH_TIMEZONE_FORMAT))
      createNotification({ message: `Organization ${data.name} approved` })
      submit()
      setIsLoading(false)
      setModalOpen(false)
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        throw e
      }
      const data = Object.keys(e.response?.data || {})
      const errors = data.map(err => `${err}: ${e.response?.data[err]}`).join(', ')
      const message = isEmpty(errors) ? 'Organization Approve failed' : errors
      createNotification({ message, variant: 'error' })
      setIsLoading(false)
    }
  }

  return {
    warnings,
    approved,
    peopleInformation,
    handleApproveOrganization,
    isLoading,
    modalOpen,
    setModalOpen,
  }
}

export default useOrganizationApprove
