export const DATE_FORMAT = 'yyyy-MM-dd'
export const LONG_ISO_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm"
export const LEGACY_DATE_FORMAT = 'YYYY-MM-DD'
export const PDF_DATE_FORMAT = 'MMMM dd, yyyy'
export const TABLE_DATE_FORMAT = 'MMM dd, yyyy'
// this is the time format valid in Safari
export const TIME_FORMAT = 'T00:00:00'
export const DATETIME_WITH_TIMEZONE_FORMAT = 'MMM dd, yyyy HH:mm:ss zzz'
export const YEAR_FORMAT = 'yyyy'
export const MONTH_YEAR_FORMAT = 'MM-yyyy'
export const WEEK_OF_FORMAT = "'Week of' MMM dd"

const formats = {
  DATE_FORMAT,
  LEGACY_DATE_FORMAT,
  TIME_FORMAT,
}
export default formats
