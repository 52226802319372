import React, { useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { daysLeft } from 'utils/format'
import { TABLE_DATE_FORMAT } from 'constants/formats'
import { useQueryClient } from 'react-query'

import { useSessionState } from 'contexts/Session'
import { saveSite } from 'services/customerWebsites'

import { useNotification } from 'components/Notification'

import useCustomer from './hooks/useCustomer'
import useCustomerAuthorization from 'components/CustomerActionsDropdown/hooks/useCustomerAuthorization'
import useUserForm from 'components/UserForm/useUserForm'

import { formatDate } from '@the-platform-group/formatters/dateFormat'
import { Grid, Typography, useTheme } from '@the-platform-group/component-library'
import { Button } from '@the-platform-group/component-library/Button'
import { Warning as WarningIcon } from '@the-platform-group/component-library/Icons'
import Spinner from 'components/Spinner'
import SiteTable from './Table/SiteTable'
import SiteModal from './Modal/SiteModal'
import SnippetModal from './Modal/SnippetModal'
import UsersTable from './Table/UsersTable'
import CustomerActionsDropdown from 'components/CustomerActionsDropdown/CustomerActionsDropdown'
import FactsetLocations from 'pages/Factset/FactsetLocations'
import FactsetUsers from 'pages/Factset/FactsetUsers'
import { FactsetMetadataProvider } from 'contexts/FactsetMetadataContext'

function CustomerAccountComponent() {
  const [isSiteModalOpen, setIsSiteModalOpen] = useState(false)
  const [isSnippetModalOpen, setIsSnippetModalOpen] = useState(false)

  const [siteObj, setSiteObj] = useState(null)
  const theme = useTheme()
  const { setupAuthorizeCustomerMutation } = useCustomerAuthorization()
  const { createNotification } = useNotification()
  const queryClient = useQueryClient()

  const { currentUser } = useSessionState()

  const siteTableCompRef = useRef()

  const { id: customerId } = useParams()

  const authorization = currentUser.customerAuthorizations.find(
    auth => auth.customer.id === customerId,
  )

  const {
    customer,
    getModalComponents,
    handleCustomerArchive,
    handleCustomerUnarchive,
    handleCustomerSecurity,
    handleCustomerEdit,
    handleCustomerFeatureToggle,
  } = useCustomer(customerId)

  const onUserAdd = user => {
    createNotification({ message: `User ${user.firstName} ${user.lastName} created` })
    queryClient.invalidateQueries({ queryKey: ['customerAuthorizations', customerId] })
  }
  const { handleUserEdit, getUserFormModal } = useUserForm(onUserAdd)

  // add mutation for authorizing to a customer for actions dropdown to the
  // defaultMutations
  setupAuthorizeCustomerMutation()

  const showSnippetModal = siteObj => {
    setSiteObj(siteObj)
    setIsSnippetModalOpen(true)
  }

  const showEditSiteModal = siteObj => {
    setSiteObj(siteObj)
    setIsSiteModalOpen(true)
  }

  const defaultSecurity = customer?.defaultSecurity ? (
    <>
      {customer.defaultSecurity.name} ${customer.defaultSecurity.listingPrimary?.tickerExchange}
    </>
  ) : (
    <>
      <WarningIcon fontSize="small" sx={{ verticalAlign: 'top' }} />
      &nbsp;Security not setup yet
    </>
  )

  return customer ? (
    <FactsetMetadataProvider>
      <Grid container justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={6}>
          <Typography variant="h4" component="h1" gutterBottom>
            {customer.name}
          </Typography>
          <Typography
            variant="subtitle2"
            component="h3"
            gutterBottom
            color={customer.defaultSecurity ? 'default' : 'error'}
          >
            {defaultSecurity}
          </Typography>
          {customer.expiredAt && (
            <Typography variant="subtitle2" component="h4">
              Trial Period Ends: {formatDate(customer.expiredAt, TABLE_DATE_FORMAT)}
              <Typography variant="subtitle" sx={{ color: theme.palette.primary.warn }}>
                {' '}
                {daysLeft(customer.expiredAt)}
              </Typography>
            </Typography>
          )}
          <Typography variant="subtitle2" component="h4">
            {`Created ${format(new Date(customer.createdAt), 'PP')} by `}
            {customer.creator
              ? `${customer.creator.firstName} ${customer.creator.lastName}`
              : 'Unknown'}
          </Typography>
        </Grid>
        <Grid item xs={0}>
          <CustomerActionsDropdown
            data={{
              id: customer.id,
              authorization,
              archived: !!customer.archivedAt,
              onCustomerEdit: () => handleCustomerEdit(customer),
              onCustomerFeatureToggle: () => handleCustomerFeatureToggle(customer),
              onCustomerArchive: () => handleCustomerArchive(customer),
              onCustomerUnarchive: () => handleCustomerUnarchive(customer),
              onCustomerSecurity: () => handleCustomerSecurity(customer),
            }}
          />
        </Grid>
      </Grid>
      <br />

      <Grid container spacing={2} justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={6}>
          <Typography variant="h5" component="h2">
            Users
          </Typography>
        </Grid>
        <Grid item xs={0}>
          <Button onClick={() => handleUserEdit()}>Create User</Button>
        </Grid>
      </Grid>
      <UsersTable customerId={customerId} />
      <br />

      <Grid container spacing={2} justifyContent="space-between" alignItems="flex-end">
        <Grid item xs={6}>
          <Typography variant="h5" component="h2">
            IrwinIQ
          </Typography>
        </Grid>
        <Grid item xs={0}>
          <Button onClick={() => setIsSiteModalOpen(true)}>Add Website</Button>
        </Grid>
      </Grid>

      <br />
      <SiteTable
        customerId={customerId}
        ref={siteTableCompRef}
        showSnippet={showSnippetModal}
        showEditSite={showEditSiteModal}
      />
      <br />
      <FactsetLocations customer={customer} />
      <br />
      <FactsetUsers customer={customer} />
      <br />

      {getUserFormModal(customerId)}
      {getModalComponents()}
      <SiteModal
        open={isSiteModalOpen}
        onSave={saveSite}
        onClose={() => {
          setIsSiteModalOpen(false)
          setSiteObj(null)
        }}
        customerId={customerId}
        refreshTable={() => siteTableCompRef.current.fetchData()}
        editableSite={siteObj ? { ...siteObj, securityId: siteObj?.security?.id } : null}
      />
      <SnippetModal
        open={isSnippetModalOpen}
        onClose={() => {
          setIsSnippetModalOpen(false)
          setSiteObj(null)
        }}
        snippet={siteObj?.trackingSnippet}
      />
    </FactsetMetadataProvider>
  ) : (
    <Spinner />
  )
}

export default CustomerAccountComponent
