import {
  DATE,
  NUMBER,
  ACTIONS,
  DATETIME,
} from '@the-platform-group/component-library/DataGrid/constant'
import { Publish as PublishIcon } from '@the-platform-group/component-library/Icons'
import { Loader } from '@the-platform-group/component-library/Loader'
import { DataGridActionCell } from '@the-platform-group/component-library/DataGrid'
import { useFileDownload } from 'hooks/useFileDownload'

const ReportDownloadComponent = ({ url, title }) => {
  const { download, isLoading } = useFileDownload()

  if (isLoading) {
    return <Loader />
  } else {
    return (
      <DataGridActionCell
        icon={<PublishIcon style={{ transform: 'rotate(180deg)' }} />}
        title={title}
        onClick={() => download(url)}
      />
    )
  }
}

export const getColumnValues = () => {
  return [
    {
      align: 'left',
      headerName: 'Customer',
      headerAlign: 'left',
      field: 'customer',
    },
    { field: 'cusip', headerName: 'CUSIP' },
    {
      field: 'date',
      type: DATE,
      dateFormat: 'MMM DD, YYYY',
      headerName: 'Report Date',
    },
    { field: 'datasource' },
    { field: 'records', type: NUMBER },
    {
      field: 'importedAt',
      type: DATETIME,
      headerName: 'Imported',
    },
    {
      field: 'actions',
      type: ACTIONS,
      getActions: ({ row }) => {
        return [
          <ReportDownloadComponent url={row.links.original_file} title="Download Original File" />,
        ]
      },
    },
  ]
}

export const getRowValues = reports =>
  reports.map(report => ({
    id: report.id,
    customer: report.customer.name,
    cusip: report.cusip,
    date: new Date(report.date),
    datasource: report.datasource,
    records: report.recordsScanned,
    importedAt: new Date(report.importedAt),
    links: report.links,
  }))
