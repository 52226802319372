import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@the-platform-group/component-library/Button'
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@the-platform-group/component-library/Modal'
import { Typography } from '@the-platform-group/component-library'
import { Grid } from '@the-platform-group/component-library/Layout'
import CustomerSecurityForm from './CustomerSecurityForm'
import useCustomerSecurities from '../../hooks/useCustomerSecurities'
import Content from './Content'
import Spinner from 'components/Spinner'

const NOBOCustomerSecurityModal = ({ onClose, open, customer }) => {
  const {
    customerSecurities,
    mode,
    status,
    createSecurity,
    editSecurity,
    viewSecurities,
    registerForm,
    formErrors,
    clearErrors,
    generateFormState,
    save,
  } = useCustomerSecurities({ customer })

  function handleClose() {
    clearErrors()
    onClose()
  }

  return (
    <Modal open={open} onClose={handleClose} fullWidth maxWidth="md">
      <ModalTitle
        title={
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">
                {mode === 'view' && `View NOBO Securities for ${customer.name}`}
                {mode === 'create' && `Create NOBO Security for ${customer.name}`}
                {mode === 'edit' && `Edit ${customer.name}`}
              </Typography>
            </Grid>

            <Grid item>
              {(mode === 'create' || mode === 'edit') && (
                <Button variant="text" onClick={viewSecurities}>
                  Cancel
                </Button>
              )}
              {mode === 'view' && <Button onClick={createSecurity}>Add Security</Button>}
            </Grid>
          </Grid>
        }
      />
      <ModalContent>
        {status === 'loading' && (
          <Grid item xs>
            <Spinner size={75} />
          </Grid>
        )}
        {status !== 'loading' && mode === 'view' && (
          <Content customerSecurities={customerSecurities} editSecurity={editSecurity} />
        )}
        {status !== 'loading' && mode === 'create' && (
          <CustomerSecurityForm
            customer={customer}
            register={registerForm}
            formErrors={formErrors}
          />
        )}
        {status !== 'loading' && mode === 'edit' && (
          <CustomerSecurityForm
            customer={customer}
            formState={generateFormState()}
            register={registerForm}
            formErrors={formErrors}
          />
        )}
      </ModalContent>
      <ModalActions>
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        {(mode === 'create' || mode === 'edit') && (
          <Button isLoading={status === 'saving'} onClick={save}>
            Save
          </Button>
        )}
      </ModalActions>
    </Modal>
  )
}

NOBOCustomerSecurityModal.defaultProps = {
  customer: {},
}

NOBOCustomerSecurityModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  customer: PropTypes.object,
}

export default NOBOCustomerSecurityModal
