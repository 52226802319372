import React from 'react'
import { Grid, TextField } from '@the-platform-group/component-library'
import { Button } from '@the-platform-group/component-library/Button'

import FactsetTableTitle from './Components/FactsetTableTitle'
import FactsetUsersTable from './Components/FactsetUsersTable'
import CreateFactsetUserModal from './Components/CreateFactsetUserModal'
import UpdateFactsetUserModal from './Components/UpdateFactsetUserModal'
import LinkFactsetUserModal from './Components/LinkFactsetUserModal'

import { useModal } from 'hooks/useModal'
import { useFactsetUserUpdateModal } from './hooks/useCreateUpdateFactsetUser'
import { useUnlinkFactsetUser } from './hooks/useLinkFactsetUser'
import useLinkFactsetEntityActions from './hooks/useLinkFactsetEntityActions'

/**
 * @param {object} param0 - component props
 * @param {string} param0.customer -  available when under context of an Irwin customer (e.g. on customer page)
 * @param {string} param0.irwinUser - available when under context of an Irwin user (e.g. on user page)
 * @returns {Node} Factset user tables with title, optional search, and action buttons
 */
function FactsetUsers({ irwinUser, customer, factsetLocation }) {
  const [searchTerm, setSearchTerm] = React.useState('')
  const {
    handleOpen: openCreateFactsetUserModal,
    handleClose: closeCreateFactsetUserModal,
    open: isCreateFactsetUserModalOpen,
  } = useModal()

  const {
    selected: selectedFactsetUser,
    handleOpen: openLinkFactsetUserModal,
    handleClose: closeLinkFactsetUserModal,
    open: isLinkFactsetUserModalOpen,
    onLink,
  } = useLinkFactsetEntityActions()

  const {
    selectedFactsetUserId,
    closeUpdateFactsetUserModal,
    isUpdateFactsetUserModalOpen,
    onOpenUpdateFactsetUserModal,
  } = useFactsetUserUpdateModal()

  const { onUnlink } = useUnlinkFactsetUser()

  // component is a subsection if its under irwin user or customer context
  const isSubsection = !!irwinUser || !!customer

  return (
    <Grid container spacing={2} justifyContent="space-between" alignItems="center">
      <FactsetTableTitle title="Factset Users" isSubTitle={isSubsection} />
      {!isSubsection && (
        // show search bar if we are NOT on customer or user page
        <Grid item xs>
          <TextField
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            name="search"
            label="Search Factset Users"
            fullWidth
          />
        </Grid>
      )}
      <Grid item xs={0}>
        <Button onClick={openLinkFactsetUserModal} sx={{ mr: 1 }}>
          Link Factset / Irwin Users
        </Button>
        <Button onClick={openCreateFactsetUserModal}>Create FactSet User</Button>
        <CreateFactsetUserModal
          isModalOpen={isCreateFactsetUserModalOpen}
          closeModal={closeCreateFactsetUserModal}
          customerId={customer?.id}
          factsetLocation={factsetLocation}
          irwinUser={irwinUser}
        />
        <UpdateFactsetUserModal
          isModalOpen={isUpdateFactsetUserModalOpen}
          closeModal={closeUpdateFactsetUserModal}
          factsetUserId={selectedFactsetUserId}
          factsetLocation={factsetLocation}
          irwinUser={irwinUser}
        />
        <LinkFactsetUserModal
          isModalOpen={isLinkFactsetUserModalOpen}
          closeModal={closeLinkFactsetUserModal}
          factsetUser={selectedFactsetUser}
          irwinUser={irwinUser}
        />
      </Grid>
      <Grid item xs={12}>
        <FactsetUsersTable
          customerId={customer?.id}
          userId={irwinUser?.id}
          locationId={factsetLocation?.id}
          searchTerm={searchTerm}
          tableActions={{
            onOpenUpdateFactsetUserModal,
            onLink,
            onUnlink,
          }}
        />
      </Grid>
    </Grid>
  )
}

export default FactsetUsers
