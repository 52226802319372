import { REPORT_FIELD_PREFIXES } from './reports'
import HeaderCell from '../components/HeaderCell'
import TotalShareInputCell from '../components/TotalShareInputCell'
import FormattedViewOnlyCell from '../components/FormattedViewOnlyCell'
import HolderNameCell from '../components/HolderNameCell'
import ReportTitleCell from '../components/ReportTitleCell'
import { DATE_FORMAT } from '@the-platform-group/formatters/constants'

const SURVEILLANCE_HOLDER_NAME_COLUMN = ({ isLoading }) => ({
  field: 'name',
  headerName: 'Holder Name',
  hideable: false,
  pinnable: false,
  minWidth: 350,
  renderCell: props => <HolderNameCell {...props} isLoading={isLoading} />,
})

const SURVEILLANCE_HOLDER_COLUMNS = [
  {
    field: 'reportedDate',
    headerAlign: 'center',
    align: 'center',
    minWidth: 130,
    type: 'date',
    dateFormat: DATE_FORMAT,
  },
  {
    field: 'currentShares',
    headerAlign: 'center',
    align: 'center',
    minWidth: 160,
    type: 'number',
  },
  {
    field: 'currentValue',
    headerName: 'Current Value (USD MM)',
    headerAlign: 'center',
    align: 'center',
    minWidth: 130,
    type: 'currency',
    format: 'MM',
    precision: 1,
  },
  {
    field: 'previousShares',
    headerAlign: 'center',
    align: 'center',
    minWidth: 160,
    type: 'number',
  },
  {
    field: 'previousValue',
    headerName: 'Previous Value (USD MM)',
    headerAlign: 'center',
    align: 'center',
    minWidth: 130,
    type: 'currency',
    format: 'MM',
    precision: 1,
  },
  {
    field: 'source',
    headerAlign: 'center',
    align: 'center',
  },
]

const SURVEILLANCE_REPORT_COLUMNS = ({ id, title }) => [
  {
    field: REPORT_FIELD_PREFIXES.shares + id,
    headerName: `Shares (${title})`,
    headerAlign: 'center',
    align: 'center',
    minWidth: 150,
    editable: true,
    type: 'number',
    cellClassName: 'editable-cell', // making padding 0 for cells, since we replace cell view with FormattedViewOnlyCell component we need this
    renderHeader: () => <HeaderCell>Shares</HeaderCell>,
    renderEditCell: TotalShareInputCell,
    renderCell: FormattedViewOnlyCell,
  },
  {
    field: REPORT_FIELD_PREFIXES.value + id,
    headerName: `Value (${title})`,
    headerAlign: 'center',
    align: 'center',
    minWidth: 150,
    type: 'currency',
    format: 'MM',
    precision: 1,
    renderHeader: () => (
      <HeaderCell>
        Value <br />
        (USD MM)
      </HeaderCell>
    ),
  },
  {
    field: REPORT_FIELD_PREFIXES.change + id,
    headerName: `Change (${title})`,
    headerAlign: 'center',
    align: 'center',
    minWidth: 150,
    renderHeader: () => <HeaderCell>Change</HeaderCell>,
  },
  {
    field: REPORT_FIELD_PREFIXES.notes + id,
    headerName: `Notes (${title})`,
    headerAlign: 'center',
    align: 'center',
    minWidth: 150,
    editable: true,
    cellClassName: 'editable-cell', // making padding 0 for cells, since we replace cell view with FormattedViewOnlyCell component we need this
    renderHeader: () => <HeaderCell>Notes</HeaderCell>,
    renderCell: FormattedViewOnlyCell,
  },
]

const SURVEILLANCE_COLUMN_GROUPING_CHILDREN = {
  holders: SURVEILLANCE_HOLDER_COLUMNS.map(({ field }) => ({ field })),
  reports: reportId => [
    {
      field: REPORT_FIELD_PREFIXES.shares + reportId,
    },
    {
      field: REPORT_FIELD_PREFIXES.value + reportId,
    },
    {
      field: REPORT_FIELD_PREFIXES.change + reportId,
    },
    {
      field: REPORT_FIELD_PREFIXES.notes + reportId,
    },
  ],
}

const SURVEILLANCE_COLUMN_HOLDERS_GROUPING = [
  {
    groupId: 'holders',
    headerName: 'Reported Shares',
    headerAlign: 'center',
    children: SURVEILLANCE_COLUMN_GROUPING_CHILDREN.holders,
  },
]

const SURVEILLANCE_COLUMN_REPORT_GROUPING = ({ id, title }) => ({
  groupId: id,
  headerName: title,
  headerAlign: 'center',
  renderHeaderGroup: () => <ReportTitleCell title={title} id={id} />,
  children: SURVEILLANCE_COLUMN_GROUPING_CHILDREN.reports(id),
})

export {
  REPORT_FIELD_PREFIXES,
  SURVEILLANCE_HOLDER_NAME_COLUMN,
  SURVEILLANCE_HOLDER_COLUMNS,
  SURVEILLANCE_REPORT_COLUMNS,
  SURVEILLANCE_COLUMN_HOLDERS_GROUPING,
  SURVEILLANCE_COLUMN_REPORT_GROUPING,
}
