import { useState, useEffect } from 'react'
import { getReviews } from 'services/nobo'

const initialSortState = {
  field: 'created_at',
  direction: 'asc',
}

const initialPaginationState = {
  rowsPerPage: 25,
  page: 0,
}

function useReviews() {
  const [processing, setProcessing] = useState(false)
  const [filterState, setFilterState] = useState({ customerId: '' })
  const [sortState, setSortState] = useState(initialSortState)
  const [paginationState, setPaginationState] = useState(initialPaginationState)
  const [reviews, setReviews] = useState([])

  function toggleSort(field) {
    let newDirection = 'desc'

    if (field === sortState.field) {
      newDirection = sortState.direction === 'asc' ? 'desc' : 'asc'
    }

    setSortState({ field: field, direction: newDirection })
  }

  function setCustomerFilter(option) {
    const value = option ? option.value : null
    setFilterState({ ...filterState, customerId: value })
  }

  function setPage(number) {
    setPaginationState({ ...paginationState, page: number })
  }

  function setRowsPerPage(number) {
    setPaginationState({ ...paginationState, rowsPerPage: number })
  }

  useEffect(() => {
    async function fetchData() {
      try {
        setProcessing(true)

        const reviews = await getReviews({ sort: `${sortState.field}_${sortState.direction}` })

        setProcessing(false)

        setReviews(reviews)
      } catch (error) {
        setReviews(() => {
          throw error
        })
      }
    }

    fetchData()
  }, [sortState])

  const customerOptions = []
  reviews.forEach(review => {
    const option = { label: review.customer.name, value: review.customer.id }
    const exists = customerOptions.find(o => o.label === option.label && o.value === option.value)

    if (!exists) {
      customerOptions.push(option)
    }
  })

  const filteredReviews = reviews.filter(review => {
    return !filterState.customerId || filterState.customerId === review.customer.id
  })

  const { page, rowsPerPage } = paginationState

  return {
    filterState,
    reviews: filteredReviews,
    sortState,
    toggleSort,
    customerOptions,
    setCustomerFilter,
    processing,
    totalItems: filteredReviews.length,
    page: page,
    rowsPerPage: rowsPerPage,
    setPage,
    setRowsPerPage,
  }
}

export default useReviews
