import React, { Fragment, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Snackbar, Alert } from '@the-platform-group/component-library'

const initialState = {
  open: false,
  message: '',
  variant: 'success',
}
const NotificationContext = React.createContext({
  createNotification() {},
})

function NotificationProvider({ children }) {
  const [state, setState] = useState(initialState)
  const createNotification = ({ message, variant = state.variant }) => {
    setState({ open: true, message, variant })
  }
  const handleClose = () => setState(s => ({ ...s, ...initialState }))

  return (
    <NotificationContext.Provider value={{ createNotification }}>
      <NotificationComponent {...state} onClose={handleClose} />
      {children}
    </NotificationContext.Provider>
  )
}

const useNotification = () => {
  const { createNotification } = useContext(NotificationContext)
  return {
    createNotification,
  }
}

const NotificationComponent = ({ open, message, variant, onClose }) => {
  return (
    <Fragment>
      {variant && message && (
        <Snackbar
          autoHideDuration={4000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={open}
          onClose={onClose}
        >
          <Alert
            severity={variant}
            sx={{
              boxShadow: 10,
            }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
    </Fragment>
  )
}

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

NotificationComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string,
  variant: PropTypes.oneOf(['success', 'error', 'info', 'warning']),
  onClose: PropTypes.func.isRequired,
}
NotificationComponent.defaultProps = {
  message: null,
  variant: 'success',
}

export { NotificationProvider, useNotification }
