import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox as RawCheckbox, FormControlLabel } from '@the-platform-group/component-library'

const Checkbox = ({ name, label, value, ...rest }) => (
  <FormControlLabel control={<RawCheckbox color="primary" {...rest} />} label={label} name={name} />
)

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
}

export default Checkbox
