import Jsona, { SwitchCaseJsonMapper } from 'jsona'
import { irwin } from 'config'
import { httpClient } from './httpClient'
import { snakeKeys } from 'change-object-case'
import Axios from 'axios'

const deserializerSnake = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper(),
})

const deserializerCamel = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper({ switchChar: '_' }),
})

function client() {
  return httpClient(irwin.baseUrl)
}

async function getReports(params) {
  const response = await client().get(`v2/holdings/reports`, {
    params: snakeKeys(params, { recursive: true, arrayRecursive: true }),
  })

  return { data: deserializerSnake.deserialize(response) }
}

// This function will replace the getReports function above once Surveillance Portal DataGrid is active
async function getAllReports(reportsIds, maxReports = 6) {
  const holderReports = await Promise.all(reportsIds.slice(0, maxReports).map(getReport))

  return {
    data: holderReports.map(report => report.data),
  }
}

async function getReport(id) {
  const response = await client().get(`v2/holdings/reports/${id}`)

  return {
    data: deserializerSnake.deserialize(response),
  }
}

async function reportGrant(params) {
  const response = await client().post('v2/reporting_grants', {
    ...snakeKeys(params, { recursive: true, arrayRecursive: true }),
  })

  return {
    data: deserializerCamel.deserialize(response),
    meta: response.meta,
  }
}

async function deleteReportGrant(id) {
  const response = await client().delete(`v2/reporting_grants/${id}`)

  return response
}

async function getReportingGrants(params) {
  const response = await client().get('v2/reporting_grants', {
    ...snakeKeys(params, { recursive: true, arrayRecursive: true }),
  })

  return {
    data: deserializerCamel.deserialize(response),
    meta: response.meta,
  }
}

async function createReport(params) {
  const response = await Axios.all(
    params.map(paramValues => client().post('v2/holdings/reports', paramValues)),
  )

  return response.map(response => ({
    data: deserializerSnake.deserialize(response),
    meta: response.meta,
  }))
}

async function saveReport(params) {
  const responses = await Axios.all(
    params.map(({ id, upsert }) => client().put(`v2/holdings/reports/${id}`, { upsert })),
  )

  return responses.map(response => ({
    data: deserializerSnake.deserialize(response),
    meta: response.meta,
  }))
}

async function deleteHolderFromReport(params) {
  const response = await Axios.all(
    params.map(({ id, deleteIds }) =>
      client().put(`v2/holdings/reports/${id}`, { delete: deleteIds }),
    ),
  )

  return {
    data: deserializerSnake.deserialize(response),
    meta: response.meta,
  }
}

async function deleteReport(id) {
  const response = await client().delete(`v2/holdings/reports/${id}`)

  return response
}

export {
  getReports,
  getAllReports,
  reportGrant,
  createReport,
  getReport,
  saveReport,
  deleteHolderFromReport,
  getReportingGrants,
  deleteReportGrant,
  deleteReport,
}
