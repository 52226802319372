import { useMutation, useQueryClient } from 'react-query'
import { Switch } from '@the-platform-group/component-library'
import { useNotification } from 'components/Notification'

import { updateNotificationSubscription } from 'services/notificationSubscriptions'

const ToggleSubscriptionSwitch = subscription => {
  const queryClient = useQueryClient()
  const { createNotification } = useNotification()

  const updateSubscriptionMutation = useMutation(
    async subscription => {
      const params = {
        notificationId: subscription.notificationId,
        userId: subscription.userId,
        customerId: subscription.customerId,
        enabled: !subscription.enabled,
        configuration: subscription.configuration,
      }

      await updateNotificationSubscription(subscription.id, params)
    },
    {
      onSuccess: () => {
        createNotification({
          message: 'Successfully updated notification.',
          variant: 'success',
        })
        queryClient.invalidateQueries({
          queryKey: ['getNotificationSubscriptions', subscription.userId],
        })
      },
      onError: () => {
        createNotification({
          message: 'There was an error updating the notification.',
          variant: 'error',
        })
      },
    },
  )

  return (
    <Switch
      edge="end"
      color="primary"
      checked={subscription?.enabled}
      onChange={() => updateSubscriptionMutation.mutate(subscription)}
      inputProps={{ 'aria-labelledby': `${subscription?.id}-switch` }}
    />
  )
}

export default ToggleSubscriptionSwitch
