import { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import Rollbar from 'utils/rollbar'
import { isAuth0Timeout, isRoleAllowed, saveCustomerIdFromCustomerAuth } from 'utils/auth'
import { fetchCurrentUser, saveAuthToken, terminateSession } from 'services/auth'

import { ForbiddenError } from 'constants/errors'
import { CUSTOMER_ID } from 'constants/localStorage'

const TOKEN_ERRORS = ['login_required', 'consent_required']

// This token is only set up to minimize the impact of having an account
// that has not been properly configured and has a valid authenticated_id present,
// in this case the client cannot determine that the user is invalid (since it receives a 401)
// and will attempt to reauthenticate against the tenant. Since the session is valid
// on auth0 the flow will continue to the API, where the invalid user will once again
// fail to be authenticated. To avoid this infinite redirect loop, we set a control flag and report
// that a problem was encountered.
const AUTH0_AUTH_ATTEMPT_TOKEN = 'auth0-authentication-attempted'
export const auth0AuthAttempted = () => !!localStorage.getItem(AUTH0_AUTH_ATTEMPT_TOKEN)
export const registerAuth0AuthAttempt = () => localStorage.setItem(AUTH0_AUTH_ATTEMPT_TOKEN, true)

function useAuth() {
  const [currentUser, setCurrentUser] = useState(null)

  const { isLoading, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0()

  useEffect(() => {
    async function fetchAuth0User() {
      try {
        if (auth0AuthAttempted()) {
          return
        }

        const token = await getAccessTokenSilently()
        saveAuthToken(token)
        await fetchUser()
      } catch (error) {
        if (TOKEN_ERRORS.includes(error?.error)) {
          loginWithRedirect({
            appState: {
              returnTo: window.location.pathname,
            },
            redirectUri: window.location.origin,
          })
          return
        }

        // ignore timeout errors to avoid excesive noise if the refresh fails,
        // in most cases this is a false positive due to the auth token being valid
        // for a day
        if (isAuth0Timeout(error)) {
          return
        }

        setCurrentUser(() => {
          throw error
        })
      }
    }

    async function fetchUser() {
      try {
        // we want to clear any customer id previously saved in localStorage before fetching current user again
        // this is to ensure we are always fetching the user with default customer within Admin app
        localStorage.removeItem(CUSTOMER_ID)
        const user = await fetchCurrentUser()

        if (!isRoleAllowed(user)) {
          throw new ForbiddenError({ user })
        }

        saveCustomerIdFromCustomerAuth(user.customerAuthorizations)
        Rollbar.configure({ payload: { person: { id: user.id, email: user.email } } })

        setCurrentUser(user)
      } catch (error) {
        if (error?.response?.status === 401) {
          registerAuth0AuthAttempt()
        }

        throw error
      }
    }

    fetchAuth0User()
  }, [getAccessTokenSilently, loginWithRedirect])

  function endSession() {
    terminateSession()

    logout({
      returnTo: window.location.origin,
    })
    return
  }

  async function refetchCurrentUser() {
    const user = await fetchCurrentUser()
    setCurrentUser(user)
  }

  return {
    state: { isLoading, currentUser },
    handlers: { endSession, refetchCurrentUser },
  }
}

export default useAuth
