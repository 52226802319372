import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { H3 } from 'components/Typography'
import { Select } from '@the-platform-group/component-library/Select'
import useFocuses from 'hooks/useFocuses'

const Focus = ({ onFocusChange, focuses, categories }) => {
  const [category, setCategory] = useState()
  const { focuses: focusDefinitions } = useFocuses()

  const categoryOptions = category && focusDefinitions.CATEGORY_VALUES[category]
  const categoryValues =
    categoryOptions &&
    focuses
      .filter(f => f.category === category)
      .map(f => f.categoryValue)
      .filter(f => Object.keys(categoryOptions).includes(f))

  return (
    <Fragment>
      <H3>Focuses</H3>
      <Select
        data-testid="category-select"
        fullWidth
        name="category"
        label="Category"
        options={Object.keys(categories).map(category => ({
          value: category,
          label: categories[category],
        }))}
        value={category}
        onChange={({ target: { value } }) => setCategory(value)}
      />
      {category && (
        <Select
          fullWidth
          data-testid="category-value-select"
          label="Category Value"
          name="categoryValue"
          options={[
            ...(category !== 'geo_focus' ? [{ value: 'all', label: 'Select All' }] : []),
            ...Object.keys(categoryOptions).map(categoryOption => ({
              value: categoryOption,
              label: categoryOptions[categoryOption],
            })),
          ]}
          value={categoryValues || []}
          onChange={e => onFocusChange({ category, categoryValues: e.target.value })}
          isMulti
        />
      )}
    </Fragment>
  )
}

Focus.propTypes = {
  categories: PropTypes.object.isRequired,
  focuses: PropTypes.array.isRequired,
  onFocusChange: PropTypes.func.isRequired,
}

export default Focus
