const formatReportingGrant = (
  { id, customerId, userId, security, datasource },
  customersMap,
  usersMap,
) => {
  const customer = customersMap.get(customerId)

  if (!customer) {
    return null
  }

  const user = usersMap.get(userId)

  if (!user) {
    return null
  }

  return {
    id,
    customer,
    user,
    security,
    datasource,
  }
}

export const filterReportingGrants = (reportingGrants = [], customersList = [], usersList = []) => {
  const customersMap = new Map(customersList.map(customer => [customer.id, customer]))
  const usersMap = new Map(usersList.map(user => [user.id, user]))

  return reportingGrants.reduce((acc, reporting) => {
    const formattedReporting = formatReportingGrant(reporting, customersMap, usersMap)

    if (!formattedReporting) {
      return acc
    }

    acc.push(formattedReporting)

    return acc
  }, [])
}
