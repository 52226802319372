import { useMutation, useQuery } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import { getCustomers } from 'services/customers'
import { deleteReportGrant, getReportingGrants, reportGrant } from 'services/reports'
import { getUsers } from 'services/users'
import { ADMIN_ROLE, ANALYST_ROLE, ANALYST_SURVEILLANCE_ROLE } from 'utils/auth'
import { filterReportingGrants } from './utils'
import { useNotification } from 'components/Notification'
import { useMemo } from 'react'
import { useSessionHandlers } from 'contexts/Session'

const MAX_CUSTOMER_SIZE = 1000

/**
 * Custom Hook for Reporting Grants Lists
 * fething customers, users and reporting grants and mutate add and remove reporting grants
 * @param {Object} props
 * @param {Object} props.selectedCustomer - selected customer
 * @param {Object} props.selectedSecurity - selected security
 * @param {Object} props.selectedUser - selected user
 * @param {Object} props.selectedDatasource - selected datasource
 * @param {Function} props.setOpenRemoveDialog - set open remove dialog
 * @returns {Object} - reporting grants list, loading, add and remove reporting grants and users options(used in select)
 */
const useReportingList = ({
  selectedCustomer,
  selectedSecurity,
  selectedUser,
  selectedDatasource,
  setOpenRemoveDialog,
}) => {
  const { refetchCurrentUser } = useSessionHandlers()
  const { createNotification } = useNotification()

  // Customers Query
  const {
    data: customersList,
    isLoading: isCustomersListLoading,
    refetch: refetchCustomersList,
  } = useQuery(
    ['customers'],
    () => {
      const params = {
        state: 'active',
        type: 'customer',
        rawData: true,
        pageSize: MAX_CUSTOMER_SIZE,
      }
      return getCustomers(params)
    },
    {
      select: ({ data }) => data,
      placeholderData: [],
      refetchOnWindowFocus: false,
    },
  )

  // Users Query used by usersOptions and reportingGrants Query
  const { data: usersList, isLoading: isUsersListLoading, refetch: refetchUsersList } = useQuery(
    ['users'],
    () =>
      getUsers({
        role: [ADMIN_ROLE, ANALYST_ROLE, ANALYST_SURVEILLANCE_ROLE],
      }),
    {
      select: ({ data }) => data,
      initialData: {
        data: [],
      },
      refetchOnWindowFocus: false,
    },
  )

  // Reporting Grants Query
  const {
    data: reportingGrants,
    isLoading: isReportingGrantsLoading,
    isFetching: isReportingGrantsFetching,
    refetch: refetchReportingGrants,
  } = useQuery(['reportingGrants'], () => getReportingGrants(), {
    placeholderData: {
      data: [],
    },
    select: ({ data }) => filterReportingGrants(data, customersList, usersList),
    enabled: !isCustomersListLoading && !isUsersListLoading,
    refetchOnWindowFocus: false,
  })

  // Add New Reporting Grant Mutation
  const { mutateAsync: addReportingGrant, isLoading: isAddReportingGrantLoading } = useMutation(
    () => {
      const params = {
        id: uuidv4(),
        customerId: selectedCustomer.id,
        securityId: selectedSecurity.id,
        userId: selectedUser.id,
        datasource: selectedDatasource.value,
      }
      return reportGrant(params)
    },
    {
      onSuccess: async () => {
        await refetchCurrentUser()
        await refetchUsersList()
        await refetchCustomersList()
        await refetchReportingGrants()
      },
      onError: () => {
        createNotification({
          message: 'Error adding reporting grant',
          variant: 'error',
        })
      },
    },
  )

  // Remove Reporting Grant Mutation Error Notification
  const removeReportingGrantErrorNotification = () => {
    createNotification({
      message: 'Error removing reporting grant',
      variant: 'error',
    })
  }

  // Remove Reporting Grant Mutation
  const {
    mutateAsync: removeReportingGrant,
    isLoading: isRemoveReportingGrantLoading,
  } = useMutation(
    id => {
      return deleteReportGrant(id)
    },
    {
      onSuccess: async data => {
        if (data.success) {
          await refetchCurrentUser()
          await refetchReportingGrants()
        } else {
          removeReportingGrantErrorNotification()
        }
      },
      onError: removeReportingGrantErrorNotification,
      onSettled: () => {
        setOpenRemoveDialog(false)
      },
    },
  )

  // Users Options used in select
  const usersOptions = useMemo(() => {
    if (!usersList) {
      return []
    }
    return usersList.map(({ id, firstName, lastName, email }) => ({
      id,
      label: `${firstName} ${lastName} - ${email}`,
      value: id,
    }))
  }, [usersList])

  const isLoading =
    isReportingGrantsFetching ||
    isReportingGrantsLoading ||
    isUsersListLoading ||
    isCustomersListLoading ||
    isAddReportingGrantLoading ||
    isRemoveReportingGrantLoading

  return {
    reportingGrants,
    isLoading,
    addReportingGrant,
    removeReportingGrant,
    usersOptions,
  }
}

export default useReportingList
