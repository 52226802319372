import React, { useEffect } from 'react'
import { Controller, useWatch } from 'react-hook-form'

import { Grid, TextField } from '@the-platform-group/component-library'

import FactsetLocationSelector from './FactsetLocationSelector'
import UserSelector from './UserSelector'

import { TextFieldWithHelpIcon, SelectWithHelpIcon } from './Common'

function CommonFactsetUserForm({
  control,
  setValue,
  type,
  customerId,
  factsetLocation,
  irwinUser,
}) {
  const rules = { required: type === 'managed' }
  const user = useWatch({ control, name: 'user' })

  useEffect(() => {
    // auto fill inputs of name/email when user selects an Irwin User from <UserSelector>
    if (user?.firstName) {
      setValue('givenName', user.firstName, { shouldValidate: true })
    }
    if (user?.lastName) {
      setValue('familyName', user.lastName, { shouldValidate: true })
    }
    if (user?.email) {
      setValue('email', user.email, { shouldValidate: true })
    }
  }, [setValue, user])

  return (
    <>
      <Grid item xs={12}>
        <FactsetLocationSelector
          control={control}
          customerId={customerId}
          required
          disabled={Boolean(factsetLocation)}
        />
      </Grid>
      <Grid item xs={12}>
        <UserSelector control={control} disabled={Boolean(irwinUser)} />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="givenName"
          rules={rules}
          render={(
            { field, formState }, // field, formState
          ) => (
            <TextField
              error={formState.errors.givenName}
              fullWidth
              label="Given Name"
              required={rules.required}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="familyName"
          rules={rules}
          render={({ field, formState }) => (
            <TextField
              error={formState.errors.familyName}
              fullWidth
              label="Family Name"
              required={rules.required}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="email"
          rules={rules}
          render={({ field, formState }) => (
            <TextField
              error={formState.errors.email}
              fullWidth
              label="Email"
              required={rules.required}
              {...field}
            />
          )}
        />
      </Grid>
    </>
  )
}

function UnmanagedFactsetUserForm(props) {
  const rules = { required: true }

  return (
    <Grid item xs={12}>
      <Controller
        control={props.control}
        name="serialNumber"
        rules={rules}
        render={({ field, formState }) => (
          <TextField
            error={formState.errors.serialNumber}
            label="Serial Number"
            fullWidth
            required={rules.required}
            {...field}
          />
        )}
      />
    </Grid>
  )
}

function ManagedFactsetUserForm(props) {
  const rules = { required: true }
  const { control, setValue, watch, factsetMetadata, isEdit } = props
  const location = watch('location')
  const username = watch('username')

  useEffect(() => {
    if (!username && location?.usernames) {
      // auto fill username when user selects a factset location from <FactsetLocationSelector>
      // and validate the value
      setValue('username', location.usernames[0], { shouldValidate: true })
    }
  }, [setValue, location, username])

  return (
    <>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="username"
          rules={rules}
          render={({ field, formState }) => (
            <SelectWithHelpIcon
              disabled={isEdit && !!username}
              fullWidth
              hasError={formState.errors.username}
              options={location?.usernames || []}
              label="Username"
              margin="none"
              required={rules.required}
              tooltipText="The username for the FactSet system. This should always be derived from Factset location if it has username values. This value can not be changed here once set."
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="assertionValue"
          render={({ field }) => (
            <TextFieldWithHelpIcon
              label="Assertion Value"
              fullWidth
              tooltipText="Optional, used for SSO purposes, leave this blank if you are unsure what this is."
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          control={control}
          name="roleName"
          rules={rules}
          render={({ field, formState }) => (
            <TextFieldWithHelpIcon
              fullWidth
              hasError={formState.errors.roleName}
              tooltipText="The name of the FactSet role to be assigned to this user. Leave this as 'Corporate' unless you know what to fill."
              label="Role Name"
              required={rules.required}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="royaltyCode"
          rules={rules}
          render={({ field, formState }) => (
            <SelectWithHelpIcon
              fullWidth
              hasError={formState.errors.royaltyCode}
              tooltipText="The FactSet royalty code to assign to this user."
              label="Royalty Code"
              margin="none"
              required={rules.required}
              options={factsetMetadata.royaltyCodes}
              variant="outlined"
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="productIds"
          render={({ field }) => {
            // 🔴 NOTE (business logic): 🔴
            // 1. product selector options are populated asynchronously from v2/ds1/metadata API
            return (
              <SelectWithHelpIcon
                disableCloseOnSelect
                fullWidth
                isMulti
                tooltipText="A list of FactSet products/features to associate with this user account. Some products may be inherited with the user and can not be changed, those products will not be listed here."
                label="Product IDs"
                margin="none"
                options={factsetMetadata.products}
                variant="outlined"
                {...field}
              />
            )
          }}
        />
      </Grid>
    </>
  )
}

export { CommonFactsetUserForm, ManagedFactsetUserForm, UnmanagedFactsetUserForm }
