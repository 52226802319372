import React from 'react'
import ProfilePicture from '.'
import { Grid } from '@the-platform-group/component-library/Layout'

const TableProfilePicture = ({ row = {} }) => {
  const { personProfileImage, firstName, lastName } = row
  return (
    <Grid container direction="row" alignItems="center" justifyContent="center">
      <ProfilePicture
        name={{ firstName, lastName }}
        profilePictureSrc={personProfileImage?.url}
        size="small"
      />
    </Grid>
  )
}

export default TableProfilePicture
