import { styled } from '@the-platform-group/component-library'
import { Paper } from '@the-platform-group/component-library/Layout'

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  marginTop: theme.spacing(2),
}))

const FormPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  marginBottom: theme.spacing(2),
}))

const ListPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
}))

export { StyledPaper, FormPaper, ListPaper }
