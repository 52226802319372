import { Grid } from '@the-platform-group/component-library/Layout'
import { TextField, Typography } from '@the-platform-group/component-library'
import { H2 } from 'components/Typography'

const InternalNoteTextField = ({ content = null, handleChange }) => {
  const helperText = 'Saved notes will appear at the bottom of the page in a table.'

  return (
    <Grid item container>
      <H2>Internal Notes</H2>

      <TextField
        id="outlined-multiline-static"
        label="Internal notes and comments"
        value={content}
        multiline
        rows={4}
        onChange={e => handleChange(e.target.value)}
        fullWidth
      />
      <Typography variant="caption" display="block" gutterBottom>
        {helperText}
      </Typography>
    </Grid>
  )
}

export default InternalNoteTextField
