import { useState } from 'react'
import { Modal, ModalContent, ModalActions } from '@the-platform-group/component-library/Modal'
import {
  Table,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableBody,
  TableCell,
  TableActionList,
  TableAction,
} from '@the-platform-group/component-library/Table'
import { H2 } from 'components/Typography'
import { Edit as EditIcon } from '@the-platform-group/component-library/Icons'
import { Button } from '@the-platform-group/component-library/Button'
import InternalNoteTextField from './InternalNoteTextField'
import { formatDate } from '@the-platform-group/formatters/dateFormat'
import { useSessionState } from '../../contexts/Session'
import { useInternalNoteList } from '../../hooks/useInternalNoteUtils'

const InternalNoteList = ({ internalNotableId, internalNotableType }) => {
  const [editingNoteIdState, setEditingNoteIdState] = useState(null)
  const [editingNoteContentState, setEditingNoteContentState] = useState(null)
  const { internalNoteList, updateInternalNoteTableItem } = useInternalNoteList(
    internalNotableId,
    internalNotableType,
  )
  const { currentUser } = useSessionState()

  const NoteRow = ({ note }) => {
    const creatorFullName = `${note.creator.firstName} ${note.creator.lastName}`
    const canNotEdit = currentUser.id !== note.creator.id

    return (
      <TableRow>
        <TableCell width="400">{note.content}</TableCell>
        <TableCell>{formatDate(note.createdAt)}</TableCell>
        <TableCell>{creatorFullName}</TableCell>
        <TableCell>
          {/* disable if user is not the one who created the note  */}
          <TableActionList>
            <TableAction
              title={canNotEdit ? 'Only Note Creator Can Edit' : 'Edit Note'}
              disabled={canNotEdit}
              onClick={() => {
                setEditingNoteIdState(note.id)
                setEditingNoteContentState(note.content)
              }}
            >
              <EditIcon fontSize="small" />
            </TableAction>
          </TableActionList>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      <H2>Internal Notes History</H2>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Note</TableHeaderCell>
            <TableHeaderCell>Created At</TableHeaderCell>
            <TableHeaderCell>Created By</TableHeaderCell>
            <TableHeaderCell />
          </TableRow>
        </TableHead>
        {internalNoteList && internalNoteList.length ? (
          <TableBody>
            {internalNoteList.map(note => (
              <NoteRow key={note.id} note={note} />
            ))}
          </TableBody>
        ) : null}
      </Table>
      <Modal open={!!editingNoteIdState} onClose={() => setEditingNoteIdState(null)}>
        <ModalContent>
          <InternalNoteTextField
            content={editingNoteContentState}
            handleChange={setEditingNoteContentState}
          />
          <ModalActions>
            <Button variant="text" onClick={() => setEditingNoteIdState(null)}>
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={async () => {
                await updateInternalNoteTableItem(editingNoteIdState, editingNoteContentState)
                setEditingNoteIdState(null)
              }}
            >
              Save
            </Button>
          </ModalActions>
        </ModalContent>
      </Modal>
    </>
  )
}

export default InternalNoteList
