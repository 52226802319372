export const env = import.meta.env.MODE

export const irwin = {
  baseUrl: import.meta.env.VITE_IRWIN_BASE_URL,
  appUrl: import.meta.env.VITE_IRWIN_APP_URL,
}

export const factset = { baseUrl: import.meta.env.VITE_FACTSET_BASE_URL }

export const featureFlags = { baseUrl: import.meta.env.VITE_FEATURE_FLAGS_BASE_URL }
