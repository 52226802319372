import { Box } from '@the-platform-group/component-library'
import { format } from '@the-platform-group/formatters/number'
import { REPORT_FIELD_PREFIXES } from '../utils/reports'

const FormattedViewOnlyCell = ({ value, row, field }) => {
  return (
    <Box
      bgcolor={row?.updatedCells?.includes(field) ? 'success.background' : 'grey.200'}
      border="1px solid"
      borderColor="grey.300"
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      cursor="cell"
    >
      {field.includes(REPORT_FIELD_PREFIXES.shares) ? format(value, 0) : value}
    </Box>
  )
}

export default FormattedViewOnlyCell
