import Jsona, { SwitchCaseJsonMapper } from 'jsona'
import { irwin } from 'config'
import { httpClient } from 'services/httpClient'

const deserializer = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper({
    switchChar: '_',
  }),
})

export default function client() {
  return httpClient(irwin.baseUrl)
}

export { deserializer, client }
