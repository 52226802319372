import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from '@the-platform-group/formatters/checks'

import { getReview, lockReview, unlockReview, updateReview } from 'services/nobo'

import { useNotification } from 'components/Notification'

import { NoboRoutes } from 'pages/Nobo/NoboRouter'

const initialReview = {
  originalAddress: '',
}

function useReview({ formState, reviewId, startProcessing, stopProcessing }) {
  const history = useHistory()

  const [review, setReview] = useState(initialReview)
  const [transition, setTransition] = useState({})
  const { createNotification } = useNotification()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const review = await getReview(reviewId)

        if (!review) {
          setTransition({
            message: 'Something went wrong, refresh your page and try again.',
            action: 'back',
            error: true,
          })
        }

        setReview(review)
      } catch (error) {
        setReview(() => {
          throw error
        })
      }
    }

    fetchData()
  }, [reviewId])

  useEffect(() => {
    const lock = async () => {
      if (!review.id) {
        return
      }

      try {
        const locked = await lockReview(review)

        if (!locked) {
          setTransition({
            message: 'This record is already being reviewed, try another.',
            action: 'back',
            error: true,
          })
        }
      } catch (error) {
        setReview(() => {
          throw error
        })
      }
    }

    lock()
  }, [review])

  useEffect(() => {
    if (isEmpty(transition)) {
      return
    }

    if (transition.message) {
      createNotification({
        message: transition.message,
        variant: transition.error ? 'error' : 'success',
      })
    }

    if (transition.action === 'back') {
      history.replace(NoboRoutes.listReviews)
    }

    // we've serviced the transition, now clear the transition
    setTransition({})
  }, [transition, setTransition, history, createNotification])

  const onRelease = async () => {
    if (!startProcessing()) {
      return
    }

    try {
      const unlocked = await unlockReview(review)

      stopProcessing()

      if (unlocked) {
        setTransition({ message: 'Lock released.', action: 'back' })
      } else {
        setTransition({
          message: "Couldn't release lock, try refreshing.",
          action: 'back',
          error: true,
        })
      }
    } catch (error) {
      setReview(() => {
        throw error
      })
    }
  }

  const onSubmit = async () => {
    if (!startProcessing()) {
      return
    }

    try {
      const success = await updateReview(review, formState)

      stopProcessing()

      if (success) {
        setTransition({
          message: 'Review submitted successfully!',
          action: 'back',
        })
      } else {
        setTransition({
          message: 'Something went wrong, verify the correctness of your submission and try again.',
          error: true,
        })
      }
    } catch (error) {
      setReview(() => {
        throw error
      })
    }
  }

  return { review, onRelease, onSubmit }
}

export default useReview
