import { useState } from 'react'
import { Tooltip } from '@the-platform-group/component-library'
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@the-platform-group/component-library/Modal'
import { Button } from '@the-platform-group/component-library/Button'

const copyToClipboard = value => {
  navigator.clipboard.writeText(value)
}

const SiteModal = ({ snippet, onClose, open }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleCopyToClipboard = text => {
    copyToClipboard(text)
    setShowTooltip(true)
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle title="Snippet for Embedding" />
      <ModalContent>
        <code style={{ wordWrap: 'break-word' }}>{snippet}</code>
      </ModalContent>
      <ModalActions>
        <Tooltip
          open={showTooltip}
          title={'Copied to Clipboard'}
          leaveDelay={1000}
          placement="top"
          onClose={() => setShowTooltip(false)}
        >
          <Button onClick={() => handleCopyToClipboard(snippet)}>Copy</Button>
        </Tooltip>
        <Button
          onClick={() => {
            setShowTooltip(false)
            onClose()
          }}
        >
          Close
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default SiteModal
