import React from 'react'
import { useQuery } from 'react-query'

import { Tooltip } from '@the-platform-group/component-library'
import { DataGrid, DataGridActionCell } from '@the-platform-group/component-library/DataGrid'
import {
  Delete as DeleteIcon,
  AddLink as LinkIcon,
  LinkOff as UnlinkIcon,
} from '@the-platform-group/component-library/Icons'

import FactsetStatusText from './FactsetStatusText'

import { getFactsetLocations } from 'services/factsetProvisioning'

import usePagination from 'hooks/usePagination'
import debounce from 'utils/debounce'

const debouncedGetLocations = debounce(getFactsetLocations, 250)

const transformFactsetLocationsData = data =>
  data.map(location => ({
    id: location.id,
    name: location.name,
    ds1Id: location.ds1Id,
    status: location.status,
    createdAt: location.createdAt,
    updatedAt: location.updatedAt,
    customer: location.customer,
  }))

const deletableLocationStatus = ['unmanaged', 'creating.failed']

const FactsetLocationsTable = ({ customerId, searchTerm, tableActions = {} }) => {
  const { pagination, handlePageChange, handleRowsChange, pageSizeOptions } = usePagination()
  const { page, pageSize, pageOffset } = pagination
  const params = { customerId, search: searchTerm, pageSize, pageOffset }
  const { data: locations, isFetching } = useQuery(
    ['factsetLocations', params],
    () => debouncedGetLocations(params),
    {
      select: response => {
        return {
          ...response,
          data: transformFactsetLocationsData(response.data),
        }
      },
      placeholderData: () => ({ data: [], meta: {} }),
    },
  )

  return (
    <DataGrid
      name="factsetLocations"
      loading={isFetching}
      columns={[
        {
          field: 'name',
          valueGetter: ({ row: { id }, value }) => ({
            to: '/factset/locations/' + id,
            text: value || 'N/A',
          }),
          type: 'link',
          minWidth: 280,
        },
        {
          field: 'ds1Id',
          headerName: 'Factset Location ID (DS1 ID)',
        },
        {
          field: 'status',
          renderCell: ({ value }) => <FactsetStatusText status={value} />,
        },
        {
          field: 'createdAt',
          type: 'date',
        },
        {
          field: 'updatedAt',
          type: 'date',
        },
        ...(customerId
          ? []
          : [
              {
                // show customer column if there's no customerId (e.g. NOT on customer page)
                field: 'customer',
                valueGetter: ({ value }) =>
                  value
                    ? {
                        to: '/accounts/customers/' + value.id,
                        text: value.name,
                      }
                    : null,
                type: 'link',
                minWidth: 150,
              },
            ]),
        {
          field: 'actions',
          type: 'actions',
          minWidth: 60,
          getActions: ({ row }) => {
            const linkLocationAction = row.customer ? (
              // show unlink button if the factset location HAS linked customer
              <DataGridActionCell
                icon={<UnlinkIcon color="warning" />}
                title="Unlink"
                onClick={tableActions.onUnlink(row.id)}
              />
            ) : (
              // otherwise show link button to allow linking
              <DataGridActionCell
                icon={<LinkIcon color="success" />}
                title="Link"
                onClick={tableActions.onLink(row)}
              />
            )

            const deleteLocationAction = (
              <Tooltip
                title={
                  deletableLocationStatus.indexOf(row.status) >= 0
                    ? ''
                    : 'Only unmanaged or locations failed in creation can be deleted. Factset managed locations must be deleted by submitting a FactSet Issue Tracker ticket.'
                }
              >
                <span>
                  <DataGridActionCell
                    disabled={deletableLocationStatus.indexOf(row.status) < 0}
                    icon={<DeleteIcon />}
                    title="Delete"
                    onClick={tableActions.onDelete(row.id, row.name)}
                  />
                </span>
              </Tooltip>
            )

            return [linkLocationAction, deleteLocationAction]
          },
        },
      ]}
      rows={locations.data}
      page={page}
      pageSize={pageSize}
      onPageChange={handlePageChange}
      onPageSizeChange={handleRowsChange}
      rowCount={locations.meta.itemCount}
      pageSizeOptions={pageSizeOptions}
    />
  )
}

export default FactsetLocationsTable
