import { useState } from 'react'

const defaultPagination = {
  pageSize: 25,
  pageOffset: 0,
  page: 0,
}

function usePagination() {
  const [pagination, setPagination] = useState(defaultPagination)

  const handlePageChange = newPage => {
    const { page, pageSize } = pagination

    if (newPage === page) {
      return
    }

    const updatingPage = newPage > page ? page + 1 : page - 1

    setPagination(prevState => ({
      ...prevState,
      page: updatingPage,
      pageOffset: updatingPage * pageSize,
    }))
  }

  const handleRowsChange = pageSize => {
    setPagination(prevState => ({ ...prevState, page: 0, pageSize: pageSize, pageOffset: 0 }))
  }

  return {
    pagination,
    handlePageChange,
    handleRowsChange,
    pageSizeOptions: [10, 25, 50, 100],
  }
}

export default usePagination
