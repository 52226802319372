import React from 'react'

import { Typography, Box } from '@the-platform-group/component-library'
import { Grid } from '@the-platform-group/component-library/Layout'
import { Button } from '@the-platform-group/component-library/Button'
import { Lock as LockIcon } from '@the-platform-group/component-library/Icons'

import ERRORS from 'constants/errorTypes'
import useAuth from 'hooks/useAuth'

export const forbiddenMesssage = 'This user does not have permission to access the admin portal.'

function ForbiddenApp() {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          height: '100%',
        }}
      >
        <Grid item>
          <Typography variant="h5">{forbiddenMesssage}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

function ForbiddenPage() {
  const { handlers } = useAuth()

  return (
    <Grid container alignItems="center" justifyContent="center" direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h6">You don't have permission to see the current page.</Typography>
      </Grid>
      <Grid item>
        <Button variant="outlined" endIcon={<LockIcon />} onClick={handlers.endSession}>
          Login
        </Button>
      </Grid>
    </Grid>
  )
}

class ForbiddenErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, appAccessForbidden: false, error: null }
  }

  static getDerivedStateFromError(error) {
    // Catch 403s only
    if (error.response?.status === 403) {
      return { hasError: true }
    }

    if (error?.error === ERRORS.FORBIDDEN) {
      return { appAccessForbidden: true }
    }

    // Propagate error to next boundary if not caught
    return { error }
  }

  componentDidUpdate(nextProps) {
    if (this.state.hasError && this.props.location !== nextProps.location) {
      this.setState({ hasError: false })
    }
  }

  render() {
    if (this.state.error) {
      throw this.state.error
    }

    if (this.state.appAccessForbidden) {
      return <ForbiddenApp />
    }

    if (this.state.hasError) {
      return <ForbiddenPage />
    }

    return this.props.children
  }
}

export default ForbiddenErrorBoundary
