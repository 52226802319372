function userToCreateParams(user) {
  return {
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    customer_id: user.customerId,
    time_zone: user.timeZone,
    role: user.role,
    send_invitation: user.sendInvitation,
    ...(user.password && { password: user.password }),
  }
}

function userToUpdateParams(user) {
  return {
    first_name: user.firstName,
    last_name: user.lastName,
    email: user.email,
    time_zone: user.timeZone,
    role: user.role,
    ...(user.password && { password: user.password }),
  }
}

export { userToCreateParams, userToUpdateParams }
