import { useState } from 'react'

const initialModalState = { isOpen: false, selectedRow: {} }

const usePlatformTableModal = ({ handleDelete }) => {
  const [modalState, setModalState] = useState(initialModalState)

  const handleModalOpen = selectedRow => {
    setModalState({ isOpen: true, selectedRow })
  }

  const handleArchive = () => {
    handleDelete(modalState.selectedRow)
    setModalState(initialModalState)
  }
  const handleModalClose = () => {
    setModalState(initialModalState)
  }

  return { modalState, handleArchive, handleModalClose, handleModalOpen }
}

export default usePlatformTableModal
