import useFactsetData from 'pages/Factset/hooks/useFactsetData'

// Components
import { DataGrid } from '@the-platform-group/component-library/DataGrid'
import { getPeopleColumns, getPeopleRows } from '../Ds1People/DS1PeopleTableValues'
import { TextField, Typography } from '@the-platform-group/component-library'
import { buttonStyle } from '../Ds1People/buttonStyle'
import useOrganizationConfig from './hooks/useOrganizationConfig'

const FacsetTable = ({ entity }) => {
  const {
    data: responseData,
    queryKey,
    pagination,
    isFetching,
    handleFilter,
    handlePageChange,
    handleRowsChange,
    pageSizeOptions,
  } = useFactsetData({ entity, ...(entity !== 'people' && { params: { source: 'ds1' } }) })
  const { page, pageSize } = pagination

  const { getOrganizationColumns } = useOrganizationConfig({ queryKey })

  const TABLE_DATA_CONFIGS = {
    people: { getColumns: getPeopleColumns, getRows: getPeopleRows },
    organizations: {
      getColumns: getOrganizationColumns,
      getRows: organization => organization,
    },
  }

  const { getColumns, getRows } = TABLE_DATA_CONFIGS[entity]

  return (
    <>
      <Typography variant="h5" gutterBottom fontWeight="fontWeightBold" textTransform="capitalize">
        Factset {entity}
      </Typography>
      <TextField
        onChange={handleFilter}
        name="search"
        label={`Search Factset ${entity}`}
        fullWidth
      />
      <DataGrid
        rows={getRows(responseData?.data || [])}
        columns={getColumns()}
        loading={isFetching}
        localeText={{ noRowsLabel: '' }}
        name={`ds1${entity}`}
        pagination
        onPageChange={handlePageChange}
        onPageSizeChange={handleRowsChange}
        page={page}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        rowCount={responseData?.meta?.itemCount || 0}
        sx={{
          '& .ds1_toggle,& .actions': buttonStyle,
        }}
      />
    </>
  )
}

export default FacsetTable
