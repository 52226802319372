import React, { useMemo } from 'react'
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@the-platform-group/component-library/Modal'
import {
  AdapterDateFns,
  RawButton as Button,
  DatePicker,
  Grid,
  MuiPickersUtilsProvider,
  Select,
} from '@the-platform-group/component-library'
import { Button as LoadingButton } from '@the-platform-group/component-library/Button'
import useNewReportHandler from '../hooks/useNewReportHandler'
import { useSurveillanceContext } from '../hooks/SurveillanceContext'
import { formatDate } from '@the-platform-group/formatters/dateFormat'
import { YEAR_FORMAT } from 'constants/formats'
import { DATE_FORMAT } from '@the-platform-group/formatters/constants'

function NewReportModal({ excludedReportDates }) {
  const {
    isLoading,
    isNewReportModalOpen,
    handleNewReportModal,
    reports,
  } = useSurveillanceContext()

  const { formValues, updateFormValues, saveNewReport } = useNewReportHandler()

  const reportOptions = useMemo(() => {
    return reports.map(report => ({
      value: report.id,
      label: `${report.name} (${formatDate(report.date, YEAR_FORMAT)})`,
    }))
  }, [reports])

  const reportValue =
    formValues.holdings_source === 'current' ? 'current' : formValues.previous_report_id

  function handleDisableDates(date) {
    if (!excludedReportDates) {
      return
    }

    const formattedCurrentDate = formatDate(date, DATE_FORMAT)

    return excludedReportDates.some(excludedDate => {
      const formattedExcludedDate = formatDate(excludedDate, DATE_FORMAT)

      return formattedExcludedDate === formattedCurrentDate
    })
  }

  function handlePreviousReportChange(event) {
    const value = event.target.value

    // Set the holdings_source and previous_report_id based on the selected previous report
    // The user can select either a specific report or no report (i.e. 'current')
    // holdings_source: 'previous' tells the back end to clone the given report
    // holdings_source: 'current' tells the back end to create the report based on the latest information
    const isValueCurrent = value === 'current'
    updateFormValues({
      holdings_source: isValueCurrent ? 'current' : 'previous',
      previous_report_id: isValueCurrent ? undefined : value,
    })
  }

  return (
    <Modal open={isNewReportModalOpen} onClose={() => handleNewReportModal(false)}>
      <MuiPickersUtilsProvider dateAdapter={AdapterDateFns}>
        <ModalTitle title="Add new report" />
        <ModalContent>
          <Grid item container xs={12} direction="column" rowSpacing={2}>
            <Grid item xs={12}>
              <DatePicker
                disableToolbar
                variant="outlined"
                inputVariant="outlined"
                format={DATE_FORMAT}
                label="Select a report date"
                margin="none"
                value={formValues.date}
                shouldDisableDate={handleDisableDates}
                fullWidth
                onChange={date =>
                  updateFormValues({
                    date,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                fullWidth
                name="previous_report"
                label="Clone previous report"
                data-testid="new-report-modal-previous-report-id-select"
                options={[{ value: 'current', label: 'Reported Holders' }, ...reportOptions]}
                value={reportValue}
                onChange={handlePreviousReportChange}
              />
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions>
          <Button onClick={() => handleNewReportModal(false)}>cancel</Button>
          <LoadingButton onClick={() => saveNewReport(formValues)} isLoading={isLoading}>
            create
          </LoadingButton>
        </ModalActions>
      </MuiPickersUtilsProvider>
    </Modal>
  )
}

export default NewReportModal
