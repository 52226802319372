import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import {
  TextField,
  InputAdornment,
  IconButton,
  Chip,
  Box,
  CircularProgress,
} from '@the-platform-group/component-library'
import {
  Search as SearchIcon,
  Clear as ClearIcon,
} from '@the-platform-group/component-library/Icons'
import { SELECT_BY_OPTIONS } from 'pages/IrwinAssistant/Summaries/utils'
import { useNotification } from 'components/Notification'
import { getFullName } from '@the-platform-group/formatters/people'
import { getOrganization } from 'services/organizations'
import { getPerson } from 'services/people'

const SelectUuid = ({ selectBy, uuidState, setUuidState, selectedProfile, setSelectedProfile }) => {
  const { createNotification } = useNotification()
  const queryClient = useQueryClient()

  const [isLoading, setIsLoading] = useState(false)

  const handleUuidSearch = async () => {
    setIsLoading(true)
    try {
      if (selectBy === SELECT_BY_OPTIONS.organizations_by_uuid.key) {
        // search organization by uuid
        const { data: selectedOrganization } = await queryClient.fetchQuery({
          queryKey: ['getOrganization', uuidState],
          queryFn: () => getOrganization(uuidState),
          select: data => data.data,
          retry: (failureCount, error) =>
            error.response.status === 404 ? false : failureCount < 3, // Retry only if status is not 404 and failureCount is less than 3, 404 can be considered as not found
        })

        setSelectedProfile({
          ...selectedOrganization,
          profileType: 'organization',
          profileName: selectedOrganization.name,
        })
      } else {
        // search person by uuid
        const { data: selectedPerson } = await queryClient.fetchQuery({
          queryKey: ['getPerson', uuidState],
          queryFn: () => getPerson(uuidState),
          select: data => data.data,
          retry: (failureCount, error) =>
            error.response.status === 404 ? false : failureCount < 3, // Retry only if status is not 404 and failureCount is less than 3, 404 can be considered as not found
        })

        setSelectedProfile({
          ...selectedPerson,
          profileType: 'person',
          profileName: getFullName(selectedPerson),
        })
      }
    } catch (error) {
      createNotification({
        message:
          selectBy === SELECT_BY_OPTIONS.organizations_by_uuid.key
            ? 'Organization not found'
            : 'Person not found',
        variant: 'error',
      })
    }

    setIsLoading(false)
  }

  const handleRemoveProfile = () => {
    setSelectedProfile(null)
    setUuidState('')
  }

  return (
    <TextField
      variant="outlined"
      label={`Enter ${SELECT_BY_OPTIONS[selectBy].label}`}
      margin="none"
      sx={{
        width: '100%',
        pr: 0,
        '& fieldset': {
          border: 'unset',
        },
        '& .MuiFormLabel-root:not(.Mui-disabled)': {
          backgroundColor: 'white',
          px: 1,
        },
        '& .MuiInputBase-root': {
          pr: 0,
          justifyContent: 'space-between',
        },
      }}
      value={uuidState}
      onChange={e => setUuidState(e.target.value)}
      InputProps={{
        ...(selectedProfile && {
          inputComponent: ChipWrapper,
          inputProps: {
            label: selectedProfile.profileName,
          },
        }),
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{
              ml: 0,
            }}
          >
            {selectedProfile ? (
              <IconButton
                sx={{
                  bgcolor: 'primary.dark',
                  borderRadius: 0,
                  borderTopRightRadius: 4,
                  borderBottomRightRadius: 4,
                  '&:hover': {
                    bgcolor: 'error.main',
                  },
                }}
                onClick={handleRemoveProfile}
              >
                <ClearIcon sx={{ color: 'white' }} />
              </IconButton>
            ) : (
              <IconButton
                sx={{
                  bgcolor: 'primary.main',
                  borderRadius: 0,
                  borderTopRightRadius: 4,
                  borderBottomRightRadius: 4,
                  '&:hover': {
                    bgcolor: 'primary.dark',
                  },
                  '&:disabled': {
                    bgcolor: 'primary.dark',
                  },
                }}
                onClick={handleUuidSearch}
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress sx={{ color: 'white' }} size={22} />
                ) : (
                  <SearchIcon sx={{ color: 'white' }} />
                )}
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      onKeyPress={e => e.key === 'Enter' && handleUuidSearch()}
    />
  )
}

/**
 * Wrapper for the Chip component
 * @param {Object} props - props object
 * @param {React.ReactNode} props.children - children of the Chip related with TextField
 * @param {string} props.label - label for the Chip
 * @returns {ChipWrapper}
 */
const ChipWrapper = ({ label }) => {
  return (
    <Box sx={{ maxWidth: '89%', height: '40px', display: 'flex', alignItems: 'center', px: 1 }}>
      <Chip
        variant="outlined"
        label={label}
        sx={{
          position: 'relative',
          border: 'unset',
          bgcolor: 'primary.main',
          px: 0.5,
          py: 1,
          height: '25px',
          '& .MuiChip-label': {
            color: 'white',
          },
        }}
      />
    </Box>
  )
}

export default SelectUuid
