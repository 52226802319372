import { useState } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { INITIAL_SORT_STATE, SORT_COLUMN_MAPS, UPLOAD_REQUEST_TABLE_NAME } from '../utils/constants'

import { getInitialSortState } from 'components/Table/Sorting/utils'
import { useNotification } from 'components/Notification'

import { getUserUploads, updateUserUploads, getUserResourceLink } from 'services/userUploadRequests'

const useUploadRequests = () => {
  const { field, sort } = getInitialSortState(
    UPLOAD_REQUEST_TABLE_NAME,
    INITIAL_SORT_STATE,
    ({ sort, field }) => ({
      sort: sort || INITIAL_SORT_STATE.sort,
      field: SORT_COLUMN_MAPS[field] || INITIAL_SORT_STATE.field,
    }),
  )

  const DEFAULT_QUERY_PARAMS = {
    pageOffset: 0,
    page: 0,
    pageSize: 50,
    customerId: '',
    customer: {},
    status: '',
    sort: `${field}_${sort}`,
  }

  const queryClient = useQueryClient()
  const { createNotification } = useNotification()
  const [queryParams, setQueryParams] = useState(DEFAULT_QUERY_PARAMS)
  const params = {
    pageOffset: queryParams.pageOffset,
    pageSize: queryParams.pageSize,
    customerId: queryParams.customerId,
    status: queryParams.status,
    sort: queryParams.sort,
  }

  const {
    data: { data: uploadRequests, meta },
    isFetching,
  } = useQuery(['useUploadRequestsQuery', params], () => getUserUploads(params), {
    initialData: { data: [], meta: { itemCount: 0 } },
    refetchOnWindowFocus: false,
    useErrorBoundary: true,
    keepPreviousData: true,
  })

  const handleOnSort = (column, direction) => {
    const field = SORT_COLUMN_MAPS[column]

    setQueryParams({ ...queryParams, sort: `${field}_${direction}` })
  }

  const handlePageChange = page => {
    setQueryParams({ ...queryParams, page, pageOffset: page * queryParams.pageSize })
  }

  const handlePageSizeChange = pageSize => {
    setQueryParams({ ...queryParams, pageSize })
  }

  const handleCustomerChange = params => {
    !!params
      ? setQueryParams({ ...queryParams, customerId: params.id, customer: params })
      : setQueryParams({ ...queryParams, customerId: null, customer: params })
  }

  const handleStatusChange = param => {
    setQueryParams({ ...queryParams, status: param?.status || '' })
  }

  const { mutate: updateUserUploadRequest } = useMutation(
    ({ id, ...params }) => updateUserUploads(id, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['useUploadRequestsQuery', params], { refetchActive: true })
      },
      onError: _ =>
        createNotification({
          variant: 'error',
          message: 'Encountered an error while trying to update. Please try again later',
        }),
    },
  )

  const handleRowChange = row => {
    const { status, assignee, comments } = row

    updateUserUploadRequest({ id: row.id, comments, status, assigneeId: assignee?.id })

    return row
  }

  // The download link generated from the BE expires after 5
  // minutes. The cached link in the FE is expired in 3 minutes
  // to give a little buffer room.
  const handleFileDownload = async (row, filePath) => {
    try {
      const { data } = await queryClient.fetchQuery(
        ['useFileDownloadQuery', { id: row.id, filePath }],
        () => getUserResourceLink(row.id, filePath),
        {
          staleTime: 180000,
        },
      )
      window.open(data.fileUrl)
    } catch (error) {
      createNotification({
        variant: 'error',
        message: 'Encountered an error while downloading the file. Please try again later',
      })
    }
  }

  return {
    queryParams,
    uploadRequests,
    itemCount: meta.itemCount,
    isFetching,
    handleOnSort,
    handleRowChange,
    handleFileDownload,
    handlePageChange,
    handlePageSizeChange,
    handleCustomerChange,
    handleStatusChange,
    updateUserUploadRequest,
  }
}

export default useUploadRequests
