import { useEffect } from 'react'
import { Typography, Grid } from '@the-platform-group/component-library'
import Modal from 'components/Modal'
import { useModal } from 'hooks/useModal'

import DefinitionsTable from './DefinitionsTable'
import useMarketCapsState from 'hooks/marketCaps/useMarketCapsState'
import useMarketCapsPresentational, {
  labelFromRange,
} from 'hooks/marketCaps/useMarketCapsPresentational'

export const LANGUAGE_DEFINITIONS = {
  title: 'Market Cap Range Updates',
  subtitles: [
    {
      key: 'sub1',
      subtitle: <>Market cap ranges have been updated.</>,
    },
    {
      key: 'sub2',
      subtitle: (
        <>
          The following <b>UPDATED</b> values will be used throughout Irwin.
        </>
      ),
    },
  ],
  footer: 'No additional action is required.',
}

function DefinitionUpdatesModal() {
  const { open: isOpen, handleOpen: open, handleClose: close } = useModal()
  const {
    marketCaps,
    outdatedMarketCaps,
    hasOutdatedMarketCaps,
    acknowledgeUpdate,
  } = useMarketCapsState()
  const { findTooltip } = useMarketCapsPresentational()

  // sync persisted state if it's a newer version
  useEffect(() => {
    // if market caps are not populated they are in flight
    if (!marketCaps.length) {
      return
    }

    if (hasOutdatedMarketCaps(outdatedMarketCaps.map(mc => mc.code))) {
      open()
    }
  }, [marketCaps, open, hasOutdatedMarketCaps, outdatedMarketCaps])

  return (
    <Modal
      open={isOpen}
      title={
        <Typography component="div" variant="h6" align="center">
          {LANGUAGE_DEFINITIONS.title}
        </Typography>
      }
      handleSave={() => {
        acknowledgeUpdate()
        close()
      }}
      saveButtonText="OK"
      footer={
        <Typography variant="body2" align="center" sx={{ color: 'primary.text' }}>
          {LANGUAGE_DEFINITIONS.footer}
        </Typography>
      }
    >
      <Grid container direction="column" spacing={2}>
        {LANGUAGE_DEFINITIONS.subtitles.map(({ key, subtitle }) => (
          <Grid item xs key={key}>
            <Typography variant="body2" align="center" sx={{ color: 'primary.text' }}>
              {subtitle}
            </Typography>
          </Grid>
        ))}
        <Grid item container spacing={4} xs>
          <Grid item xs>
            <DefinitionsTable
              title="previous"
              definitions={outdatedMarketCaps.map(mc => ({
                label: mc.label,
                range: labelFromRange(mc.range),
              }))}
            />
          </Grid>
          <Grid item xs>
            <DefinitionsTable
              title="updated"
              definitions={marketCaps.map(mc => ({
                label: mc.label,
                range: findTooltip(mc.code),
              }))}
            />
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}

export default DefinitionUpdatesModal
