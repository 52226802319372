import { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
// Services
import { getCustomers } from 'services/customers'
import useCustomerModals from 'components/CustomerModals/useCustomerModals'
import debounce from 'utils/debounce'
import { useNotification } from 'components/Notification'

const defaultParams = {
  itemCount: 0,
  pageSize: 50,
  pageOffset: 0,
  page: 0,
}

const defaultFilters = {
  state: 'active',
  type: null,
  term: null,
}

const debouncedSearch = debounce(getCustomers, 150)

function useCustomers() {
  const { createNotification } = useNotification()
  const queryClient = useQueryClient()
  const [pagination, setPagination] = useState(defaultParams)
  const [filters, setFilters] = useState(defaultFilters)

  const {
    getCustomerModals,
    handleCreateOpen,
    handleCustomerFeatureToggle,
    handleCustomerEdit,
    handleCustomerSecurity,
    defaultHandleCustomerSave,
    defaultHandleCustomerArchive,
    defaultHandleCustomerUnarchive,
    defaultHandleFeatureToggleUpdate,
  } = useCustomerModals()

  const params = {
    state: filters.state,
    type: filters.type,
    term: filters.term,
    pageSize: pagination.pageSize,
    pageOffset: pagination.pageOffset,
  }

  const queryKey = ['customerAccountsQuery', params]

  const resetCustomerAccountsQuery = () =>
    queryClient.resetQueries(queryKey, {
      exact: true,
      onError: error =>
        createNotification({
          message: `Error in fetching customer accounts, ${error.message}`,
          variant: 'error',
        }),
    })

  const { data, isFetching: isLoading } = useQuery(queryKey, () => debouncedSearch(params), {
    placeholderData: { data: [], meta: defaultParams },
    select: data => ({ customers: data.data, meta: { ...data.meta, page: pagination.page } }),
    refetchOnWindowFocus: false,
    useErrorBoundary: true,
  })

  const handleCustomerTypeFilterChange = customerType => {
    const type = customerType === 'all' ? null : customerType

    setFilters(prevState => ({ ...prevState, type }))
  }

  const handleCustomerNameSearch = e => {
    const term = e.target.value || null
    setFilters(prevState => ({ ...prevState, term }))
  }

  const handleCustomerActiveStateFilterChange = state => {
    setFilters(prevState => ({ ...prevState, state }))
  }

  const { mutateAsync: handleCustomerSave } = useMutation(defaultHandleCustomerSave, {
    onError: (error, customerArg) =>
      createNotification({
        message: customerArg.id
          ? `Error saving customer ${customerArg.id}, ${error.message}`
          : `Error creating new customer, ${error.message}`,
        variant: 'error',
      }),
    onSuccess: (updatedCustomer, customerArg) => {
      queryClient.setQueryData(queryKey, oldData => {
        const { data: oldCustomers, meta } = oldData
        const updatedCustomers = customerArg.id
          ? oldCustomers.map(oldCustomer =>
              oldCustomer.id === updatedCustomer.id ? updatedCustomer : oldCustomer,
            )
          : [updatedCustomer, ...oldCustomers]
        return { meta, data: updatedCustomers }
      })
    },
  })

  const { mutateAsync: handleCustomerArchive } = useMutation(defaultHandleCustomerArchive, {
    onError: (error, customerArg) =>
      createNotification({
        message: `Error archiving customer ${customerArg.id}, ${error.message}`,
        variant: 'error',
      }),
    onSuccess: resetCustomerAccountsQuery,
  })

  const { mutateAsync: handleCustomerUnarchive } = useMutation(defaultHandleCustomerUnarchive, {
    onError: (error, customerArg) =>
      createNotification({
        message: `Error unarchiving customer ${customerArg.id}, ${error.message}`,
        variant: 'error',
      }),
    onSuccess: resetCustomerAccountsQuery,
  })

  const { mutateAsync: handleFeatureToggleUpdate } = useMutation(
    ({ customer, features }) => defaultHandleFeatureToggleUpdate(customer, features),
    {
      onError: (error, { customer }) =>
        createNotification({
          message: `Error updating feature toggles for customer ${customer.id}, ${error.message}`,
          variant: 'error',
        }),
      onSuccess: updatedCustomer => {
        queryClient.setQueryData(queryKey, oldData => {
          const updatedCustomers = oldData.data.map(oldCustomer =>
            oldCustomer.id === updatedCustomer.id ? updatedCustomer : oldCustomer,
          )
          return { meta: oldData.meta, data: updatedCustomers }
        })
      },
    },
  )

  const getModalComponents = () => {
    return getCustomerModals(
      (customer, features) => handleFeatureToggleUpdate({ customer, features }),
      handleCustomerSave,
    )
  }

  const handleUserAdded = (user, customerId) => {
    const getUpdatedUsers = (oldCustomer, updatedUser) => {
      const filteredUsers = oldCustomer.users.filter(oldUser => oldUser.id !== updatedUser.id)
      return { ...oldCustomer, users: [updatedUser, ...filteredUsers] }
    }

    queryClient.setQueryData(queryKey, oldData => {
      const updatedCustomers = oldData.data.map(oldCustomer =>
        oldCustomer.id === customerId ? getUpdatedUsers(oldCustomer, user) : oldCustomer,
      )
      return { meta: oldData.meta, data: updatedCustomers }
    })
  }

  return {
    customers: data.customers,
    pagination: data.meta,
    isLoading,
    filters,
    setPagination,
    handleCustomerTypeFilterChange,
    handleCustomerActiveStateFilterChange,
    handleCustomerNameSearch,
    handleUserAdded,
    handleCustomerEdit,
    handleCustomerFeatureToggle,
    handleCustomerArchive,
    handleCustomerUnarchive,
    handleCustomerSecurity,
    handleCreateOpen,
    getModalComponents,
  }
}

export { useCustomers }
