import { useParams } from 'react-router-dom'
import { useQuery, useQueryClient, useMutation } from 'react-query'

import { getDs1Person } from 'services/ds1People'
import DS1PersonJobs from './DS1PersonJobs'

import { H1, H2 } from 'components/Typography'
import { Grid } from '@the-platform-group/component-library/Layout'
import { Button } from '@the-platform-group/component-library/Button'

import useProfilePictureUploader from 'components/ProfilePicture/useProfilePictureUploader'
import usePersonSocialProfiles from 'hooks/usePersonSocialProfiles'
import PersonSocialProfilesForm from 'components/PersonSocialProfiles/PersonSocialProfilesForm'

import InternalNoteTextField from 'components/InternalNotes/InternalNoteTextField'
import InternalNoteList from 'components/InternalNotes/InternalNoteList'
import INTERNAL_NOTE_TYPES from 'constants/internalNoteTypes'
import { useInternalNoteState } from 'hooks/useInternalNoteUtils'
import { createInternalNote } from 'services/internalNotes'

import { useNotification } from 'components/Notification'

const DS1Person = () => {
  const { id } = useParams()

  const { createNotification } = useNotification()
  const queryClient = useQueryClient()

  const { data: ds1Person } = useQuery({
    queryKey: ['ds1Person', id],
    queryFn: () => getDs1Person(id),
    initialData: { jobs: [] },
    refetchOnWindowFocus: false,
    useErrorBoundary: true,
  })

  const internalNoteState = useInternalNoteState()

  const { LinkedInForm, handleSave: updateSocialProfile } = LinkedInProfile(ds1Person)

  const { isLoading: isSaving, mutate: handlePersonUpdate } = useMutation(
    async () => {
      // save social profiles
      await updateSocialProfile()

      const internalNote = internalNoteState.content

      if (internalNote) {
        // only send request if there is content
        await createInternalNote(internalNote, id, INTERNAL_NOTE_TYPES.ds1Person)
      }
      internalNoteState.resetInternalNoteState()
      queryClient.invalidateQueries('internalNoteItems')
    },
    {
      onSuccess: () => {
        createNotification({
          variant: 'success',
          message: 'Person updated successfully',
        })
      },
      onError: () => {
        createNotification({
          variant: 'error',
          message: 'There was an error updating the person',
        })
      },
    },
  )

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <H1>
            Edit {ds1Person.firstName} {ds1Person.lastName}
          </H1>
          {LinkedInForm}
        </Grid>
        <Grid item xs={6}>
          <InternalNoteTextField {...internalNoteState} />
        </Grid>
        <Grid item>
          <Button isLoading={isSaving} onClick={handlePersonUpdate} color="primary">
            Save
          </Button>
        </Grid>
      </Grid>

      <H2>FDS Data</H2>
      <DS1PersonJobs {...ds1Person} />

      <InternalNoteList
        internalNotableId={id}
        internalNotableType={INTERNAL_NOTE_TYPES.ds1Person}
      />
    </>
  )
}

export default DS1Person

const LinkedInProfile = person => {
  const { id, personSocialProfiles, personProfileImage } = person
  const {
    editingPersonSocialProfiles,
    handleEditPersonSocialProfilesChange,
    handlePersonSocialProfilesSave,
  } = usePersonSocialProfiles(personSocialProfiles, id)

  const {
    selectedImage,
    handleImageChange,
    resetSelectedImage,
    handleSaveProfilePicture,
  } = useProfilePictureUploader({
    personProfileImage: personProfileImage,
  })

  // handle save for person social profiles and profile picture
  const handleSave = async () => {
    // handle profile picture save - update a new profile picture or remove existing profile picture
    let profilePicture = await handleSaveProfilePicture({
      personId: id,
      personProfileImageUrl: personProfileImage?.url,
    })

    // // if profilePicture.updated is true, we need to update the person
    if (profilePicture.updated) {
      person.personProfileImage = profilePicture.fileUrl
    }

    // save person social profiles
    await handlePersonSocialProfilesSave(editingPersonSocialProfiles)
  }

  return {
    handleSave,
    LinkedInForm: (
      <PersonSocialProfilesForm
        personSocialProfiles={editingPersonSocialProfiles}
        onPersonSocialProfilesChange={handleEditPersonSocialProfilesChange}
        person={person}
        selectedImage={selectedImage}
        handleImageChange={handleImageChange}
        resetSelectedImage={resetSelectedImage}
      />
    ),
  }
}
