import React, { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
// images
import Edit from 'images/edit.svg'
// components
import { Modal, ModalContent, ModalActions } from '@the-platform-group/component-library/Modal'
import {
  Table,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableBody,
  TableCell,
  TableActionList,
  TableAction,
} from '@the-platform-group/component-library/Table'
import { Button } from '@the-platform-group/component-library/Button'
import { Divider } from '@the-platform-group/component-library'
import { Grid } from '@the-platform-group/component-library/Layout'
import PersonDelete from 'components/EntityDelete'
import PersonFormComponent from './PersonFormComponent'
import JobFormComponent from './JobFormComponent'
import PersonSocialProfilesForm from 'components/PersonSocialProfiles/PersonSocialProfilesForm'
import ApproveModal from 'components/ApproveModal/ApproveModal'
// hooks
import { usePersonEditForm } from './hooks/usePersonEditForm'
import { useJob } from './hooks/useJob'
import usePersonSocialProfiles from 'hooks/usePersonSocialProfiles'
import usePersonApprove from './hooks/usePersonApprove'
import JOB_FUNCTIONS from 'constants/jobFunctions'
import ORGANIZATION_TYPES from 'constants/organizationTypes'
import usePlatformDelete from 'shared/PlatformTable/hooks/usePlatformDelete'
import InternalNoteTextField from 'components/InternalNotes/InternalNoteTextField'
import { useInternalNoteState } from 'hooks/useInternalNoteUtils'
import InternalNoteList from 'components/InternalNotes/InternalNoteList'
import INTERNAL_NOTE_TYPES from 'constants/internalNoteTypes'
import useProfilePictureUploader from 'components/ProfilePicture/useProfilePictureUploader'
import { useNotification } from 'components/Notification'

const PersonEditForm = ({ match, history }) => {
  const { createNotification } = useNotification()
  const {
    person,
    editingJob,
    jobToggle,
    isCreatingPerson,
    handleJobFormToggle,
    handleJobEdit,
    handleJobAdd,
    handlePersonChange,
    handlePersonUpdate,
  } = usePersonEditForm(match)

  const {
    focus,
    focuses,
    job,
    organization,
    isCreatingJob,
    handleJobSave,
    ...jobFormHandlers
  } = useJob(editingJob, handleJobAdd)

  const { personSocialProfiles, handlePersonSocialProfilesChange } = usePersonSocialProfiles(
    person.personSocialProfiles,
  )

  const internalNoteState = useInternalNoteState()

  // handle save for person profile picture and person
  const handlePersonSaveClick = async () => {
    try {
      // handle profile picture save - update a new profile picture or remove existing profile picture
      await handleSaveProfilePicture({
        personId: person?.id,
        personProfileImageUrl: person?.personProfileImage?.url,
      })

      // handle person update
      handlePersonUpdate({
        personSocialProfiles,
        internalNoteContent: internalNoteState.content,
      })
      internalNoteState.resetInternalNoteState() //clears the internal note text field
    } catch (error) {
      createNotification({
        variant: 'error',
        message: 'There was an error saving the person',
      })
    }
  }

  const {
    warnings,
    approved,
    handleApprovePerson,
    isLoading: approvedIsLoading,
    modalOpen: approvedModalOpen,
    setModalOpen: setApprovedModalOpen,
  } = usePersonApprove({
    ...person,
    submit: handlePersonSaveClick,
  })

  const { handleDelete: handleDeletePerson } = usePlatformDelete({
    history,
    toEntityLabel: selectedPerson => [selectedPerson.firstName, selectedPerson.lastName].join(' '),
    type: 'PERSON',
  })
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const handleDeleteModalOpen = () => {
    setDeleteModalOpen(true)
  }

  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false)
  }

  const {
    isLoading: isProfileImageLoading,
    selectedImage,
    handleImageChange,
    resetSelectedImage,
    handleSaveProfilePicture,
  } = useProfilePictureUploader({
    personProfileImage: person?.personProfileImage,
  })

  return (
    <Fragment>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <h2>Edit Person</h2>
          <PersonFormComponent person={person} onPersonChange={handlePersonChange} />
          <PersonSocialProfilesForm
            personSocialProfiles={personSocialProfiles}
            onPersonSocialProfilesChange={handlePersonSocialProfilesChange}
            isModal={false}
            person={person}
            selectedImage={selectedImage}
            handleImageChange={handleImageChange}
            resetSelectedImage={resetSelectedImage}
          />
        </Grid>
        <Grid item xs={6}>
          <InternalNoteTextField {...internalNoteState} />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item>
          <Button
            isLoading={isCreatingPerson || isProfileImageLoading}
            onClick={handlePersonSaveClick}
            color="primary"
          >
            Submit
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => handleDeleteModalOpen(person)} color="secondary">
            Archive
          </Button>
        </Grid>
        <Grid item>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item>
          <Button
            color="primary"
            disabled={!!approved}
            isLoading={approvedIsLoading}
            onClick={() => setApprovedModalOpen(true)}
          >
            Approve
          </Button>
        </Grid>
        {approved && (
          <Grid item sx={{ my: 'auto' }}>
            Approved At {approved}
          </Grid>
        )}
      </Grid>
      <h2>Jobs</h2>
      <Button onClick={() => handleJobFormToggle(null, true)} color="primary">
        Add Job
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>#</TableHeaderCell>
            <TableHeaderCell>Title</TableHeaderCell>
            <TableHeaderCell>Email</TableHeaderCell>
            <TableHeaderCell>Phone</TableHeaderCell>
            <TableHeaderCell>Job Function</TableHeaderCell>
            <TableHeaderCell>Organization</TableHeaderCell>
            <TableHeaderCell>Organization Type</TableHeaderCell>
            <TableHeaderCell>Active?</TableHeaderCell>
            <TableHeaderCell>Start Date</TableHeaderCell>
            <TableHeaderCell>End Date</TableHeaderCell>
            <TableHeaderCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {person.jobs.map((j, idx) => (
            <JobRow key={j.id} job={j} idx={idx} onJobEdit={handleJobEdit} />
          ))}
        </TableBody>
      </Table>
      <Modal
        open={!!jobToggle.editingJobId || jobToggle.addingJob}
        onClose={() => handleJobFormToggle(null, false)}
      >
        <ModalContent>
          <JobFormComponent
            personId={person.id}
            focus={focus}
            focuses={focuses}
            job={job}
            organization={organization}
            jobFormHandlers={jobFormHandlers}
          />
          <ModalActions>
            <Button variant="text" onClick={() => handleJobFormToggle(null, false)}>
              Cancel
            </Button>
            <Button
              color="primary"
              isLoading={isCreatingJob}
              onClick={() => handleJobSave(person.id, organization)}
            >
              Save
            </Button>
          </ModalActions>
        </ModalContent>
      </Modal>
      <InternalNoteList
        internalNotableId={person.id}
        internalNotableType={INTERNAL_NOTE_TYPES.platformPerson}
      />
      <PersonDelete
        entityType="PERSON"
        entityLabel={[person.firstName, person.lastName].join(' ')}
        modalOpen={deleteModalOpen}
        onArchive={() => handleDeletePerson(person)}
        onModalClose={() => handleDeleteModalClose()}
      />
      <ApproveModal
        modalTitle="You are about to approve this profile. Once approved, this profile may be highlighted to users in their dashboard feed."
        warnings={warnings}
        handleModalSave={handleApprovePerson}
        open={approvedModalOpen}
        onModalClose={() => {
          setApprovedModalOpen(false)
        }}
        isLoading={approvedIsLoading}
      />
    </Fragment>
  )
}

PersonEditForm.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

const JobRow = ({ job, idx, onJobEdit }) => {
  const jobFunctions = job?.jobFunctions
    ?.map(jobFunction => {
      return JOB_FUNCTIONS[jobFunction]
    })
    .join(', ')
  return (
    <TableRow>
      <TableCell>{idx + 1}</TableCell>
      <TableCell>{job.title}</TableCell>
      <TableCell>{job.email}</TableCell>
      <TableCell>{job.phone}</TableCell>
      <TableCell>{jobFunctions}</TableCell>
      <TableCell>
        {job.organization ? (
          !job.organization.legacyId ? (
            <Link to={`/platform/organizations/${job.organization.id}/edit`}>
              {job.organization.name}
            </Link>
          ) : (
            job.organization.name
          )
        ) : null}
      </TableCell>
      <TableCell>{job.organization ? ORGANIZATION_TYPES[job.organization.type] : null}</TableCell>
      <TableCell>{job.active ? 'active' : 'inactive'}</TableCell>
      <TableCell>{job.startDate}</TableCell>
      <TableCell>{job.endDate}</TableCell>
      <TableCell>
        <TableActionList>
          <TableAction title="Edit Job" onClick={() => onJobEdit(job, false)}>
            <img src={Edit} alt="Edit Icon" />
          </TableAction>
        </TableActionList>
      </TableCell>
    </TableRow>
  )
}

JobRow.propTypes = {
  job: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  onJobEdit: PropTypes.func.isRequired,
}

export default PersonEditForm
