import React from 'react'

import { useMutation } from 'react-query'
import { Modal, ModalContent, ModalActions } from '@the-platform-group/component-library/Modal'
import { Button } from '@the-platform-group/component-library/Button'
import { Typography } from '@the-platform-group/component-library'
import { useNotification } from 'components/Notification'
import { deleteAssistantSummaryCache } from 'services/assistantSummaries'

const ClearCacheModal = ({
  open,
  onClose,
  selectedCustomer,
  selectedSecurity,
  selectedEntityId,
  selectedEntityType,
}) => {
  const { createNotification } = useNotification()

  const { mutate: deleteCacheMutation, isLoading } = useMutation({
    mutationFn: () => {
      const params = {
        ...(selectedCustomer && { customerId: selectedCustomer.id }),
        ...(selectedSecurity && { securityId: selectedSecurity.id }),
        ...(selectedEntityId &&
          selectedEntityType && { profileId: selectedEntityId, type: selectedEntityType }),
      }
      return deleteAssistantSummaryCache(params)
    },
    onSuccess: () => {
      onClose()
      createNotification({
        message: 'Cache Cleared',
        variant: 'success',
      })
    },
    onError: () => {
      createNotification({
        message: 'Failed to Clear Cache',
        variant: 'error',
      })
    },
  })

  const constructCacheClearMessage = () => {
    const parts = []

    if (selectedEntityType && selectedEntityId) {
      parts.push(<strong key="entityType">selected {selectedEntityType}</strong>)
    }
    if (selectedCustomer) {
      parts.push(`customer account "${selectedCustomer.label}"`)
    }
    if (selectedSecurity) {
      parts.push(`security "${selectedSecurity.label}"`)
    }

    const message =
      parts.length > 0 ? (
        <span>
          Are you sure you want to clear the cache for the{' '}
          {parts.reduce((prev, curr, index) => [...prev, index > 0 ? ', ' : '', curr], [])}?
        </span>
      ) : (
        'Are you sure you want to clear the cache from all customer accounts?'
      )
    return message
  }

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContent>
        <Typography variant="body1">{constructCacheClearMessage()}</Typography>
      </ModalContent>
      <ModalActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={deleteCacheMutation} isLoading={isLoading}>
          Yes, Clear Cache
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default ClearCacheModal
