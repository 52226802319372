import { useState, useEffect, useCallback } from 'react'

import useMarketCapsQuery from 'hooks/queries/useMarketCapsQuery'
import { PERSISTENT_STORAGE_KEY } from 'constants/marketCaps'

function useMarketCapsState() {
  const { data: marketCaps = [], isLoading } = useMarketCapsQuery()
  const [outdatedMarketCaps, setOutdatedMarketCaps] = useState(
    JSON.parse(localStorage.getItem(PERSISTENT_STORAGE_KEY)) || [],
  )

  const hasOutdatedMarketCaps = useCallback(
    function hasOutdatedMarketCaps(codes = []) {
      // avoid side effects if market caps have not been fetched yet
      if (!marketCaps.length) {
        return false
      }

      const marketCapCodes = marketCaps.map(mc => mc.code)
      return codes.some(code => !marketCapCodes.includes(code))
    },
    [marketCaps],
  )

  const updateOutdatedMarketCaps = useCallback(() => {
    localStorage.setItem(PERSISTENT_STORAGE_KEY, JSON.stringify(marketCaps))
    setOutdatedMarketCaps(marketCaps)
  }, [marketCaps])

  useEffect(() => {
    if (!marketCaps.length) {
      return
    }

    if (!outdatedMarketCaps.length) {
      updateOutdatedMarketCaps()
    }
  }, [marketCaps, outdatedMarketCaps, updateOutdatedMarketCaps])

  // this function updates external state, and only does so when
  // there are outdated market cap definitions
  const updateMarketCaps = useCallback(
    ({ state = { marketCaps: [] }, setter = null } = {}) => {
      if (!setter) {
        throw new Error('updateMarketCaps requires a setter function to update state')
      }

      if (!marketCaps.length || !state.marketCaps.length) {
        return
      }

      // avoid mutating state if unnecesary since this is a stateful function
      if (!hasOutdatedMarketCaps(state.marketCaps)) {
        return
      }

      const prunedMarketCapCodes =
        state.marketCaps.filter(code => marketCaps.map(mc => mc.code).includes(code)) || []

      setter(state => ({
        ...state,
        marketCaps: prunedMarketCapCodes,
      }))
    },
    [marketCaps, hasOutdatedMarketCaps],
  )

  // this function updates external state, and only does so when
  // there are outdated market cap definitions
  const updateMarketCap = useCallback(
    ({ state = { marketCap: '' }, setter = null }) => {
      if (!setter) {
        throw new Error('updateMarketCap requires a setter function to update state')
      }

      if (!marketCaps.length || !state.hasOwnProperty('marketCap')) {
        return
      }

      const validMarketCap = marketCaps.map(mc => mc.code).includes(state.marketCap)

      if (!validMarketCap) {
        setter(state => ({ ...state, marketCap: '' }))
      }
    },
    [marketCaps],
  )

  function acknowledgeUpdate() {
    updateOutdatedMarketCaps()
  }

  return {
    marketCaps,
    isLoading,
    outdatedMarketCaps,
    updateMarketCaps,
    updateMarketCap,
    hasOutdatedMarketCaps,
    acknowledgeUpdate,
  }
}

export default useMarketCapsState
