export const buttonStyle = {
  '.MuiButtonBase-root': {
    minWidth: '25px',
    borderRadius: '4px',
    boxShadow: 2,
    width: '25px',
    bgcolor: 'primary.contrastText',
    py: 0.75,
    px: 2,
    height: '30px',
    '&:hover': {
      bgcolor: 'primary.light',
    },
    ' .MuiSvgIcon-root': { color: 'black' },
  },
}
