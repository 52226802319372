import React from 'react'
import { AsyncSelect } from '@the-platform-group/component-library'
import debounce from 'utils/debounce'
import { searchOrganizations } from 'services/organizations'

const debouoncedOrganizationsSearch = debounce(searchOrganizations, 250)

async function loadOrganizations(term, transformFn) {
  if (!term) {
    return []
  }

  const { data } = await debouoncedOrganizationsSearch({ term })
  if (!data) {
    return []
  }
  if (transformFn) {
    return transformFn(data)
  }
  return data
}

function OrganizationSelect({ label, value, onChange, transformFn, ...rest }) {
  return (
    <AsyncSelect
      label={label}
      variant="outlined"
      optionsLoader={term => loadOrganizations(term, transformFn)}
      onChange={onChange}
      value={value}
      {...rest}
    />
  )
}

export default OrganizationSelect
