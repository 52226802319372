import React from 'react'
import PropTypes from 'prop-types'
// components
import { Modal, ModalContent, ModalActions } from '@the-platform-group/component-library/Modal'
import { Button } from '@the-platform-group/component-library/Button'
import { Typography, List, ListItem, Grid } from '@the-platform-group/component-library'

const ApproveModal = ({ modalTitle, warnings, handleModalSave, open, onModalClose, isLoading }) => {
  return (
    <Modal open={open} onClose={onModalClose}>
      <ModalContent>
        <Typography variant="body2">{modalTitle}</Typography>
        {warnings.length > 0 && (
          <List sx={{ pl: 0 }}>
            <ListItem disablePadding>
              <Typography color="error" variant="subtitle2">
                WARNING
              </Typography>
            </ListItem>
            {warnings.map((warning, i) => {
              return (
                <ListItem key={i} dense sx={{ pl: 0, mx: 2 }}>
                  <Grid container>
                    <Grid item>
                      <Typography variant="body2" sx={{ fontWeight: 'fontWeightBold' }}>
                        {warning.title}:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" color="primary.text">
                        {warning.body}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              )
            })}
          </List>
        )}

        <Typography variant="subtitle2">Are you sure you want to continue?</Typography>
      </ModalContent>
      <ModalActions>
        <Button color="secondary" onClick={onModalClose}>
          CANCEL
        </Button>
        <Button color="primary" onClick={handleModalSave} isLoading={isLoading}>
          APPROVE
        </Button>
      </ModalActions>
    </Modal>
  )
}

ApproveModal.propTypes = {
  handleModalSave: PropTypes.func,
  onModalClose: PropTypes.func,
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default ApproveModal
