import React from 'react'
import { Typography } from '@the-platform-group/component-library'

function FactsetStatusText({ status }) {
  let color = 'secondary.dark'
  if (status === 'deleted') {
    color = 'score.veryLow'
  } else if (status === 'unmanaged') {
    color = 'score.low'
  }

  return (
    <Typography color={color} variant="overline">
      {status}
    </Typography>
  )
}

export default FactsetStatusText
