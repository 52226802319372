import React from 'react'
import { Grid, AsyncSelect } from '@the-platform-group/component-library'
import { searchSecurities } from 'services/securities'

async function fetchSecurityOptions(term) {
  try {
    const data = await searchSecurities(term)
    const options = data.map(security => ({
      value: security.id,
      label: security.name,
    }))
    return options
  } catch (error) {
    return []
  }
}

function SecuritiesSearch({ value, setSecurityOptions }) {
  return (
    <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
      <Grid item xs={12}>
        <AsyncSelect
          value={value}
          label="Search for security"
          optionsLoader={fetchSecurityOptions}
          onChange={setSecurityOptions}
          isMulti
        />
      </Grid>
    </Grid>
  )
}

export default SecuritiesSearch
