import React from 'react'
import { Box, Typography } from '@the-platform-group/component-library'
import { DataGrid } from '@the-platform-group/component-library/DataGrid'
// hooks
import { useContactImports } from './hooks/useContactImports'
import useQueryParams from './hooks/useQueryParams'
// Components
import CustomerSelect from 'pages/Users/components/CustomerSelect'
import { getColumnValues, getRowValues } from './ContactImportsImportValues'

const ContactImportsTable = () => {
  const { queryParams, handleQueryParamsChanged } = useQueryParams()
  const { ignored, contactImports, handleContactIgnore, totalCount, isLoading } = useContactImports(
    queryParams,
  )

  const { customer, pageSize, page } = queryParams

  return (
    <>
      <Typography variant="h4" sx={{ fontWeight: 'fontWeightBold' }} gutterBottom>
        Irwin Contacts
      </Typography>
      <CustomerSelect
        value={customer}
        onChange={value =>
          handleQueryParamsChanged({
            customer: value,
          })
        }
        includeClearAll
      />
      <Box sx={{ p: 1.25, mt: 2 }}>
        <DataGrid
          rows={getRowValues(contactImports)}
          columns={getColumnValues({ ignored, handleContactIgnore })}
          loading={isLoading}
          name="contactImports"
          onPageChange={newPage => handleQueryParamsChanged({ page: newPage })}
          onPageSizeChange={newPageSize => handleQueryParamsChanged({ pageSize: newPageSize })}
          page={page}
          pageSize={pageSize}
          rowCount={totalCount}
          pageSizeOptions={[10, 25, 50]}
        />
      </Box>
    </>
  )
}

export default ContactImportsTable
