import React from 'react'

import { useQueryClient, useQuery, useMutation } from 'react-query'

import { useNotification } from 'components/Notification'
import { useConfirmationDialog } from 'components/ConfirmationDialog'

import {
  getCustomerAuthorizations,
  lockCustomerAuthorization,
  unlockCustomerAuthorization,
} from 'services/customerAuthorizations'

import { DataGrid, DataGridActionCell } from '@the-platform-group/component-library/DataGrid'
import {
  Delete as DeleteIcon,
  Restore as RestoreIcon,
} from '@the-platform-group/component-library/Icons'

import USER_SCOPES from 'constants/userScopes'
import USER_ROLES from 'constants/userRoles'

const UsersTable = ({ customerId }) => {
  const { createNotification } = useNotification()
  const { createConfirmationDialog } = useConfirmationDialog()
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery(['customerAuthorizations', customerId], () =>
    getCustomerAuthorizations({ customerId: customerId }),
  )

  const createErrorNotification = err => {
    const detailedErrMessage = err.response.data.errors[0]
    createNotification({
      message: `Something went wrong: ${detailedErrMessage}`,
      variant: 'error',
    })
  }

  const removeCustomerAuthorization = useMutation({
    mutationFn: lockCustomerAuthorization,
    onSuccess: () => {
      createNotification({ message: 'User removed from customer', variant: 'success' })
      return queryClient.invalidateQueries({ queryKey: ['customerAuthorizations', customerId] })
    },
    onError: createErrorNotification,
  })

  const restoreCustomerAuthorization = useMutation({
    mutationFn: unlockCustomerAuthorization,
    onSuccess: () => {
      createNotification({ message: 'User restored to customer', variant: 'success' })
      return queryClient.invalidateQueries({ queryKey: ['customerAuthorizations', customerId] })
    },
    onError: createErrorNotification,
  })

  const scopeField = 'scope'
  const activeField = 'isActive'

  const getRows = customerAuths => {
    return customerAuths
      ? customerAuths.map(({ id, user, scope, lockedAt }) => {
          const { id: userId, email, firstName, lastName } = user
          return {
            id,
            userId,
            name: `${firstName} ${lastName}`,
            email,
            scope,
            isActive: !lockedAt,
          }
        })
      : []
  }

  return (
    <DataGrid
      name="customerUsers"
      pagination={false}
      loading={isLoading}
      disableColumnFilter={false}
      filterMode="client"
      initialState={{
        columns: {
          columnVisibilityModel: {
            scope: false,
          },
        },
        filter: {
          filterModel: {
            items: [
              { id: 1, field: scopeField, value: [USER_SCOPES.user] },
              { id: 2, field: activeField, operator: 'is', value: 'true' },
            ],
          },
        },
      }}
      columns={[
        {
          field: 'name',
          valueGetter: ({ row: { userId }, value }) => ({ to: '/users/' + userId, text: value }),
          type: 'link',
        },
        {
          field: 'email',
          filterable: true,
        },
        {
          field: scopeField,
          filterable: true,
          type: 'singleSelect',
          valueOptions: Object.entries(USER_SCOPES).map(([value, label]) => ({
            value,
            label,
          })),
        },
        {
          field: activeField,
          filterable: true,
          type: 'boolean',
        },
        {
          field: 'actions',
          type: 'actions',
          getActions: ({ row }) => {
            const { id, name, isActive } = row

            if (!isActive) {
              return [
                <DataGridActionCell
                  icon={<RestoreIcon />}
                  title="Restore user to the customer"
                  onClick={() => {
                    createConfirmationDialog({
                      title: `Restore ${name}?`,
                      body: `Are you sure you want to restore ${name} to this customer?`,
                      onConfirm: () => restoreCustomerAuthorization.mutate(id),
                    })
                  }}
                />,
              ]
            } else {
              return [
                <DataGridActionCell
                  icon={<DeleteIcon />}
                  title="Remove user from this customer"
                  onClick={() => {
                    createConfirmationDialog({
                      title: `Remove ${name}?`,
                      body: `Are you sure you want to deactivate the user ${name}? The deactivated user will no longer be able to access this customer account using the Irwin application.`,
                      onConfirm: () => removeCustomerAuthorization.mutate(id),
                    })
                  }}
                />,
              ]
            }
          },
        },
      ]}
      rows={getRows(data)}
    />
  )
}

export default UsersTable
