import React from 'react'
import { Typography, InputLabel } from '@the-platform-group/component-library'
import { TableRow, TableCell } from '@the-platform-group/component-library/Table'

const LabelledField = ({ fieldName, label, fieldValue }) => {
  return (
    <TableRow>
      <TableCell>
        <InputLabel htmlFor={fieldName}>{label}</InputLabel>
      </TableCell>
      <TableCell>
        <Typography id={fieldName} aria-label={label}>
          {fieldValue || '-'}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export default LabelledField
