import { useEffect, useState } from 'react'

import { useNotification } from 'components/Notification'
import { approvePerson } from 'services/people'
import { isEmpty } from '@the-platform-group/formatters/checks'
import { formatDate } from '@the-platform-group/formatters/dateFormat'
import { DATETIME_WITH_TIMEZONE_FORMAT } from 'constants/formats'
import { FEED_VALID_JOB_FUNCTIONS } from 'constants/jobFunctions'
import { FEED_DISABLED_ORG_TYPES } from 'constants/organizationTypes'

const WARNING_MESSAGES = {
  email: {
    title: 'Email',
    body:
      "There is no associated email address with the profile and approving it won't show this record in the feed",
  },
  biography: {
    title: 'Description',
    body:
      "There is no associated biography with the profile and approving it won't show this record in the feed",
  },
  jobFunction: {
    title: 'Job Function',
    body:
      "The job function of this profile is not relevant and approving it won't show this record in the feed",
  },
  jobFocus: {
    title: 'Focus',
    body:
      'There is no job focus for this profile, but if you approve this record, it will show up in the feed',
  },
  orgType: {
    title: 'Organization Type',
    body:
      'Organization type associated with this job will not get added in the feed. They will appear in the application but not in the feed',
  },
}

const usePersonApprove = ({ id, approvedAt, bio, jobs, submit }) => {
  const [approved, setApproved] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [warnings, setWarnings] = useState([])
  const { createNotification } = useNotification()

  useEffect(() => {
    setApproved(formatDate(approvedAt, DATETIME_WITH_TIMEZONE_FORMAT))

    const warningsText = []
    const primaryJob = jobs?.find(job => job.isPrimary === true)

    if (!bio) {
      warningsText.push(WARNING_MESSAGES.biography)
    }

    if (primaryJob) {
      const { email, jobFunctions, jobFocuses, organization } = primaryJob || {}
      if (!email) {
        warningsText.push(WARNING_MESSAGES.email)
      }

      if (jobFunctions) {
        const validJobFuncs = jobFunctions.filter(jobFunc => {
          return FEED_VALID_JOB_FUNCTIONS.includes(jobFunc)
        })
        if (validJobFuncs.length === 0) {
          warningsText.push(WARNING_MESSAGES.jobFunction)
        }
      } else {
        warningsText.push(WARNING_MESSAGES.jobFunction)
      }

      if (jobFocuses?.length === 0) {
        warningsText.push(WARNING_MESSAGES.jobFocus)
      }

      if (FEED_DISABLED_ORG_TYPES.includes(organization?.type)) {
        warningsText.push(WARNING_MESSAGES.orgType)
      }
    }

    setWarnings(warningsText)
  }, [approvedAt, bio, jobs])

  const handleApprovePerson = async () => {
    setIsLoading(true)
    try {
      const { data } = await approvePerson(id)
      setApproved(formatDate(data.approvedAt, DATETIME_WITH_TIMEZONE_FORMAT))
      createNotification({ message: `Person approved` })
      submit()
      setIsLoading(false)
      setModalOpen(false)
    } catch (e) {
      if (e.status === 401 || e.status === 403) {
        throw e
      }
      const data = Object.keys(e.response?.data || {})
      const errors = data.map(err => `${err}: ${e.response?.data[err]}`).join(', ')
      const message = isEmpty(errors) ? 'Person Approve failed' : errors
      createNotification({ message, variant: 'error' })
      setIsLoading(false)
    }
  }

  return {
    warnings,
    approved,
    handleApprovePerson,
    isLoading,
    modalOpen,
    setModalOpen,
  }
}

export default usePersonApprove
