import client, { deserializer } from 'services/client'
import { toSnake } from 'change-object-case'

const URL = 'v2/notification_subscriptions'

function url(subscriptionId = null) {
  if (subscriptionId) {
    return `${URL}/${subscriptionId}`
  } else {
    return URL
  }
}

export async function getNotificationSubscriptions(params) {
  const data = await client().get(url(), { params: toSnake(params) })
  return deserializer.deserialize(data)
}

export async function createNotificationSubscription(params) {
  const data = await client().post(url(), toSnake(params))
  return deserializer.deserialize(data)
}

export async function updateNotificationSubscription(id, params) {
  const data = await client().put(url(id), toSnake(params))
  return deserializer.deserialize(data)
}
