import React from 'react'
import { useMutation, useQueryClient } from 'react-query'

// Components
import { DataGrid } from '@the-platform-group/component-library/DataGrid'
import JobOverrideForm from './components/JobOverrideForm'
import { useNotification } from 'components/Notification'
import { getColumnValues, getRowValues } from './DS1PersonJobsValues'
import { buttonStyle } from './buttonStyle'
import { deleteJobOverride, saveJobOverride } from 'services/jobs'
import { useModal } from 'hooks/useModal'

const DS1PersonJobs = ({ id, jobs }) => {
  const [editingJob, setEditingJob] = React.useState({})
  const { createNotification } = useNotification()
  const queryClient = useQueryClient()

  const setOverride = (jobId, override) =>
    queryClient.setQueryData(['ds1Person', id], oldData => ({
      ...oldData,
      jobs: oldData.jobs.map(j => (j.id === jobId ? { ...j, jobOverride: override } : j)),
    }))

  const { isLoading: isDeleteOverrideLoading, mutate: handleDeleteOverride } = useMutation(
    deleteJobOverride,
    {
      onSuccess: (_, job) => {
        setOverride(job.id, null)
        createNotification({ message: 'Override was deleted' })
      },
      onError: error => {
        createNotification({
          message: `There was a problem with the override deletions. ${error.message}`,
        })
      },
    },
  )

  const { isLoading: isSaveOverrideLoading, mutate: handleJobOverrideSave } = useMutation(
    saveJobOverride,
    {
      onSuccess: ({ data }) => {
        setOverride(data.id, data)
        createNotification({ message: `Override was ${data.id ? 'updated' : 'created'}` })
      },
      onError: error => {
        createNotification({ message: `There was a problem saving the override. ${error.message}` })
      },
    },
  )

  const { open, handleOpen, handleClose: handleModalClose } = useModal()
  const handleModalOpen = job => {
    setEditingJob(job)
    handleOpen()
  }

  const isLoading = isDeleteOverrideLoading || isSaveOverrideLoading
  const rowProps = { isLoading, handleDeleteOverride, handleModalOpen }

  return (
    <>
      <DataGrid
        rows={getRowValues(jobs, rowProps)}
        columns={getColumnValues()}
        commonColumnProps={{ headerAlign: 'center' }}
        loading={isLoading}
        name="ds1PersonJobs"
        hideFooter
        localeText={{
          noRowsLabel: 'Sorry there are no jobs for this person',
        }}
        pagination={false}
        rowCount={jobs.length}
        sx={{
          '& .MuiDataGrid-actionsCell': buttonStyle,
        }}
      />
      {open && (
        <JobOverrideForm
          isLoading={isLoading}
          job={editingJob}
          open={open}
          onJobOverrideSave={handleJobOverrideSave}
          onModalClose={handleModalClose}
        />
      )}
    </>
  )
}

export default DS1PersonJobs
