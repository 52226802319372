import React from 'react'

import { TextField, Grid } from '@the-platform-group/component-library'
import CustomerSelect from './CustomerSelect'

function UserFilters({ handleUserNameSearch, handleCustomerSearch, customers }) {
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={6}>
        <TextField
          onChange={handleUserNameSearch}
          placeholder="Search by first name, last name, or email"
          name="user"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <CustomerSelect value={customers} onChange={handleCustomerSearch} isMulti={true} />
      </Grid>
    </Grid>
  )
}

export default UserFilters
