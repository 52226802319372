import { CUSTOMER_ID } from 'constants/localStorage'

export const USER_ROLE = 'user'
export const ADMIN_ROLE = 'admin'
export const ANALYST_ROLE = 'analyst'
export const ANALYST_SURVEILLANCE_ROLE = 'analyst_surveillance'

export const ROLES = [USER_ROLE, ADMIN_ROLE, ANALYST_ROLE, ANALYST_SURVEILLANCE_ROLE]

export function isAdmin(user) {
  if (!user) {
    return false
  }

  return user.role === ADMIN_ROLE
}

export function isAnalyst(user) {
  if (!user) {
    return false
  }

  return user.role === ANALYST_ROLE
}

export function isAnalystSurveillance(user) {
  if (!user) {
    return false
  }

  return user.role === ANALYST_SURVEILLANCE_ROLE
}

export function isRoleAllowed(user) {
  if (!user) {
    return false
  }

  return isAnalyst(user) || isAnalystSurveillance(user) || isAdmin(user) || false
}

export const auth0Config = {
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  redirectUri: window.location.origin,
  audience: 'https://api.imirwin.com/',
}

// timeouts coming from auth0 will have an 'error' prop of 'timeout'
// https://github.com/auth0/auth0-spa-js/blob/2ebbe54037324181028611d0e9623bcabd4658f8/src/errors.ts#L43
// unfortunately we can't perform an instanceof TimeoutError since react will recreate this error with an
// Error prototype
export const isAuth0Timeout = error => error?.error === 'timeout'

/** ************************************************
 * NOTE: the following functions are migrated & simplified from @irwin-fe src/contexts/utils.js
 * ************************************************ */
/**
 * @param {Array} customerAuth list of customers
 * @returns a customer within the list that has the oldest creation date & is  NOT locked out (e.g. @locked_at is null)
 */
const getCurrentCustomerAuth = (customerAuth = []) => {
  return customerAuth.reduce((prevCustomer, currentCustomer) => {
    if (!currentCustomer.lockedAt && currentCustomer.scope === 'user') {
      // proceed if current customer is NOT locked & scope is user (NO assistant)
      if (!prevCustomer.createdAt) {
        return currentCustomer
      }
      const currentCreationDate = new Date(currentCustomer.createdAt)
      const prevCreationDate = new Date(prevCustomer.createdAt)
      // keep the customer record that's created earlier
      return currentCreationDate < prevCreationDate ? currentCustomer : prevCustomer
    }
    return prevCustomer
  }, {})
}

/**
 * Save customer ID in localStorage to keep track of the most recently logged-in customer ID
 * this value will persist across multiple browser tabs
 */
function saveCustomerIdFromCustomerAuth(customerAuth = []) {
  const { customer } = getCurrentCustomerAuth(customerAuth)
  if (customer?.id) {
    localStorage.setItem(CUSTOMER_ID, customer.id) // save customer ID so it can be added to API custom header
  }
}

/**
 * @returns current customer ID stored in localStorage
 */
function getCustomerId() {
  return localStorage.getItem(CUSTOMER_ID)
}

export { saveCustomerIdFromCustomerAuth, getCustomerId }
