import { useState, useEffect, forwardRef, useImperativeHandle, useCallback } from 'react'

// Tables
import {
  Table,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableRow,
} from '@the-platform-group/component-library/Table'
import { Code as CodeIcon, Edit as EditIcon } from '@the-platform-group/component-library/Icons'

// hooks
import useHealthCheck from '../hooks/useHealthCheck'

// Components
import {
  Typography,
  IconButton,
  Tooltip,
  Grid,
  Box,
  useTheme,
} from '@the-platform-group/component-library'
import { Circle as CircleIcon } from '@the-platform-group/component-library/Icons'

import { getSites } from 'services/customerWebsites'
import { formatDate } from '@the-platform-group/formatters/dateFormat'

const SiteTable = forwardRef((props, ref) => {
  const fetchData = useCallback(async () => {
    const value = await getSites({ customerId: props.customerId })
    setSiteCollection(value.data)
  }, [props.customerId])

  //allows us to call the fetchData method from a ref
  useImperativeHandle(
    ref,
    () => ({
      fetchData,
    }),
    [fetchData],
  )
  const [siteCollection, setSiteCollection] = useState([])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeaderCell>Site Id</TableHeaderCell>
          <TableHeaderCell>Name (URL)</TableHeaderCell>
          <TableHeaderCell>Security</TableHeaderCell>
          <TableHeaderCell>Snippet</TableHeaderCell>
          <TableHeaderCell>Created</TableHeaderCell>
          <TableHeaderCell>Status</TableHeaderCell>
          <TableHeaderCell>Options</TableHeaderCell>
        </TableRow>
      </TableHead>

      <TableBody
        columns={7}
        isLoading={!siteCollection}
        isEmpty={!siteCollection?.length}
        renderEmpty={<Typography>This customer currently has no sites provisioned</Typography>}
      >
        {siteCollection?.map(site => (
          <TableRow key={site?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">
              {site?.id}
            </TableCell>
            <TableCell>{site?.name}</TableCell>
            <TableCell>
              {site?.security
                ? `${site.security.name} ${site.security.listingPrimary?.tickerExchange}`
                : ''}
            </TableCell>
            <TableCell>
              <Tooltip title="View Code Snippet">
                <IconButton aria-label="View Code Snippet" onClick={() => props.showSnippet(site)}>
                  <CodeIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
            <TableCell>{formatDate(new Date(site?.createdAt), 'PP')}</TableCell>
            <TableCell>
              <HealthCheck site={site} />
            </TableCell>
            <TableCell>
              <Tooltip title="Edit Site Details">
                <IconButton aria-label="Edit Site Details" onClick={() => props.showEditSite(site)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
})

const HealthCheck = ({ site }) => {
  const {
    status,
    isLoading,
    executeNewSiteHealthCheck,
    refreshLastSiteHealthCheck,
    lastUpdated,
  } = useHealthCheck(site)

  const theme = useTheme()
  const statusColor = getStatusColor({ status, theme, isLoading })
  const disabled = status === 'pending' || isLoading
  const title =
    status === 'unavailable' ? 'Health check has never been run' : `Last Checked: ${lastUpdated}`

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <Tooltip
          title={
            <>
              <Typography variant="body2">{title}</Typography>
              <br />
              <Typography variant="body2" align="center">
                Click icon to run a new check
              </Typography>
            </>
          }
          placement="top"
          arrow
        >
          <Box component="span">
            <IconButton
              onClick={async () => {
                await executeNewSiteHealthCheck()
                refreshLastSiteHealthCheck()
              }}
              disabled={disabled}
            >
              <CircleIcon
                sx={{ color: statusColor, stroke: `${statusColor}88`, strokeWidth: '2px' }}
              />
            </IconButton>
          </Box>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

const getStatusColor = ({ status, theme, isLoading }) => {
  const COLORS = {
    unavailable: theme.palette.status.unavailable.text,
    success: theme.palette.status.success.light.background,
    failed: theme.palette.status.failure.background,
    pending: theme.palette.status.caution.background,
  }

  if (isLoading) {
    return COLORS['pending']
  }

  switch (status) {
    case 'success':
      return COLORS['success']
    case 'failed':
      return COLORS['failed']
    default:
      return COLORS['unavailable']
  }
}

export default SiteTable
