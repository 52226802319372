import { client, deserializer } from 'services/client'
import { toSnake } from 'change-object-case'

const baseUrl = '/v2/customer_authorizations'

export async function getCustomerAuthorizations(params = {}) {
  const response = await client().get(baseUrl, { params: toSnake(params) })
  return deserializer.deserialize(response)
}

export async function createCustomerAuthorization(params = {}) {
  const requestBody = toSnake(params)
  const response = await client().post(baseUrl, requestBody)
  return deserializer.deserialize(response)
}

export async function lockCustomerAuthorization(customerAuthorizationId) {
  const response = await client().put(`/v2/customer_authorizations/${customerAuthorizationId}/lock`)
  return deserializer.deserialize(response)
}

export async function unlockCustomerAuthorization(customerAuthorizationId) {
  const response = await client().put(
    `/v2/customer_authorizations/${customerAuthorizationId}/unlock`,
  )
  return deserializer.deserialize(response)
}
