import React, { useReducer } from 'react'

export default function createDataContext(reducer, initialState) {
  const Context = React.createContext()

  const Provider = props => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const value = [state, dispatch]

    return (
      <Context.Provider value={value} {...props}>
        {props.children}
      </Context.Provider>
    )
  }

  return { Context, Provider }
}
