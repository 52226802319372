import React, { useEffect, useContext } from 'react'
import { Button } from '@the-platform-group/component-library/Button'

import { FactsetModalActionsContext } from '../Context/FactsetModalActionsContext'

const LAST_STEP = 1

/**
 * @param {object} param0 - component props
 * @param {boolean} param0.isModalOpen - is the parent modal open
 * @param {function} param0.closeModal - function that closes the parent modal
 * @param {string} param0.type - entity type "managed|unmanaged"
 * @param {function} param0.handleSubmit - react hook form submit form callback
 * @param {function} param0.resetForm - react hook form reset form
 * @param {boolean} param0.isSaving - isSaving flag for saving form values = mutation.isLoading
 * @param {function} param0.onSaveForm - saving form values = mutation.mutate
 * @param {function} param0.resetMutation - reset form values = mutation.mutate
 * @returns {Node} action buttons that direct user through the factset form steps
 */
function ModalStepsActions({
  isModalOpen,
  closeModal,
  type,
  handleSubmit,
  resetForm,
  isSaving,
  onSaveForm,
  resetMutation,
}) {
  const { currentStep, gotoFirstStep, gotoPrevStep, gotoNextStep } = useContext(
    FactsetModalActionsContext,
  )

  useEffect(() => {
    // Effect triggered when form goes back to first page
    if (currentStep === 0) {
      resetMutation()
      resetForm()
    }
  }, [currentStep, resetForm, resetMutation])

  useEffect(() => {
    if (!isModalOpen) {
      // reset the form & step when the modal closes
      gotoFirstStep()
      resetForm()
    }
  }, [gotoFirstStep, isModalOpen, resetForm])

  return (
    <>
      <Button disabled={isSaving} variant="text" onClick={closeModal}>
        Cancel
      </Button>
      {currentStep > 0 && (
        <Button disabled={isSaving} variant="outlined" onClick={gotoPrevStep}>
          Previous
        </Button>
      )}
      {currentStep < LAST_STEP && (
        <Button disabled={!type} onClick={gotoNextStep}>
          Next
        </Button>
      )}
      {currentStep === LAST_STEP && (
        <Button isLoading={isSaving} onClick={handleSubmit(onSaveForm)}>
          Save
        </Button>
      )}
    </>
  )
}

export default ModalStepsActions
