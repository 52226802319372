import { useState } from 'react'

const useJobOverrideForm = (job, onJobOverrideSave, onModalClose) => {
  const { id: jobId, jobOverride: override } = job
  const [overrides, setOverrides] = useState({
    ...override,
    jobId,
  })

  const handleJobOverride = ({ target }) => {
    setOverrides({
      ...overrides,
      [target.name]: target.value,
    })
  }

  const handleOverrideSave = async () => {
    try {
      await onJobOverrideSave(overrides)
      onModalClose()
    } catch (error) {
      throw error
    }
  }

  return {
    overrides,
    handleJobOverride,
    handleOverrideSave,
  }
}

export default useJobOverrideForm
