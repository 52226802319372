import { useContext } from 'react'
import { SearchInputDispatchContext } from '../contexts/SearchInputContext'

function useSearchInputWrite() {
  const dispatchContext = useContext(SearchInputDispatchContext)

  if (!dispatchContext) {
    throw new Error('useSearchInput must be used within a SearchInputDispatchContext.Provider')
  }

  const [dispatch] = dispatchContext

  const setSearchInput = inputValue => {
    dispatch({ type: 'AFFILIATION:SEARCH_INPUT_SET', payload: inputValue })
  }

  const setAffiliatedId = () => {
    dispatch({ type: 'AFFILIATION:AFIILIATED_ID_SET' })
  }

  return {
    setSearchInput,
    setAffiliatedId,
  }
}

export default useSearchInputWrite
