import { useQuery } from 'react-query'
import { getMarketCaps } from 'services/marketCap'

export const QUERY_KEY = 'marketCaps'

function useMarketCapsQuery(options = {}) {
  return useQuery(QUERY_KEY, getMarketCaps, {
    useErrorBoundary: true,
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
  })
}

export default useMarketCapsQuery
