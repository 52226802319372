import React from 'react'
import PropTypes from 'prop-types'
import { Chip as RawChip } from '@the-platform-group/component-library'

const Chip = ({ label, ...rest }) => (
  <RawChip
    label={label}
    color="primary"
    sx={{
      margin: 1,
    }}
    {...rest}
  />
)

Chip.propTypes = {
  label: PropTypes.string.isRequired,
}

export default Chip
