import { Box, Typography } from '@the-platform-group/component-library'
import DeleteReport from './DeleteReport'

const ReportTitleCell = ({ title, id }) => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gap={2}
    >
      <Typography
        variant="subtitle2"
        sx={{
          color: 'primary.text',
          fontWeight: 'bold',
        }}
      >
        {title}
      </Typography>
      <DeleteReport title={title} id={id} />
    </Box>
  )
}

export default ReportTitleCell
