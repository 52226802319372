import { UnleashClient } from 'unleash-proxy-client'
import { FEATURE_FLAG_CONFIG } from 'config/featureFlags'

var _unleashClient = null

// Return the singleton UnleashClient instance, used to access GitLab feature flags
// (within a React component, you should use useFlag() instead)
const unleashClient = () => {
  if (!_unleashClient) {
    _unleashClient = new UnleashClient(FEATURE_FLAG_CONFIG)
  }

  return _unleashClient
}

export default unleashClient
