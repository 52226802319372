function transformHolding(holding) {
  const {
    name,
    security,
    reportedDate,
    reportedShares,
    reportedPreviousShares,
    reportedType,
    surveilledDate,
    surveilledShares,
    type,
    id,
    holderId,
  } = holding

  const currentShares = reportedShares

  const { price } = security

  const marketValue = {
    current: currentShares * price,
    previous: reportedPreviousShares * price,
  }

  const date = reportedDate || surveilledDate

  return {
    id,
    name,
    reportedDate: date,
    currentShares,
    currentValue: marketValue.current,
    previousShares: reportedPreviousShares,
    previousValue: marketValue.previous,
    source: reportedType,
    price,
    type,
    surveilledShares,
    isNewHolder: reportedShares === null,
    holderId,
  }
}

const transformHoldings = holdings =>
  holdings
    .map(transformHolding)
    .filter(({ currentShares, isNewHolder }) => Boolean(currentShares !== null || isNewHolder))

export default transformHoldings
