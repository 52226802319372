import React from 'react'
import { Typography } from '@the-platform-group/component-library'

const HeaderCell = ({ children }) => (
  <Typography color="primary.text" sx={{ fontWeight: 'fontWeightBold' }} variant="body2">
    {children}
  </Typography>
)

export default HeaderCell
