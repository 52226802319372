import { useEffect, useState, useCallback } from 'react'

import useMarketCapsState from 'hooks/marketCaps/useMarketCapsState'
import { toMillions, toBillions } from '@the-platform-group/formatters/number'

function currencyLabel(n) {
  const amount = Number(n)
  const exp = amount.toExponential().split('+')[1]

  if (exp >= 9 && exp < 12) {
    return `$${toBillions(amount)}B`
  } else if (exp >= 6) {
    return `$${toMillions(amount)}M`
  }

  return `$${amount}`
}

export function labelFromRange(range) {
  const [lowerBound, upperBound] = range.split('...')

  if (upperBound === 'Infinity') {
    return `Above ${currencyLabel(lowerBound)} USD`
  }

  return `${currencyLabel(lowerBound)} to ${currencyLabel(upperBound)} USD`
}

function useMarketCapsPresentational() {
  const { marketCaps } = useMarketCapsState()
  const [optionsMap, setOptionsMap] = useState(new Map())

  const findLabel = useCallback(
    function(code) {
      if (!marketCaps.length) {
        return ''
      }

      const marketCap = marketCaps.find(marketCap => marketCap.code === code)
      return marketCap.label
    },
    [marketCaps],
  )

  useEffect(() => {
    if (!marketCaps.length) {
      return
    }

    const currentOptionsMap = marketCaps.reduce((acc, { code }) => {
      acc.set(code, findLabel(code))
      return acc
    }, new Map())

    setOptionsMap(currentOptionsMap)
  }, [marketCaps, findLabel])

  const sortedOptionKeys = Array.from(optionsMap).map(o => o[0])

  const getLabel = code => optionsMap.get(code)

  const tooltips = marketCaps.reduce((acc, { code }) => ({ [code]: findTooltip(code), ...acc }), {})

  const stringifiedOptions = Array.from(optionsMap.values())
    .map(label => `${label} Cap`)
    .join(', ')

  function findTooltip(code) {
    if (!marketCaps.length) {
      return ''
    }

    const marketCap = marketCaps.find(marketCap => marketCap.code === code)
    return labelFromRange(marketCap.range)
  }

  return {
    optionsMap,
    tooltips,
    stringifiedOptions,
    sortedOptionKeys,
    getLabel,
    findLabel,
    findTooltip,
  }
}

export default useMarketCapsPresentational
