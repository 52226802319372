import { useState, useEffect } from 'react'
import {
  fetchFactsetSecurities,
  deleteOverride,
  saveOverride as saveOverrideService,
} from 'services/securities'
import focuses from 'constants/focus'

async function getSecurities({ setIsLoading, setSecurities, setPagination, ids = [] }) {
  try {
    setIsLoading(true)
    const { data, next, totalCount } = await fetchFactsetSecurities(ids)
    setSecurities(data)
    setPagination({ next, totalCount })
    setIsLoading(false)
  } catch (error) {
    setSecurities(() => {
      throw error
    })
  }
}

function useSecuritySearch() {
  const [term, setTerm] = useState()
  const [securities, setSecurities] = useState([])
  const [securityOptions, setSecurityOptions] = useState([])
  const [selectedSecurity, setSelectedSecurity] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [pagination, setPagination] = useState({ totalCount: 0 })

  useEffect(() => {
    const securityIds = securityOptions ? securityOptions.map(security => security.value) : []
    getSecurities({ setIsLoading, setSecurities, setPagination, ids: securityIds })
  }, [securityOptions, setIsLoading, setSecurities, setPagination])

  async function fetchNext() {
    try {
      setIsLoading(true)
      const securityIds = securityOptions.map(security => security.value)
      const { data, next, totalCount } = await fetchFactsetSecurities(securityIds, pagination.next)
      setSecurities([...securities, ...data])
      setPagination({ next, totalCount })
      setIsLoading(false)
    } catch (error) {
      setSecurities(() => {
        throw error
      })
    }
  }

  async function removeOverride({ issuerLegacyId }) {
    try {
      setIsLoading(true)
      await deleteOverride({ issuerLegacyId })

      // refresh after deletion to retrieve original sector/subsector
      const securityIds = securityOptions.map(security => security.value)
      getSecurities({ setIsLoading, setSecurities, setPagination, ids: securityIds })

      setIsLoading(false)
    } catch (error) {
      setSecurities(() => {
        throw error
      })
    }
  }

  async function saveOverride({ sectorSelected, subSectorSelected, selectedSecurity }) {
    try {
      setIsLoading(true)
      const response = await saveOverrideService({
        ficsSectorCode: focuses.SECTOR_SUBSECTOR_PICS_FICS[sectorSelected][sectorSelected],
        ficsSubSectorCode: focuses.SECTOR_SUBSECTOR_PICS_FICS[sectorSelected][subSectorSelected],
        sectorCode: sectorSelected,
        subsectorCode: subSectorSelected,
        issuer: selectedSecurity.issuer,
        existing:
          selectedSecurity.issuer.sectorOverride &&
          selectedSecurity.issuer.sectorOverride.sectorCode,
      })
      // Manually override security
      const updatedSecurities = securities.map(security => {
        if (security.id === selectedSecurity.id) {
          return {
            ...selectedSecurity,
            sectorCode: sectorSelected,
            subsectorCode: subSectorSelected,
            issuer: { ...selectedSecurity.issuer, sectorOverride: response },
          }
        }
        return security
      })
      setSecurities(updatedSecurities)
      setIsLoading(false)
    } catch (error) {
      setSecurities(() => {
        throw error
      })
    }
  }

  return {
    term,
    setTerm,
    selectedSecurity,
    setSelectedSecurity,
    securities,
    pagination,
    setSecurities,
    isLoading,
    securityOptions,
    setSecurityOptions,
    fetchNext,
    removeOverride,
    saveOverride,
  }
}

export default useSecuritySearch
