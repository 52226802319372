import { Link } from 'react-router-dom'
import { Box, styled, Typography } from '@the-platform-group/component-library'
import ToggleSubscriptionSwitch from '../ToggleSubscriptionSwitch'

const StyledBox = styled(Box)(() => ({ display: 'flex', flexDirection: 'column' }))

export const getColumnValues = () => {
  return [
    {
      field: 'customer',
      cellClassName: 'hoverCell',
      renderCell: ({ row }) => {
        const { id, name } = row.customer
        return (
          <StyledBox>
            <Link to={`/accounts/customers/${id}`}>{name}</Link>
            <Typography variant="body2" component="span">
              {id}
            </Typography>
          </StyledBox>
        )
      },
    },
    { field: 'groupName' },
    { field: 'securities' },
    { field: 'sources' },
    {
      field: 'enabled',
      width: 180,
      flex: null,
      renderCell: ({ row }) => {
        return <ToggleSubscriptionSwitch {...row.groupSub} />
      },
    },
  ]
}

export const getRowValues = rowValues => {
  return rowValues.map(row => {
    const { id, groupName, customer, securities, sources, enabled, groupSub } = row
    return {
      id: id,
      customer: {
        id: customer.id,
        name: customer.name,
      },
      groupName,
      securities,
      sources,
      enabled,
      groupSub,
    }
  })
}
