import client, { deserializer } from 'services/client'
import { toSnake, toCamel } from 'change-object-case'

const baseUrl = '/v2/ds1'

export async function getFactsetLocations(params = {}) {
  const response = await client().get(`${baseUrl}/locations`, { params: toSnake(params) })
  return {
    data: deserializer.deserialize(response),
    meta: toCamel(response.meta),
  }
}

export async function getFactsetLocationById(id) {
  const response = await client().get(`${baseUrl}/locations/${id}`)
  return {
    data: deserializer.deserialize(response),
    meta: toCamel(response.meta),
  }
}

export async function createFactsetLocation(formData) {
  const data = await client().post(`${baseUrl}/locations`, toSnake(formData, { recursive: true }))
  return deserializer.deserialize(data)
}

export async function updateFactsetLocation({ id, ...rest }) {
  const data = await client().put(`${baseUrl}/locations/${id}`, toSnake(rest, { recursive: true }))
  return deserializer.deserialize(data)
}

export async function linkFactsetLocation(factsetLocationId, customerId) {
  const response = await client().post(`${baseUrl}/locations/${factsetLocationId}/link`, {
    customer_id: customerId,
  })
  return deserializer.deserialize(response)
}

export async function unlinkFactsetLocation(factsetLocationId) {
  const response = await client().delete(`${baseUrl}/locations/${factsetLocationId}/link`)
  return deserializer.deserialize(response)
}

export async function deleteFactsetLocation(id) {
  const response = await client().delete(`${baseUrl}/locations/${id}`)
  return deserializer.deserialize(response)
}

export async function getFactsetUsers(params = {}) {
  const response = await client().get(`${baseUrl}/users`, { params: toSnake(params) })
  return {
    data: deserializer.deserialize(response),
    meta: toCamel(response.meta),
  }
}

export async function getFactsetUserById(id) {
  const data = await client().get(`${baseUrl}/users/${id}`)
  return deserializer.deserialize(data)
}

export async function deleteFactsetUser(id) {
  const response = await client().delete(`${baseUrl}/users/${id}`)
  return deserializer.deserialize(response)
}

export async function createFactsetUser(params = {}) {
  const response = await client().post(
    'v2/ds1/users',
    toSnake(params, { recursive: true, arrayRecursive: true }),
  )
  return deserializer.deserialize(response)
}

export async function updateFactsetUser({ id, ...rest }) {
  const data = await client().put(`${baseUrl}/users/${id}`, toSnake(rest, { recursive: true }))
  return deserializer.deserialize(data)
}

export async function linkFactsetUser(factsetUserId, irwinUserId) {
  const response = await client().post(`${baseUrl}/users/${factsetUserId}/link`, {
    user_id: irwinUserId,
  })
  return deserializer.deserialize(response)
}

export async function unlinkFactsetUser(factsetUserId) {
  const response = await client().delete(`${baseUrl}/users/${factsetUserId}/link`)
  return deserializer.deserialize(response)
}

export async function getFactsetMetadata() {
  const response = await client().get(`${baseUrl}/metadata`)
  return deserializer.deserialize(response)
}

export async function retryFactsetAPIRequest(requestId) {
  const response = await client().post(`${baseUrl}/api_requests/${requestId}/retry`)
  return deserializer.deserialize(response)
}
