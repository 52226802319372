import React from 'react'

// Components
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@the-platform-group/component-library/Modal'
import { TextField } from '@the-platform-group/component-library'
import { Button } from '@the-platform-group/component-library/Button'
import { Select } from '@the-platform-group/component-library/Select'
import useJobOverrideForm from 'pages/Ds1People/hooks/useJobOverrideForm'

const JobOverrideForm = ({ job, open, onJobOverrideSave, onModalClose, isLoading }) => {
  const { overrides, handleJobOverride, handleOverrideSave } = useJobOverrideForm(
    job,
    onJobOverrideSave,
    onModalClose,
  )
  const { email, phone, active } = overrides
  const options = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ]

  return (
    <Modal open={open} onClose={onModalClose}>
      <ModalTitle title="Override Job Details" />
      <ModalContent>
        <Select
          allowEmpty
          fullWidth
          id="active"
          name="active"
          label="Override Active"
          options={options}
          onChange={handleJobOverride}
          SelectProps={{ renderValue: selected => options.find(o => o.value === selected).label }}
          value={active}
        />
        <TextField
          name="email"
          label="Override Email"
          onChange={handleJobOverride}
          value={email || ''}
          sx={{
            mt: 1,
            mb: 0.5,
          }}
          fullWidth
        />
        <TextField
          name="phone"
          label="Override Phone"
          onChange={handleJobOverride}
          value={phone || ''}
          sx={{
            mt: 1,
            mb: 0.5,
          }}
          fullWidth
        />
        <p>
          <strong>FDS Active: </strong>
          {job.active.toString()}
        </p>
        <p>
          <strong>FDS Email:</strong> {job.email || '-'}
        </p>
        <p>
          <strong>FDS Phone:</strong> {job.phone || '-'}
        </p>
        <p>
          <strong>Title: </strong>
          {job.title || '-'}
        </p>
        <p>
          <strong>Job Function(s): </strong> {job.jobFunctions ? job.jobFunctions.join(', ') : '-'}
        </p>
        <p>
          <strong>Start Date:</strong> {job.startDate || '-'}
        </p>
        <p>
          <strong>End Date:</strong> {job.endDate || '-'}
        </p>
      </ModalContent>
      <ModalActions>
        <Button onClick={handleOverrideSave} isLoading={isLoading}>
          Save
        </Button>
      </ModalActions>
    </Modal>
  )
}
export default JobOverrideForm
