import { createContext, useCallback, useContext, useReducer } from 'react'
import { PARENT_ORGANIZATION_KEY } from 'constants/organizationTypes'
import merge from 'utils/merge'

export const ORGANIZATION_FORM_TYPE = { PARENT: 'parent', CHILD: 'regular' }

const DEFAULT_RESET_STATE = {
  internalNote: '',
  isDirty: false,
  deletedChildOrganizations: {},
  newChildOrganizations: [],
}

const INITIAL_CONTEXT = {
  organizationFormType: ORGANIZATION_FORM_TYPE.CHILD,
  isParentOrgForm: false,
  organization: { address: {}, coverage: {}, focuses: [] },
  updatedFocuses: [],
  ...DEFAULT_RESET_STATE,
}

export const OrganizationFormActionTypes = {
  CHILD_ORGANIZATION_ADD_NEW: 'CHILD_ORGANIZATION_ADD_NEW',
  CHILD_ORGANIZATIONS_LINK: 'CHILD_ORGANIZATIONS_LINK',
  CHILD_ORGANIZATION_REMOVE: 'CHILD_ORGANIZATION_REMOVE',
  CHILD_ORGANIZATIONS_UNLINK: 'CHILD_ORGANIZATIONS_UNLINK',
  ORGANIZATION_FORM_RESET_STATE: 'ORGANIZATION_FORM_RESET_STATE',
  ORGANIZATION_FORM_SET_STATE: 'ORGANIZATION_FORM_SET_STATE',
  ORGANIZATION_FORM_SET_STATE_BY_NAME: 'ORGANIZATION_FORM_SET_STATE_BY_NAME',
  ORGANIZATION_FORM_TOGGLE_TYPE: 'ORGANIZATION_FORM_TOGGLE_TYPE',
}

const OrganizationFormContext = createContext(INITIAL_CONTEXT)

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case OrganizationFormActionTypes.CHILD_ORGANIZATION_ADD_NEW:
      return {
        ...state,
        newChildOrganizations: [payload].concat(state.newChildOrganizations),
        isDirty: true,
      }
    case OrganizationFormActionTypes.CHILD_ORGANIZATIONS_LINK:
      return { ...state, newChildOrganizations: payload, isDirty: true }
    case OrganizationFormActionTypes.CHILD_ORGANIZATION_REMOVE:
      return {
        ...state,
        newChildOrganizations: state.newChildOrganizations.filter(org => org.id !== payload),
        isDirty: true,
      }
    case OrganizationFormActionTypes.CHILD_ORGANIZATIONS_UNLINK:
      return {
        ...state,
        deletedChildOrganizations: { ...state.deletedChildOrganizations, [payload]: true },
        isDirty: true,
      }
    case OrganizationFormActionTypes.ORGANIZATION_FORM_RESET_STATE:
      return { ...state, ...DEFAULT_RESET_STATE }
    case OrganizationFormActionTypes.ORGANIZATION_FORM_SET_STATE:
      return merge(
        {},
        state,
        payload,
        // when organization type is parent, set organizationFormType to PARENT
        payload.organization.type === PARENT_ORGANIZATION_KEY && {
          organizationFormType: ORGANIZATION_FORM_TYPE.PARENT,
          isParentOrgForm: true,
        },
      )
    case OrganizationFormActionTypes.ORGANIZATION_FORM_SET_STATE_BY_NAME:
      return merge({}, state, payload, { isDirty: true })
    case OrganizationFormActionTypes.ORGANIZATION_FORM_TOGGLE_TYPE:
      return {
        ...state,
        ...INITIAL_CONTEXT,
        organizationFormType: payload,
        // when organization type is parent, pre-fill organization.type to irwin_ultimate_parent
        ...(payload === ORGANIZATION_FORM_TYPE.PARENT && {
          organization: { ...INITIAL_CONTEXT.organization, type: PARENT_ORGANIZATION_KEY },
          isParentOrgForm: true,
        }),
      }
    default:
      return state
  }
}

export const OrganizationFormContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_CONTEXT)

  return (
    <OrganizationFormContext.Provider value={{ state, dispatch }}>
      {children}
    </OrganizationFormContext.Provider>
  )
}

export const useOrganizationFormContext = () => {
  const { state, dispatch } = useContext(OrganizationFormContext)
  if (state === undefined || dispatch === undefined) {
    throw new Error(
      'useOrganizationFormContext must be used within a OrganizationFormContextProvider',
    )
  }

  // wrap in a callback since this is one of useEffect dependencies
  const handleOrganizationFormTypeChange = useCallback(
    payload =>
      dispatch({ type: OrganizationFormActionTypes.ORGANIZATION_FORM_TOGGLE_TYPE, payload }),
    [dispatch],
  )

  const handleSetStateByName = payload =>
    dispatch({ type: OrganizationFormActionTypes.ORGANIZATION_FORM_SET_STATE_BY_NAME, payload })

  const handleChildOrganizationsUpdate = (payload, isNew) => {
    const type = isNew
      ? OrganizationFormActionTypes.CHILD_ORGANIZATIONS_LINK
      : OrganizationFormActionTypes.CHILD_ORGANIZATIONS_UNLINK
    dispatch({ type, payload })
  }

  const handleAddNewChildOrganization = payload =>
    dispatch({ type: OrganizationFormActionTypes.CHILD_ORGANIZATION_ADD_NEW, payload })

  const handleRemoveNewChildOrganization = payload =>
    dispatch({ type: OrganizationFormActionTypes.CHILD_ORGANIZATION_REMOVE, payload })

  const handleSetOrganizationFormState = useCallback(
    payload => dispatch({ type: OrganizationFormActionTypes.ORGANIZATION_FORM_SET_STATE, payload }),
    [dispatch],
  )
  const handleResetOrganizationFormState = () =>
    dispatch({ type: OrganizationFormActionTypes.ORGANIZATION_FORM_RESET_STATE })

  return {
    state,
    handleAddNewChildOrganization,
    handleChildOrganizationsUpdate,
    handleOrganizationFormTypeChange,
    handleRemoveNewChildOrganization,
    handleResetOrganizationFormState,
    handleSetOrganizationFormState,
    handleSetStateByName,
  }
}
