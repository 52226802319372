import React from 'react'

import { UPLOAD_STATUS } from '../utils/constants'
import { humanizeSnakeCase } from 'utils/format'

import { Chip } from '@the-platform-group/component-library'
import { DropdownButton } from '@the-platform-group/component-library/Button'

const getStatusActionOptions = (row, onStatusChange) => {
  return UPLOAD_STATUS.map(option => ({
    label: <Chip label={humanizeSnakeCase(option.status)} sx={{ backgroundColor: option.color }} />,
    value: option.status,
    onClick: () => onStatusChange({ ...row, status: option.status }),
  }))
}

const StatusDropdown = ({ row, onStatusChange }) => (
  <DropdownButton
    sx={{ color: 'black' }}
    size="small"
    popperProps={{ disablePortal: false }}
    options={getStatusActionOptions(row, onStatusChange)}
    label={humanizeSnakeCase(row.status)}
  />
)

export default StatusDropdown
