import React, { useState } from 'react'

import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@the-platform-group/component-library/Modal'
import { Button } from '@the-platform-group/component-library/Button'
import { Select, AsyncSelect } from '@the-platform-group/component-library'
import { debouncedLoadCustomers } from 'utils/optionsLoaders'

const CustomerAuthorizationModal = ({ onSave, onClose, isOpen, isSaving }) => {
  const [selectedCustomer, setSelectedCustomer] = useState()
  const optionsLoader = term => debouncedLoadCustomers({ term })

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalTitle title="Select Customer to Add" />
      <ModalContent>
        <AsyncSelect
          hideArrow
          includeClearAll
          id="customerId"
          label="Search Customer"
          variant="outlined"
          value={selectedCustomer}
          onChange={setSelectedCustomer}
          optionsLoader={optionsLoader}
        />
        <br />
        <Select
          disabled
          helperText="Scope is currently restricted to 'user'."
          label="Scope"
          value="user"
          fullWidth
          options={[
            { value: 'user', label: 'user' },
            { value: 'assistant', label: 'assistant' },
          ]}
        />
      </ModalContent>
      <ModalActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={onSave(selectedCustomer)}
          disabled={!selectedCustomer}
          isLoading={isSaving}
        >
          Create
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default CustomerAuthorizationModal
