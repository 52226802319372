import React, { useState } from 'react'

import { Typography, Grid } from '@the-platform-group/component-library'
import { ArrowForward as ArrowForwardIcon } from '@the-platform-group/component-library/Icons'

import MergeInfoBox from './components/MergeInfoBox'
import ProfileCard from './components/ProfileCard'
import MergeType from './components/MergeType'
import Preview from './components/Preview'

const MergeProfiles = () => {
  const [mergeType, setMergeType] = useState('people') // 'people' or 'organization'
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Typography variant="h4">Merge Profiles</Typography>
      </Grid>
      <Grid item>
        <MergeType value={mergeType} onChangeTypeSelection={e => setMergeType(e.target.value)} />
      </Grid>
      <Grid item>
        <MergeInfoBox />
      </Grid>
      <Grid item container alignItems="center" justifyContent="space-between" spacing={1}>
        <Grid item xs={5}>
          <ProfileCard type="source" mergeType={mergeType} />
        </Grid>
        <Grid item xs={2}>
          <ArrowForwardIcon
            color="primary"
            sx={{
              height: '100%',
              width: '100%',
            }}
            viewBox="-2 0 27 27"
          />
        </Grid>
        <Grid item xs={5}>
          <ProfileCard type="target" mergeType={mergeType} />
        </Grid>
      </Grid>
      <Grid item>
        <Preview />
      </Grid>
    </Grid>
  )
}

export default MergeProfiles
