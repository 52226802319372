import React, { useState } from 'react'

import {
  Grid,
  Typography,
  ToggleButtons,
  Box,
  FormControlLabel,
  Checkbox,
} from '@the-platform-group/component-library'

import ProfileSelectionActions from './components/ProfileSelectionActions'
import SummariesView from './components/SummariesView'
import ActivityNotesView from './components/ActivityNotesView'
import { SCREEN_TYPES } from 'pages/IrwinAssistant/Summaries/utils'
import useActivitySummaries from 'hooks/activitySummaries/useActivitySummaries'
import { Button } from '@the-platform-group/component-library/Button'
import { useModal } from 'hooks/useModal'
import ClearCacheModal from './components/ClearCacheModal'

const Summaries = () => {
  const [screenType, setScreenType] = useState(SCREEN_TYPES[0])
  const [willUseDefaultFormatting, setWillUseDefaultFormatting] = useState(true)
  const { open, handleOpen, handleClose } = useModal()

  const {
    selectedCustomer,
    setSelectedCustomer,
    selectedSecurity,
    setSelectedSecurity,
    selectedEntityId,
    setSelectedEntityId,
    selectedEntityType,
    setSelectedEntityType,
    beforeNotesPrompt,
    setBeforeNotesPrompt,
    afterNotesPrompt,
    setAfterNotesPrompt,
    activityNotesSummaryData,
    activityNotesSummaryDataIsLoading,
    isValidToSummarize,
  } = useActivitySummaries(willUseDefaultFormatting)

  return (
    <Grid container spacing={2}>
      <Grid item container>
        <Grid item xs={12} sm={6}>
          <Typography variant="h4">Irwin Assistant Summaries</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ToggleButtons
            options={SCREEN_TYPES}
            selectedOption={screenType}
            onToggle={setScreenType}
            fullWidth
            buttonStyles={{ '&': { width: '100%' } }}
            willUseDefaultFormatting={willUseDefaultFormatting}
            setWillUseDefaultFormatting={setWillUseDefaultFormatting}
          />
        </Grid>
      </Grid>
      <ProfileSelectionActions
        selectedCustomer={selectedCustomer}
        setSelectedCustomer={setSelectedCustomer}
        selectedSecurity={selectedSecurity}
        setSelectedSecurity={setSelectedSecurity}
        setSelectedEntityId={setSelectedEntityId}
        setSelectedEntityType={setSelectedEntityType}
      />
      <Grid item container>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleOpen}>
            Clear Cache
          </Button>
          <ClearCacheModal
            open={open}
            onClose={handleClose}
            selectedCustomer={selectedCustomer}
            selectedSecurity={selectedSecurity}
            selectedEntityId={selectedEntityId}
            selectedEntityType={selectedEntityType}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={willUseDefaultFormatting}
                onChange={() => setWillUseDefaultFormatting(!willUseDefaultFormatting)}
              />
            }
            label="Default Format"
            labelPlacement="start"
          />
        </Grid>
      </Grid>
      <Grid item container>
        <Box
          sx={{
            width: '100%',
            minHeight: '70vh',
            bgcolor: 'primary.defaultCalendar',
            border: '1px solid',
            borderColor: 'grey.400',
            borderRadius: 2,
            p: 2,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          {screenType === SCREEN_TYPES[0] ? (
            <SummariesView
              beforeNotesPrompt={beforeNotesPrompt}
              setBeforeNotesPrompt={setBeforeNotesPrompt}
              afterNotesPrompt={afterNotesPrompt}
              setAfterNotesPrompt={setAfterNotesPrompt}
              activityNotesSummaryData={activityNotesSummaryData}
              activityNotesSummaryDataIsLoading={activityNotesSummaryDataIsLoading}
              isValidToSummarize={isValidToSummarize}
              willUseDefaultFormatting={willUseDefaultFormatting}
            />
          ) : (
            <ActivityNotesView
              selectedEntityId={selectedEntityId}
              selectedCustomer={selectedCustomer}
              selectedSecurity={selectedSecurity}
              selectedEntityType={selectedEntityType}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default Summaries
