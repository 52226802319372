import { useQuery } from 'react-query'

import { Grid, Typography } from '@the-platform-group/component-library'
import { DataGrid } from '@the-platform-group/component-library/DataGrid'

import { getUserGroups } from 'services/holderNotificationGroups'
import { getColumnValues, getRowValues } from './HolderAlertsTableValues'
import { SHAREHOLDER_CHANGES_FILING_OPTIONS } from 'constants/filingSources'

const transformGroupData = (data, subscriptions) => {
  return data.map(group => {
    let groupSub = subscriptions.filter(s => s.configuration.group_id === group.id)[0]
    return {
      id: group.id,
      groupName: group.name,
      customer: group.customer,
      securities: group.securities.map(s => s.name.trim()).join(', '),
      sources: group.sourceTypes
        .map(source => SHAREHOLDER_CHANGES_FILING_OPTIONS.find(opt => opt.value === source).label)
        .join(', '),
      enabled: groupSub.enabled,
      groupSub,
    }
  })
}

const HolderAlertsTable = ({ subscriptions, user }) => {
  const { data: userGroups, isLoading: groupsLoading } = useQuery(
    ['getUserGroups', user.id],
    () => getUserGroups({ userId: user.id }),
    {
      enabled: !!(user.id && subscriptions),
      select: data => transformGroupData(data, subscriptions),
      placeholderData: () => [],
    },
  )

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">Holder Alerts</Typography>
        <DataGrid
          name="HolderAlertsTable"
          columns={getColumnValues()}
          commonColumnProps={{ headerAlign: 'left' }}
          rows={getRowValues(userGroups)}
          loading={groupsLoading}
          localeText={{
            noRowsLabel: 'No alert groups found for this user.',
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'customer', sort: 'asc' }],
            },
          }}
          sx={{
            '.hoverCell': {
              '&.MuiDataGrid-cell:hover span, &.MuiDataGrid-cell:hover .MuiButtonBase-root': {
                display: 'block',
                fontSize: 10,
              },
              'span, .MuiButtonBase-root': { display: 'none' },
            },
          }}
          page={0}
          pageSize={50}
          rowCount={userGroups.length}
          pageSizeOptions={[10, 25, 50]}
        />
      </Grid>
    </>
  )
}

export default HolderAlertsTable
