import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'

import { createCustomerAuthorization } from 'services/customerAuthorizations'
import { useNotification } from 'components/Notification'
import { useModal } from 'hooks/useModal'

export default function useCustomerAuthModal() {
  const { id: userId } = useParams()
  const { createNotification } = useNotification()
  const queryClient = useQueryClient()
  const customerAuthModalState = useModal()

  const customerAuthMutation = useMutation(createCustomerAuthorization, {
    onSuccess: () => {
      createNotification({ message: 'Successfully created customer authorization' })

      // resets getUser query (executed in src/pages/UserDetail/UserDetailComponent.js)
      // this will trigger the query to refetch to get latest user+customerAuthorizations
      // the user page will then re-render to show new customer authorizations data
      queryClient.resetQueries(['getUser', userId], { exact: true })
      customerAuthModalState.handleClose()
    },
    onError: error => {
      const message = error.response?.data?.errors[0].message || error.message
      createNotification({ message, variant: 'error' })
    },
  })

  const onSaveCustomerAuth = customer => () =>
    customerAuthMutation.mutate({ customerId: customer.id, userId })

  return {
    userId,
    ...customerAuthModalState,
    onSave: onSaveCustomerAuth,
    isSaving: customerAuthMutation.isLoading,
  }
}
