import { useState } from 'react'

const defaultQueryParams = {
  term: '',
  page: 0,
  pageSize: 10,
  customerId: null,
}

const useQueryParams = () => {
  const [queryParams, setQueryParams] = useState(defaultQueryParams)
  const handleQueryParamsChanged = e => {
    // want to accept events or states to deconstruct
    const { target } = e
    if (target) {
      setQueryParams({ ...queryParams, [target.name]: target.value })
      return
    }
    setQueryParams({ ...queryParams, ...e })
  }

  return {
    queryParams,
    handleQueryParamsChanged,
  }
}

export default useQueryParams
