import { Check as CheckIcon, Edit as EditIcon } from '@the-platform-group/component-library/Icons'
import { DataGridActionCell } from '@the-platform-group/component-library/DataGrid'
import focuses from 'constants/focus'
import React from 'react'
import { Box } from '@the-platform-group/component-library'

export const getColumnValues = openModal => {
  return [
    ...['name', 'mainTicker', 'sector', 'subSector'].map(v => ({ field: v })),
    {
      field: 'overridden',
      type: 'boolean',
      renderCell: ({ value }) => (value ? <CheckIcon /> : <Box />),
    },
    {
      field: 'actions',
      type: 'actions',
      getActions: ({ row }) => [
        <DataGridActionCell
          icon={<EditIcon />}
          title="Edit Security"
          onClick={() => openModal(row.security)}
        />,
      ],
    },
  ]
}

export const getRowData = security => {
  const name = security.name
  const mainTicker = security.listingPrimary?.tickerExchange
  const sector = focuses.SECTORS[security.sectorCode] || '-'
  const subSector = security.sectorCode
    ? focuses.SUBSECTOR_GROUPS[security.sectorCode][security.subsectorCode]
    : '-'
  const isOverridden = security.issuer.sectorOverride && security.issuer.sectorOverride.sectorCode

  return {
    id: security.id,
    name,
    mainTicker,
    sector,
    subSector,
    overridden: isOverridden,
    security,
  }
}

export const getRowValues = securities => securities.map(getRowData)
