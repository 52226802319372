import { useState } from 'react'

// this function creates state (default to false) for processing and returns the
// current state as well as functions to set / unset that state flag.  the primary
// purpose of this state is to prevent multiple clicks on a UI element while an
// asyncronous activity is taking place.
const useProcessing = () => {
  const [processing, setProcessing] = useState(false)

  const startProcessing = () => {
    if (processing) {
      return false
    } else {
      setProcessing(true)
      return true
    }
  }

  const stopProcessing = () => {
    setProcessing(false)
  }

  return [processing, startProcessing, stopProcessing]
}

export default useProcessing
