import { PARENT_ORGANIZATION_KEY } from 'constants/organizationTypes'
import { searchAllOrganizations } from 'services/organizations'
import { useOrganizationFormContext } from '../contexts/OrganizationFormContext'

/**
 * Custom hook to handle adding and deleting child organizations
 * @returns {object}  {
    newChildOrganizations, - list of child organizations to be linked to the parent
    newChildOrganizationsMapping, - map version of newChildOrganizations
    handleAddOrganization, - Function called when an option is selected from organization UUID dropdown
    handleChildOrganizationsSearch, - Function called when an option is selected from organization name dropdown
    handleDeleteOrganization, - Function called a child organization is deleted from the child orgs table
    loadChildrenOrganizationsByName, - Function called when searching by organization name 
    loadChildrenOrganizationsByUuid, - Function called when searching by organization UUID 
  } 
 */
const useChildrenOrganization = () => {
  const {
    state: { newChildOrganizations },
    handleAddNewChildOrganization,
    handleChildOrganizationsUpdate,
    handleRemoveNewChildOrganization,
  } = useOrganizationFormContext()

  const loadOrganizations = async (value, params, signal) => {
    if (!value) {
      return []
    }
    const response = await searchAllOrganizations(
      {
        ...params,
        exclude_has_parent: true,
        system_only: true,
        type_not: PARENT_ORGANIZATION_KEY,
      },
      signal,
    )
    return response?.data || []
  }

  const loadChildrenOrganizationsByName = (term, signal) =>
    loadOrganizations(term, { term, page_size: 50 }, signal)

  const loadChildrenOrganizationsByUuid = (uuid, signal) =>
    loadOrganizations(uuid, { organization_ids: [uuid] }, signal)

  const handleChildOrganizationsSearch = orgs => handleChildOrganizationsUpdate(orgs, true)

  const handleAddOrganization = organization => handleAddNewChildOrganization(organization)

  const newChildOrganizationsMapping = newChildOrganizations.reduce((result, organization) => {
    result[organization.id] = organization
    return result
  }, {})

  const handleDeleteOrganization = deletedOrganizationId => {
    if (newChildOrganizationsMapping[deletedOrganizationId]) {
      handleRemoveNewChildOrganization(deletedOrganizationId)
    } else {
      handleChildOrganizationsUpdate(deletedOrganizationId, false)
    }
  }

  return {
    newChildOrganizations,
    newChildOrganizationsMapping,
    handleAddOrganization,
    handleChildOrganizationsSearch,
    handleDeleteOrganization,
    loadChildrenOrganizationsByName,
    loadChildrenOrganizationsByUuid,
  }
}

export default useChildrenOrganization
