import React, { useEffect, useState } from 'react'
import { Grid } from '@the-platform-group/component-library/Layout'
import {
  RawButton as Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TypeaheadSingleSelect,
} from '@the-platform-group/component-library'

import CustomerSelect from 'pages/Users/components/CustomerSelect'
import SecuritySelect from 'components/Select/SecuritySelect'
import {
  Table,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  TableAction,
  TableActionList,
} from '@the-platform-group/component-library/Table'
import { Delete as DeleteIcon } from '@the-platform-group/component-library/Icons'
import useReportingList from './hooks/useReportingList'
import {
  ReportingGrantOptionsContextProvider,
  useReportingGrantOptionsContext,
} from './hooks/ReportingGrantOptionsContext'

const securityLabel = ({ name, listingPrimary: { tickerExchange } }) =>
  `${tickerExchange} - ${name}`

const datasourcesOptions = [
  { value: 'ds5', label: 'Alliance' },
  { value: 'ds7', label: 'Okapi' },
  { value: 'ds11', label: 'Morrow Sodali' },
]

function ReportingGrants() {
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false)
  const [removePermissionId, setRemovePermissionId] = useState(null)

  const {
    customer: selectedCustomer,
    security: selectedSecurity,
    user: selectedUser,
    datasource: selectedDatasource,
    handleChangeCustomer,
    handleChangeSecurity,
    handleChangeUser,
    handleChangeDatasource,
  } = useReportingGrantOptionsContext()

  const {
    reportingGrants,
    isLoading,
    addReportingGrant,
    removeReportingGrant,
    usersOptions,
  } = useReportingList({
    selectedCustomer,
    selectedSecurity,
    selectedUser,
    selectedDatasource,
    setOpenRemoveDialog,
  })

  const [isEnableReportDisabled, setIsEnableReportDisabled] = useState(true)

  useEffect(() => {
    // if any of the fields is not selected do not enable the button
    if (!(selectedCustomer && selectedSecurity && selectedUser && selectedDatasource)) {
      return
    }

    // check if there is a permission for this combination
    const foundPermission = reportingGrants.find(({ customer, security, user, datasource }) => {
      const hasCustomer = customer.id === selectedCustomer.id
      const hasSecurity = security.id === selectedSecurity.id
      const hasUser = user.id === selectedUser.id
      const hasDatasource = datasource === selectedDatasource.value

      return Boolean(hasUser && hasCustomer && hasSecurity && hasDatasource)
    })

    // if there is permission for this combination, disable the button otherwise enable it
    setIsEnableReportDisabled(Boolean(foundPermission))
  }, [
    selectedCustomer,
    selectedSecurity,
    selectedUser,
    selectedDatasource,
    reportingGrants,
    isEnableReportDisabled,
  ])

  return (
    <>
      <h1>Reporting Permissions</h1>
      <Grid container wrap="nowrap" direction="column" spacing={4}>
        <Grid item container xs={12} spacing={4}>
          <Grid item xs={3}>
            <CustomerSelect
              label="Select a customer"
              value={selectedCustomer}
              onChange={handleChangeCustomer}
              optionsLoaderParams={{ state: 'active', rawData: true }}
            />
          </Grid>
          {selectedCustomer && (
            <Grid item xs={3}>
              <SecuritySelect
                label="Select a security"
                value={selectedSecurity}
                onChange={handleChangeSecurity}
              />
            </Grid>
          )}
          {selectedCustomer && (
            <Grid item xs={3}>
              <TypeaheadSingleSelect
                label="Select a user"
                variant="outlined"
                options={usersOptions}
                value={selectedUser}
                onChange={(_, value) => handleChangeUser(value)}
              />
            </Grid>
          )}
          {selectedCustomer && selectedUser && (
            <Grid item xs={3}>
              <TypeaheadSingleSelect
                label="Select a datasource"
                variant="outlined"
                options={datasourcesOptions}
                value={selectedDatasource}
                onChange={(_, value) => handleChangeDatasource(value)}
              />
            </Grid>
          )}
        </Grid>
        <Grid item container xs={12} spacing={4}>
          <Grid item xs={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={addReportingGrant}
              disabled={isEnableReportDisabled}
            >
              Enable reports
            </Button>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Table>
            <TableHead>
              <TableHeaderCell>Customer</TableHeaderCell>
              <TableHeaderCell>User</TableHeaderCell>
              <TableHeaderCell>Security</TableHeaderCell>
              <TableHeaderCell>Datasource</TableHeaderCell>
              <TableHeaderCell></TableHeaderCell>
            </TableHead>
            <TableBody columns={5} isLoading={isLoading}>
              {reportingGrants.map(({ id, customer, user, security, datasource }) => (
                <TableRow key={id}>
                  <TableCell>{customer.name}</TableCell>
                  <TableCell>
                    {user.firstName} {user.lastName} - {user.email}
                  </TableCell>
                  <TableCell>{securityLabel(security)}</TableCell>
                  <TableCell>
                    {datasourcesOptions.find(dso => dso.value === datasource)?.label}
                  </TableCell>
                  <TableCell>
                    <TableActionList>
                      <TableAction
                        title="Delete"
                        onClick={() => {
                          setOpenRemoveDialog(true)
                          setRemovePermissionId(id)
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </TableAction>
                    </TableActionList>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <DeleteReportGrantDialog
        open={openRemoveDialog}
        handleClose={() => setOpenRemoveDialog(false)}
        handleRemove={() => removeReportingGrant(removePermissionId)}
      />
    </>
  )
}

function DeleteReportGrantDialog({ open, handleClose, handleRemove }) {
  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Remove permission</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure that you want to remove?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleRemove} color="primary" autoFocus>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ReportingGrantsPageContextWrapper = () => {
  return (
    <ReportingGrantOptionsContextProvider>
      <ReportingGrants />
    </ReportingGrantOptionsContextProvider>
  )
}

export default ReportingGrantsPageContextWrapper
