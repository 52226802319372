import React from 'react'
import {
  styled,
  TextField,
  Select,
  TooltipIcon,
  TypeaheadSingleSelect,
} from '@the-platform-group/component-library'
import { AsyncSelect } from '@the-platform-group/component-library/Select'
import { ArrowDropDown as ArrowDropDownIcon } from '@the-platform-group/component-library/Icons'

const getInputStyles = ({ theme }) => ({
  '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
    paddingRight: `${theme.spacing(1.5)} !important`,
  },
  '& .MuiSelect-iconOutlined': {
    // this style moves default select dropdown icon to the left so there would be enough
    // space for the help tooltip icon
    right: theme.spacing(4), // default is 7px
  },
  '& .MuiIconButton-root .MuiSvgIcon-root': {
    color: theme.palette.info.main,
  },
})

const StyledTextField = styled(TextField)(getInputStyles)
const StyledSelect = styled(Select)(getInputStyles)
const StyledTypeaheadSingleSelect = styled(TypeaheadSingleSelect)(getInputStyles)
const StyledAsyncSelect = styled(AsyncSelect)(getInputStyles)

// NOTE: XWithHelpIcon components should ONLY be used for components with help tooltip text

export const TextFieldWithHelpIcon = React.forwardRef(({ tooltipText, ...rest }, ref) => {
  return (
    <StyledTextField
      ref={ref}
      InputProps={{
        endAdornment: <TooltipIcon text={tooltipText} />,
      }}
      {...rest}
    />
  )
})

export const SelectWithHelpIcon = React.forwardRef(({ tooltipText, ...rest }, ref) => {
  return (
    <StyledSelect
      ref={ref}
      InputProps={{
        endAdornment: <TooltipIcon text={tooltipText} />,
      }}
      {...rest}
    />
  )
})

export const TypeaheadSingleSelectWithHelpIcon = React.forwardRef(
  ({ tooltipText, ...rest }, ref) => {
    return (
      <StyledTypeaheadSingleSelect
        ref={ref}
        InputProps={{
          endAdornment: (
            <>
              <ArrowDropDownIcon sx={{ color: 'grey.600' }} />
              {tooltipText && <TooltipIcon text={tooltipText} />}
            </>
          ),
        }}
        {...rest}
      />
    )
  },
)

export const AsyncSelectWithHelpIcon = React.forwardRef(({ tooltipText, ...rest }, ref) => {
  return (
    <StyledAsyncSelect
      ref={ref}
      InputProps={{
        endAdornment: (
          <>
            <ArrowDropDownIcon sx={{ color: 'grey.600' }} />
            {tooltipText && <TooltipIcon text={tooltipText} />}
          </>
        ),
      }}
      {...rest}
    />
  )
})
