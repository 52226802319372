import { useState } from 'react'
import { useQuery } from 'react-query'
import usePagination from 'hooks/usePagination'
import { getDs1People } from 'services/ds1People'
import { searchAllOrganizations } from 'services/organizations'

const SERVICES = {
  people: getDs1People,
  organizations: searchAllOrganizations,
}

const useFactsetData = ({ entity, params: otherParams }) => {
  const { pagination, handlePageChange, handleRowsChange, pageSizeOptions } = usePagination()

  const [filters, setFilters] = useState({})
  const params = {
    term: filters.term,
    pageSize: pagination.pageSize,
    pageOffset: pagination.pageOffset,
    ...otherParams,
  }

  const handleFilter = async ({ target: { value } }) => setFilters({ term: value || undefined })

  const getRequest = SERVICES[entity]
  const queryKey = ['ds1Query', entity, params]
  const { data, isFetching } = useQuery({
    queryKey,
    queryFn: () => getRequest(params),
    initialData: [],
    refetchOnWindowFocus: false,
  })

  return {
    data,
    queryKey,
    pagination,
    isFetching,
    handleFilter,
    handlePageChange,
    handleRowsChange,
    pageSizeOptions,
  }
}

export default useFactsetData
