import Jsona, { SwitchCaseJsonMapper } from 'jsona'
import { irwin } from 'config'
import { httpClient } from './httpClient'

const deserializer = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper(),
})

function client() {
  return httpClient(irwin.baseUrl)
}

async function updatePersonSocialProfiles(id, params) {
  const response = await client().patch(`v2/person_social_profiles/${id}`, {
    person_social_profiles: params,
  })

  return {
    data: deserializer.deserialize(response),
    meta: response.meta,
  }
}

export { updatePersonSocialProfiles }
