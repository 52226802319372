import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { getAssistantNoteSummary } from 'services/assistantSummaries'
import { useNotification } from 'components/Notification'

const useActivitySummaries = willUseDefaultFormatting => {
  const { createNotification } = useNotification()

  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [selectedSecurity, setSelectedSecurity] = useState(null)
  const [selectedEntityId, setSelectedEntityId] = useState(null)
  const [selectedEntityType, setSelectedEntityType] = useState(null)
  const [beforeNotesPrompt, setBeforeNotesPrompt] = useState(null)
  const [afterNotesPrompt, setAfterNotesPrompt] = useState(null)

  const [isValidToSummarize, setIsValidToSummarize] = useState(false)
  useEffect(() => {
    setIsValidToSummarize(
      Boolean(selectedCustomer && selectedSecurity && selectedEntityId && selectedEntityType),
    )
  }, [selectedCustomer, selectedSecurity, selectedEntityId, selectedEntityType])

  const { data: activityNotesSummaryData, isLoading: activityNotesSummaryDataIsLoading } = useQuery(
    [
      'activityNoteSummary',
      {
        beforeNotesPrompt,
        afterNotesPrompt,
        defaultInstructions: willUseDefaultFormatting,
        entityId: selectedEntityId,
        type: selectedEntityType,
        customerId: selectedCustomer?.id,
        securityId: selectedSecurity?.id,
      },
    ],
    getAssistantNoteSummary,
    {
      onSuccess: data => {
        if (data?.status) {
          createNotification({
            message: 'Failed to generate a summary: ' + data.status,
            variant: 'error',
          })
        }
      },
      onError: error =>
        createNotification({
          message: error.response?.data?.error || error.message,
          variant: 'error',
        }),
      enabled: Boolean(
        (beforeNotesPrompt !== null || afterNotesPrompt !== null) &&
          selectedEntityId &&
          selectedEntityType &&
          selectedCustomer &&
          selectedSecurity?.id,
      ),
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
      cacheTime: 0,
    },
  )

  return {
    selectedCustomer,
    setSelectedCustomer,
    selectedSecurity,
    setSelectedSecurity,
    selectedEntityId,
    setSelectedEntityId,
    selectedEntityType,
    setSelectedEntityType,
    beforeNotesPrompt,
    setBeforeNotesPrompt,
    afterNotesPrompt,
    setAfterNotesPrompt,
    activityNotesSummaryData,
    activityNotesSummaryDataIsLoading,
    isValidToSummarize,
  }
}

export default useActivitySummaries
