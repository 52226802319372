import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@the-platform-group/component-library/Modal'
import { Button } from '@the-platform-group/component-library/Button'
import {
  InputLabel,
  Box,
  Switch,
  FormControl,
  FormLabel,
  FormControlLabel,
  TextField,
} from '@the-platform-group/component-library'
import { useForm, Controller } from 'react-hook-form'
import useSiteForm from './useSiteForm'
import SecuritySelect from 'components/Select/SecuritySelect'
import { useSessionState } from 'contexts/Session'

/**
 * @param {object} props - component props
 * @param {function} props.onSave - save site data
 * @param {function} props.onClose - close this modal
 * @param {boolean} props.open - open/close status of this modal
 * @param {object} props.editableSite - web site data
 * @param {string} props.customerId - current customer id
 * @param {function} props.refreshTable - async function that refreshes the IQ website data table
 * @returns {Node} Modal component that allows adding/updating an IQ website info
 */
function SiteModal({ onSave, onClose, open, editableSite, customerId, refreshTable }) {
  const { currentUser } = useSessionState()
  const {
    control,
    getValues,
    setError,
    clearErrors,
    register,
    handleSubmit,
    formState,
    reset,
  } = useForm({
    mode: 'onChange', // validate form upon onChange
    defaultValues: {
      id: '',
      name: '',
      url: '',
      security: '',
      enabled: true,
    },
  })

  const { saveSite } = useSiteForm({
    onSave,
    onClose,
    refreshTable,
    formProps: { reset, setError },
    customerId,
    editableSite,
  })

  // Clear server errors that is saved in the formState
  // We need to run this when any field is changed so we can save the form again
  const clearServerErrors = () => clearErrors('root.serverErrors')

  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle title={`${editableSite?.id ? 'Update' : 'Add'} Website`} />
      <ModalContent>
        <form>
          <InputLabel htmlFor="name" />
          <TextField
            id="name"
            name="name"
            label="Name *"
            placeholder="Website Name"
            {...register('name', { onChange: clearServerErrors, required: true })}
            sx={{ mt: 1, mb: 0.5 }}
            fullWidth
          />
          <InputLabel htmlFor="website" />
          <TextField
            id="website"
            name="url"
            label="Website *"
            placeholder="http://www.example.com"
            {...register('url', { onChange: clearServerErrors, required: true })}
            sx={{ mt: 1, mb: 0.5 }}
            fullWidth
          />
          <InputLabel htmlFor="defaultSecurity" />
          <Controller
            control={control}
            name="security"
            rules={{ required: true }}
            render={({ field }) => {
              const onChange = value => {
                clearServerErrors()
                field.onChange(value)
              }
              return (
                <SecuritySelect
                  id="security"
                  label="Security *"
                  name={field.name}
                  value={field.value}
                  onChange={onChange}
                />
              )
            }}
          />
          <FormControl
            margin="dense"
            fullWidth
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <FormLabel>Toggle Site Provisioning</FormLabel>
            <FormControlLabel
              sx={{ mr: 0 }}
              control={
                <Switch
                  defaultChecked={getValues('enabled')}
                  edge="end"
                  inputProps={{ 'aria-labelledby': `website-switch` }}
                  {...register('enabled', { onChange: clearServerErrors })}
                />
              }
            />
          </FormControl>
          <Box component="span" color="red">
            {formState.errors?.root?.serverErrors.message}
          </Box>
          <Box my={2}>
            <strong>Snippet</strong> <br />
            {editableSite?.trackingSnippet ? (
              <code style={{ wordWrap: 'break-word' }}>{editableSite?.trackingSnippet}</code>
            ) : (
              <span>Generated after website is provisioned</span>
            )}
          </Box>
          <Box>
            <strong>Created By</strong> <br />
            {editableSite?.creator ? (
              <span>{`${editableSite.creator?.firstName} ${editableSite.creator?.lastName}`}</span>
            ) : (
              <span>{`${currentUser?.firstName} ${currentUser?.lastName}`}</span>
            )}
          </Box>
        </form>
      </ModalContent>
      <ModalActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!formState.isValid}
          onClick={handleSubmit(saveSite)}
          isLoading={formState.isSubmitting || formState.isLoading}
        >
          Save
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default SiteModal
