import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { listTimeZones } from 'timezone-support'
import { ModalContent, ModalActions } from '@the-platform-group/component-library/Modal'
import { Button } from '@the-platform-group/component-library/Button'
import {
  TextField,
  TypeaheadSingleSelect,
  FormControlLabel,
  Checkbox,
  Grid,
} from '@the-platform-group/component-library'

import { ROLES } from 'utils/auth'

const UserForm = ({
  customerId,
  onClose,
  onChange,
  onSave,
  onInvite,
  user,
  isLoading,
  isFormEdited,
}) => {
  const {
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
    sendInvitation,
    timeZone,
    role,
  } = user

  return (
    <Fragment>
      <ModalContent>
        <form>
          {!user.profileEditingEnabled && (
            <>
              <h2>{`${firstName} ${lastName} (${email})`}</h2>
              <i>This user's profile is managed by an SSO Identity Provider.</i>
              <br />
              <br />
            </>
          )}
          {user.profileEditingEnabled && (
            <>
              <TextField
                label="First Name"
                name="firstName"
                value={firstName || ''}
                onChange={onChange}
                type="text"
                sx={{
                  mt: 0.5,
                  mb: 1,
                }}
                fullWidth
              />
              <TextField
                label="Last Name"
                name="lastName"
                value={lastName || ''}
                onChange={onChange}
                type="text"
                sx={{
                  mt: 0.5,
                  mb: 1,
                }}
                fullWidth
              />
              <TextField
                label="Email"
                name="email"
                value={email || ''}
                onChange={onChange}
                type="email"
                sx={{
                  mt: 0.5,
                  mb: 1,
                }}
                fullWidth
              />
              <TextField
                label="Password"
                name="password"
                value={password || ''}
                onChange={onChange}
                type="password"
                sx={{
                  mt: 0.5,
                  mb: 1,
                }}
                fullWidth
              />
              <TextField
                label="Confirm Password"
                name="confirmPassword"
                value={confirmPassword || ''}
                onChange={onChange}
                type="password"
                sx={{
                  mt: 0.5,
                  mb: 1,
                }}
                fullWidth
              />
            </>
          )}
          <TypeaheadSingleSelect
            id="timeZone"
            name="timeZone"
            label="Time Zone"
            value={timeZone}
            onChange={(e, newValue) => onChange({ target: { name: 'timeZone', value: newValue } })}
            variant="outlined"
            options={listTimeZones()}
          />
          <TypeaheadSingleSelect
            id="role"
            name="role"
            label="Role"
            value={role}
            onChange={(e, newValue) => onChange({ target: { name: 'role', value: newValue } })}
            variant="outlined"
            options={ROLES}
          />
          {!user.id && (
            <FormControlLabel
              label="Send Invitation Email?"
              control={
                <Checkbox
                  value={true}
                  checked={sendInvitation}
                  name="sendInvitation"
                  onChange={onChange}
                />
              }
            ></FormControlLabel>
          )}
        </form>
      </ModalContent>
      <ModalActions>
        <Grid container direction="row">
          <Grid item xs={6} justifyContent="flex-start">
            {user.profileEditingEnabled && user.id && (
              <Button onClick={onInvite} disabled={isFormEdited}>
                Send Invitation
              </Button>
            )}
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end" spacing={1}>
            <Grid item>
              <Button onClick={onClose}>Cancel</Button>
            </Grid>
            <Grid item>
              <Button onClick={() => onSave(customerId, onClose)} disabled={isLoading}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </ModalActions>
    </Fragment>
  )
}

UserForm.propTypes = {
  customerId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onInvite: PropTypes.func.isRequired,
}

export default UserForm
