import { useParams } from 'react-router-dom'
import { titleCase } from 'change-object-case'
import orderBy from 'lodash/orderBy'
import { formatDate } from '@the-platform-group/formatters/dateFormat'
import { Grid, Typography, CustomLink, Tooltip, Chip } from '@the-platform-group/component-library'
import { Button } from '@the-platform-group/component-library/Button'
import {
  AddLink as LinkIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  LinkOff as UnlinkIcon,
} from '@the-platform-group/component-library/Icons'

import Spinner from 'components/Spinner'
import { useConfirmationDialog } from 'components/ConfirmationDialog'
import KeyValuePair from './Components/KeyValuePair'
import LinkFactsetUserModal from './Components/LinkFactsetUserModal'
import UpdateFactsetUserModal from './Components/UpdateFactsetUserModal'
import FactsetUserApiRequestsTable from './Components/FactsetUserApiRequestsTable'

import { useFactsetMetadata } from 'contexts/FactsetMetadataContext'
import useLinkFactsetEntityActions from './hooks/useLinkFactsetEntityActions'
import { useFactsetUser } from './hooks/useFactsetUser'
import { useUnlinkFactsetUser } from './hooks/useLinkFactsetUser'
import useDeleteFactsetUser from './hooks/useDeleteFactsetUser'
import { useFactsetUserUpdateModal } from './hooks/useCreateUpdateFactsetUser'

import { transformFactsetUser } from './Utils'

const IMMEDIATE_DELETE_PROCESS = 'immediateDeleteProcess'
const ASYNCHRONOUS_DELETE_PROCESS = 'asynchronousDeleteProcess'
const UNAVAILABLE_DELETE_PROCESS = 'unavailableDeleteProcess'

/**
 * Return the available deletion process based on the current status of the Factset user record
 * The status of the record determines if the deletion process can be requested and if so, whether
 * the process can be done immediately or if it needs to be done asynchronously.
 * @param {string} status - the status of the Factset user record
 * @returns {string} delete process available for user record
 */
const getDeleteProcessState = status => {
  switch (status) {
    case 'creating.failed':
    case 'unmanaged':
      return IMMEDIATE_DELETE_PROCESS
    case 'updating.failed':
    case 'updating.timed_out':
    case 'created':
      return ASYNCHRONOUS_DELETE_PROCESS
    default:
      return UNAVAILABLE_DELETE_PROCESS
  }
}

function FactsetUserDetail() {
  const { id: factsetUserId } = useParams()
  const { createConfirmationDialog } = useConfirmationDialog()

  const { data: factsetMetadata } = useFactsetMetadata()

  const { data: factsetUser, isFetching } = useFactsetUser(factsetUserId)

  const { onUnlink, isUnlinking } = useUnlinkFactsetUser()

  const { mutateAsync: deleteFactsetUser, isLoading: isDeletingFactsetUser } = useDeleteFactsetUser(
    factsetUserId,
  )

  const {
    handleOpen: openLinkFactsetUserModal,
    handleClose: closeLinkFactsetUserModal,
    open: isLinkFactsetUserModalOpen,
  } = useLinkFactsetEntityActions()

  const {
    closeUpdateFactsetUserModal,
    isUpdateFactsetUserModalOpen,
    onOpenUpdateFactsetUserModal,
  } = useFactsetUserUpdateModal()

  if (isFetching) {
    return <Spinner />
  }

  const {
    apiRequests,
    id,
    givenName,
    familyName,
    createdAt,
    updatedAt,
    lastRefreshedAt,
    location,
    ds1Id,
    user,
    status,
    products,
    // start - fields to remove from UI
    version,
    type,
    relationshipNames,
    // end
    ...restOfData
  } = factsetUser
  const dates = { createdAt, updatedAt, lastRefreshedAt }
  const displayData = [
    [
      'Products',
      <>
        {products.map(userProduct => {
          const productName = userProduct.name || 'Unknown'
          return (
            <Chip
              label={`${productName} (ID: ${userProduct.id})`}
              size="small"
              sx={{ mr: 0.5, mb: 0.5 }}
            />
          )
        })}
      </>,
    ],
    ['Factset ID (DS1 ID)', ds1Id],
    ...Object.entries(dates).map(([label, value]) => [titleCase(label), formatDate(value, 'PPp')]),
    ...Object.entries(restOfData).map(([label, value]) => [titleCase(label), value]),
  ]
  const deleteProcessState = getDeleteProcessState(factsetUser.status)
  const canEdit = ['unmanaged', 'created', 'updating.failed'].includes(factsetUser.status)
  const canDelete = deleteProcessState !== UNAVAILABLE_DELETE_PROCESS
  const fullName = `${givenName} ${familyName}`
  const isFactsetUserDeleted = factsetUser.status === 'deleted'

  return (
    <>
      <Grid container item flexWrap="nowrap" justifyContent="space-between">
        <Grid item container xs spacing={1} alignItems="center" mb={2}>
          <Grid item>
            <Typography variant="h4" component="h1">
              {fullName}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              ID: {id}
            </Typography>
          </Grid>
          {isFactsetUserDeleted && (
            <Grid item>
              <Chip color="error" label="Deleted" />
            </Grid>
          )}
        </Grid>
        {!isFactsetUserDeleted && (
          <Grid item container spacing={1} sx={{ width: 'initial' }}>
            <Grid item>
              <Tooltip
                title={
                  !canDelete &&
                  `The current state ("${factsetUser.status}") of the Factset user record does not allow for a deletion request. Please wait for the current procces to complete before attempting to delete the record.`
                }
              >
                <span>
                  <Button
                    color="error"
                    isLoading={isDeletingFactsetUser}
                    disabled={isDeletingFactsetUser || !canDelete}
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      createConfirmationDialog({
                        title: `Delete ${fullName}`,
                        body: (
                          <>
                            <Typography>
                              Are you sure you want to delete the factset user {fullName}?
                            </Typography>
                            {deleteProcessState === ASYNCHRONOUS_DELETE_PROCESS && (
                              <Typography mt={2}>
                                Note: The current state ("{factsetUser.status}") of the Factset user
                                record will require that the deprovisioning process is done via an
                                asynchronous process. The Factset user will temporarily have a
                                "deleting.queued" status.
                              </Typography>
                            )}
                          </>
                        ),
                        onConfirm: deleteFactsetUser,
                      })
                    }}
                  >
                    Delete
                  </Button>
                </span>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title={!canEdit && 'Can not edit this Factset user due to its current status'}
              >
                <span>
                  <Button
                    onClick={onOpenUpdateFactsetUserModal()}
                    startIcon={<EditIcon />}
                    disabled={!canEdit}
                  >
                    Edit
                  </Button>
                </span>
              </Tooltip>
            </Grid>
            <Grid item>
              <Button
                disabled={isUnlinking || !user}
                isLoading={isUnlinking}
                onClick={onUnlink(factsetUser)}
                startIcon={<UnlinkIcon />}
              >
                Unlink
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={openLinkFactsetUserModal} disabled={user} startIcon={<LinkIcon />}>
                Link Irwin User
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container flexDirection="column" rowSpacing={1}>
        <KeyValuePair
          label="Factset Location"
          value={
            location && (
              <CustomLink to={`/factset/locations/${location.id}`} underline="always">
                {location.name}
              </CustomLink>
            )
          }
        />
        <KeyValuePair
          label="Linked Irwin User"
          value={
            user && (
              <CustomLink to={`/users/${user.id}`} underline="always">
                {user.firstName} {user.lastName}
              </CustomLink>
            )
          }
        />
        {orderBy(displayData, ([label]) => label).map(([label, value]) => {
          return <KeyValuePair key={label} label={label} value={value} />
        })}
      </Grid>
      <FactsetUserApiRequestsTable rows={apiRequests} factsetUserId={factsetUserId} />
      <LinkFactsetUserModal
        isModalOpen={isLinkFactsetUserModalOpen}
        closeModal={closeLinkFactsetUserModal}
        factsetUser={factsetUser}
      />
      <UpdateFactsetUserModal
        isModalOpen={isUpdateFactsetUserModalOpen}
        closeModal={closeUpdateFactsetUserModal}
        factsetUser={transformFactsetUser(factsetMetadata)(factsetUser)}
      />
    </>
  )
}

export default FactsetUserDetail
