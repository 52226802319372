import React from 'react'

// Components
import { DataGridActionCell } from '@the-platform-group/component-library/DataGrid'
import { Edit as EditIcon, Delete as DeleteIcon } from '@the-platform-group/component-library/Icons'

export const getColumnValues = () => [
  ...[
    'fdsEmail',
    'fdsPhone',
    'fdsActive',
    'emailOverride',
    'phoneOverride',
    'activeOverride',
  ].map(v => ({ field: v })),
  { field: 'updatedAt', type: 'date' },
  {
    field: 'actions',
    type: 'actions',
    getActions: ({ row }) => [
      <DataGridActionCell icon={<EditIcon />} title="Edit" onClick={row.handleModalOpen} />,
      <DataGridActionCell
        icon={<DeleteIcon />}
        title="Delete"
        onClick={row.handleDeleteOverride}
        disabled={row.deleteDisabled}
      />,
    ],
  },
]

export const getRowValues = (jobs, props) => {
  const { isLoading, handleDeleteOverride, handleModalOpen } = props
  return jobs.map(job => {
    const { id, jobOverride } = job
    return {
      id,
      fdsEmail: job.email || '-',
      fdsPhone: job.phone || '-',
      fdsActive: job.active.toString(),
      updatedAt: job.updatedAt,
      emailOverride: jobOverride?.email || '-',
      phoneOverride: jobOverride?.phone || '-',
      activeOverride:
        !jobOverride || jobOverride?.active === null ? '-' : JSON.stringify(jobOverride?.active),
      deleteDisabled: isLoading || !jobOverride?.id,
      handleDeleteOverride: () => handleDeleteOverride(job),
      handleModalOpen: () => handleModalOpen(job),
    }
  })
}
