import { useController } from 'react-hook-form'
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@the-platform-group/component-library/Modal'
import { Button } from '@the-platform-group/component-library/Button'

import { useLinkFactsetLocation, useLinkFactsetLocationForm } from '../hooks/useLinkFactsetLocation'

import FactsetLocationSelector from './FactsetLocationSelector'
import CustomerSelect from 'pages/Users/components/CustomerSelect'

import { getLinkFactsetLocationTitle } from '../Utils'

function LinkFactsetLocationToCustomerModal({ isModalOpen, closeModal, customer, location }) {
  const { control, formState, handleSubmit, selectedLocation } = useLinkFactsetLocationForm({
    isModalOpen,
    customer,
    location,
  })
  const { isSaving, onSubmit } = useLinkFactsetLocation({
    selectedLocation,
    handleSubmit,
    closeModal,
  })

  const { field: customerSelectField } = useController({
    control,
    name: 'customer',
    rules: { required: true },
  })

  return (
    <Modal open={isModalOpen} closeModal={closeModal}>
      <ModalTitle title={getLinkFactsetLocationTitle(customer)} />
      <ModalContent>
        <CustomerSelect
          {...customerSelectField}
          activeCustomerOnly
          disabled={!!customer}
          includeCustomerType="all"
          label="Customer *"
          hasError={formState.errors.customer}
        />
        <br />
        <FactsetLocationSelector
          control={control}
          name="location"
          required
          disabled={!!location}
          hasError={formState.errors.location}
        />
      </ModalContent>
      <ModalActions>
        <Button variant="text" onClick={closeModal}>
          Cancel
        </Button>
        <Button isLoading={isSaving} onClick={onSubmit}>
          Link
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default LinkFactsetLocationToCustomerModal
