import { useEffect, useState } from 'react'
import { useModal } from 'hooks/useModal'

function useLinkFactsetEntityActions() {
  const [selected, setSelected] = useState()
  const { open, handleClose, handleOpen } = useModal()

  useEffect(() => {
    if (!open) {
      // clear selected location value upon modal close
      setSelected(null)
    }
  }, [open])

  const onLink = item => () => {
    if (item) {
      setSelected(item)
    }
    handleOpen()
  }

  return {
    selected,
    open,
    handleClose,
    handleOpen,
    onLink,
  }
}

export default useLinkFactsetEntityActions
