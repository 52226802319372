import Jsona, { SwitchCaseJsonMapper } from 'jsona'
import { irwin } from 'config'
import axios from 'axios'
import { httpClient } from './httpClient'
import { cancellableQuery } from './utils'
import { camelCase, snakeCase } from 'change-case-object'

const deserializer = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper(),
})
const deserializerAndCase = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper({ switchChar: '_' }),
})

function client() {
  return httpClient(irwin.baseUrl)
}

let searchRequestAbortController

async function getOrganization(id) {
  const response = await client().get(`v2/organizations/${id}`)
  return {
    data: deserializerAndCase.deserialize(response),
  }
}

async function getOrganizations(pageOffset, pageSize, term, options = {}) {
  const params = {
    exclude_nil_types: true,
    page_offset: pageOffset,
    page_size: pageSize,
    ...(term ? { term } : {}),
    ...options,
  }
  const response = await client().get('/v2/organizations/search', { params })

  return {
    data: deserializer.deserialize(response),
    meta: response.meta,
  }
}

async function getOrganizationPeople(organizationId) {
  const response = await client().get(`v2/organizations/${organizationId}/people`)

  return {
    data: deserializer.deserialize(response),
  }
}

async function getPlatformOrganizations(pageOffset, pageSize, term, options = {}) {
  const params = {
    page_offset: pageOffset,
    page_size: pageSize,
    ...(term ? { term } : {}),
    ...options,
  }
  const response = await client().get('v1/platform_organizations', { params })

  return {
    data: deserializer.deserialize(response),
    meta: response.meta,
  }
}

async function getPlatformOrganization(id) {
  const response = await client().get(`v1/platform_organizations/${id}`)
  return {
    data: deserializer.deserialize(response),
  }
}

async function approveOrganization(organizationId) {
  const response = await client().patch(`v1/platform_organizations/${organizationId}/approve`)

  return {
    data: deserializer.deserialize(response),
    meta: response.meta,
  }
}

async function saveOrganization(organization) {
  return organization.id ? updateOrganization(organization) : createOrganization(organization)
}

async function createOrganization(platform_organization) {
  //eslint-disable-line
  const response = await client().post('v1/platform_organizations', { platform_organization })

  return {
    data: deserializer.deserialize(response),
    meta: response.meta,
  }
}

async function updateOrganization(platform_organization) {
  //eslint-disable-line
  const response = await client().patch(`v1/platform_organizations/${platform_organization.id}`, {
    platform_organization,
  })

  return {
    data: deserializer.deserialize(response),
    meta: response.meta,
  }
}

async function deleteOrganization(platformOrganizationId) {
  return await client().delete(`v1/platform_organizations/${platformOrganizationId}`)
}

async function getPlatformOrganizationPeople(organizationId) {
  const response = await client().get(`v1/platform_organizations/${organizationId}/people`)

  return {
    data: deserializer.deserialize(response),
  }
}

async function searchOrganizations(params) {
  const pagination = params.page_size !== undefined && params.page_offset !== undefined

  if (searchRequestAbortController) {
    searchRequestAbortController.abort('Operation cancelled by the user.')
  }

  try {
    searchRequestAbortController = new AbortController()
    const response = await client().get('/v2/organizations/search', {
      params,
      signal: searchRequestAbortController.signal,
    })

    return {
      data: deserializer.deserialize(response),
      ...(pagination && {
        links: response.links,
        count: response.meta.total,
      }),
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request cancelled', error.message)
      return {}
    }

    throw error
  }
}

async function searchOrganizationsAdmin(params) {
  params = snakeCase(params)
  const pagination = params.page_size !== undefined && params.page_offset !== undefined

  if (searchRequestAbortController) {
    searchRequestAbortController.abort('Operation cancelled by the user.')
  }

  try {
    searchRequestAbortController = new AbortController()
    const response = await client().get('/v2/organizations/admin_search', {
      params,
      signal: searchRequestAbortController.signal,
    })

    return {
      data: deserializer.deserialize(response),
      ...(pagination && {
        links: response.links,
        count: response.meta.total,
      }),
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request cancelled', error.message)
      return {}
    }

    throw error
  }
}

async function getAllOrganizations(params, signal) {
  const response = await client().get('v2/organizations', { params: snakeCase(params), signal })

  return { data: deserializerAndCase.deserialize(response), meta: camelCase(response.meta) }
}

function searchAllOrganizations(params) {
  return cancellableQuery(getAllOrganizations, params, 300)
}

async function updateOrganizationSetting(params) {
  const response = await client().patch(
    `v2/organizations/${params.organizationId}/settings`,
    snakeCase(params),
  )
  return deserializerAndCase.deserialize(response)
}

export {
  getPlatformOrganizations,
  getPlatformOrganization,
  getPlatformOrganizationPeople,
  saveOrganization,
  approveOrganization,
  getOrganization,
  deleteOrganization,
  getOrganizations,
  getOrganizationPeople,
  searchOrganizations,
  searchOrganizationsAdmin,
  searchAllOrganizations,
  updateOrganizationSetting,
}
