const stringToColor = string => {
  let hash = 0
  let i
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

/**
 * Generate a background color based on the name
 * @param {object} params - The params object
 * @param {string} params.fullName - The full name to generate the color from
 * @returns {string} The generated color
 */
export const generateBgcolorByName = ({ fullName }) => stringToColor(fullName)

/**
 * Return the initials of a name
 * @param {string} name - The name to get the initials from
 * @returns {string} The initials
 */
export const getInitials = name => {
  const { firstName = '', lastName = '' } =
    typeof name === 'string' ? getFirstAndLastName(name) : name

  const firstNameInitial = firstName.charAt(0)
  const lastNameInitial = lastName.charAt(0)

  return firstNameInitial + lastNameInitial
}

/**
 * Return the first and last name from a full name
 * @param {string} name - The full name to get the first and last name from
 * @returns {object} { firstName, lastName }
 */
export const getFirstAndLastName = name => {
  const nameParts = name.split(' ')

  return {
    firstName: nameParts[0] || '',
    lastName: nameParts[nameParts.length - 1] || '',
  }
}

/**
 * Return KB or MB depending on the size as string
 * @param {number} size size of the file in bytes
 * @returns {string} The size as string
 */
export const getCalulatedSize = size => {
  const sizeInKB = Math.round(size / 1024)
  const returnString = calculatedSize => ` (${calculatedSize} KB)`

  if (sizeInKB < 1024) {
    return returnString(sizeInKB)
  }

  const sizeInMB = Math.round(sizeInKB / 1024)
  return returnString(sizeInMB)
}

export const AVATAR_SIZES = {
  small: 30,
  medium: 40,
  default: 60,
}

export const AVATAR_FONT_SIZES = {
  small: '1.5rem',
  medium: '2rem',
  default: '2.5rem',
}
