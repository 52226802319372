import { Link } from 'react-router-dom'
import { NoboRoutes } from './NoboRouter'
// icons
import { Edit as EditIcon } from '@the-platform-group/component-library/Icons'
import { DataGridActionCell } from '@the-platform-group/component-library/DataGrid'
import { ACTIONS, DATE, NUMBER } from '@the-platform-group/component-library/DataGrid/constant'

export const getColumnValues = customerOptions => {
  return [
    {
      align: 'left',
      headerName: 'NOBO',
      headerAlign: 'left',
      width: 520,
      flex: null,
      field: 'nobo',
    },
    {
      field: 'customer',
    },
    { field: 'shares', type: NUMBER, sortable: true, minWidth: 90 },
    { field: 'created', type: DATE, dateFormat: 'MMM DD, YYYY', sortable: true },
    {
      field: 'reviewer',
      valueGetter: ({ row }) => {
        const { lockedBy } = row
        return lockedBy ? [lockedBy.firstName, lockedBy.lastName].join(' ') : '-'
      },
    },
    {
      field: 'actions',
      type: ACTIONS,
      getActions: ({ id, row }) => {
        const { lockedBy, currentUserId } = row
        let result = []
        if (!lockedBy || lockedBy.id === currentUserId) {
          result = [
            <DataGridActionCell
              icon={<EditIcon />}
              component={Link}
              to={NoboRoutes.editReview(id)}
              title="Review"
            />,
          ]
        }
        return result
      },
    },
  ]
}

export const getRowValues = (reviews, currentUser) =>
  reviews.map(review => ({
    id: review.id,
    nobo: review.originalAddress,
    customer: review.customer.name,
    shares: review.shares,
    created: review.createdAt,
    lockedBy: review.lockedBy,
    currentUserId: currentUser.commonId,
  }))
