import { Auth0Client, PopupTimeoutError } from '@auth0/auth0-spa-js'
import axios from 'axios'
import { auth0Config, getCustomerId } from 'utils/auth'
import { loadAuthToken, saveAuthToken } from './auth'
import { toSnake } from 'change-object-case'
import { AUTHENTICATION_IN_FLIGHT } from 'constants/localStorage'

class Mutex {
  constructor() {
    this.delay = 250 // ms
  }

  isLocked() {
    return !!localStorage.getItem(AUTHENTICATION_IN_FLIGHT)
  }

  lock() {
    // the value doesn't actually matter here, we just need the presence of the token
    return localStorage.setItem(AUTHENTICATION_IN_FLIGHT, 'set')
  }

  release() {
    localStorage.removeItem(AUTHENTICATION_IN_FLIGHT)
  }

  awaitResolution(cb) {
    return new Promise(resolve => {
      const intervalId = setInterval(() => {
        if (!this.isLocked()) {
          resolve(cb())
          clearInterval(intervalId)
        }
      }, this.delay)
    })
  }
}

const requestMutex = new Mutex()

// clear any pending state from a previous browser session
localStorage.removeItem(AUTHENTICATION_IN_FLIGHT)

function resolveRequestWithCurrentToken(request) {
  return Promise.resolve(
    axios({
      ...request,
      headers: {
        ...request.headers,
        Authorization: `Bearer ${loadAuthToken()}`,
      }, // perform the same incoming request with new token
    }),
  )
    .then(response => response.data)
    .catch(async error => {
      return Promise.reject(error)
    })
}

function httpClient(url, rawResponse = false) {
  const token = loadAuthToken()
  const customerId = getCustomerId()
  const customerIdHeader = customerId ? { 'X-Irwin-Customer-Id': customerId } : {}
  const authHeader = token ? { Authorization: `Bearer ${token}` } : {}

  const API = axios.create({
    baseURL: url,
    responseType: 'json',
    headers: { ...authHeader, ...customerIdHeader },
  })

  API.interceptors.response.use(
    response => (rawResponse ? response : response.data),
    error => {
      // Avoid throwing on 401s, they won't be considered errors to
      // avoid bubbling up to an error boundary and clearing the exiting
      // DOM state, since the authentication will happen on a separate window
      // we can await for the success of the token.
      if (error?.response?.status === 401) {
        const client = new Auth0Client(toSnake(auth0Config))

        function retryRequestWithAuthentication(client, authenticationAttempted) {
          // Lock any other requests from going out until the user has been authenticated,
          // and poll to find if this lock has been resolved on a separate request
          if (requestMutex.isLocked()) {
            return requestMutex.awaitResolution(() => resolveRequestWithCurrentToken(error.config))
          }

          requestMutex.lock()

          return client
            .getTokenWithPopup()
            .then(token => {
              saveAuthToken(token)
              return Promise.resolve(
                axios({
                  ...error.config,
                  headers: { ...error.config.headers, Authorization: `Bearer ${token}` }, // perform the same incoming request with new token
                }),
              )
            })
            .then(response => response.data)
            .catch(async error => {
              // retry authenticating in case of network failure
              if (!authenticationAttempted && error instanceof PopupTimeoutError) {
                requestMutex.release()
                return retryRequestWithAuthentication(client, true)
              }

              return Promise.reject(error)
            })
            .finally(() => requestMutex.release())
        }

        return retryRequestWithAuthentication(client, false)
      }

      return Promise.reject(error)
    },
  )

  return API
}

export { httpClient }
