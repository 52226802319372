import { AsyncSelect } from '@the-platform-group/component-library'
import { useController } from 'react-hook-form'

import useFactsetLocationSearch from '../hooks/useFactsetLocationSearch'
import debounce from 'utils/debounce'

/**
 * @param {object} param0 - props
 * @param {object} param0.control - react hook form control
 * @param {string} param0.name - react hook form field name
 * @param {boolean} param0.required - is the field required or not
 * @param {function} param0.onChange - additional function to call with onChange event
 * @returns {Node} Async selector for factset location
 */
function FactsetLocationSelector({ control, customerId, name = 'location', required, ...rest }) {
  const { searchLocations } = useFactsetLocationSearch(customerId)
  // this field is current required for all existing use cases
  // but feel free to update it if that changes in the future
  const { field, formState } = useController({ control, name, rules: { required } })

  return (
    <AsyncSelect
      fullWidth
      hasError={formState.errors.location}
      includeClearAll
      label={`Factset Location ${required ? '*' : ''}`}
      margin="none"
      optionsLoader={debounce(searchLocations)}
      placeholder="Search for Factset location"
      variant="outlined"
      {...field} // name, onChange, onBlur, ref, value, etc.
      {...rest}
    />
  )
}

export default FactsetLocationSelector
