import { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { TextField, InputAdornment, CircularProgress } from '@the-platform-group/component-library'

const ShareInput = ({ api, ...props }) => {
  const [isLoading, setIsLoading] = useState(false)

  // Subscribe to the cellEditStop event to show a loading indicator
  useEffect(() => {
    const handleCellEditStopEvent = () => {
      setIsLoading(true)
    }
    // Unsubscribe from the event when the component unmounts - subscribeEvent returns an unsubscribe function
    return api.subscribeEvent('cellEditStop', handleCellEditStopEvent)
  }, [api])

  return (
    <TextField
      variant="outlined"
      size="small"
      {...props}
      InputProps={{
        ...(isLoading && {
          endAdornment: (
            <InputAdornment position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          ),
        }),
      }}
      disabled={isLoading}
    />
  )
}

const TotalShareInputCell = ({ id, value, field, api }) => {
  const handleChange = values => {
    api.setEditCellValue({ id, field, value: Number(values.value) })
  }
  return (
    <NumberFormat
      value={value}
      placeholder="Total Shares"
      allowNegative={false}
      onValueChange={handleChange}
      thousandSeparator
      decimalScale={0}
      customInput={ShareInput}
      api={api} // Pass the api to the custom input so we can subscribe to events
    />
  )
}

export default TotalShareInputCell
