import { Link } from 'react-router-dom'
import { DataGrid } from '@the-platform-group/component-library/DataGrid'
import { Box, styled, Typography } from '@the-platform-group/component-library'

const StyledBox = styled(Box)(() => ({ display: 'flex', flexDirection: 'column' }))

const getColumnValues = () => {
  return [
    {
      field: 'customer',
      cellClassName: 'hoverCell',
      renderCell: ({ row }) => {
        const { id, name } = row.customer
        return (
          <StyledBox>
            <Link to={`/accounts/customers/${id}`}>{name}</Link>
            <Typography variant="body2" component="span">
              {id}
            </Typography>
          </StyledBox>
        )
      },
    },
    { field: 'scope' },
    {
      field: 'lockedAt',
      headerName: 'Active',
      valueGetter: ({ row }) => (row.lockedAt ? 'No' : 'Yes'),
    },
  ]
}

const getRowValues = rowValues => {
  return rowValues.map(row => {
    const { id, customer, lockedAt, scope } = row
    return {
      id: id,
      customer: {
        id: customer.id,
        name: customer.name,
      },
      scope: scope,
      lockedAt: lockedAt,
    }
  })
}

const UserCustomers = ({ user }) => {
  const { customerAuthorizations } = user

  return (
    <>
      <Typography variant="h5" component="h2">
        Customers
      </Typography>
      <DataGrid
        name="UserCustomersTable"
        columns={getColumnValues()}
        commonColumnProps={{ headerAlign: 'left' }}
        rows={getRowValues(customerAuthorizations)}
        rowCount={customerAuthorizations.length}
        localeText={{
          noRowsLabel: 'No customers have authorized this user.',
        }}
        sx={{
          '.hoverCell': {
            '&.MuiDataGrid-cell:hover span, &.MuiDataGrid-cell:hover .MuiButtonBase-root': {
              display: 'block',
              fontSize: 11,
            },
            'span, .MuiButtonBase-root': { display: 'none' },
          },
        }}
      />
    </>
  )
}

export default UserCustomers
