import React from 'react'

import {
  Typography,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
} from '@the-platform-group/component-library'

const MergeType = ({ value, onChangeTypeSelection }) => {
  return (
    <FormControl fullWidth>
      <RadioGroup
        id="mergeType"
        aria-labelledby="mergeType"
        name="mergeType"
        value={value}
        onChange={onChangeTypeSelection}
        row={true}
      >
        <FormControlLabel
          value="people"
          control={<Radio />}
          label={<Typography variant="body2">People</Typography>}
        />
        <FormControlLabel
          value="organization"
          control={<Radio />}
          label={<Typography variant="body2">Organization</Typography>}
        />
      </RadioGroup>
    </FormControl>
  )
}

export default MergeType
