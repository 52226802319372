import React, { useState } from 'react'
import {
  RawButton as Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@the-platform-group/component-library'
import { Button as LoadingButton } from '@the-platform-group/component-library/Button'

const DeleteConfirmationDialog = ({
  onClickConfirm,
  dialogTitle,
  dialogContent,
  isOpen,
  handleClose,
  disabled,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{dialogContent}</DialogContentText>
        {disabled && (
          <DialogContentText
            sx={{
              color: 'error.main',
            }}
          >
            Holder cannot be deleted because it may have holdings recorded on previous reports.
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Disagree
        </Button>
        <LoadingButton
          onClick={() => {
            setIsLoading(true)
            onClickConfirm()
          }}
          color="primary"
          isLoading={isLoading}
          disabled={disabled}
        >
          Agree
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteConfirmationDialog
