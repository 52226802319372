import React from 'react'
import PropTypes from 'prop-types'
// Tables
import { DataGrid } from '@the-platform-group/component-library/DataGrid'
// components
import { Button } from '@the-platform-group/component-library/Button'
import { TextField, Typography } from '@the-platform-group/component-library'
import DeleteRowModal from 'components/EntityDelete'

import ENTITY_TYPES from 'constants/entityTypes'
import { getColumnValues } from './PlatformColumnConfig'
// hooks
import usePlatformData from './hooks/usePlatformData'
import usePlatformDelete from './hooks/usePlatformDelete'
import usePlatformTableModal from './hooks/usePlatformTableModal'

const PlatformTable = ({
  columns = [],
  history,
  toEntityLabel = l => l,
  toRowData = r => r,
  type,
}) => {
  const label = ENTITY_TYPES[type].plural

  const {
    data,
    canPaginate,
    handlePagination,
    term,
    handleSearchTerm,
    isLoading,
  } = usePlatformData({ type })

  const { deletedIds, handleDelete } = usePlatformDelete({
    history,
    toEntityLabel,
    type,
  })

  const { modalState, handleArchive, handleModalClose, handleModalOpen } = usePlatformTableModal({
    handleDelete,
  })

  return (
    <>
      <Typography
        gutterBottom
        variant="h5"
        sx={{ fontWeight: 'fontWeightBold', my: 2 }}
      >{`Platform ${label}`}</Typography>
      <TextField
        placeholder="Search"
        margin="none"
        name="term"
        variant="outlined"
        value={term}
        onChange={handleSearchTerm}
        sx={{ mb: 1 }}
      />
      <Button color="primary" onClick={() => history.push(`/platform/${label}/new`)} sx={{ ml: 2 }}>
        {`Add new ${ENTITY_TYPES[type].singular}`}
      </Button>
      <DataGrid
        columns={getColumnValues(columns, { handleModalOpen, type })}
        rows={toRowData(data, deletedIds)}
        getRowHeight={() => 'auto'}
        loading={isLoading}
        name={`platform${type}`}
        pagination={false}
      />
      <Button onClick={handlePagination} disabled={!canPaginate}>
        Show More
      </Button>
      <DeleteRowModal
        entityType={type}
        entityLabel={toEntityLabel(modalState.selectedRow)}
        modalOpen={modalState.isOpen}
        onArchive={handleArchive}
        onModalClose={handleModalClose}
      />
    </>
  )
}
PlatformTable.propTypes = {
  history: PropTypes.object.isRequired,
}

export default PlatformTable
