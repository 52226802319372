import React, { useMemo } from 'react'

import { Grid } from '@the-platform-group/component-library'
import { AsyncSelect } from '@the-platform-group/component-library'

import SecuritySelect from 'components/Select/SecuritySelect'

import { debouncedLoadCustomers } from 'utils/optionsLoaders'
import SelectProfile from './SelectProfile'

const ProfileSelectionActions = ({
  selectedCustomer,
  setSelectedCustomer,
  selectedSecurity,
  setSelectedSecurity,
  setSelectedEntityId,
  setSelectedEntityType,
}) => {
  const optionsLoader = useMemo(() => {
    return term => debouncedLoadCustomers({ term })
  }, [])

  const handleChangeSecurity = security => {
    setSelectedSecurity(security)
  }

  return (
    <Grid item container spacing={2} alignItems="center">
      <Grid item container xs={12} sm={5} spacing={2} alignItems="center">
        <Grid item xs={12} sm={6}>
          <AsyncSelect
            includeClearAll
            id="customerId"
            label="Search Customer"
            variant="outlined"
            value={selectedCustomer}
            onChange={setSelectedCustomer}
            optionsLoader={optionsLoader}
            margin="none"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SecuritySelect
            id="securityId"
            label="Select a Security"
            value={selectedSecurity}
            onChange={handleChangeSecurity}
            margin="none"
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={7} spacing={2} alignItems="center">
        <Grid item container direction="row">
          <SelectProfile
            setSelectedEntityId={setSelectedEntityId}
            setSelectedEntityType={setSelectedEntityType}
            selectedCustomer={selectedCustomer}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ProfileSelectionActions
