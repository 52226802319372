import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from '@the-platform-group/formatters/checks'
import Chip from 'components/Chip'
import useFocuses from 'hooks/useFocuses'

const FocusChips = ({ focuses, handleFocusDelete, type }) => {
  const { focuses: focusDefinitions } = useFocuses()
  return (
    <Fragment>
      {isEmpty(focuses) && <p>You haven&apos;t selected any focuses yet</p>}
      {focuses
        .filter(jobFocus => !jobFocus.destroy && jobFocus.category)
        .map(f => (
          <Chip
            key={f.categoryValue}
            data-testid="selected-focus"
            // we can use focusDefinitions.CATEGORIES here even if it's organization
            // it will never show STYLE since org won't have that value
            label={`${focusDefinitions.CATEGORIES[f.category]}: ${
              focusDefinitions.CATEGORY_VALUES[f.category][f.categoryValue]
            }`}
            onDelete={() => handleFocusDelete(f, type)}
          />
        ))}
    </Fragment>
  )
}

FocusChips.propTypes = {
  focuses: PropTypes.array.isRequired,
  handleFocusDelete: PropTypes.func.isRequired,
  type: PropTypes.string,
}

export default FocusChips
