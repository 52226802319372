export const datasources = {
  ds1: 'Factset',
  ds2: 'Irwin',
  ds3: 'PGL',
  ds4: 'Nylas',
  ds5: 'Alliance',
  ds6: 'Broadridge',
  ds7: 'Okapi',
  ds11: 'Morrow Sodali',
}
