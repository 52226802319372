import React from 'react'

import { Grid } from '@the-platform-group/component-library/Layout'
import { Box } from '@the-platform-group/component-library'
import { H1 } from 'components/Typography'

import Actions from './components/Actions'
import NewReportModal from './components/NewReportModal'
import NewHolderModal from './components/NewHolderModal'

import SurveillanceDataGrid from 'pages/Surveillance/components/SurveillanceDataGrid'
import useSurveillanceTable from './hooks/useSurveillanceTable'
import useSurveillanceData from './hooks/useSurveillanceData'
import { SurveillanceContextProvider, useSurveillanceContext } from './hooks/SurveillanceContext'
import { useNotification } from 'components/Notification'

function SurveillancePage() {
  const { createNotification } = useNotification()

  // Surveillance Context (Pagination, Search, Select Security and Include Zeroed Holders)
  const {
    page,
    pageSize,
    handlePageChange,
    handlePageSizeChange,
    isLoading,
  } = useSurveillanceContext()

  const {
    handleRowEditStart,
    handleRowEditStop,
    processRowUpdate,
    onCellEditStop,
  } = useSurveillanceTable({ createNotification }) //Datagrid related hook

  const {
    holdersData,
    reportsContent,
    holdersMap,
    isLoading: isSurveillanceDataLoading,
    combinedRows,
    columns,
    columnGroupingModel,
    excludedReportDates,
  } = useSurveillanceData() //Data related hook

  const latestReport = reportsContent?.[0]

  return (
    <>
      <H1>Surveillance Portal</H1>
      <Grid container wrap="nowrap" direction="column">
        <Grid item container xs={12}>
          <Actions
            isLoading={isSurveillanceDataLoading || isLoading}
            hasHolders={Boolean(holdersData.data.length)}
            hasReport={Boolean(reportsContent.length)}
          />
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ height: 'calc(100vh - 255px)' }} // 255px is other elements' height to make the grid full screen
          // 48px(Layout Padding) + 64px (Navigation) + 74px (Page Title) + 69px (Table Actions)
        >
          <Box sx={{ height: '100%', position: 'relative', width: '100%' }}>
            <SurveillanceDataGrid
              loading={isSurveillanceDataLoading || isLoading}
              rows={combinedRows}
              columns={columns}
              columnGroupingModel={columnGroupingModel}
              handleRowEditStart={handleRowEditStart}
              handleRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              onCellEditStop={onCellEditStop}
              // pagination
              page={page}
              pageSize={pageSize}
              rowCount={holdersData.count}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              sx={{
                '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
                  p: '0 !important',
                },
                '& .editable-cell': {
                  padding: '0 !important', // override default padding for editable cells, since we using FormattedViewOnlyCell as custom cell renderer
                },
              }}
              createNotification={createNotification}
            />
          </Box>
        </Grid>
      </Grid>
      <NewReportModal excludedReportDates={excludedReportDates} />
      <NewHolderModal
        securityPrice={holdersData.data[0]?.price}
        holdersMap={holdersMap}
        latestReport={latestReport}
      />
    </>
  )
}

const SurveillancePageContextWrapper = () => {
  return (
    <SurveillanceContextProvider>
      <SurveillancePage />
    </SurveillanceContextProvider>
  )
}

export default SurveillancePageContextWrapper
