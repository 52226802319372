import { formatDate } from '@the-platform-group/formatters/dateFormat'
import { MONTH_YEAR_FORMAT } from 'constants/formats'
import { createContext, useContext, useMemo, useReducer } from 'react'

const initialContext = {
  selectedSecurity: {},
  page: 0,
  pageSize: 50,
  searchTerm: '',
  includeZeroedHolders: false,
  isNewHolderModalOpen: false,
  isNewReportModalOpen: false,
  isLoading: false,
  reports: [],
  selectedDateByMonth: null,
  from: null,
  to: null,
}

export const SurveillanceActionTypes = {
  SET_SELECTED_SECURITY: 'SET_SELECTED_SECURITY',
  SET_PAGE: 'SET_PAGE',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  SET_SEARCH_TERM: 'SET_SEARCH_TERM',
  TOGGLE_INCLUDE_ZEROED_HOLDERS: 'TOGGLE_INCLUDE_ZEROED_HOLDERS',
  SET_NEW_HOLDER_MODAL: 'SET_NEW_HOLDER_MODAL',
  SET_NEW_REPORT_MODAL: 'SET_NEW_REPORT_MODAL',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_REPORTS: 'SET_REPORTS',
  SET_SELECTED_DATE_BY_MONTH: 'SET_SELECTED_DATE_BY_MONTH',
  SET_REPORTS_DATE_RANGE: 'SET_REPORTS_DATE_RANGE',
}

const SurveillanceContext = createContext(initialContext)

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case SurveillanceActionTypes.SET_SELECTED_SECURITY:
      return {
        ...state,
        selectedSecurity: payload,
        searchTerm: '', // reset search term when security changes
        page: 0, // reset page when security changes
        includeZeroedHolders: false, // reset includeZeroedHolders when security changes
      }
    case SurveillanceActionTypes.SET_PAGE:
      return {
        ...state,
        page: payload,
      }
    case SurveillanceActionTypes.SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: payload,
        page: 0, // reset page when pageSize changes
      }
    case SurveillanceActionTypes.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: payload,
        page: 0, // reset page when searchTerm changes
      }
    case SurveillanceActionTypes.TOGGLE_INCLUDE_ZEROED_HOLDERS:
      return {
        ...state,
        includeZeroedHolders: !state.includeZeroedHolders,
        page: 0, // reset page when includeZeroedHolders changes
      }
    case SurveillanceActionTypes.SET_NEW_HOLDER_MODAL:
      return {
        ...state,
        isNewHolderModalOpen: payload,
      }
    case SurveillanceActionTypes.SET_NEW_REPORT_MODAL:
      return {
        ...state,
        isNewReportModalOpen: payload,
      }
    case SurveillanceActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case SurveillanceActionTypes.SET_REPORTS:
      return {
        ...state,
        reports: payload,
      }
    case SurveillanceActionTypes.SET_SELECTED_DATE_BY_MONTH:
      return {
        ...state,
        selectedDateByMonth: payload,
        ...(payload === null && { from: null, to: null }), // reset year date range when date is null
      }
    case SurveillanceActionTypes.SET_REPORTS_DATE_RANGE:
      return {
        ...state,
        from: payload.from,
        to: payload.to,
      }
    default:
      return state
  }
}

export const SurveillanceContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialContext)

  return (
    <SurveillanceContext.Provider value={{ state, dispatch }}>
      {children}
    </SurveillanceContext.Provider>
  )
}

export const useSurveillanceContext = () => {
  const { state, dispatch } = useContext(SurveillanceContext)
  if (state === undefined || dispatch === undefined) {
    throw new Error('useSurveillance must be used within a SurveillanceContextProvider')
  }

  const handleChangeSecurity = payload =>
    dispatch({
      type: SurveillanceActionTypes.SET_SELECTED_SECURITY,
      payload,
    })

  const handlePageChange = payload =>
    dispatch({
      type: SurveillanceActionTypes.SET_PAGE,
      payload,
    })

  const handlePageSizeChange = payload =>
    dispatch({
      type: SurveillanceActionTypes.SET_PAGE_SIZE,
      payload,
    })

  const handleSearchTermChange = payload =>
    dispatch({
      type: SurveillanceActionTypes.SET_SEARCH_TERM,
      payload,
    })

  const toggleIncludeZeroedHolders = () =>
    dispatch({
      type: SurveillanceActionTypes.TOGGLE_INCLUDE_ZEROED_HOLDERS,
    })

  const handleNewHolderModal = payload =>
    dispatch({
      type: SurveillanceActionTypes.SET_NEW_HOLDER_MODAL,
      payload,
    })

  const handleNewReportModal = payload =>
    dispatch({
      type: SurveillanceActionTypes.SET_NEW_REPORT_MODAL,
      payload,
    })

  const setIsLoading = payload =>
    dispatch({
      type: SurveillanceActionTypes.SET_IS_LOADING,
      payload,
    })

  const setReports = payload =>
    dispatch({
      type: SurveillanceActionTypes.SET_REPORTS,
      payload,
    })

  const setSelectedDateByMonth = payload =>
    dispatch({
      type: SurveillanceActionTypes.SET_SELECTED_DATE_BY_MONTH,
      payload,
    })

  const setReportsDateRange = payload =>
    dispatch({
      type: SurveillanceActionTypes.SET_REPORTS_DATE_RANGE,
      payload,
    })

  const reportIds = useMemo(() => state.reports.map(report => report.id), [state.reports])

  const selectedMonthAndYear = useMemo(() => {
    if (!state.selectedDateByMonth) {
      return null
    }

    return formatDate(state.selectedDateByMonth, MONTH_YEAR_FORMAT)
  }, [state.selectedDateByMonth])

  return {
    ...state,
    reportIds,
    selectedMonthAndYear,
    handleChangeSecurity,
    handlePageChange,
    handlePageSizeChange,
    handleSearchTermChange,
    toggleIncludeZeroedHolders,
    handleNewHolderModal,
    handleNewReportModal,
    setIsLoading,
    setReports,
    setSelectedDateByMonth,
    setReportsDateRange,
  }
}
