import { Button } from '@the-platform-group/component-library/Button'
import { Alert, Chip, Grid } from '@the-platform-group/component-library'
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@the-platform-group/component-library/Modal'
import { useFactsetMetadata } from 'contexts/FactsetMetadataContext'
import { useFactsetUser, useFactsetUserForm } from '../hooks/useFactsetUser'
import { useFactsetUserUpdateActions } from '../hooks/useCreateUpdateFactsetUser'

import {
  CommonFactsetUserForm,
  ManagedFactsetUserForm,
  UnmanagedFactsetUserForm,
} from './FactsetUserForms'

import { transformFactsetUser } from '../Utils'

/**
 * @param {object} param0 - modal component props
 * @param {boolean} param0.isModalOpen - required, flag of whether modal is isModalOpen or closed
 * @param {function} param0.closeModal - required, function to close modal
 * @param {string} param0.factsetUserId - optional, a FS user id by which we'll use to fetch user info
 *    this is typically passed in where we don't have full FS user info, such as on FS users table
 * @param {object} param0.factsetUser - optional, a FS user info, if this is passed in, then we will use
 *    this as the base factset user object & won't need to call an API
 *    @factsetUser & @factsetUserId serve the same purpose in the end
 * @param {object} param0.irwinUser - optional, an Irwin user info, if this is passed in, then we will
 *    use it to prefill Irwin user selector on the form
 * @returns {Node} Modal containing form to update a FS user
 */
function UpdateFactsetUserModal({
  closeModal,
  isModalOpen,
  customerId,
  factsetUser,
  factsetUserId,
  factsetLocation,
  irwinUser,
}) {
  const {
    data: factsetMetadata,
    isFetching: isFetchingMetadata,
    error: metadataError,
  } = useFactsetMetadata()

  const { data: fetchedFactsetUser, error: factsetUserError } = useFactsetUser(factsetUserId, {
    enabled: Boolean(factsetUserId && !isFetchingMetadata),
    select: transformFactsetUser(factsetMetadata),
    keepPreviousData: false,
  })

  const formValues = factsetUser || fetchedFactsetUser
  const formControls = useFactsetUserForm({
    factsetUser: formValues,
    factsetLocation,
    irwinUser,
  })
  const formActions = useFactsetUserUpdateActions(closeModal)

  const type = formValues?.type

  const modalContent =
    factsetUserError || (metadataError && type === 'managed') ? (
      <Alert severity="error">
        There was an error loading the form. Please try reloading the page.
      </Alert>
    ) : (
      <Grid container spacing={2}>
        <CommonFactsetUserForm
          customerId={customerId}
          factsetLocation={factsetLocation}
          irwinUser={irwinUser}
          type={type}
          {...formControls}
        />
        {type === 'unmanaged' && <UnmanagedFactsetUserForm {...formControls} isEdit />}
        {type === 'managed' && (
          <ManagedFactsetUserForm {...formControls} factsetMetadata={factsetMetadata} isEdit />
        )}
      </Grid>
    )

  return (
    <Modal open={isModalOpen} closeModal={closeModal}>
      <ModalTitle
        title={
          <>
            Update Factset User&nbsp;&nbsp;
            {type && <Chip label={type} size="small" color="secondary" />}
          </>
        }
      />
      <ModalContent>
        <Grid container>{modalContent}</Grid>
      </ModalContent>
      <ModalActions>
        <Button disabled={formActions.isSaving} variant="text" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          isLoading={formActions.isSaving}
          onClick={formControls.handleSubmit(formActions.onSaveForm(factsetMetadata))}
        >
          Save
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default UpdateFactsetUserModal
