import React from 'react'
import { Provider as CustomerAffiliationProvider } from './contexts/CustomerAffiliationContext'
import { Provider as AffiliationHoldingContext } from './contexts/AffiliationHoldingContext'
import { SearchInputProvider } from './contexts/SearchInputContext'
import { Provider as AffiliationTaskContext } from './contexts/AffiliationTaskContext'
import { Provider as AffiliationNoteContext } from './contexts/AffiliationNoteContext'
import AffiliationsTable from './components/AffiliationsTable'
import Header from './components/Header'
import HoldingsTable from './components/HoldingsTable'
import TasksTable from './components/TasksTable'
import NotesTable from './components/NotesTable'
import { StyledPaper } from 'components/Paper'
import { H3 } from 'components/Typography'

function AffiliationPage() {
  return (
    <SearchInputProvider>
      <Header />
      <CustomerAffiliationProvider>
        <StyledPaper>
          <H3>Affiliations</H3>
          <AffiliationsTable />
        </StyledPaper>
      </CustomerAffiliationProvider>
      <AffiliationHoldingContext>
        <StyledPaper>
          <H3>Holdings</H3>
          <HoldingsTable />
        </StyledPaper>
      </AffiliationHoldingContext>
      <AffiliationTaskContext>
        <StyledPaper>
          <H3>Task</H3>
          <TasksTable />
        </StyledPaper>
      </AffiliationTaskContext>
      <AffiliationNoteContext>
        <StyledPaper>
          <H3>Notes</H3>
          <NotesTable />
        </StyledPaper>
      </AffiliationNoteContext>
    </SearchInputProvider>
  )
}

export default AffiliationPage
