import React from 'react'

import { Grid } from '@the-platform-group/component-library'
import { Table, TableBody } from '@the-platform-group/component-library/Table'
import LabelledField from './LabelledField'

const PeopleProfileContent = ({ person }) => {
  if (!person) {
    return null
  }

  return (
    <Grid item>
      <Table>
        <TableBody>
          <LabelledField fieldName="source" label="Source" fieldValue={person.legacyType} />
          <LabelledField
            fieldName="name"
            label="Name"
            fieldValue={`${person.firstName} ${person.lastName}`}
          />
          {/* Add more fields */}
        </TableBody>
      </Table>
    </Grid>
  )
}

export default PeopleProfileContent
