import React from 'react'
import PropTypes from 'prop-types'

// imports
import { DataGrid } from '@the-platform-group/component-library/DataGrid'
import { Box, Select } from '@the-platform-group/component-library'
import { Grid } from '@the-platform-group/component-library/Layout'

// hooks
import useReviews from './hooks/useReviews'
import { useSessionState } from 'contexts/Session'
import { getColumnValues, getRowValues } from './ReviewTableValues'

const ReviewListComponent = () => {
  const { filterState, reviews, customerOptions, processing, setCustomerFilter } = useReviews()
  const { currentUser } = useSessionState()

  return (
    <>
      <h1>NOBO Reviews</h1>
      <Grid container spacing={3}>
        <Grid item xs={9} />
        <Grid item xs={3}>
          <Select
            allowEmpty
            fullWidth
            id="customer_id"
            name="customer_id"
            placeholder="Filter by Customer..."
            options={customerOptions}
            value={filterState.customerId}
            onChange={e => setCustomerFilter(e.target)}
          />
        </Grid>
      </Grid>
      <Box sx={{ flexGrow: 1 }}>
        <DataGrid
          columns={getColumnValues(customerOptions)}
          commonColumnProps={{ align: 'center', headerAlign: 'center' }}
          rows={getRowValues(reviews, currentUser)}
          initialState={{
            sorting: {
              sortModel: [{ field: 'created', sort: 'asc' }],
            },
          }}
          loading={processing}
          name="noboReviews"
          paginationMode="client"
          pageSizeOptions={[25, 50, 100]}
        />
      </Box>
    </>
  )
}

ReviewListComponent.propTypes = {
  history: PropTypes.object.isRequired,
}

export default ReviewListComponent
