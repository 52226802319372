import { useState, useEffect } from 'react'
import changeCase from 'change-case-object'
import transform_linkedin_url from 'linkedin-canonical-url'
// services
import { useMutation, useQueryClient } from 'react-query'
import { getPlatformPerson, savePerson } from 'services/people'
// hooks
import { useNotification } from 'components/Notification'
import { isEmpty } from '@the-platform-group/formatters/checks'
import { createInternalNote } from 'services/internalNotes'
import INTERNAL_NOTE_TYPES from 'constants/internalNoteTypes'

const defaultPerson = {
  id: null,
  firstName: '',
  lastName: '',
  bio: '',
  jobs: [],
  personSocialProfiles: [],
  internalNotes: [{ content: '' }],
}

const defaultJobToggle = {
  editingJobId: null,
  addingJob: false,
}

const usePersonEditForm = match => {
  const [person, setPerson] = useState({ ...defaultPerson })
  const [editingJob, setEditingJob] = useState({})
  const [jobToggle, setJobToggle] = useState({ ...defaultJobToggle })
  const { createNotification } = useNotification()
  const personId = match.params.id

  // mutation for saving an internal note and refreshing the internal note list/table
  const queryClient = useQueryClient()
  const saveInternalNotesMutation = useMutation(
    ({ internalNoteContent, personId, internalNoteType }) =>
      createInternalNote(internalNoteContent, personId, internalNoteType),
    {
      onSuccess: () => {
        // Invalidate internal note list query to refetch the data
        queryClient.invalidateQueries('internalNoteItems')
      },
    },
  )

  useEffect(() => {
    async function fetchPerson() {
      try {
        const { data: editingPerson } = await getPlatformPerson(personId)
        setPerson(buildPersonFromResponse(editingPerson))
      } catch (error) {
        setPerson(() => {
          throw error
        })
      }
    }

    fetchPerson()
  }, [personId])

  const handlePersonChange = ({ target }) => {
    setPerson({ ...person, [target.name]: target.value })
  }

  const { isLoading: isCreatingPerson, mutateAsync: savePersonMutation } = useMutation(
    personParams => savePerson(personParams),
    {
      onSuccess: ({ data: updatedPerson }) => {
        setPerson(buildPersonFromResponse(updatedPerson))
        createNotification({ variant: 'success', message: 'Person was saved' })
      },
      onError: e => {
        if (e.status === 401 || e.status === 403) {
          setPerson(() => {
            throw e
          })
        }
        const data = Object.keys(e.response?.data || {})
        const errors = data.map(err => `${err}: ${e.response?.data[err]}`).join(', ')
        const message = isEmpty(errors) ? 'Person can not save' : errors
        createNotification({ message, variant: 'error' })
      },
    },
  )

  const handlePersonUpdate = ({ personSocialProfiles, internalNoteContent }) => {
    const personSocialProfilesParams = personSocialProfiles.map(data => {
      data.url = data.url ? transform_linkedin_url(data.url.trim()) : ''
      return data
    })
    const personParams = {
      ...changeCase.snake(person),
      person_social_profiles_attributes: personSocialProfilesParams,
    }

    savePersonMutation(personParams)
    if (internalNoteContent) {
      // only send request if there is content
      saveInternalNotesMutation.mutate({
        internalNoteContent,
        personId: person.id,
        internalNoteType: INTERNAL_NOTE_TYPES.platformPerson,
      })
    }
  }

  const handleJobFormToggle = (jobId, addJob) => {
    setJobToggle({
      editingJobId: jobId,
      addingJob: addJob,
    })
  }
  const handleJobEdit = selectedJob => {
    setEditingJob({ ...selectedJob })
    handleJobFormToggle(selectedJob.id, false)
  }
  const handleJobAdd = editedJob => {
    if (editedJob.id) {
      const uneditedJobs = person.jobs.filter(job => !(job.id === editedJob.id)) || []
      setPerson({
        ...person,
        jobs: [editedJob].concat(uneditedJobs),
      })
    } else {
      setPerson(prevPerson => ({
        ...prevPerson,
        jobs: [editedJob].concat(prevPerson.jobs),
      }))
    }
    setEditingJob({})
    setJobToggle({ ...defaultJobToggle })
  }

  return {
    person,
    editingJob,
    jobToggle,
    isCreatingPerson,
    handleJobFormToggle,
    handleJobEdit,
    handleJobAdd,
    handlePersonChange,
    handlePersonUpdate,
  }
}

function buildPersonFromResponse(person) {
  return {
    ...person,
    jobs: person.jobs.map(j => {
      // TODO: delete this when we get rid of the iso's on the backend
      const { countryIso, ...address } = j.address || {}

      return {
        ...j,
        address: { ...address, country: countryIso || address.country },
        organization: person.organizations.find(o => o.id === j.organizationId),
      }
    }),
  }
}

export { usePersonEditForm }
