import { useState } from 'react'

const initialState = {
  firstName: '',
  lastName: '',
  street: '',
  street2: '',
  city: '',
  state: '',
  country: '',
  postalCode: '',
}

function useReviewForm() {
  const [formState, setFormState] = useState(initialState)

  const onChange = ({ target }) => {
    setFormState({ ...formState, [target.name]: target.value })
  }

  const onAddressSelect = address => {
    const { street, street2, city, state, country, postalCode } = address
    setFormState({ ...formState, street, street2, city, state, country, postalCode })
  }

  return { formState, onChange, onAddressSelect }
}

export default useReviewForm
