const ENTITY_TYPES = {
  ORGANIZATION: {
    singular: 'organization',
    plural: 'organizations',
  },
  PERSON: {
    singular: 'person',
    plural: 'people',
  },
}

export default ENTITY_TYPES
