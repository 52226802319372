import React, { Component } from 'react'

import {
  AppBar,
  RawButton as Button,
  Toolbar,
  Typography,
} from '@the-platform-group/component-library'

import { SessionHandlerContext } from 'contexts/Session'

class MenuTopComponent extends Component {
  logout = () => {
    const { endSession } = this.context
    endSession()
  }

  static contextType = SessionHandlerContext

  render() {
    return (
      <AppBar
        position="fixed"
        sx={theme => ({
          backgroundColor: theme.palette.primary.dark,
          zIndex: theme.zIndex.drawer + 1,
        })}
      >
        <Toolbar>
          <Typography
            variant="h6"
            color="inherit"
            sx={{
              flexGrow: 1,
            }}
          >
            Platform Group Admin
          </Typography>
          <Button color="inherit" onClick={this.logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    )
  }
}

export default MenuTopComponent
