import { useMutation, useQueryClient } from 'react-query'
import { deleteFactsetUser as deleteFactsetUserService } from 'services/factsetProvisioning'
import { useNotification } from 'components/Notification'

function useDeleteFactsetUser(id) {
  const { createNotification } = useNotification()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async () => {
      return deleteFactsetUserService(id)
    },
    onSuccess: () => {
      createNotification({
        variant: 'success',
        message: 'Sucessfully deleted Factset user.',
      })
      queryClient.invalidateQueries({ queryKey: ['factsetUser', id] })
    },
    onError: error => {
      createNotification({
        variant: 'error',
        message: error.response.data.errors?.[0] || error.message,
      })
    },
  })
}

export default useDeleteFactsetUser
