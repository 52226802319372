import { useQueryClient } from 'react-query'

import { useNotification } from 'components/Notification'
import { getFactsetLocations } from 'services/factsetProvisioning'

/**
 * @returns {function} async function that searches a factset location based on term
 */
function useFactsetLocationSearch(customerId) {
  const { createNotification } = useNotification()
  const queryClient = useQueryClient()

  const searchLocations = async searchTerm => {
    try {
      const locationSearchParams = {
        search: searchTerm,
        ...(customerId ? { customerId } : {}),
        pageOffset: 0,
        pageSize: 20,
      }
      const data = await queryClient.fetchQuery(['getFactsetLocations', locationSearchParams], () =>
        getFactsetLocations(locationSearchParams),
      )

      return data?.data.map(location => ({
        value: location.id, // normally this is the ID we need
        label: location.name,
        secondaryText: `DS1 ID: ${location.ds1Id}`,
        usernames: location.usernames,
        ds1Id: location.ds1Id,
        customer: location.customer
          ? { id: location.customer.id, name: location.customer.name }
          : null,
      }))
    } catch (error) {
      createNotification({
        variant: 'error',
        message: `Error fetching factset locations "${searchTerm}"`,
      })
      return []
    }
  }

  return { searchLocations }
}

export default useFactsetLocationSearch
