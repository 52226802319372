import { toSnake, toCamel } from 'change-object-case'
import { deserializer, client } from 'services/client'

async function getUserUploads(params) {
  const response = await client().get('v2/user_upload_requests', {
    params: toSnake(params),
  })

  return {
    data: deserializer.deserialize(response),
    meta: toCamel(response.meta),
  }
}

async function updateUserUploads(id, params) {
  const response = await client().patch(`v2/user_upload_requests/${id}`, toSnake(params))

  return {
    data: deserializer.deserialize(response),
  }
}

async function getUserResourceLink(id, filePath) {
  const response = await client().get(`v2/user_upload_requests/${id}/download`, {
    params: { file_path: toSnake(filePath) },
  })

  return toCamel(response, { recursive: true })
}

export { getUserUploads, updateUserUploads, getUserResourceLink }
