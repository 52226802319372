import React from 'react'
import { TextField, Typography } from '@the-platform-group/component-library'
import { Grid } from '@the-platform-group/component-library/Layout'
import { Button } from '@the-platform-group/component-library/Button'

import useSearchInputRead from '../hooks/useSearchInputRead'
import useSearchInputWrite from '../hooks/useSearchInputWrite'

function Header() {
  const { searchInput } = useSearchInputRead()

  const { setSearchInput, setAffiliatedId } = useSearchInputWrite()

  const handleChange = e => {
    setSearchInput(e.target.value)
  }

  const handleSearch = e => {
    setAffiliatedId()
  }

  return (
    <>
      <Typography variant="h4">Customer Affiliations</Typography>
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="column"
        spacing={0}
      >
        <Grid item>
          <Typography variant="h6">Search the record</Typography>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-start" direction="row" spacing={2}>
          <Grid item xs={4}>
            <TextField
              label="Enter the UUID"
              value={searchInput || ''}
              onChange={handleChange}
              sx={{ mt: 0.5 }}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Button color="primary" size="large" onClick={handleSearch}>
              Search
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Header
