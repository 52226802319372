import React from 'react'

import useAffiliationHoldings from '../hooks/useAffiliationHoldings'
import { DATE } from '@the-platform-group/component-library/DataGrid/constant'
import SharedTable from './SharedTable'

export const emptyMessage = 'No result found.'

const columns = [
  ...['customer', 'securityName', 'shares'].map(v => ({ field: v })),
  { field: 'purchaseDate', type: DATE },
]

const getRowData = holding => {
  const { id, customer, securityName, shares, date } = holding
  return { id, customer, securityName, shares, purchaseDate: `${date}T00:00:00` }
}

function HoldingsTable() {
  const useHook = useAffiliationHoldings()
  const props = { columns, emptyMessage, getRowData, useHook, name: 'holdingsTable' }
  return <SharedTable rows={useHook.state.holdings} {...props} />
}

export default HoldingsTable
