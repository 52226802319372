import React from 'react'
import { useQuery } from 'react-query'
import { DataGrid, DataGridActionCell } from '@the-platform-group/component-library/DataGrid'
import {
  AddLink as LinkIcon,
  LinkOff as UnlinkIcon,
  Edit as EditIcon,
} from '@the-platform-group/component-library/Icons'
import FactsetStatusText from './FactsetStatusText'
import { getFactsetUsers } from 'services/factsetProvisioning'
import usePagination from 'hooks/usePagination'
import debounce from 'utils/debounce'

const debouncedGetFactsetUsers = debounce(getFactsetUsers, 250)

const transformFactsetUsersData = data =>
  data.map(factsetUser => ({
    ...factsetUser,
    name: `${factsetUser.givenName} ${factsetUser.familyName}`,
    factsetLocation: factsetUser.location,
    isEditable: ['unmanaged', 'created', 'updating.failed'].includes(factsetUser.status),
  }))

const FactsetUsersTable = ({ userId, customerId, locationId, searchTerm, tableActions }) => {
  const { pagination, handlePageChange, handleRowsChange, pageSizeOptions } = usePagination()
  const { page, pageSize, pageOffset } = pagination
  const params = {
    userId,
    customerId,
    DS1LocationID: locationId,
    search: searchTerm,
    pageSize,
    pageOffset,
  }
  const { data: factsetUsers, isFetching } = useQuery(
    ['factsetUsers', params],
    () => debouncedGetFactsetUsers(params),
    {
      select: response => {
        return {
          ...response,
          data: transformFactsetUsersData(response.data),
        }
      },
      placeholderData: () => ({ data: [], meta: { itemCount: 0 } }),
    },
  )

  return (
    <DataGrid
      name="factsetUsers"
      loading={isFetching}
      columns={[
        {
          field: 'name',
          valueGetter: ({ row: { id }, value }) => ({
            to: '/factset/users/' + id,
            text: value,
          }),
          type: 'link',
        },
        {
          field: 'email',
          minWidth: 200,
        },
        {
          field: 'serialNumber',
        },
        {
          field: 'status',
          renderCell: ({ value }) => <FactsetStatusText status={value} />,
        },
        {
          field: 'createdAt',
          type: 'date',
        },
        {
          field: 'updatedAt',
          type: 'date',
        },
        {
          field: 'factsetLocation',
          valueGetter: ({ value }) =>
            value
              ? {
                  to: '/factset/locations/' + value.id,
                  text: value.name,
                }
              : '',
          type: 'link',
        },
        {
          field: 'actions',
          type: 'actions',
          minWidth: 60,
          getActions: ({ row }) => {
            const linkUserAction = row.user ? (
              // show unlink button if the factset location HAS linked user
              <DataGridActionCell
                icon={<UnlinkIcon color="warning" />}
                title="Unlink"
                onClick={tableActions.onUnlink(row)}
              />
            ) : (
              // otherwise show link button to allow linking
              <DataGridActionCell
                icon={<LinkIcon color="success" />}
                title="Link"
                onClick={tableActions.onLink(row)}
              />
            )
            return [
              linkUserAction,
              <DataGridActionCell
                disabled={!row.isEditable}
                icon={<EditIcon />}
                title="Edit"
                onClick={tableActions.onOpenUpdateFactsetUserModal(row.id)}
              />,
            ]
          },
        },
      ]}
      rows={factsetUsers.data}
      page={page}
      pageSize={pageSize}
      onPageChange={handlePageChange}
      onPageSizeChange={handleRowsChange}
      rowCount={factsetUsers.meta.itemCount}
      pageSizeOptions={pageSizeOptions}
    />
  )
}

export default FactsetUsersTable
