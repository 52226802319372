import { useMemo } from 'react'
import { generateHoldingsMap, transformReport } from '../transforms/report'
import { getAllReports, getReports } from 'services/reports'
import { useQuery } from 'react-query'
import transformHoldings from '../transforms/holding'
import { getSecurityHolders } from 'services/holdings'
import { useSurveillanceContext } from './SurveillanceContext'
import { transformSurveillanceColumns, transformSurveillanceRows } from '../transforms/datagrid'
import { getReportIdsQueryKey } from '../utils/reports'
import { formatDate } from '@the-platform-group/formatters/dateFormat'
import { MONTH_YEAR_FORMAT } from 'constants/formats'

// This hook is responsible for fetching the surveillance data (Holders and Reports) and transforming them into the format required by the DataGrid
const useSurveillanceData = () => {
  const {
    selectedSecurity,
    page,
    pageSize,
    searchTerm,
    includeZeroedHolders,
    from,
    to,
    setReports,
    selectedDateByMonth,
    selectedMonthAndYear,
  } = useSurveillanceContext()

  const { security, customer, datasource } = selectedSecurity

  const isHoldersFetchEnabled = !!security?.id && !!customer?.id

  // fetch security holders list associated with the selected security
  // this needs to be refetched when the selected security, customer, page, pageSize, searchTerm or isZeroedHoldersShown changes
  const {
    data: holdersData,
    isLoading: isHoldersLoading,
    isFetching: isHoldersFetching,
  } = useQuery(
    [
      'holdings',
      {
        customerId: customer?.id,
        securityId: security?.id,
        page,
        pageSize,
        searchTerm,
        includeZeroedHolders: includeZeroedHolders ? 'includeZeroedHolders' : 'default',
      }, // Params wrapped in an object for accessing query key on other places with only using `holdings` as key
    ],
    () => {
      const params = {
        ...(includeZeroedHolders && {
          surveillance_with_shares_only: false,
        }),
        customer_id: customer?.id,
        include: ['reported', 'surveillance'],
        sort: ['reported_shares_desc'],
        page_offset: page * pageSize,
        page_size: pageSize,
        ...(searchTerm && { term: searchTerm }),
      }
      return getSecurityHolders(security.id, params)
    },
    {
      placeholderData: {
        data: [],
        count: 0,
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: isHoldersFetchEnabled,
      retry: false,
      select: ({ data, count }) => ({
        data: transformHoldings(data),
        count,
      }),
    },
  )

  const isReportIdsFetchEnabled = isHoldersFetchEnabled && !isHoldersLoading

  const { data: reportIdsData = [], isLoading: isReportIdsLoading } = useQuery(
    getReportIdsQueryKey({
      customerId: customer?.id,
      securityId: security?.id,
      datasource,
      selectedMonthAndYear, // selectedMonthAndYear is used to refetch the reportIds when the selected month and year changes, we have refetchQueries in DeleteReport and useNewReportHandler to refetch the reportIds query when a report is deleted or created
    }), // Params wrapped in an object for accessing query key on other places with only using `reportIds` as key
    () => {
      const params = {
        customer_id: customer?.id,
        securityId: security?.id,
        datasource: datasource,
        page_size: 52, // 52 weeks in a year we are expecting get 1 report per week maximum, so we are fetching 1 year of reports. BE support more than 52 weeks but we are limiting it to 52 weeks to scale the data
        from,
        to,
      }
      return getReports(params) // Return all reports data fetched from Promise.all so we can use all in the transformReport function
    },
    {
      refetchOnWindowFocus: false,
      enabled: isReportIdsFetchEnabled,
      retry: false,
      select: ({ data }) => data,
      onSuccess: setReports,
    },
  )

  const isReportsFetchEnabled = isReportIdsFetchEnabled && !isReportIdsLoading

  // get report ids from reportIdsData and filter them by if selectedDateByMonth is selected otherwise return all report ids
  const reportIds = useMemo(
    () =>
      reportIdsData.reduce((acc, { id, date }) => {
        // compare the month of the report date with the selectedDateByMonth, we use formatDate function due to the timezone issue, if reports date is something like 2023-01-01 in previous new Date(date).getMonth() it will return 11 instead of 0 because of the timezone issue so we are using formatDate function to get the month and year which has timezone support

        // get the month of the selectedDateByMonth to compare with the report date
        // we don't need to compare the year because the reportIdsData is already filtered by the selected year with the from and to params
        const selectedMonth =
          selectedDateByMonth && formatDate(selectedDateByMonth, MONTH_YEAR_FORMAT)

        // (if selectedDateByMonth is not selected) or (selectedDateByMonth is selected and the report date is in the selectedDateByMonth then return the report id)
        if (selectedMonth === null || selectedMonth === formatDate(date, MONTH_YEAR_FORMAT)) {
          acc.push(id)
        }
        return acc
      }, []),
    [reportIdsData, selectedDateByMonth],
  )

  // fetch reports list associated with the selected security
  // this needs to be refetched when only the selected security or customer changes
  const { data: holderReports = [], isLoading: isHolderReportsLoading } = useQuery(
    ['reports', { reportIds }], // Params wrapped in an object for accessing query key on other places with only using reports as key
    () => getAllReports(reportIds, 7), // Return all reports data fetched from Promise.all so we can use all in the transformReport function, 7 is the maximum number of `reports` to fetch
    {
      refetchOnWindowFocus: false,
      enabled: isReportsFetchEnabled,
      retry: false,
      select: generateHoldingsMap,
    },
  )

  // transform reports data to be used in the data grid and calculate change values on each holdings
  const reportsContent = useMemo(() => {
    // if holders data is loading or reports data is loading return empty array
    if (isHolderReportsLoading || isHoldersLoading) {
      return []
    }

    // transform reports data for each holder with calculating change with previous report
    return holderReports.map((report, index) => {
      const currentReport = report
      const previousReport = holderReports[index + 1]
      const nextReportId = holderReports[index - 1]?.id // next report id is used to update the change value for the next report during processRowUpdate

      return transformReport({
        currentReport,
        previousReport,
        nextReportId,
        holdersData: holdersData.data,
        selectedDateByMonth,
      })
    })
  }, [
    holderReports,
    holdersData.data,
    isHolderReportsLoading,
    isHoldersLoading,
    selectedDateByMonth,
  ])

  const isLoading =
    isHolderReportsLoading || isHoldersLoading || isHoldersFetching || isReportIdsLoading
  // transform reports data to generate columns and columnGroupingModel for the data grid
  const { columns, columnGroupingModel } = useMemo(
    () =>
      transformSurveillanceColumns({
        reportsContent,
        isLoading,
      }),
    [reportsContent, isLoading],
  )

  // transform reports data to generate rows for the data grid
  const combinedRows = useMemo(
    () =>
      transformSurveillanceRows({
        holdersData: holdersData?.data,
        reportsContent,
      }),
    [holdersData?.data, reportsContent],
  )

  const excludedReportDates = useMemo(
    () => reportIdsData.map(({ date }) => new Date(date.replace(/-/g, '/'))),
    [reportIdsData],
  )

  return {
    holdersData,
    reportsContent,
    holdersMap: holderReports[0]?.holdings,
    isLoading,
    combinedRows,
    columns,
    columnGroupingModel,
    excludedReportDates,
  }
}

export default useSurveillanceData
