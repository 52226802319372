import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useMutation, useQueryClient } from 'react-query'
import { createReport } from 'services/reports'
import { useNotification } from 'components/Notification'
import { WARNINGS } from 'constants/surveillancePortal'
import { useSurveillanceContext } from './SurveillanceContext'
import { DATE_FORMAT } from '@the-platform-group/formatters/constants'
import { formatDate } from '@the-platform-group/formatters/dateFormat'
import { WEEK_OF_FORMAT } from 'constants/formats'
import { getReportIdsQueryKey } from '../utils/reports'

const INITIAL_FORM_VALUES = {
  date: new Date(),
  holdings_source: 'current',
  previous_report_id: undefined,
}

/**
 * An object with a callback to submit the creation of a new report.
 * @typedef {object} HookData
 * @property {function} saveNewReport Function to trigger when submitting the creation of a new report.
 */

/**
 * Hook that handles the creation of a new surveillance report.
 * @returns {HookData} An object with a callback to submit the creation of a new report.
 */
function useNewReportHandler() {
  const { createNotification } = useNotification()
  const queryClient = useQueryClient()

  const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES)

  const {
    selectedSecurity: { security, customer, datasource },
    selectedMonthAndYear,
    setIsLoading,
    handleNewReportModal,
  } = useSurveillanceContext()

  const resetNewForm = () => {
    setIsLoading(false)
    handleNewReportModal(false)
    setFormValues(INITIAL_FORM_VALUES)
  }

  const { mutateAsync: saveNewReport } = useMutation({
    mutationFn: params => {
      setIsLoading(true)
      const { date, holdings_source, previous_report_id } = params

      const newReport = [
        {
          id: uuidv4(),
          date: formatDate(date, DATE_FORMAT),
          name: formatDate(date, WEEK_OF_FORMAT),
          holdings_source,
          previous_report_id,
          customer_id: customer.id,
          security_id: security.id,
          datasource,
        },
      ]

      return createReport(newReport)
    },
    onSuccess: async () => {
      // invalidate reportIds query since it shows the latest 20 reports
      await queryClient.refetchQueries(
        getReportIdsQueryKey({
          customerId: customer?.id,
          securityId: security?.id,
          datasource,
          selectedMonthAndYear,
        }),
        {
          refetchActive: true,
          refetchInactive: false,
        },
      )
      resetNewForm()
    },
    onError: error => {
      // if the error is 422, it means that the report's supplemental holding already exists
      if (error.response?.status === 422) {
        error.message = WARNINGS.error422
      }
      createNotification({ message: error.message, variant: 'error' })
      setIsLoading(false)
    },
  })

  function updateFormValues(newValue) {
    setFormValues({
      ...formValues,
      ...newValue,
    })
  }

  return {
    formValues,
    updateFormValues,
    saveNewReport,
  }
}

export default useNewReportHandler
