import React from 'react'

import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@the-platform-group/component-library/Modal'
import { Button } from '@the-platform-group/component-library/Button'
import {
  Checkbox,
  FormControlLabel,
  InputLabel,
  TextField,
  TypeaheadSingleSelect,
  MuiPickersUtilsProvider,
  AdapterDateFns,
  DatePicker,
} from '@the-platform-group/component-library'

import customerTypes from 'constants/customerTypes'
import useCustomerForm from './useCustomerForm'
import SecuritySelect from 'components/Select/SecuritySelect'

const CustomerModal = ({ onSave, onClose, open, editableCustomer }) => {
  const { state, handlers } = useCustomerForm({ onSave, editableCustomer })

  const { customer, isSubmitting } = state
  const { handleChange, handleChecked, handleDateChange, saveCustomer } = handlers

  return (
    <Modal open={open} onClose={onClose}>
      <ModalTitle title={`${editableCustomer?.id ? 'Update' : 'Create'} Customer`} />
      <ModalContent>
        <form>
          <InputLabel htmlFor="name" />
          <TextField
            id="name"
            name="name"
            label="Name"
            value={customer.name || ''}
            onChange={handleChange}
            sx={{ mb: 0.5 }}
            fullWidth
            required
          />
          <InputLabel htmlFor="type" />
          <TypeaheadSingleSelect
            id="type"
            name="type"
            value={customer.type || ''}
            onChange={(e, newValue) => handleChange({ target: { name: 'type', value: newValue } })}
            variant="outlined"
            label="Type"
            options={customerTypes}
          />
          {customer.type === 'trial' && (
            <>
              <InputLabel htmlFor="trialEndDate" />
              <MuiPickersUtilsProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disablePast
                  id="trialEndDate"
                  variant="outlined"
                  value={customer.expiredAt ? new Date(customer.expiredAt + ' EST') : null}
                  onChange={date =>
                    handleDateChange({ target: { name: 'expiredAt', value: date } })
                  }
                  label="Trial End Date"
                />
              </MuiPickersUtilsProvider>
            </>
          )}
          <InputLabel htmlFor="defaultSecurity" />
          <SecuritySelect
            id="defaultSecurity"
            label="Default Security *"
            value={customer.defaultSecurity}
            onChange={value => handleChange({ target: { name: 'defaultSecurity', value } })}
          />

          <FormControlLabel
            label="Include in Research?"
            control={
              <Checkbox
                value={true}
                checked={!!customer.includeInResearch}
                name="includeInResearch"
                onChange={handleChecked}
              />
            }
          ></FormControlLabel>
        </form>
      </ModalContent>
      <ModalActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!customer.name || (!customer.defaultSecurityId && !customer.defaultSecurity)}
          onClick={saveCustomer}
          isLoading={isSubmitting}
        >
          Save
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default CustomerModal
