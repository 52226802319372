import React from 'react'

import { DataGrid } from '@the-platform-group/component-library/DataGrid'
function SharedTable({ useHook, emptyMessage, getRowData, rows, ...rest }) {
  const {
    state: { total, page, rowsPerPage, isLoading, failed },
    setPage,
    setRowsPerPage,
  } = useHook
  const emptyRows = failed && { rows: [] }
  return (
    <DataGrid
      commonColumnProps={{ headerAlign: 'left' }}
      getRowHeight={() => 'auto'}
      sx={{ ' .MuiDataGrid-cell': { p: 0 } }}
      loading={isLoading}
      rowCount={total}
      pageSize={rowsPerPage}
      pageSizeOptions={[10, 25, 50]}
      page={page}
      onPageChange={setPage}
      onPageSizeChange={newPageSize => setRowsPerPage({ page: 0, rowsPerPage: newPageSize })}
      // localeText definitions found here: https://github.com/mui/mui-x/blob/HEAD/packages/grid/x-data-grid/src/constants/localeTextConstants.ts
      localeText={{
        noRowsLabel: emptyMessage,
        noResultsOverlayLabel: emptyMessage,
      }}
      {...rest}
      rows={React.useMemo(() => rows.map(getRowData), [getRowData, rows])}
      // overwrite rows when api requests fail
      {...emptyRows}
    />
  )
}

export default SharedTable
