import { client, deserializer } from 'services/client'

function findRangeStart(range) {
  return range.split('...')[0]
}

function compareRanges(a, b) {
  return findRangeStart(a.range) - findRangeStart(b.range)
}

export async function getMarketCaps() {
  const response = await client().get('/v2/market_caps')
  const data = deserializer.deserialize(response)
  return data.sort(compareRanges)
}
