import { snakeKeys } from 'change-object-case'
import axios from 'axios'
import Jsona, { SwitchCaseJsonMapper } from 'jsona'

import { irwin } from 'config'
import { httpClient } from './httpClient'

const switchCaseOptions = {
  switchChar: '_',
}
const deserializer = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper(switchCaseOptions),
})

let abortController

function client() {
  return httpClient(irwin.baseUrl)
}

async function getSecurityHolders(securityId, params) {
  try {
    if (abortController) {
      abortController.abort('Operation cancelled by the user.')
    }

    abortController = new AbortController()

    const response = await client().get(`v2/securities/${securityId}/holders`, {
      params: snakeKeys(params, { recursive: true, arrayRecursive: true }),
      signal: abortController.signal,
    })

    return {
      data: deserializer.deserialize(response),
      links: response.links,
      count: response.meta.item_count,
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request cancelled', error.message)
      return {
        data: [],
        count: 0,
      }
    }

    throw error
  }
}

export { getSecurityHolders }
