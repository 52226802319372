import { formatStringAddress } from 'transforms/address'
import TYPES from 'constants/organizationTypes'
import { Box } from '@the-platform-group/component-library'
import { DataGrid, DataGridActionCell } from '@the-platform-group/component-library/DataGrid'
import { HighlightOff as DeleteIcon } from '@the-platform-group/component-library/Icons'
import { useParentOrganizationContext } from '../contexts/ParentOrganizationContext'

const ChildrenOrganizationsTable = ({ onOrganizationDelete }) => {
  const {
    isLoading,
    childOrganizations,
    pagination,
    handlePageUpdate,
  } = useParentOrganizationContext()

  const handlePageChange = newPage => handlePageUpdate('page', newPage)
  const handlePageSizeChange = newPageSize => handlePageUpdate('pageSize', newPageSize)

  const columns = [
    { field: 'no', flex: null },
    { field: 'organizationName' },
    { field: 'location' },
    { field: 'type' },

    {
      field: 'actions',
      flex: null,
      headerName: '',
      type: 'actions',
      getActions: ({ row }) => [
        <DataGridActionCell
          ize="50px"
          icon={<DeleteIcon color="primary" />}
          title="Remove child organization from this parent"
          onClick={() => onOrganizationDelete(row.id)}
        />,
      ],
    },
  ]

  const { itemCount, page, pageSize } = pagination
  const pageOffset = page * pageSize

  const rows = childOrganizations.map((childOrg, idx) => ({
    id: childOrg.id,
    no: pageOffset + idx + 1,
    organizationName: childOrg.name,
    location: formatStringAddress(childOrg.address),
    type: TYPES[childOrg.type],
  }))

  return (
    <Box height={500}>
      <DataGrid
        autoHeight={false}
        hideFooter={!itemCount}
        isLoading={isLoading}
        name="childrenOrganizations"
        columns={columns}
        rows={rows}
        page={page}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        rowCount={itemCount}
        pageSizeOptions={[25, 50, 100, 150]}
      />
    </Box>
  )
}
export default ChildrenOrganizationsTable
