import { v4 as uuid } from 'uuid'
import { userToCreateParams, userToUpdateParams } from 'transforms/users'
import client, { deserializer } from 'services/client'
import { toCamel, toSnake } from 'change-object-case'

async function saveUser(user) {
  if (user.id) {
    return await updateUser(user.id, user)
  } else {
    return await createUser(user)
  }
}

async function updateUser(id, user) {
  const requestBody = userToUpdateParams(user)
  const responseBody = await client().put(`/v2/users/${id}`, requestBody)

  return deserializer.deserialize(responseBody)
}

async function createUser(user) {
  const requestBody = userToCreateParams(user)
  const responseBody = await client().post('/v2/users', { id: uuid(), ...requestBody })

  return deserializer.deserialize(responseBody)
}

async function getUsers(params) {
  const response = await client().get('/v2/users', { params: toSnake(params) })

  return {
    data: deserializer.deserialize(response),
    meta: toCamel(response.meta),
  }
}

async function getUser(id) {
  const response = await client().get(`v2/users/${id}`)

  return deserializer.deserialize(response)
}

async function inviteUser(id) {
  const response = await client().post(`v2/users/${id}/invite`)

  return response
}

export { saveUser, getUsers, getUser, inviteUser }
