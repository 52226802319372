import React, { useReducer } from 'react'

const SearchInputStateContext = React.createContext()
const SearchInputDispatchContext = React.createContext()

const searchInputReducer = (state, action) => {
  switch (action.type) {
    case 'AFFILIATION:SEARCH_INPUT_SET':
      return { ...state, searchInput: action.payload }
    case 'AFFILIATION:AFIILIATED_ID_SET':
      return { ...state, affiliatedId: state.searchInput }
    default:
      throw new Error(`Unsupported action type: ${action.type}`)
  }
}

const initialState = {
  searchInput: null,
  affiliatedId: null,
}

function SearchInputProvider(props) {
  const [state, dispatch] = useReducer(searchInputReducer, initialState)

  return (
    <SearchInputStateContext.Provider value={state} {...props}>
      <SearchInputDispatchContext.Provider value={[dispatch]} {...props}>
        {props.children}
      </SearchInputDispatchContext.Provider>
    </SearchInputStateContext.Provider>
  )
}

export { SearchInputStateContext, SearchInputDispatchContext, SearchInputProvider }
