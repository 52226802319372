import { useState, useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { snakeCase } from 'change-case-object'
import {
  saveOrganization,
  getPlatformOrganization as getOrganization,
  getPlatformOrganizationPeople as getOrganizationPeople,
} from 'services/organizations'
import { useNotification } from 'components/Notification'
import { deleteFromFocuses, formatFocus, getUpdatedFocuses } from 'transforms/focus'
import { MARKET_CAP_ERROR_FIELD } from 'constants/marketCaps'
import { createInternalNote } from 'services/internalNotes'
import INTERNAL_NOTE_TYPES from 'constants/internalNoteTypes'
import { PARENT_ORGANIZATION_KEY } from 'constants/organizationTypes'
import { useParentOrganizationContext } from '../contexts/ParentOrganizationContext'
import { useOrganizationFormContext } from '../contexts/OrganizationFormContext'
import useFocuses from 'hooks/useFocuses'

const useOrganizationForm = ({ id, history }) => {
  const {
    state: { organization, internalNote, updatedFocuses },
    handleSetStateByName,
    handleSetOrganizationFormState,
    handleResetOrganizationFormState,
  } = useOrganizationFormContext()

  const [isSavingOrganization, setIsSavingOrganization] = useState(false)
  const { createNotification } = useNotification()
  const { handleLinkSubmit } = useParentOrganizationContext()

  const { newFocusFromCategory } = useFocuses()

  const queryClient = useQueryClient()
  const organizationQueryKey = ['organizationQuery', id]
  const { data: organizationData, isLoading: isOrganizationLoading } = useQuery(
    organizationQueryKey,
    () => getOrganization(id),
    {
      select: ({ data }) => data,
      enabled: !!id,
      refetchOnWindowFocus: false,
    },
  )
  const { data: organizationPeopleData, isLoading: isOrganizationPeopleLoading } = useQuery(
    ['organizationPeopleQuery', id],
    () => getOrganizationPeople(id),
    { select: ({ data }) => data, enabled: !!organizationData?.id, refetchOnWindowFocus: false },
  )

  useEffect(() => {
    if (organizationData && organizationPeopleData) {
      const { address: addressValues, ...organizationValues } = organizationData
      handleSetOrganizationFormState({
        organization: {
          ...organizationValues,
          people: organizationPeopleData,
          // TODO: just leave setAddress(addressValues) after BE iso removal
          address: {
            ...addressValues,
            country: addressValues.countryIso || addressValues.country,
          },
        },
        updatedFocuses: organizationValues.focuses,
      })
    }
  }, [handleSetOrganizationFormState, organizationData, organizationPeopleData])

  const handleOrganizationChange = ({ target: { name, value } }) => {
    handleSetStateByName({ organization: { [name]: value } })
  }
  const handleAddressChange = ({ target: { name, value } }) => {
    handleSetStateByName({ organization: { address: { [name]: value } } })
  }
  const handleCoverageChange = ({ target: { name, value } }) => {
    handleSetStateByName({ organization: { coverage: { [name]: value } } })
  }
  const handleAddressSelect = selAddress => {
    handleSetStateByName({ organization: { address: formatAddress(selAddress) } })
  }

  const handleFocusChange = focus =>
    handleSetStateByName({
      updatedFocuses: getUpdatedFocuses(updatedFocuses, focus, newFocusFromCategory),
    })

  const handleFocusDelete = deletedFocuses =>
    handleSetStateByName({
      updatedFocuses: deleteFromFocuses(updatedFocuses, deletedFocuses),
    })

  const handleInternalNoteChange = newNote => handleSetStateByName({ internalNote: newNote })

  const handleSubmit = async () => {
    setIsSavingOrganization(true)
    const {
      relationshipNames: relationships,
      focuses: organizationFocuses,
      address,
      coverage,
      ...restOrgs
    } = organization
    const { street2, ...restAddress } = address
    try {
      const orgParams = { ...snakeCase(restOrgs) }
      const addressParams = { ...snakeCase(restAddress), street2 }
      const coverageParams = { ...snakeCase(coverage) }
      const focusParams = { ...formatFocus(updatedFocuses, organizationFocuses) }
      const params = {
        ...orgParams,
        address_attributes: { ...addressParams },
        coverage_attributes: { ...coverageParams },
        focuses_attributes: { ...focusParams },
      }
      const { data: newOrg } = await saveOrganization({ ...params })
      if (internalNote) {
        // only send request if there is content
        await createInternalNote(internalNote, newOrg.id, INTERNAL_NOTE_TYPES.platformOrganization)
      }
      if (organization.type === PARENT_ORGANIZATION_KEY) {
        await handleLinkSubmit(newOrg.id)
      }
      handleResetOrganizationFormState()
      const isNewOrg = !Boolean(organization.id)
      createNotification({
        message: `Organization ${isNewOrg ? 'created' : 'updated'} successfully`,
      })
      // only navigate to org's edit page when newly created
      isNewOrg
        ? history.push(`/platform/organizations/${newOrg.id}/edit`)
        : queryClient.resetQueries(organizationQueryKey)
    } catch (e) {
      // handle 401s and 403s in error boundary
      if (e.status === 401 || e.status === 403) {
        throw e
      }

      // intentionally throw on market cap definition changes,
      // these errors will be handled by an error boundary that will
      // refresh the values on the page
      if (e.response?.data?.errors?.map(e => e.field).includes(MARKET_CAP_ERROR_FIELD)) {
        throw e
      }

      createNotification({ message: e.message, variant: 'error' })
    } finally {
      setIsSavingOrganization(false)
    }
  }

  return {
    organization,
    isLoading: isOrganizationLoading || isOrganizationPeopleLoading,
    isSavingOrganization,
    handleOrganizationChange,
    handleAddressChange,
    handleSubmit,
    handleAddressSelect,
    handleCoverageChange,
    focuses: updatedFocuses,
    handleFocusChange,
    handleFocusDelete,
    internalNoteState: {
      content: internalNote,
      handleChange: handleInternalNoteChange,
    },
  }
}

function formatAddress(rawAddress) {
  const { coordinates, ...rest } = rawAddress
  if (!coordinates) {
    return rawAddress
  }
  return {
    ...rest,
    latLng: [coordinates.lat, coordinates.lng],
  }
}

export default useOrganizationForm
