import React from 'react'
import PropTypes from 'prop-types'

import { InputAdornment, Select, TextField } from '@the-platform-group/component-library'
import { Grid } from '@the-platform-group/component-library/Layout'
import COVERAGE_STYLES from 'constants/coverageStyles'
import COVERAGE_TURNOVER_LABELS from 'constants/coverageTurnoverLables'
import { Focus, FocusChips } from 'shared/Focus'
import { H2, H3 } from 'components/Typography'
import { FormPaper } from 'components/Paper'
import useFocuses from 'hooks/useFocuses'
import OrganizationFields from './OrganizationFields'

const RegularOrganizationForm = ({
  organization,
  onOrganizationChange,
  coverage,
  onCoverageChange,
  focuses,
  onFocusChange,
  onFocusDelete,
}) => {
  const { focuses: focusDefinitions } = useFocuses()
  const { styles, turnover, aum } = coverage || {}

  return (
    <FormPaper>
      <Grid container spacing={3}>
        <OrganizationFields
          gridProps={{ xs: 6 }}
          organization={organization}
          onOrganizationChange={onOrganizationChange}
        />
        <Grid item xs={6}>
          <H2>Coverage</H2>
          <Select
            fullWidth
            isMulti
            showCheckBox={false}
            label="Style(s)"
            value={styles || []}
            name="styles"
            onChange={onCoverageChange}
            options={Object.entries(COVERAGE_STYLES).map(([value, label]) => ({
              value,
              label,
            }))}
          />
          <Select
            fullWidth
            label="Turnover"
            value={turnover || ''}
            name="turnover"
            onChange={onCoverageChange}
            options={Object.entries(COVERAGE_TURNOVER_LABELS).map(([value, label]) => ({
              value,
              label,
            }))}
          />
          <TextField
            value={`${aum}` || ''}
            label="AUM"
            name="aum"
            onChange={onCoverageChange}
            type="number"
            sx={{ my: 1 }}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Focus
            focuses={focuses}
            onFocusChange={onFocusChange}
            categories={focusDefinitions.ORGANIZATION_CATEGORIES}
          />
        </Grid>
        <Grid item xs={6}>
          <H3>Selected Focuses</H3>
          <FocusChips focuses={focuses} handleFocusDelete={onFocusDelete} />
        </Grid>
      </Grid>
    </FormPaper>
  )
}

RegularOrganizationForm.propTypes = {
  organization: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    website: PropTypes.string,
    phone: PropTypes.string,
    type: PropTypes.string,
    focuses: PropTypes.array,
  }).isRequired,
  onOrganizationChange: PropTypes.func.isRequired,
  coverage: PropTypes.shape({}).isRequired,
  onCoverageChange: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func.isRequired,
  onFocusDelete: PropTypes.func.isRequired,
}

export default RegularOrganizationForm
