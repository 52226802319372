const INITIAL_SORT_STATE = {
  field: 'created_at',
  sort: 'desc',
}

const SORT_COLUMN_MAPS = {
  createdAt: 'created_at',
}

const UPLOAD_REQUEST_TABLE_NAME = 'irwinAssistant/uploadRequestsTable'
const FILE_PATH_TABLE_NAME = 'irwinAssistant/uploadRequests/filePathsTable'

const UPLOAD_STATUS = [
  { status: 'received', color: 'secondary.main' },
  { status: 'work_in_progress', color: 'warning.main' },
  { status: 'to_be_reviewed', color: 'primary.light' },
  { status: 'in_review', color: 'score.medium' },
  { status: 'hold', color: 'error.main' },
  { status: 'completed', color: 'success.main' },
]

const UPLOAD_STATUS_STYLES = UPLOAD_STATUS.reduce((acc, curr) => {
  const key = ` .status-button-${curr.status} button`

  return { ...acc, [key]: { backgroundColor: curr.color, color: 'black' } }
}, {})

export {
  INITIAL_SORT_STATE,
  SORT_COLUMN_MAPS,
  UPLOAD_REQUEST_TABLE_NAME,
  FILE_PATH_TABLE_NAME,
  UPLOAD_STATUS,
  UPLOAD_STATUS_STYLES,
}
