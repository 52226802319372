import React from 'react'
import PropTypes from 'prop-types'
// components
import { Button } from '@the-platform-group/component-library/Button'
import { Grid } from '@the-platform-group/component-library/Layout'
import PersonFormComponent from './PersonFormComponent'
import JobFormComponent from './JobFormComponent'
import PersonSocialProfilesForm from 'components/PersonSocialProfiles/PersonSocialProfilesForm'
// hooks
import { usePersonCreateForm } from './hooks/usePersonCreateForm'
import { useJob } from './hooks/useJob'
import usePersonSocialProfiles from 'hooks/usePersonSocialProfiles'
import { FormPaper } from 'components/Paper'
import InternalNoteTextField from 'components/InternalNotes/InternalNoteTextField'
import { useInternalNoteState } from 'hooks/useInternalNoteUtils'
import useProfilePictureUploader from 'components/ProfilePicture/useProfilePictureUploader'

const PersonCreateForm = ({ history }) => {
  const { isCreatingPerson, person, handlePersonChange, handlePersonCreate } = usePersonCreateForm(
    history,
  )
  const { job, focuses, organization, ...jobFormHandlers } = useJob(person.job)
  const { personSocialProfiles, handlePersonSocialProfilesChange } = usePersonSocialProfiles(
    person.personSocialProfiles,
  )
  const internalNote = useInternalNoteState()

  const {
    selectedImage,
    handleImageChange,
    resetSelectedImage,
    handleSaveProfilePicture,
  } = useProfilePictureUploader({
    profilePicture: {},
  })

  return (
    <FormPaper>
      <Grid container spacing={3}>
        <Grid item container direction="column" xs={6}>
          <h2>Create Person</h2>
          <PersonFormComponent person={person} onPersonChange={handlePersonChange} />
          <PersonSocialProfilesForm
            personSocialProfiles={personSocialProfiles}
            onPersonSocialProfilesChange={handlePersonSocialProfilesChange}
            isModal={false}
            person={person}
            selectedImage={selectedImage}
            handleImageChange={handleImageChange}
            resetSelectedImage={resetSelectedImage}
          />
          <InternalNoteTextField {...internalNote} />
        </Grid>
        <Grid item xs={6}>
          <JobFormComponent
            job={job}
            focuses={focuses}
            organization={organization}
            jobFormHandlers={jobFormHandlers}
          />
        </Grid>
      </Grid>
      <Button
        isLoading={isCreatingPerson}
        onClick={() =>
          handlePersonCreate({
            job,
            focuses,
            organization,
            personSocialProfiles,
            internalNoteContent: internalNote.content,
            handleSaveProfilePicture,
          })
        }
        color="primary"
      >
        Submit
      </Button>
    </FormPaper>
  )
}

PersonCreateForm.propTypes = {
  history: PropTypes.object.isRequired,
}

export default PersonCreateForm
