import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'

import { getUsers } from 'services/users'

import debounce from 'utils/debounce'

const defaultParams = {
  itemCount: 0,
  pageSize: 50,
  pageOffset: 0,
  page: 0,
}

const defaultFilters = {
  nameTerm: null,
  customerIds: null,
}

function useUsers() {
  const [pagination, setPagination] = useState(defaultParams)
  const [filters, setFilters] = useState(defaultFilters)
  const [customers, setCustomers] = useState([])

  const { isLoading, data: users, refetch } = useQuery(
    ['getUsers', pagination, filters],
    () =>
      getUsers({
        nameTerm: filters.nameTerm,
        customerIds: filters.customerIds,
        pageSize: pagination.pageSize,
        pageOffset: pagination.pageOffset,
      }),
    {
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
      onSuccess: data => {
        setPagination(prevState => ({
          ...prevState,
          pageOffset: data.meta.pageOffset,
          pageSize: data.meta.pageSize,
          itemCount: data.meta.itemCount,
        }))
      },
    },
  )

  useEffect(() => {
    async function refetchUsers() {
      await debounce(refetch(), 150)
    }

    refetchUsers()
  }, [filters.nameTerm, filters.customerIds, refetch])

  const handlePageChange = newPage => {
    const { page, pageSize } = pagination
    if (newPage > page) {
      setPagination(prevState => ({
        ...prevState,
        page: page + 1,
        pageOffset: (page + 1) * pageSize,
      }))
    } else if (newPage < page) {
      setPagination(prevState => ({
        ...prevState,
        page: page - 1,
        pageOffset: (page - 1) * pageSize,
      }))
    }
  }

  const handleRowsChange = pageSize => {
    setPagination(prevState => ({ ...prevState, page: 0, pageSize: pageSize, pageOffset: 0 }))
  }

  async function handleUserNameSearch(e) {
    const nameTerm = e.target.value || null
    setFilters(prevState => ({ ...prevState, nameTerm }))
  }

  async function handleCustomerSearch(customers) {
    const customerIds = customers.map(customer => customer.id)
    setFilters(prevState => ({ ...prevState, customerIds }))
    setCustomers(customers)
  }

  return {
    users,
    customers,
    pagination,
    isLoading,
    filters,
    handlePageChange,
    handleRowsChange,
    handleUserNameSearch,
    handleCustomerSearch,
  }
}

export { useUsers }
