import React from 'react'
import PropTypes from 'prop-types'
import { Typography, styled } from '@the-platform-group/component-library'
import {
  Modal,
  ModalActions,
  ModalContent,
  ModalTitle,
} from '@the-platform-group/component-library/Modal'
import { Button } from '@the-platform-group/component-library/Button'

import ENTITY_TYPES from 'constants/entityTypes'

const EntityDeleteComponent = ({ entityType, entityLabel, modalOpen, onArchive, onModalClose }) => {
  return (
    <Modal open={modalOpen} onClose={onModalClose}>
      <ModalTitle title={`Archive ${entityLabel}?`} align="center" />
      <ModalContent>
        <Typography
          align="center"
          sx={{
            px: 2,
            pt: 0.25,
            pb: 0.5,
          }}
        >
          {`Archived ${ENTITY_TYPES[entityType].plural} will not appear in search or targeting in Irwin,
          and can't have jobs assigned to them in the admin application.`}
        </Typography>
      </ModalContent>
      <ModalActions>
        <StyledButton variant="text" color="secondary" onClick={onModalClose}>
          Cancel
        </StyledButton>
        <StyledButton color="primary" onClick={onArchive}>
          Archive
        </StyledButton>
      </ModalActions>
    </Modal>
  )
}

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2),
}))

EntityDeleteComponent.propTypes = {
  entityType: PropTypes.string.isRequired,
  entityLabel: PropTypes.string.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  onArchive: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
}

export default EntityDeleteComponent
