import { useHistory } from 'react-router-dom'
import { useQueryClient, useMutation } from 'react-query'
import { deleteFactsetLocation as deleteFactsetLocationService } from 'services/factsetProvisioning'
import { useNotification } from 'components/Notification'
import { useConfirmationDialog } from 'components/ConfirmationDialog'

function useDeleteFactsetLocation() {
  const queryClient = useQueryClient()
  const { createNotification } = useNotification()
  const { createConfirmationDialog } = useConfirmationDialog()
  const history = useHistory()

  const deleteFactsetLocationMutation = useMutation({
    mutationFn: deleteFactsetLocationService,
    onSuccess: () => {
      history.push('/factset/locations')
      createNotification({
        variant: 'success',
        message: 'Sucessfully deleted Factset location.',
      })
      queryClient.invalidateQueries({ queryKey: ['factsetLocations'] })
    },
    onError: error => {
      createNotification({
        variant: 'error',
        message: error.response.data.errors?.[0] || error.message,
      })
    },
  })

  const onDelete = (locationId, locationName) => () => {
    createConfirmationDialog({
      title: `Delete ${locationName}`,
      body: `Are you sure you want to delete the factset location ${locationName}?`,
      onConfirm: () => deleteFactsetLocationMutation.mutateAsync(locationId),
    })
  }

  return {
    isDeleting: deleteFactsetLocationMutation.isLoading,
    onDelete,
  }
}

export default useDeleteFactsetLocation
