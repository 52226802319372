import { useEffect, useState } from 'react'

import STATIC_FOCUSES from 'constants/focus'
import STATIC_COUNTRIES from 'constants/countries'
import useMarketCapsPresentational from 'hooks/marketCaps/useMarketCapsPresentational'

export const initialFocuses = {
  ...STATIC_FOCUSES,
  COUNTRIES: STATIC_COUNTRIES,
  MARKET_CAPS: {},
  CATEGORY_VALUES: {
    ...STATIC_FOCUSES.CATEGORY_VALUES,
    market_cap: {},
  },
}

function useFocuses() {
  const { optionsMap: marketCapOptions } = useMarketCapsPresentational()
  const [focuses, setFocuses] = useState(initialFocuses)

  useEffect(() => {
    setFocuses(focuses => {
      const MARKET_CAPS = Object.fromEntries(marketCapOptions)
      return {
        ...focuses,
        MARKET_CAPS,
        CATEGORY_VALUES: {
          ...STATIC_FOCUSES.CATEGORY_VALUES,
          market_cap: MARKET_CAPS,
        },
      }
    })
  }, [marketCapOptions])

  function focusLabel(category, code) {
    if (!focuses.hasOwnProperty(category)) {
      throw new Error(`Focus category provided does not exist: ${category}`)
    }

    return focuses[category][code]
  }

  function marketCapLabel(code) {
    const label = focusLabel('MARKET_CAPS', code)
    return label ? `${label} Cap` : label
  }

  function sectorLabel(code) {
    return focusLabel('SECTORS', code)
  }

  function subSectorLabel(code) {
    return focusLabel('SUBSECTORS', code)
  }

  function countryLabel(code) {
    return focusLabel('COUNTRIES', code)
  }

  function geoFocusLabel(code) {
    return focusLabel('GEO_FOCUS', code)
  }

  // attempts to find a label in all market caps and
  // returns the code if none is found
  function findLabel(code) {
    return (
      marketCapLabel(code) ||
      sectorLabel(code) ||
      subSectorLabel(code) ||
      countryLabel(code) ||
      geoFocusLabel(code) ||
      code
    )
  }

  function newFocusFromCategory(category) {
    return Object.keys(focuses.CATEGORY_VALUES[category]).map(f => ({
      category: category,
      categoryValue: f,
      id: f,
      isNew: true,
    }))
  }

  return {
    focuses,
    focusLabel,
    sectorLabel,
    subSectorLabel,
    marketCapLabel,
    findLabel,
    newFocusFromCategory,
  }
}

export default useFocuses
