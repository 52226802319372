import { isEmpty } from '@the-platform-group/formatters/checks'
import { useEffect, useState, useCallback } from 'react'

import {
  getCustomerSecurities,
  createCustomerSecurity,
  updateCustomerSecurity,
} from 'services/nobo'

function useCustomerSecurities({ customer }) {
  const [customerSecurities, setCustomerSecurities] = useState([])
  const [mode, setMode] = useState('view')
  const [status, setStatus] = useState('')
  const [customerSecurity, setCustomerSecurity] = useState(null)
  const [formState, setFormState] = useState({})
  const [formErrors, setFormErrors] = useState([])

  const loadCustomerSecurities = useCallback(async () => {
    setStatus('loading')
    if (isEmpty(customer)) {
      return
    }

    try {
      const response = await getCustomerSecurities(customer.id)
      setStatus('')
      setCustomerSecurities(response)
      setMode('view')
    } catch (error) {
      setCustomerSecurities(() => {
        throw error
      })
    }
  }, [customer])

  useEffect(() => {
    loadCustomerSecurities()
  }, [loadCustomerSecurities])

  function createSecurity() {
    setMode('create')
  }

  function editSecurity(customerSecurity) {
    setMode('edit')
    setCustomerSecurity(customerSecurity)
  }

  function viewSecurities() {
    setMode('view')
    setCustomerSecurity(null)
    clearErrors()
  }

  function clearErrors() {
    setFormErrors([])
  }

  const registerForm = useCallback(state => {
    setFormState(state)
  }, [])

  function generateFormState() {
    const { security, cusip, user, notifyUsers } = customerSecurity
    return {
      security: {
        label: `${security.name} (${security.tickerExchange})`,
        value: security.id,
      },
      cusip,
      user: user.id,
      notifyUsers: notifyUsers.map(user => user.commonId),
    }
  }

  function save() {
    async function saveNewCustomerSecurity() {
      try {
        await createCustomerSecurity({
          customerId: customer.id,
          securityId: formState.security.value,
          ...formState,
        })
        setMode('view')
        loadCustomerSecurities()
      } catch (error) {
        if (error.response.status === 422) {
          setFormErrors(() => error.response.data.errors)
          setStatus('')
          return
        }

        // Bubble unexpected errors
        setFormErrors(() => {
          throw error
        })
      }
    }

    async function saveExistingCustomerSecurity() {
      try {
        await updateCustomerSecurity({
          id: customerSecurity.id,
          customerId: customer.id,
          securityId: formState.security.value,
          ...formState,
        })
        setMode('view')
        loadCustomerSecurities()
      } catch (error) {
        if (error.response.status === 422) {
          setFormErrors(() => error.response.data.errors)
          setStatus('')
          return
        }

        // Bubble unexpected errors
        setFormErrors(() => {
          throw error
        })
      }
    }

    setStatus('saving')
    if (mode === 'create') {
      saveNewCustomerSecurity()
    } else if (mode === 'edit') {
      saveExistingCustomerSecurity()
    }
  }

  return {
    customerSecurities,
    customerSecurity,
    mode,
    status,
    createSecurity,
    editSecurity,
    viewSecurities,
    clearErrors,
    formErrors,
    registerForm,
    generateFormState,
    save,
  }
}

export default useCustomerSecurities
