import React from 'react'
import PropTypes from 'prop-types'

import TYPES, { PARENT_ORGANIZATION_TYPE } from 'constants/organizationTypes'
import { Select, TextField } from '@the-platform-group/component-library'
import { Grid } from '@the-platform-group/component-library/Layout'
import { H2 } from 'components/Typography'

const OrganizationFields = ({ gridProps, isParentForm, organization, onOrganizationChange }) => {
  const { name, description, website, phone, type } = organization
  return (
    <Grid item {...gridProps}>
      <H2>Organization</H2>
      <TextField
        value={name || ''}
        label="Name"
        name="name"
        onChange={onOrganizationChange}
        sx={{ my: 1 }}
        fullWidth
      />
      {isParentForm ? (
        <TextField
          value={PARENT_ORGANIZATION_TYPE[type]}
          label="Type"
          name="type"
          sx={{ my: 1 }}
          fullWidth
          InputProps={{ readOnly: true }}
        />
      ) : (
        <>
          <Select
            fullWidth
            id="type"
            label="Type"
            value={type || ''}
            name="type"
            onChange={onOrganizationChange}
            options={Object.entries(TYPES).map(([value, label]) => ({ value, label }))}
          />
          <TextField
            value={description || ''}
            label="Description"
            name="description"
            onChange={onOrganizationChange}
            multiline
            sx={{ my: 1 }}
            fullWidth
          />
          <TextField
            value={website || ''}
            label="Website"
            name="website"
            onChange={onOrganizationChange}
            sx={{ my: 1 }}
            fullWidth
          />
          <TextField
            value={phone || ''}
            label="Phone"
            name="phone"
            onChange={onOrganizationChange}
            sx={{ my: 1 }}
            fullWidth
          />
        </>
      )}
    </Grid>
  )
}

OrganizationFields.propTypes = {
  organization: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    website: PropTypes.string,
    phone: PropTypes.string,
    type: PropTypes.string,
    focuses: PropTypes.array,
  }).isRequired,
  onOrganizationChange: PropTypes.func.isRequired,
}

export default OrganizationFields
