import React from 'react'

import {
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from '@the-platform-group/component-library'

const MergeInfoBox = () => {
  return (
    <Card>
      <CardContent sx={{ bgcolor: 'secondary.light' }}>
        <Typography variant="subtitle2" gutterBottom>
          Merge Profile (Checklist):
        </Typography>
        <Typography variant="body2">
          Merge duplicate profile affiliations into target profile along with all of its associated
          activities, tasks and details. Ensure these things when merging a profile:
        </Typography>
        <List dense sx={{ mb: -2 }}>
          <ListItem>
            <ListItemText variant="body2">
              1. Only platform group limited profiles will be merged into Factset profiles
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText variant="body2">
              2. Make sure in target profile, data (email, phone number, org etc) should be
              up-to-dated. If it’s a Factset profile then raise an issue tracker
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText variant="body2">
              3. When merging an organization; make sure to merge or move the people of the
              organization before merging organization
            </ListItemText>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  )
}

export default MergeInfoBox
