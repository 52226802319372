import { Grid } from '@the-platform-group/component-library/Layout'
import { AsyncSelect } from '@the-platform-group/component-library/Select'

import { FormPaper } from 'components/Paper'
import { H2 } from 'components/Typography'

import OrganizationFields from './OrganizationFields'
import AddressForm from './AddressForm'
import useChildrenOrganization from '../hooks/useChildrenOrganization'
import ChildrenOrganizationsTable from './ChildrenOrganizationsTable'

/**
 * Component that renders the Parent Organization form
 * @param {object} props - props
 * @param {boolean} props.isDirty - whether the form has been modified by the user or not
 * @param {object} props.address - address state
 * @param {object} props.organization - organization state
 * @param {Function} props.onAddressChange - function called when address is changed
 * @param {Function} props.onAddressSelect - function called when address is selected
 * @param {Function} props.onOrganizationChange - function called when organization is changed
 * @returns {React}  Parent Organization form
 */
const ParentOrganizationForm = ({
  address,
  organization,
  onAddressChange,
  onAddressSelect,
  onOrganizationChange,
}) => {
  const {
    newChildOrganizations,
    newChildOrganizationsMapping,
    handleAddOrganization,
    handleChildOrganizationsSearch,
    handleDeleteOrganization,
    loadChildrenOrganizationsByName,
    loadChildrenOrganizationsByUuid,
  } = useChildrenOrganization()

  const isChildOrganization = option => !!newChildOrganizationsMapping[option.id]

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <FormPaper>
          <OrganizationFields
            isParentForm
            organization={organization}
            onOrganizationChange={onOrganizationChange}
          />
        </FormPaper>
      </Grid>
      <Grid item xs={6}>
        <AddressForm
          address={address}
          onAddressChange={onAddressChange}
          onAddressSelect={onAddressSelect}
        />
      </Grid>
      <Grid item xs={12}>
        <FormPaper>
          <H2>Child Organizations</H2>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <AsyncSelect
                isMulti
                getOptionDisabled={isChildOrganization}
                renderTags={() => null}
                id="childrenOrgSearch"
                label="Organizations"
                value={newChildOrganizations}
                onChange={handleChildOrganizationsSearch}
                optionsLoader={loadChildrenOrganizationsByName}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={option => option.name}
                hideOverflow
                disableCloseOnSelect
              />
            </Grid>
            <Grid item xs={6}>
              <AsyncSelect
                variant="outlined"
                getOptionDisabled={isChildOrganization}
                value=""
                id="childrenOrgSearchUuid"
                label="Search by UUID"
                onChange={handleAddOrganization}
                optionsLoader={loadChildrenOrganizationsByUuid}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={option => option.name}
                fullWidth
              />
            </Grid>
          </Grid>
          <ChildrenOrganizationsTable onOrganizationDelete={handleDeleteOrganization} />
        </FormPaper>
      </Grid>
    </Grid>
  )
}

export default ParentOrganizationForm
