import React from 'react'
import { AsyncSelectWithHelpIcon } from 'pages/Factset/Components/Common'
import { debouncedLoadCustomers } from 'utils/optionsLoaders'

const CustomerSelect = props => {
  const { optionsLoaderParams, includeCustomerType, activeCustomerOnly, ...rest } = props

  let params = { ...optionsLoaderParams }
  // Only add `type` param if we want to include specific types of customers (e.g. trial, customer, etc.)
  // Otherwise simply omit `type` param to include all customer types
  if (includeCustomerType !== 'all') {
    params.type = includeCustomerType || 'customer' // default type to "customer"
  }
  // If we only want active customers, include `state` param
  if (activeCustomerOnly) {
    params.state = 'active'
  }

  return (
    <AsyncSelectWithHelpIcon
      id="customersFilter"
      placeholder="Search customers"
      variant="outlined"
      optionsLoader={term => debouncedLoadCustomers({ term, ...params })}
      {...rest}
    />
  )
}

export default CustomerSelect
