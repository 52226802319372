import { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

class ScrollToTop extends Component {
  componentDidUpdate({ location: prevLocation }) {
    const { location } = this.props
    if (location !== prevLocation) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { children } = this.props
    return children
  }
}

ScrollToTop.propTypes = {
  location: PropTypes.shape({}).isRequired,
  children: PropTypes.object.isRequired,
}

export default withRouter(ScrollToTop)
