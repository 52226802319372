import React from 'react'
import { Box, Grid, Typography } from '@the-platform-group/component-library'
import {
  DataGrid,
  DataGridActionCell,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from '@the-platform-group/component-library/DataGrid'
import { Sync as RetryIcon } from '@the-platform-group/component-library/Icons'
import { Loader } from '@the-platform-group/component-library/Loader'

import { DATETIME } from '@the-platform-group/component-library/DataGrid/constant'
import { formatDate } from '@the-platform-group/formatters/dateFormat'

import useFactsetAPIRequest from '../hooks/useFactsetAPIRequest'

const DATE_FORMAT = 'MMM DD, YYYY, HH:mm:ss'

function FactsetUserApiRequestsTable({ rows, factsetUserId, factsetLocationId }) {
  const {
    mutate: retryFactsetRequestMutate,
    isLoading: isRetryingFactsetRequest,
    selectedRequestId,
    setSelectedRequestId,
  } = useFactsetAPIRequest({
    factsetUserId,
    factsetLocationId,
  })

  const getDetailPanelContent = React.useCallback(({ row }) => {
    return (
      <Grid
        container
        sx={{ px: 2, py: 4, background: theme => theme.palette.primary.defaultContrast }}
        rowSpacing={2}
        flexDirection="column"
      >
        <Grid item>
          <Typography variant="subtitle2">ID: {row.id}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            Created At: {formatDate(row.createdAt, DATE_FORMAT)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            Started At: {formatDate(row.startedAt, DATE_FORMAT)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">
            Completed At: {formatDate(row.completedAt, DATE_FORMAT)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">Params:</Typography>
          <pre>{JSON.stringify(row.params, null, 2)}</pre>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">Request Body:</Typography>
          <pre>{JSON.stringify(row.requestBody, null, 2)}</pre>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2">Response Body:</Typography>
          <pre>{JSON.stringify(row.responseBody, null, 2)}</pre>
        </Grid>
      </Grid>
    )
  }, [])

  return (
    <Box my={4}>
      <Typography variant="h4">API Requests</Typography>
      <DataGrid
        name="factsetUserApiRequests"
        columns={[
          {
            ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
            maxWidth: 70,
            renderHeader: () => (
              <Typography
                ml={1}
                color="primary.text"
                sx={{ fontWeight: 'fontWeightBold' }}
                variant="body2"
              >
                Details
              </Typography>
            ),
          },
          { field: 'requestId', headerName: 'Req. ID' },
          { field: 'responseCode', headerName: 'Res. Code', maxWidth: 100, align: 'center' },
          { field: 'status', maxWidth: 110 },
          { field: 'type' },
          {
            field: 'exceptionUrl',
            headerName: 'Exception',
            maxWidth: 100,
            valueGetter: ({ value }) =>
              value
                ? {
                    to: { pathname: value },
                    text: 'link',
                    linkProps: { target: '__blank', title: value },
                  }
                : null,
            type: 'link',
          },
          {
            field: 'time',
            minWidth: 120,
            valueGetter: ({ row: { createdAt, startedAt, completedAt } }) =>
              completedAt || startedAt || createdAt,
            type: DATETIME,
            dateFormat: DATE_FORMAT,
          },
          {
            field: 'retryId',
            headerName: 'Retried?',
            maxWidth: 85,
            valueGetter: ({ value }) => (value ? 'Yes' : 'No'),
          },
          { field: 'attempt', maxWidth: 85, align: 'center' },
          { field: 'scheduledAt', type: DATETIME, dateFormat: DATE_FORMAT },
          {
            field: 'actions',
            type: 'actions',
            getActions: ({ row }) => {
              // we want to disable all retry buttons when one of the requests is being retried
              // this should prevent user from retrying multiple requests back-to-back, which may
              // potentially cause data conflicts
              const isRetryDisabled = !row.retryable || isRetryingFactsetRequest

              return [
                selectedRequestId === row.id && isRetryingFactsetRequest ? (
                  <Loader type="circular" />
                ) : (
                  <DataGridActionCell
                    disabled={isRetryDisabled}
                    icon={<RetryIcon color={isRetryDisabled ? 'secondary' : 'success'} />}
                    title="Retry this request, it may take some time for the request to complete"
                    onClick={() => {
                      setSelectedRequestId(row.id)
                      retryFactsetRequestMutate(row.id)
                    }}
                  />
                ),
              ]
            },
          },
        ]}
        rows={rows}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={() => 300}
        pagination={false}
        initialStateOverride={{
          sorting: {
            sortModel: [{ field: 'time', sort: 'desc' }],
          },
        }}
      />
    </Box>
  )
}

export default FactsetUserApiRequestsTable
