import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@the-platform-group/component-library'
import LocationTypeahead from '@the-platform-group/component-library/LocationTypeahead'
import { Button } from '@the-platform-group/component-library/Button'
import { isEmpty } from '@the-platform-group/formatters/checks'
import OrganizationSelect from 'components/OrganizationSelect'
import Checkbox from './components/Checkbox'
import DatePicker from './components/datePicker'
import { Focus, FocusChips } from 'shared/Focus'
import JobFunctionsSelect from 'components/Select/JobFunctionsSelect'
import useFocuses from 'hooks/useFocuses'

const JobFormComponent = ({ focuses, job, organization, jobFormHandlers }) => {
  const {
    handleFocusCopy,
    handleJobChange,
    handleJobCheckChange,
    handleOrgChange,
    handleAddressChange,
    handleAddressSelect,
    handleFocusChange,
    handleFocusDelete,
  } = jobFormHandlers

  const { focuses: focusDefinitions } = useFocuses()

  return (
    <Fragment>
      <h2>{`${job.id ? 'Edit' : 'Create'} Job`}</h2>
      <Checkbox
        label="Active"
        name="active"
        value={job.active || false}
        checked={job.active}
        onChange={handleJobCheckChange}
      />
      <Checkbox
        label="Retail Discretionary"
        name="retailDiscretionaryFlag"
        value={job.retailDiscretionaryFlag || false}
        checked={job.retailDiscretionaryFlag}
        onChange={handleJobCheckChange}
      />
      <TextField
        label="Job Title"
        InputLabelProps={{ htmlFor: 'title' }}
        id="title"
        name="title"
        value={job.title || ''}
        onChange={handleJobChange}
        sx={{
          mt: 1,
          mb: 0.5,
        }}
        fullWidth
      />
      <JobFunctionsSelect value={job.jobFunctions} onChange={handleJobChange} />
      <TextField
        label="Job Email"
        name="email"
        value={job.email || ''}
        onChange={handleJobChange}
        sx={{
          mt: 1,
          mb: 0.5,
        }}
        fullWidth
      />
      <TextField
        label="Job Phone"
        name="phone"
        value={job.phone || ''}
        onChange={handleJobChange}
        sx={{
          mt: 1,
          mb: 0.5,
        }}
        fullWidth
      />
      <DatePicker
        label="Start Date"
        name="startDate"
        value={job.startDate || ''}
        onChange={handleJobChange}
      />
      <DatePicker
        label="End Date"
        name="endDate"
        value={job.endDate || ''}
        onChange={handleJobChange}
      />
      <OrganizationSelect
        label="Organization"
        organization={organization}
        onChange={handleOrgChange}
      />
      <h3>Focus</h3>
      <Button onClick={handleFocusCopy} disabled={isEmpty(organization)}>
        Copy From Org
      </Button>
      <Focus
        focuses={focuses}
        onFocusChange={handleFocusChange}
        categories={focusDefinitions.CATEGORIES}
      />
      <FocusChips focuses={focuses} handleFocusDelete={handleFocusDelete} />
      <h3>Address</h3>
      <Checkbox
        label="Use Organization Address"
        name="delegateAddress"
        value={job.delegateAddress || false}
        checked={job.delegateAddress || false}
        onChange={handleJobCheckChange}
      />
      {!job.delegateAddress && (
        <Fragment>
          <LocationTypeahead
            freeSolo
            name="street"
            onAddressChange={handleAddressChange}
            onAddressSelect={handleAddressSelect}
            value={job.address.street || ''}
          />
          <TextField
            label="Unit, Floor, Etc."
            name="street2"
            value={job.address.street2 || ''}
            onChange={handleAddressChange}
            sx={{
              mt: 1,
              mb: 0.5,
            }}
            fullWidth
          />
          <TextField
            label="City"
            name="city"
            value={job.address.city || ''}
            onChange={handleAddressChange}
            sx={{
              mt: 1,
              mb: 0.5,
            }}
            fullWidth
          />
          <TextField
            label="State"
            name="state"
            value={job.address.state || ''}
            onChange={handleAddressChange}
            sx={{
              mt: 1,
              mb: 0.5,
            }}
            fullWidth
          />
          <TextField
            label="Country"
            name="country"
            value={job.address.country || ''}
            onChange={handleAddressChange}
            sx={{
              mt: 1,
              mb: 0.5,
            }}
            fullWidth
          />
          <TextField
            label="Postal Code"
            name="postalCode"
            value={job.address.postalCode || ''}
            onChange={handleAddressChange}
            sx={{
              mt: 1,
              mb: 0.5,
            }}
            fullWidth
          />
        </Fragment>
      )}
    </Fragment>
  )
}

JobFormComponent.propTypes = {
  focuses: PropTypes.array.isRequired,
  job: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  jobFormHandlers: PropTypes.object.isRequired,
}

export default JobFormComponent
