import { useState, useEffect } from 'react'
import AVAILABLE_FEATURES from 'constants/features'

function useFeatures({ currentFeatures, onToggleChange }) {
  const [checked, setChecked] = useState([])

  useEffect(() => {
    const turnedOnFeatures = currentFeatures.filter(feat => AVAILABLE_FEATURES.includes(feat))
    setChecked(turnedOnFeatures)
  }, [currentFeatures])

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
    onToggleChange(newChecked)
  }

  return { checked, handleToggle }
}

export default useFeatures
