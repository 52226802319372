// TODO: Add to component library
import React from 'react'
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from '@the-platform-group/component-library/Icons'
import { IconButton } from '@the-platform-group/component-library'

export default function CustomDetailPanelToggle({ isExpanded, count }) {
  const hasDetail = count > 0

  return hasDetail ? (
    <>
      {count}
      <IconButton size="small" tabIndex={-1} aria-label={isExpanded ? 'Close' : 'Open'}>
        {isExpanded ? (
          <ArrowDropDownIcon fontSize="inherit" />
        ) : (
          <ArrowDropUpIcon fontSize="inherit" />
        )}
      </IconButton>
    </>
  ) : null
}
