import { createContext, useContext, useReducer } from 'react'

const initialContext = {
  customer: null,
  security: null,
  user: null,
  datasource: null,
}

export const ReportingGrantOptionsActionTypes = {
  SET_CUSTOMER: 'SET_CUSTOMER',
  SET_SECURITY: 'SET_SECURITY',
  SET_USER: 'SET_USER',
  SET_DATASOURCE: 'SET_DATASOURCE',
}

const ReportingGrantOptionsContext = createContext(initialContext)

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case ReportingGrantOptionsActionTypes.SET_CUSTOMER:
      // Reset user, security and datasource when customer changes
      return {
        ...state,
        customer: payload,
        user: null,
        security: null,
        datasource: null,
      }
    case ReportingGrantOptionsActionTypes.SET_SECURITY:
      return {
        ...state,
        security: payload,
      }
    case ReportingGrantOptionsActionTypes.SET_USER:
      return {
        ...state,
        user: payload,
      }
    case ReportingGrantOptionsActionTypes.SET_DATASOURCE:
      return {
        ...state,
        datasource: payload,
      }
    default:
      return state
  }
}

export const ReportingGrantOptionsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialContext)

  return (
    <ReportingGrantOptionsContext.Provider value={{ state, dispatch }}>
      {children}
    </ReportingGrantOptionsContext.Provider>
  )
}

/**
 * Context hook for customer, user, security and datasource selection in Reporting Grants page
 */
export const useReportingGrantOptionsContext = () => {
  const { state, dispatch } = useContext(ReportingGrantOptionsContext)
  if (state === undefined || dispatch === undefined) {
    throw new Error('useReportingGrants must be used within a ReportingGrantOptionsContextProvider')
  }

  const handleChangeCustomer = customer => {
    dispatch({ type: ReportingGrantOptionsActionTypes.SET_CUSTOMER, payload: customer })
  }

  const handleChangeSecurity = security => {
    dispatch({ type: ReportingGrantOptionsActionTypes.SET_SECURITY, payload: security })
  }

  const handleChangeUser = user => {
    dispatch({ type: ReportingGrantOptionsActionTypes.SET_USER, payload: user })
  }

  const handleChangeDatasource = datasource => {
    dispatch({ type: ReportingGrantOptionsActionTypes.SET_DATASOURCE, payload: datasource })
  }

  return {
    ...state,
    handleChangeCustomer,
    handleChangeSecurity,
    handleChangeUser,
    handleChangeDatasource,
  }
}
