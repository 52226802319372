import React from 'react'
import { withRouter } from 'react-router-dom'

import NotFound from 'pages/NotFound'

class NotFoundErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { notFoundRoute: false }
  }

  static getDerivedStateFromError(error) {
    if (error.response && error.response.status === 404) {
      return { notFoundRoute: true }
    }

    throw error
  }

  componentDidUpdate(nextProps) {
    if (this.state.notFoundRoute && this.props.location !== nextProps.location) {
      this.setState({ notFoundRoute: false })
    }
  }

  render() {
    if (this.state.notFoundRoute) {
      return <NotFound />
    }

    return this.props.children
  }
}

export default withRouter(NotFoundErrorBoundary)
