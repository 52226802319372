import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient, useMutation } from 'react-query'

import { linkFactsetLocation, unlinkFactsetLocation } from 'services/factsetProvisioning'
import { useNotification } from 'components/Notification'
import { useConfirmationDialog } from 'components/ConfirmationDialog'

function useLinkFactsetLocationForm({ isModalOpen, customer, location }) {
  const { control, handleSubmit, watch, formState, setValue, reset: resetForm } = useForm({
    mode: 'onChange',
    defaultValues: {
      location: '',
      customer: '',
    },
  })

  const selectedLocation = watch('location')

  useEffect(() => {
    if (!isModalOpen) {
      // when modal closes, reset form values
      resetForm()
    } else {
      // when modal opens, properly set location & customer values if available
      if (location) {
        setValue(
          'location',
          { value: location.id, label: location.name, ...location },
          { shouldValidate: true },
        )
      }
      if (customer) {
        setValue(
          'customer',
          { value: customer.id, label: customer.name, ...customer },
          { shouldValidate: true },
        )
      }
    }
  }, [customer, isModalOpen, location, resetForm, setValue])

  return {
    control,
    handleSubmit,
    formState,
    selectedLocation,
  }
}

function useLinkFactsetLocation({ selectedLocation, handleSubmit, closeModal }) {
  const { createConfirmationDialog } = useConfirmationDialog()
  const { createNotification } = useNotification()
  const queryClient = useQueryClient()

  const { isLoading: isSaving, mutate: linkFactsetLocationMutate } = useMutation({
    mutationFn: formData => {
      const { location, customer } = formData
      return linkFactsetLocation(location.value, customer.value)
    },
    onSuccess: data => {
      createNotification({
        variant: 'success',
        message: 'Factset location successfully linked',
      })
      queryClient.invalidateQueries({ queryKey: ['factsetLocations'] })
      queryClient.invalidateQueries({ queryKey: ['factsetLocation', data.id] })
      closeModal()
    },
    onError: error => {
      const errorData = error.response.data?.errors?.at(0)
      const errorMessage = errorData ? `${errorData.field} ${errorData.message}` : error.message

      createNotification({
        variant: 'error',
        message: `Error linking factset location: ${errorMessage}`,
      })
    },
  })

  const onSubmit = () => {
    if (selectedLocation.customer?.id) {
      // selected factset location already has a customer, show a confirmation before linking
      createConfirmationDialog({
        body: `The selected Factset location is already linked to a customer "${selectedLocation.customer.name}", continue linking will unlink the existing customer, proceed?`,
        onConfirm: handleSubmit(linkFactsetLocationMutate),
      })
    } else {
      handleSubmit(linkFactsetLocationMutate)()
    }
  }

  return { isSaving, onSubmit }
}

function useUnlinkFactsetLocation() {
  const { createConfirmationDialog } = useConfirmationDialog()
  const { createNotification } = useNotification()
  const queryClient = useQueryClient()

  const unlinkFactsetLocationMutation = useMutation({
    mutationFn: unlinkFactsetLocation,
    onSuccess: data => {
      queryClient.invalidateQueries({ queryKey: ['factsetLocations'] })
      queryClient.invalidateQueries({ queryKey: ['factsetLocation', data.id] })
      createNotification({ message: 'Location successfully unlinked', variant: 'success' })
    },
    onError: error => {
      createNotification({ message: error.message, variant: 'error' })
    },
  })

  const onUnlink = locationId => () => {
    createConfirmationDialog({
      body: `Are you sure you want to unlink this Factset location?`,
      onConfirm: () => unlinkFactsetLocationMutation.mutate(locationId),
    })
  }

  return { onUnlink, isUnlinking: unlinkFactsetLocationMutation.isLoading }
}

export { useLinkFactsetLocationForm, useLinkFactsetLocation, useUnlinkFactsetLocation }
