// TODO: Move this into the component library
import React, { useState, useRef, useEffect } from 'react'
import Modal from 'components/Modal'
import { useModal } from 'hooks/useModal'
import { CustomLink as Link, Typography } from '@the-platform-group/component-library'
import { formatWithLineBreaks, formatPreviewString } from 'utils/format'
import Highlight from './HighLight'

export function TruncatedText({
  title,
  content,
  contentPreview = '',
  maxLines = 3,
  viewMoreProps = {},
  ...rest
}) {
  const { open, handleOpen, handleClose } = useModal()
  const [isOverflowing, setIsOverflowing] = useState(false)
  const heightRef = useRef(null)

  useEffect(() => {
    const el = heightRef.current
    setIsOverflowing(el.scrollHeight > el.clientHeight)
  }, [])

  return (
    <>
      <Typography
        variant="body2"
        component="div"
        sx={{
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: maxLines,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        }}
        ref={heightRef}
        {...rest}
      >
        <Highlight
          text={formatWithLineBreaks(
            !!contentPreview ? formatPreviewString(contentPreview, content) : content,
          )}
        />
      </Typography>
      {(isOverflowing || !!contentPreview) && (
        <>
          <Link
            sx={{
              fontWeight: 'fontWeightBold',
              cursor: 'pointer',
            }}
            color="textPrimary"
            underline="always"
            onClick={handleOpen}
            to="#"
            {...viewMoreProps}
          >
            View More
          </Link>
          <Modal title={title} open={!!open} handleClose={handleClose} maxWidth="md">
            <Typography variant="body2">
              <Highlight text={formatWithLineBreaks(content)} />
            </Typography>
          </Modal>
        </>
      )}
    </>
  )
}
