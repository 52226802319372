import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'

import Spinner from 'components/Spinner'
import { MARKET_CAP_ERROR_FIELD } from 'constants/marketCaps'

function MarketCapErrorState({ removeError }) {
  const queryClient = useQueryClient()

  // clear any cached requests since they could hold outdated market cap
  // information, then rerender the page to trigger the same requests to go
  // out to issue the same requests with the latest information.
  //
  // With the query cache busted, query hooks will refetch the same information
  // which for market caps means new definitions will be retrieved, as well as
  // other requests returning market cap information will have the codes for the
  // new definitions.
  useEffect(() => {
    queryClient.clear()
    removeError()
  }, [queryClient, removeError])

  // this component is only used as a transient state until we get back the new market caps
  return <Spinner size={75} fullPage />
}

class MarketCapsErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    if (error.response?.data?.errors?.some(errors => errors.field === MARKET_CAP_ERROR_FIELD)) {
      return { hasError: true }
    }

    throw error
  }

  render() {
    // the logic for this error boundary has been moved to this component
    // to make use of hooks
    if (this.state.hasError) {
      return <MarketCapErrorState removeError={() => this.setState({ hasError: false })} />
    }

    return this.props.children
  }
}

export default MarketCapsErrorBoundary
