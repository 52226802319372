import React, { useState, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import {
  Modal,
  ModalTitle,
  ModalContent,
  ModalActions,
} from '@the-platform-group/component-library/Modal'
import { Grid } from '@the-platform-group/component-library/Layout'
import { RawButton as Button, TextField, Typography } from '@the-platform-group/component-library'
import { Button as LoadingButton } from '@the-platform-group/component-library/Button'
import OrganizationSelect from 'components/Select/OrganizationSelect'
import NumberFormat from 'react-number-format'

import { WARNINGS } from 'constants/surveillancePortal'
import { useSurveillanceContext } from '../hooks/SurveillanceContext'
import { saveReport } from 'services/reports'
import { useNotification } from 'components/Notification'
import { DATE_FORMAT } from '@the-platform-group/formatters/constants'
import { formatDate } from '@the-platform-group/formatters/dateFormat'

const SharesValueInput = props => <TextField {...props} fullWidth />

function NewHolderModal({ securityPrice, holdersMap, latestReport }) {
  const queryClient = useQueryClient()
  const { createNotification } = useNotification()

  const { isNewHolderModalOpen, reportIds, handleNewHolderModal } = useSurveillanceContext()

  const [formValues, setFormValues] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!isNewHolderModalOpen) {
      setFormValues(false)
    }
  }, [isNewHolderModalOpen, setFormValues])

  const closeNewHolderModal = () => {
    handleNewHolderModal(false)
  }

  const { mutateAsync: addNewHolderToLatestReport } = useMutation({
    mutationFn: () => {
      const params = {
        id: latestReport.id,
        upsert: [
          {
            holder_id: formValues.organization.id,
            holder_type: 'organization',
            shares: formValues.shares,
            date: formatDate(latestReport.date, DATE_FORMAT),
          },
        ],
      }

      return saveReport([params])
    },
    onSuccess: data => {
      // update the latest report with the updated version
      queryClient.setQueryData(['reports', { reportIds }], oldData => {
        const updatedLatestReport = data[0].data

        return {
          ...oldData,
          data: [updatedLatestReport, ...oldData.data.slice(1)],
        }
      })
      // reset holdings queries
      queryClient.resetQueries(['holdings'], {
        refetchActive: true,
        refetchInactive: false,
      })

      createNotification({ message: 'Holder added successfully', variant: 'success' })

      // reset the form values and close the modal
      setFormValues({})
      setIsLoading(false)
      closeNewHolderModal()
    },
    onError: () => {
      createNotification({ message: 'Error adding holder', variant: 'error' })
      setIsLoading(false)
    },
  })

  return (
    <Modal open={isNewHolderModalOpen} onClose={closeNewHolderModal}>
      <ModalTitle title="Add new holder" />
      <ModalContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OrganizationSelect
              label="Search organizations"
              value={formValues.organization}
              getOptionLabel={({ name }) => name}
              transformFn={data => data.filter(({ id }) => !holdersMap.has(id))}
              onChange={organization =>
                setFormValues(prevState => ({
                  ...prevState,
                  organization,
                }))
              }
            />
          </Grid>
          {formValues.organization && (
            <Grid item xs={12}>
              <NumberFormat
                placeholder="Current shares value"
                allowNegative={false}
                onValueChange={({ value }) =>
                  setFormValues(prevState => ({
                    ...prevState,
                    shares: value,
                    price: securityPrice,
                  }))
                }
                thousandSeparator
                decimalScale={0}
                customInput={SharesValueInput}
                label="Current shares"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body2">{WARNINGS.newReport}</Typography>
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        <LoadingButton
          isLoading={isLoading}
          disabled={!formValues.organization || !formValues.shares || !formValues.price}
          onClick={() => {
            setIsLoading(true)
            addNewHolderToLatestReport()
          }}
        >
          add holder
        </LoadingButton>
        <Button onClick={closeNewHolderModal}>cancel</Button>
      </ModalActions>
    </Modal>
  )
}

export default NewHolderModal
