import ORGANIZATION_TYPES, { PARENT_ORGANIZATION_TYPE } from 'constants/organizationTypes'
import PropTypes from 'prop-types'
import PlatformTable from 'shared/PlatformTable'

const OrganizationComponent = ({ history }) => {
  const ORGANIZATION_COLUMNS = [
    { field: 'name' },
    {
      field: 'type',
      valueFormatter: ({ value }) => ORGANIZATION_TYPES[value] || PARENT_ORGANIZATION_TYPE[value],
    },
    { field: 'city' },
    { field: 'phone' },
    { field: 'approvedAt' },
    { field: 'actions' },
  ]

  const transformRowData = (organizations, deletedOrgIds) =>
    organizations.reduce((res, organization) => {
      if (!deletedOrgIds.includes(organization.id)) {
        const {
          id,
          name,
          type,
          phone,
          address: { city, country },
          approvedAt,
        } = organization
        res.push({
          id,
          name,
          type,
          city: city ? [city, country].join(', ') : '',
          phone,
          approvedAt,
        })
      }
      return res
    }, [])

  return (
    <PlatformTable
      columns={ORGANIZATION_COLUMNS}
      history={history}
      toEntityLabel={selectedOrg => selectedOrg.name}
      toRowData={transformRowData}
      type="ORGANIZATION"
    />
  )
}

OrganizationComponent.propTypes = {
  history: PropTypes.object.isRequired,
}

export default OrganizationComponent
