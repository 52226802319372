import React, { useState, useEffect } from 'react'
import { Grid, Typography, Divider, Select } from '@the-platform-group/component-library'
import { DataGrid } from '@the-platform-group/component-library/DataGrid'
import { Button } from '@the-platform-group/component-library/Button'
import { Modal, ModalContent } from '@the-platform-group/component-library/Modal'
import focuses from 'constants/focus'
import { getColumnValues, getRowValues } from './SecurityTableValues'

const sectorOptions = Object.keys(focuses.SECTORS).map(sector => ({
  label: focuses.SECTORS[sector],
  value: sector,
}))

const subSectorOptions = sector => {
  const subSectorsForSector = Object.keys(focuses.SUBSECTOR_GROUPS).find(group => group === sector)
  const subSectors = focuses.SUBSECTOR_GROUPS[subSectorsForSector]
  return Object.keys(subSectors).map(subSector => ({
    label: subSectors[subSector],
    value: subSector,
  }))
}

function SecuritiesTable({
  securities,
  isLoading,
  pagination,
  fetchNext,
  saveOverride,
  removeOverride,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [sectorSelected, setSectorSelected] = useState('')
  const [subSectorSelected, setSubSectorSelected] = useState('')
  const [selectedSecurity, setSelectedSecurity] = useState(null)
  const [error, setError] = useState('')
  const [saveInProgress, setSaveInProgress] = useState(false)
  const [deleteInProgress, setDeleteInProgress] = useState(false)
  const hasNextPage = !!pagination.next

  function closeModal() {
    setSelectedSecurity(null)
    setSectorSelected('')
    setSubSectorSelected('')
    setIsOpen(false)
    setError('')
  }

  const openModal = clickedSecurity => {
    setSelectedSecurity(clickedSecurity)
    setIsOpen(true)
  }

  useEffect(() => {
    setSubSectorSelected('')
  }, [sectorSelected])

  async function submitOverride() {
    try {
      if (!sectorSelected || !subSectorSelected) {
        setError('Both a sector and subsector must be specified.')
        return
      }

      setSaveInProgress(true)
      await saveOverride({ sectorSelected, subSectorSelected, selectedSecurity })
      closeModal()
      setSaveInProgress(false)
    } catch (serviceError) {
      setSectorSelected(() => {
        throw error
      })
    }
  }

  return (
    <>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <DataGrid
            columns={getColumnValues(openModal)}
            commonColumnProps={{ headerAlign: 'left' }}
            hideFooter
            loading={isLoading}
            name="ds1Securities"
            rowCount={pagination.totalCount}
            rows={getRowValues(securities)}
            onPageChange={fetchNext}
          />
        </Grid>

        {hasNextPage && (
          <Grid item>
            <Button isLoading={isLoading} onClick={fetchNext}>
              Load More
            </Button>
          </Grid>
        )}
      </Grid>

      <Modal open={isOpen} onClose={closeModal}>
        <ModalContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h4">Overrides</Typography>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item container>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Sector</Typography>
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  value={sectorSelected}
                  options={sectorOptions}
                  onChange={e => setSectorSelected(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Sub Sector</Typography>
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  value={subSectorSelected}
                  options={sectorSelected ? subSectorOptions(sectorSelected) : []}
                  onChange={e => setSubSectorSelected(e.target.value)}
                  disabled={!sectorSelected}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography color="error">{error}</Typography>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item container justifyContent="space-between" spacing={2}>
              <Grid item container xs={6}>
                {selectedSecurity &&
                  selectedSecurity.issuer.sectorOverride &&
                  selectedSecurity.issuer.sectorOverride.sectorCode && (
                    <Button
                      variant="contained"
                      color="error"
                      isLoading={deleteInProgress}
                      disabled={saveInProgress}
                      onClick={async () => {
                        setDeleteInProgress(true)
                        await removeOverride({ issuerLegacyId: selectedSecurity.issuer.legacyId })
                        closeModal()
                        setDeleteInProgress(false)
                      }}
                    >
                      DELETE
                    </Button>
                  )}
              </Grid>
              <Grid item container justifyContent="flex-end" xs={6}>
                <Grid item>
                  <Button variant="text" onClick={closeModal}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    isLoading={saveInProgress}
                    disabled={deleteInProgress}
                    onClick={submitOverride}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ModalContent>
      </Modal>
    </>
  )
}

export default SecuritiesTable
