import { useQuery } from 'react-query'
import { getNotifications } from 'services/notifications'

const useNotifications = () => {
  const { data: notifications, isLoading: notificationsLoading } = useQuery(
    ['getNotifications'],
    () => getNotifications(),
  )

  return {
    notifications,
    notificationsLoading,
  }
}

export { useNotifications }
