import { useQuery } from 'react-query'
import { getNotificationSubscriptions } from 'services/notificationSubscriptions'

const useNotificationSubscriptions = userId => {
  const { data: userSubscriptions, isLoading: subscriptionsLoading } = useQuery(
    ['getNotificationSubscriptions', userId],
    () => getNotificationSubscriptions({ userId: userId }),
    { enabled: !!userId },
  )

  return {
    userSubscriptions,
    subscriptionsLoading,
  }
}

export { useNotificationSubscriptions }
