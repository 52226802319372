import { useState } from 'react'

import {
  saveCustomer,
  archiveCustomer,
  updateCustomer,
  unarchiveCustomer,
} from 'services/customers'
import { useNotification } from 'components/Notification'

import NOBOCustomerSecurityModal from './components/NOBOCustomerSecurityModal/NOBOCustomerSecurityModal'
import CustomerFeatureToggleModal from './components/CustomerFeatureToggleModal'
import CustomerModal from './components/CustomerModal'

function useCustomerModals() {
  const [isCreate, setIsCreate] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isFeatureToggleOpen, setIsFeatureToggleOpen] = useState(false)
  const [isNOBOCustomerSecuritiesOpen, setIsNOBOSecurityOpen] = useState(false)
  const [custEditing, setCustEditing] = useState({ name: '', includeInResearch: true })
  const { createNotification } = useNotification()

  const handleCreateOpen = () => {
    setIsCreate(true)
  }

  const handleCustomerFeatureToggle = customer => {
    setCustEditing(customer || {})
    setIsFeatureToggleOpen(true)
  }

  function handleClose() {
    setIsCreate(false)
    setIsEdit(false)
    setIsFeatureToggleOpen(false)
    setIsNOBOSecurityOpen(false)
    setCustEditing({ includeInResearch: true })
  }

  const handleCustomerEdit = customer => {
    setCustEditing(customer || {})
    setIsEdit(!!customer)
  }

  const handleCustomerSecurity = customer => {
    setCustEditing(customer || {})
    setIsNOBOSecurityOpen(true)
  }

  const defaultHandleCustomerSave = async customer => {
    try {
      const customerParams = {
        ...customer,
        defaultSecurityId: customer.defaultSecurityId || customer.defaultSecurity.id,
      }
      const newCustomer = await saveCustomer(customerParams)
      handleClose()
      createNotification({ message: `The customer was ${customer.id ? 'updated' : 'created'}` })
      return newCustomer
    } catch (error) {
      if (error.response.status === 422) {
        createNotification({ message: 'The customer was already created.' })
        return
      }
    }
    return customer
  }

  const defaultHandleCustomerArchive = async customer => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Archiving a customer will also lock out its users. Are you sure?')) {
      try {
        const archivedCustomer = await archiveCustomer(customer)

        createNotification({ message: `The customer ${customer.name} was archived successfully` })
        return archivedCustomer
      } catch (error) {
        throw error
      }
    }
    return customer
  }

  const defaultHandleCustomerUnarchive = async customer => {
    // eslint-disable-next-line no-alert
    if (window.confirm(`Are you sure you want to restore ${customer.name}?`)) {
      try {
        const unarchivedCustomer = await unarchiveCustomer(customer)

        createNotification({ message: `The customer ${customer.name} was unarchived successfully` })
        return unarchivedCustomer
      } catch (error) {
        throw error
      }
    }
    return customer
  }

  const defaultHandleFeatureToggleUpdate = async (customer, features) => {
    if (window.confirm(`Are you sure you want to update features for ${customer.name}?`)) {
      try {
        const customerParams = {
          ...customer,
          features,
          defaultSecurityId: customer.defaultSecurityId || customer.defaultSecurity.id,
        }
        const updatedCustomer = await updateCustomer(customerParams)

        handleClose()
        createNotification({ message: `Successfully updated feature toggles for ${customer.name}` })
        return updatedCustomer
      } catch (error) {
        throw error
      }
    }
    return customer
  }

  const getCustomerModals = (onFeatureToggleSave, onCustomerSave) => {
    return (
      <>
        <CustomerModal
          open={isCreate || isEdit}
          onSave={onCustomerSave}
          onClose={handleClose}
          editableCustomer={custEditing}
        />
        <CustomerFeatureToggleModal
          open={isFeatureToggleOpen}
          onSave={onFeatureToggleSave}
          onClose={handleClose}
          customer={custEditing}
        />
        <NOBOCustomerSecurityModal
          open={isNOBOCustomerSecuritiesOpen}
          onSave={handleCustomerSecurity}
          onClose={handleClose}
          customer={custEditing.id && custEditing} // needs undefined if no valid customer
        />
      </>
    )
  }

  return {
    getCustomerModals,
    handleCustomerEdit,
    handleCustomerFeatureToggle,
    handleCustomerSecurity,
    handleCreateOpen,
    defaultHandleCustomerSave,
    defaultHandleCustomerArchive,
    defaultHandleCustomerUnarchive,
    defaultHandleFeatureToggleUpdate,
  }
}

export default useCustomerModals
