import React, { useState } from 'react'
import { saveUser, inviteUser } from 'services/users'
import { useNotification } from 'components/Notification'

import { Modal, ModalTitle } from '@the-platform-group/component-library/Modal'
import UserForm from './'

const userFormDefaults = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  profileEditingEnabled: true,
  sendInvitation: false,
  timeZone: 'America/Toronto',
  role: 'user',
}

const userFormStateDefault = {
  isEdited: false,
  editingId: null,
  addingUser: false,
}

function useUserForm(onUserAdd) {
  const [isLoading, setIsLoading] = useState(false)
  const [values, setValues] = useState(userFormDefaults)
  const [userFormState, setUserFormState] = useState(userFormStateDefault)
  const { createNotification } = useNotification()

  const handleInputChange = e => {
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    setValues({ ...values, [e.target.name]: value })
    setUserFormState({ ...userFormState, isEdited: true })
  }

  async function handleSave(customerId, onClose) {
    setIsLoading(true)
    const { password, confirmPassword, email } = values
    const { editingId } = userFormState
    if (password === confirmPassword && email && customerId) {
      const userParams = { ...values, customerId, id: editingId }
      try {
        setUserFormState({ ...userFormState, isEdited: false })

        const user = await saveUser(userParams)
        onUserAdd(user, customerId)

        onClose()
      } catch (e) {
        if (e.response && e.response.data) {
          displayErrors(e.response.data)
          return
        }

        setValues(() => {
          throw e
        })
      } finally {
        setIsLoading(false)
      }
    }
  }

  function displayErrors(data) {
    if (data.errors) {
      const messages = data.errors.map(e => `${e.field} ${e.message}`).join(',')
      createNotification({ message: messages, variant: 'error' })
    }
  }

  const handleUserEdit = (user = {}) => {
    if (user.id) {
      setValues({ ...user })
      setUserFormState(prev => ({ ...prev, editingId: user.id, isEdited: false }))
    } else {
      setValues({ ...userFormDefaults })
      setUserFormState(prev => ({ ...prev, addingUser: !prev.addingUser }))
    }
  }

  const handleFormClose = () => {
    setUserFormState({ ...userFormStateDefault })
  }

  async function handleInvite() {
    setIsLoading(true)
    try {
      await inviteUser(values.id)
      createNotification({ message: 'Invitation sent successfully!' })
    } catch (e) {
      if (e.response && e.response.data) {
        displayErrors(e.response.data)
        return
      }
    } finally {
      setIsLoading(false)
    }
  }

  const getUserFormModal = customerId => {
    const { editingId, addingUser, isEdited } = userFormState
    const title = editingId ? `Edit User (id: ${values.id})` : 'Create User'
    return (
      <Modal open={!!editingId || addingUser} onClose={handleFormClose}>
        <ModalTitle title={title} />
        <UserForm
          isLoading={isLoading}
          isFormEdited={isEdited}
          user={values}
          customerId={customerId}
          onClose={handleFormClose}
          onChange={handleInputChange}
          onSave={handleSave}
          onInvite={handleInvite}
        />
      </Modal>
    )
  }

  return {
    isLoading,
    values,
    userFormState,
    handleInputChange,
    handleSave,
    handleUserEdit,
    handleFormClose,
    handleInvite,
    getUserFormModal,
  }
}

export default useUserForm
