import React from 'react'
import { Prompt } from 'react-router-dom'

const DEFAULT_MESSAGE = 'Are you sure you want to leave the page? Any unsaved content will be lost.'

const withNavigationConfirmation = Component => props => (
  <>
    <Prompt when={props.prompt} message={props.message || DEFAULT_MESSAGE} />
    <Component {...props} />
  </>
)

export default withNavigationConfirmation
