import { Link } from 'react-router-dom'
// icons
import { styled } from '@the-platform-group/component-library'
import { Delete as DeleteIcon, Edit as EditIcon } from '@the-platform-group/component-library/Icons'
import { DataGridActionCell } from '@the-platform-group/component-library/DataGrid'
import { DATE } from '@the-platform-group/component-library/DataGrid/constant'

const StyledDataGridActionCell = styled(DataGridActionCell)(({ ignored }) => ({
  '&': { color: ignored ? 'red' : 'black' },
}))

const getTableActions = (id, { ignored, handleContactIgnore }) => [
  <StyledDataGridActionCell
    icon={<EditIcon />}
    title="Edit"
    component={Link}
    to={`/imports/contacts/${id}/edit`}
  />,
  <StyledDataGridActionCell
    ignored={ignored.id === id}
    icon={<DeleteIcon />}
    title={ignored.id === id ? 'Are you sure?' : 'Ignore'}
    onClick={() => handleContactIgnore(id)}
  />,
]
export const getColumnValues = ignoredProps => {
  return [
    {
      field: 'irwinClient',
      width: 10,
    },
    ...['name', 'institution', 'job', 'email', 'mobile', 'phone'].map(v => ({ field: v })),
    { field: 'updated', type: DATE },
    {
      field: 'actions',
      type: 'actions',
      align: 'center',
      getActions: ({ id }) => {
        return getTableActions(id, ignoredProps)
      },
    },
  ]
}

export const getRowValues = contactImports =>
  contactImports.map(contactImport => {
    const { sourceUpdatedAt, contact, id, institution, customer } = contactImport
    const { email, phone } = contact
    return {
      id,
      irwinClient: customer.name,
      name: `${contact.firstName} ${contact.lastName}`,
      institution: institution.name,
      job: contact.job || contact.title,
      email,
      mobile: contact.mobilePhone,
      phone,
      updated: sourceUpdatedAt,
      customerId: customer.id,
    }
  })
