import { irwin } from 'config'
import { httpClient } from './httpClient'
import Jsona, { SwitchCaseJsonMapper } from 'jsona'
import { toCamel, toSnake } from 'change-object-case'

const deserializer = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper({ switchChar: '_' }),
})

function client() {
  return httpClient(irwin.baseUrl)
}

async function getCustomers(params = {}) {
  const response = await client().get('v2/customers', { params: toSnake(params) })

  return {
    data: deserializer.deserialize(response),
    meta: toCamel(response.meta),
    links: response.links,
  }
}

async function getCustomer(id) {
  const response = await client().get(`v2/customers/${id}`)

  return {
    data: deserializer.deserialize(response),
    meta: toCamel(response.meta),
    links: response.links,
  }
}

async function saveCustomer(customer) {
  const savedCustomer = customer.id
    ? await updateCustomer(customer)
    : await createCustomer(customer)

  return savedCustomer
}

async function createCustomer(customer) {
  const response = await client().post(
    'v2/customers',
    toSnake(customer, { recursive: true, arrayRecursive: true }),
  )
  return deserializer.deserialize(response)
}

async function updateCustomer(customer) {
  const response = await client().put(
    `v2/customers/${customer.id}`,
    toSnake(customer, { recursive: true, arrayRecursive: true }),
  )
  return deserializer.deserialize(response)
}

async function archiveCustomer(customer) {
  const response = await client().post(`v2/customers/${customer.id}/archive`)

  return deserializer.deserialize(response)
}

async function unarchiveCustomer(customer) {
  const response = await client().post(`v2/customers/${customer.id}/unarchive`)

  return deserializer.deserialize(response)
}

async function fetchOrCreateAuthorization(customerId) {
  return await client().put(`v2/customers/${customerId}/authorization`)
}

export {
  getCustomers,
  getCustomer,
  saveCustomer,
  archiveCustomer,
  unarchiveCustomer,
  updateCustomer,
  fetchOrCreateAuthorization,
}
