import usePlatformDelete from 'shared/PlatformTable/hooks/usePlatformDelete'
import useOrganizationForm from '../hooks/useOrganizationForm'
import { useParentOrganizationContext } from '../contexts/ParentOrganizationContext'
import useOrganizationApprove from '../hooks/useOrganizationApprove'
import Spinner from 'components/Spinner'
import ParentOrganizationForm from './ParentOrganizationForm'
import RegularOrganizationForm from './RegularOrganizationForm'
import AddressForm from './AddressForm'
import InternalNoteTextField from 'components/InternalNotes/InternalNoteTextField'
import { FormPaper } from 'components/Paper'
import { Button } from '@the-platform-group/component-library/Button'
import { Divider, Grid, Tooltip } from '@the-platform-group/component-library'
import PeopleTable from './PeopleTable'
import InternalNoteList from 'components/InternalNotes/InternalNoteList'
import INTERNAL_NOTE_TYPES from 'constants/internalNoteTypes'
import ApproveModal from 'components/ApproveModal/ApproveModal'
import OrganizationDelete from 'components/EntityDelete'
import withNavigationConfirmation from 'shared/WithNavigationConfirmation'
import { useOrganizationFormContext } from '../contexts/OrganizationFormContext'
import { useModal } from 'hooks/useModal'

const OrganizationFormContent = ({ id, history }) => {
  const {
    organization,
    isLoading,
    isSavingOrganization,
    handleOrganizationChange,
    handleAddressChange,
    handleSubmit,
    handleAddressSelect,
    handleCoverageChange,
    focuses,
    handleFocusChange,
    handleFocusDelete,
    internalNoteState,
  } = useOrganizationForm({ id, history })
  const { handleDelete: handleDeleteOrganization } = usePlatformDelete({
    history,
    toEntityLabel: org => org.name,
    type: 'ORGANIZATION',
  })
  const { isArchiveDisabled } = useParentOrganizationContext()
  const {
    state: { isDirty, isParentOrgForm },
  } = useOrganizationFormContext()

  const {
    warnings,
    approved,
    peopleInformation,
    handleApproveOrganization,
    isLoading: approvedIsLoading,
    modalOpen: approvedModalOpen,
    setModalOpen: setApprovedModalOpen,
  } = useOrganizationApprove({ ...organization, focuses: focuses, submit: handleSubmit })

  const {
    open: orgDeleteModalOpen,
    handleOpen: handleOrgDeleteModalOpen,
    handleClose: handleOrgDeleteModalClose,
  } = useModal()

  const { approveButtonEnabled } = peopleInformation
  const showApprovedButton = !isParentOrgForm && id && organization?.id
  if (isLoading) {
    return <Spinner size={75} />
  }

  return (
    <form>
      {isParentOrgForm ? (
        <ParentOrganizationForm
          organization={organization}
          onOrganizationChange={handleOrganizationChange}
          address={organization.address}
          onAddressSelect={handleAddressSelect}
          onAddressChange={handleAddressChange}
        />
      ) : (
        <>
          <RegularOrganizationForm
            organization={organization}
            onOrganizationChange={handleOrganizationChange}
            coverage={organization.coverage}
            onCoverageChange={handleCoverageChange}
            focuses={focuses}
            onFocusChange={handleFocusChange}
            onFocusDelete={handleFocusDelete}
          />
          <AddressForm
            address={organization.address}
            onAddressSelect={handleAddressSelect}
            onAddressChange={handleAddressChange}
          />
        </>
      )}
      <FormPaper>
        <InternalNoteTextField {...internalNoteState} />
      </FormPaper>

      <Grid container spacing={2} mb={2}>
        <Grid item>
          <Button
            color="primary"
            disabled={!isDirty}
            isLoading={isSavingOrganization}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            onClick={handleOrgDeleteModalOpen}
            disabled={!organization.id || isArchiveDisabled}
          >
            Archive
          </Button>
        </Grid>
        {showApprovedButton && (
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
        )}
        <Grid item>
          {showApprovedButton &&
            (approveButtonEnabled ? (
              <Button
                color="primary"
                disabled={!!approved}
                isLoading={approvedIsLoading}
                onClick={() => setApprovedModalOpen(true)}
              >
                Approve
              </Button>
            ) : (
              <Tooltip title="One or more person associated with this organization has not been approved. In order to approve the Organization, all associated people should be approved.">
                <span>
                  <Button color="primary" disabled={true}>
                    Approve
                  </Button>
                </span>
              </Tooltip>
            ))}
        </Grid>
        {approved && (
          <Grid item sx={{ my: 'auto' }}>
            Approved At {approved}
          </Grid>
        )}
      </Grid>
      {!isParentOrgForm && id && (
        <PeopleTable
          organizationId={id}
          people={organization.people || []}
          peopleInformation={peopleInformation}
        />
      )}
      {id && (
        <FormPaper>
          <InternalNoteList
            internalNotableId={organization.id}
            internalNotableType={INTERNAL_NOTE_TYPES.platformOrganization}
          />
        </FormPaper>
      )}

      <ApproveModal
        modalTitle="You are about to approve this organization. Once approved, this organization may be highlighted to users in their dashboard feed."
        warnings={warnings}
        handleModalSave={handleApproveOrganization}
        open={approvedModalOpen}
        onModalClose={() => {
          setApprovedModalOpen(false)
        }}
        isLoading={approvedIsLoading}
      />
      <OrganizationDelete
        entityType="ORGANIZATION"
        entityLabel={organization.name || ''}
        modalOpen={orgDeleteModalOpen}
        onArchive={() => handleDeleteOrganization(organization)}
        onModalClose={handleOrgDeleteModalClose}
      />
    </form>
  )
}

const OrganizationFormContentConfirmation = withNavigationConfirmation(OrganizationFormContent)

export default OrganizationFormContentConfirmation
