import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { FeatureFlagProvider } from 'contexts/FeatureFlagContext'

ReactDOM.render(
  <FeatureFlagProvider>
    <App />
  </FeatureFlagProvider>,
  document.getElementById('root'),
)
