import React from 'react'
import { Link } from 'react-router-dom'
import { Box, styled, Typography } from '@the-platform-group/component-library'

const StyledBox = styled(Box)(() => ({ display: 'flex', flexDirection: 'column' }))

const renderCustomerCell = params => {
  if (!params.value) {
    return <Link to={`users/${params.row.id}`}>Multiple</Link>
  }

  const { id, name } = params.value

  return (
    <StyledBox>
      <Link to={`accounts/customers/${id}`}>{name}</Link>
      <Typography variant="body2" component="span">
        {id}
      </Typography>
    </StyledBox>
  )
}

export const getColumnValues = () => {
  return [
    {
      field: 'fullName',
      headerName: 'Name',
      cellClassName: 'hoverCell',
      renderCell: ({ id, value }) => (
        <StyledBox>
          <Link to={'users/' + id}>{value}</Link>
          <Typography variant="body2" component="span">
            {id}
          </Typography>
        </StyledBox>
      ),
    },
    { field: 'email' },
    { field: 'role' },
    {
      field: 'customer',
      cellClassName: 'hoverCell',
      renderCell: renderCustomerCell,
    },
  ]
}

export const getRowValues = users => {
  return users.map(user => {
    const { id, firstName, lastName, email, role, customerAuthorizations } = user

    /*
     * Users with more than one authorization will not have a customer value as
     * it's not practical to list out every customer they are associated with in
     * this table.  Users can see the customer list in the detail page.
     */
    let customerValue = null
    if (customerAuthorizations.length === 1) {
      const { id, name } = customerAuthorizations[0].customer
      customerValue = { id, name }
    }

    return {
      id: id,
      fullName: [firstName, lastName].join(' '),
      email: email,
      role: role,
      customer: customerValue,
    }
  })
}
