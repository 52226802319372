import React, { useState } from 'react'

import {
  Card,
  CardContent,
  CardHeader,
  Alert,
  Grid,
  TextField,
  IconButton,
} from '@the-platform-group/component-library'
import { Search as SearchIcon } from '@the-platform-group/component-library/Icons'

import PeopleProfileContent from './PeopleProfileContent'
import OrganizationProfileContent from './OrganizationProfileContent'

import { ERROR, CARD_TITLE } from '../constants'

const ProfileCard = ({ type, mergeType }) => {
  const [profileId, setProfileId] = useState(undefined)
  const [fetchProfile, setFetchProfile] = useState(false)

  const onChangeProfileId = value => {
    setProfileId(value)
  }

  const handleFindProfile = () => {
    setFetchProfile(true)
  }

  const isError = false //this will come from query
  const title = CARD_TITLE[type]
  const errorText = ERROR[type]
  return (
    <Card>
      <CardHeader titleTypographyProps={{ variant: 'h6' }} title={title} />
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item container>
            <Grid item>
              <TextField
                placeholder="Enter the UUID"
                name="profile-id"
                value={profileId}
                variant="outlined"
                onChange={event => onChangeProfileId(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item>
              <IconButton aria-label="Find Profile" variant="outlined" onClick={handleFindProfile}>
                <SearchIcon />
              </IconButton>
            </Grid>
          </Grid>
          {isError && (
            <Grid item>
              <Alert severity="error">{errorText}</Alert>
            </Grid>
          )}
          <Grid item>
            {mergeType === 'people' ? <PeopleProfileContent /> : <OrganizationProfileContent />}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ProfileCard
