import React from 'react'
import { useController } from 'react-hook-form'

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Typography,
} from '@the-platform-group/component-library'

/**
 * @param {object} param0 - props
 * @param {string} param0.title - Radio group selector title
 * @param {control} param0.control - react hook form control
 * @param {string} param0.name - react hook form control name
 * @returns {Node} Radio group selector component to select managed/unmanaged type
 */
function TypeSelect({ title, control, name = 'type' }) {
  const { field } = useController({ control, name }) // field: { name, onChange, onBlur, ref, value, etc. }

  return (
    <FormControl fullWidth>
      <FormLabel id="type">{title}</FormLabel>
      <RadioGroup {...field}>
        <FormControlLabel
          value="unmanaged"
          control={<Radio />}
          label={<Typography variant="body2">Yes</Typography>}
        />
        <FormControlLabel
          value="managed"
          control={<Radio />}
          label={<Typography variant="body2">No</Typography>}
        />
      </RadioGroup>
    </FormControl>
  )
}

export default TypeSelect
