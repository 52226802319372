import Jsona, { SwitchCaseJsonMapper } from 'jsona'
import { irwin } from 'config'
import { toCamel } from 'change-object-case'

import { AUTH_TOKEN_KEY, USER_KEY, CUSTOMER_ID } from 'constants/localStorage'
import { httpClient } from './httpClient'

function client() {
  return httpClient(irwin.baseUrl)
}

const deserializer = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper({ switchChar: '_' }),
})

function loadAuthToken() {
  try {
    return localStorage.getItem(AUTH_TOKEN_KEY) || undefined
  } catch (err) {
    return undefined
  }
}

function loadUser() {
  try {
    const userString = localStorage.getItem(USER_KEY)
    return (userString && JSON.parse(userString)) || undefined
  } catch (err) {
    return undefined
  }
}

async function login(email, password) {
  const requestBody = {
    auth: { email, password },
  }

  try {
    const response = await client().post(`/user_token/`, requestBody)

    if (response.role === 'user') {
      throw new Error('Forbidden')
    }

    saveLoginInfo(response)
    return response.user
  } catch (err) {
    console.log(err.message) //eslint-disable-line
    if (err.message === 'Forbidden') {
      throw new Error("You don't have permission to access this area!")
    }
    if (err.message === 'Network Error') {
      throw new Error('Network Error: Please check your connection')
    } else {
      throw new Error('Incorrect email or password.')
    }
  }
}

function saveAuthToken(token) {
  localStorage.setItem(AUTH_TOKEN_KEY, token)
}
function saveLoginInfo({ jwt, user }) {
  localStorage.setItem(USER_KEY, JSON.stringify(user))
  saveAuthToken(jwt)
}

async function fetchCurrentUser() {
  const url = '/v2/users/current'
  const token = loadAuthToken() || ''

  const options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await client().get(url, options)
  return toCamel(deserializer.deserialize(response), { recursive: true, arrayRecursive: true })
}

async function terminateSession() {
  localStorage.removeItem(CUSTOMER_ID)
  await client().delete('v2/session')
}

export { loadAuthToken, loadUser, login, fetchCurrentUser, saveAuthToken, terminateSession }
