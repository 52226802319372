import React from 'react'
import { Grid } from '@the-platform-group/component-library/Layout'
import { Typography } from '@the-platform-group/component-library'

import UserFilters from './components/UserFilters'
import UsersTable from './components/Table/UsersTable'
import { ListPaper } from 'components/Paper'
import Spinner from 'components/Spinner'

import { useUsers } from './hooks/useUsers'

const UsersComponent = () => {
  const {
    users,
    customers,
    pagination,
    isLoading,
    handlePageChange,
    handleRowsChange,
    handleUserNameSearch,
    handleCustomerSearch,
  } = useUsers()

  return (
    <React.Fragment>
      <Typography variant="h3">Users</Typography>
      <Grid container direction="column" spacing={2}>
        <Grid item justifyContent="center" alignItems="center" container xs>
          <UserFilters
            handleUserNameSearch={handleUserNameSearch}
            handleCustomerSearch={handleCustomerSearch}
            customers={customers}
          />
        </Grid>

        {isLoading ? (
          <Spinner />
        ) : (
          <Grid item>
            <ListPaper>
              <UsersTable
                isLoading={isLoading}
                users={users.data}
                pagination={pagination}
                handlePageChange={handlePageChange}
                handleRowsChange={handleRowsChange}
              />
            </ListPaper>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  )
}

export default UsersComponent
