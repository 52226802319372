import React from 'react'

import { Loader } from '@the-platform-group/component-library/Loader'
import { Box } from '@the-platform-group/component-library'

const LoaderComponent = props => (
  <Loader
    sx={{
      position: 'relative',
      top: 'calc(50% - 23px)',
      left: 'calc(50% - 23px)',
    }}
    {...props}
  />
)

function Spinner({ fullPage, ...props }) {
  return fullPage ? (
    <Box sx={{ height: '100vh', width: '100%', position: 'relative', top: '50px' }}>
      <LoaderComponent {...props} />
    </Box>
  ) : (
    <LoaderComponent {...props} />
  )
}

export default Spinner
