import React from 'react'

import { IconButton, Typography } from '@the-platform-group/component-library'
import { Edit as EditIcon } from '@the-platform-group/component-library/Icons'
import {
  Table,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
} from '@the-platform-group/component-library/Table'

function Content({ customerSecurities, editSecurity }) {
  if (!customerSecurities.length) {
    return <Typography variant="h6">No securities are available for this customer.</Typography>
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeaderCell>Customer</TableHeaderCell>
          <TableHeaderCell>Security</TableHeaderCell>
          <TableHeaderCell>CUSIP</TableHeaderCell>
          <TableHeaderCell>User</TableHeaderCell>
          <TableHeaderCell>Notification For</TableHeaderCell>
          <TableHeaderCell>Edit</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {customerSecurities.map(customerSecurity => (
          <TableRow key={customerSecurity.id}>
            <TableCell>{customerSecurity.customer.name}</TableCell>
            <TableCell>{customerSecurity.security.name}</TableCell>
            <TableCell>{customerSecurity.cusip}</TableCell>
            <TableCell>{`${customerSecurity.user.firstName} ${customerSecurity.user.lastName}`}</TableCell>
            <TableCell>
              {customerSecurity.notifyUsers
                .map(user => `${user.firstName} ${user.lastName}`)
                .join(', ')}
            </TableCell>
            <TableCell>
              <IconButton
                aria-label="editSecurity"
                onClick={() => editSecurity(customerSecurity)}
                size="large"
              >
                <EditIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default Content
