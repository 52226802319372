import React from 'react'
import { DataGrid } from '@the-platform-group/component-library/DataGrid'
import { CustomLink as Link } from '@the-platform-group/component-library'
import { FILE_PATH_TABLE_NAME } from '../utils/constants'

const FilePathTable = ({ row, onFileDownload, isFetching }) => {
  const { filePaths } = row

  const getRowValues = _ =>
    filePaths.map((path, idx) => {
      return {
        id: path,
        count: `${idx + 1}`,
        downloadLink: path,
      }
    })

  const getColumnValues = _ => [
    { field: 'count', maxWidth: 80 },
    {
      field: 'downloadLink',
      renderCell: ({ value }) => {
        const fileName = value.split('/')

        return (
          <Link
            color="primary"
            sx={{ cursor: 'pointer' }}
            onClick={_ => onFileDownload(row, value)}
            to="#"
          >
            {fileName.at(-1)}
          </Link>
        )
      },
    },
  ]

  return (
    <DataGrid
      name={FILE_PATH_TABLE_NAME}
      rows={getRowValues()}
      columns={getColumnValues()}
      disableColumnSelector={true}
      hideFooterPagination={true}
    />
  )
}

export default FilePathTable
