import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { getInternalNotesList, updateInternalNote } from 'services/internalNotes'

export function useInternalNoteState() {
  const [noteState, setNoteState] = useState('')

  const handleInternalNoteContentChange = content => {
    setNoteState(content)
  }
  const resetInternalNoteState = () => {
    setNoteState('')
  }

  return {
    content: noteState,
    resetInternalNoteState,
    handleChange: handleInternalNoteContentChange,
  }
}

export function useInternalNoteList(internalNotableId, internalNotableType) {
  //fetch list for current person/org using react query
  const query = useQuery(
    ['internalNoteItems', internalNotableId, internalNotableType],
    () => getInternalNotesList({ internalNotableId, internalNotableType }),
    {
      refetchOnWindowFocus: false,
      useErrorBoundary: true,
      enabled: Boolean(internalNotableId),
    },
  )

  // mutation for updating an internal note and refreshing the internal note list/table
  const updateInternalNoteMutation = useMutation(
    internalNoteData =>
      updateInternalNote(internalNoteData.internalNoteId, internalNoteData.content),
    {
      onSuccess: () => {
        // When the mutation is successful refresh the note table
        query.refetch()
      },
    },
  )

  return {
    internalNotesLoading: query.isLoading,
    internalNoteList: query.data,
    updateInternalNoteTableItem: (internalNoteId, content) => {
      updateInternalNoteMutation.mutate({ internalNoteId, content })
    },
  }
}
