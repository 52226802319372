import { irwin } from 'config'
import Jsona, { SwitchCaseJsonMapper } from 'jsona'
import { httpClient } from './httpClient'
import axios from 'axios'

const switchCaseOptions = {
  switchChar: '_',
}
const deserializer = new Jsona({
  jsonPropertiesMapper: new SwitchCaseJsonMapper(switchCaseOptions),
})

function client() {
  return httpClient(irwin.baseUrl)
}

let searchRequestAbortController

async function searchSecurities(term = '') {
  const params = { term, include: 'listings_active' }

  if (searchRequestAbortController) {
    searchRequestAbortController.abort('Operation cancelled by the user.')
  }

  try {
    searchRequestAbortController = new AbortController()
    const response = await client().get('v2/securities/search', {
      params,
      signal: searchRequestAbortController.signal,
    })
    return deserializer.deserialize(response)
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request cancelled', error.message)
      return []
    }

    throw error // propagate error if not a cancelled request
  }
}

async function fetchSecurities(ids = [], params) {
  const response = await client().get(`v2/securities`, { params: { ids, ...params } })
  return deserializer.deserialize(response)
}

async function fetchSecurityById(id, params) {
  const response = await client().get(`v2/securities/${id}`, { params: { ...params } })
  return deserializer.deserialize(response)
}

async function fetchFactsetSecurities(ids = [], nextUrl = '') {
  const params = ids.map(id => `ids[]=${id}`).join('&')
  const url = nextUrl ? nextUrl : `v2/ds1/securities?${params}`
  const response = await client().get(url)
  return {
    data: deserializer.deserialize(response),
    next: response.links?.next,
    totalCount: response.meta['item_count'],
  }
}

async function saveOverride({
  sectorCode,
  subsectorCode,
  ficsSectorCode,
  ficsSubSectorCode,
  issuer,
  existing,
}) {
  const payload = {
    factset_entity_id: issuer.legacyId,
    organization_id: issuer.organizationId,
    ds1_sector_code: ficsSectorCode,
    ds1_subsector_code: ficsSubSectorCode,
    pics_sector_code: sectorCode,
    pics_subsector_code: subsectorCode,
  }

  if (!existing) {
    const response = await client().post('v2/ds1/sector_overrides', payload)
    return deserializer.deserialize(response)
  }

  const response = await client().patch(`v2/ds1/sector_overrides/${issuer.legacyId}`, payload)
  return deserializer.deserialize(response)
}

async function deleteOverride({ issuerLegacyId }) {
  return await client().delete(`v2/ds1/sector_overrides/${issuerLegacyId}`)
}

async function search(params) {
  const pagination = params.page_size !== undefined && params.page_offset !== undefined

  if (searchRequestAbortController) {
    searchRequestAbortController.abort('Operation cancelled by the user.')
  }

  try {
    searchRequestAbortController = new AbortController()
    const response = await client().get('/v2/securities/search', {
      params,
      signal: searchRequestAbortController.signal,
    })

    return {
      data: deserializer.deserialize(response),
      ...(pagination && {
        links: response.links,
        count: response.meta.total,
      }),
    }
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log('Request cancelled', error.message)
      return {}
    }

    throw error
  }
}

export {
  search,
  searchSecurities,
  fetchSecurities,
  fetchSecurityById,
  fetchFactsetSecurities,
  saveOverride,
  deleteOverride,
}
