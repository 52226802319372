import { useMutation, useQueryClient } from 'react-query'
import { Switch } from '@the-platform-group/component-library'
import { useNotification } from 'components/Notification'

import { createNotificationSubscription } from 'services/notificationSubscriptions'

const CreateSubscriptionSwitch = ({ notificationId, userId, customerId, configuration }) => {
  const queryClient = useQueryClient()
  const { createNotification } = useNotification()

  const createSubscriptionMutation = useMutation(
    async ({ notificationId, userId, customerId, configuration }) => {
      const params = {
        notificationId: notificationId,
        userId: userId,
        customerId: customerId,
        configuration: configuration || {},
      }
      await createNotificationSubscription(params)
    },
    {
      onSuccess: () => {
        createNotification({
          message: 'Successfully created notification.',
          variant: 'success',
        })
        queryClient.invalidateQueries({ queryKey: ['getNotificationSubscriptions', userId] })
      },
      onError: () => {
        createNotification({
          message: 'There was an error creating the notification.',
          variant: 'error',
        })
      },
    },
  )

  return (
    <Switch
      edge="end"
      color="primary"
      checked={false}
      onChange={() =>
        createSubscriptionMutation.mutate({ notificationId, userId, customerId, configuration })
      }
      inputProps={{ 'aria-labelledby': `${notificationId}-switch` }}
    />
  )
}

export default CreateSubscriptionSwitch
