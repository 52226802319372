import React from 'react'
import { Grid, Typography } from '@the-platform-group/component-library'

function FactsetTableTitle({ title, isSubTitle }) {
  return isSubTitle ? (
    <Grid item xs={6}>
      <Typography variant="h5" component="h2" gutterBottom>
        {title}
      </Typography>
    </Grid>
  ) : (
    <Grid item xs={12}>
      <Typography variant="h4" component="h1" gutterBottom>
        {title}
      </Typography>
    </Grid>
  )
}

export default FactsetTableTitle
